import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

let scene, camera, renderer, controls;
let dice = [];
const particleSize = 1;

function initialize3DSceneForDice() {
    return new Promise((resolve, reject) => {
        const canvas = document.getElementById('canvas3d');
        const container = document.getElementById("diceCanvasContainer");

        scene = new THREE.Scene();

        camera = new THREE.PerspectiveCamera(75, container.clientWidth / container.clientHeight, 0.1, 1000);
        camera.position.set(0, 75, 100);
        camera.lookAt(0, 0, 0);

        renderer = new THREE.WebGLRenderer({ canvas: canvas, antialias: true });
        renderer.setSize(container.clientWidth, container.clientHeight);
        container.appendChild(renderer.domElement);

        const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
        scene.add(ambientLight);
        const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
        directionalLight.position.set(5, 10, 5);
        scene.add(directionalLight);

        const planeLength = 100;
        const halfPlaneLength = planeLength / 2;

        const groundGeometry = new THREE.PlaneGeometry(planeLength, planeLength);
        const groundMaterial = new THREE.MeshStandardMaterial({
            color: 0x228B22,
            roughness: 0.9,
            metalness: 0.0,
            side: THREE.DoubleSide
        });
        const ground = new THREE.Mesh(groundGeometry, groundMaterial);
        ground.rotation.x = -Math.PI / 2;
        ground.position.y = 0;
        scene.add(ground);

        const rimThickness = 0.5;
        const rimHeight = 0.2;
        const woodMaterial = new THREE.MeshPhongMaterial({ color: 0x8B4513 });

        const rimFront = new THREE.Mesh(new THREE.BoxGeometry(planeLength, rimHeight, rimThickness), woodMaterial);
        rimFront.position.set(0, rimHeight / 2, -halfPlaneLength);
        scene.add(rimFront);

        const rimBack = new THREE.Mesh(new THREE.BoxGeometry(planeLength, rimHeight, rimThickness), woodMaterial);
        rimBack.position.set(0, rimHeight / 2, halfPlaneLength);
        scene.add(rimBack);

        const rimLeft = new THREE.Mesh(new THREE.BoxGeometry(rimThickness, rimHeight, planeLength), woodMaterial);
        rimLeft.position.set(-halfPlaneLength, rimHeight / 2, 0);
        scene.add(rimLeft);

        const rimRight = new THREE.Mesh(new THREE.BoxGeometry(rimThickness, rimHeight, planeLength), woodMaterial);
        rimRight.position.set(halfPlaneLength, rimHeight / 2, 0);
        scene.add(rimRight);

        controls = new OrbitControls(camera, renderer.domElement);
        controls.enableDamping = true;
        controls.dampingFactor = 0.25;
        controls.screenSpacePanning = true;
        controls.minDistance = 1;
        controls.maxDistance = 200;
        controls.maxPolarAngle = Math.PI;

        animate();
        window.addEventListener('resize', () => resizeCanvasToContainer(container));
    });
}

function initializeDice(count) {
    if (!scene) {
        console.error('Scene is not initialized.');
        return;
    }
    for (let die of dice) {
        scene.remove(die);
    }
    dice = [];

    const loader = new THREE.TextureLoader();
    const materials = [
        new THREE.MeshLambertMaterial({ map: loader.load('textures/dice3.png') }),
        new THREE.MeshLambertMaterial({ map: loader.load('textures/dice4.png') }),
        new THREE.MeshLambertMaterial({ map: loader.load('textures/dice1.png') }),
        new THREE.MeshLambertMaterial({ map: loader.load('textures/dice6.png') }),
        new THREE.MeshLambertMaterial({ map: loader.load('textures/dice5.png') }),
        new THREE.MeshLambertMaterial({ map: loader.load('textures/dice2.png') }),
    ];

    const gridSize = Math.ceil(Math.sqrt(count));
    const spacing = 1.5;

    for (let i = 0; i < count; i++) {
        const die = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 1), materials);
        dice.push(die);
        scene.add(die);
    }

    console.log(`Initialized ${count} dice in Three.js scene.`);
}

function resizeCanvasToContainer(container) {
    renderer.setSize(container.clientWidth, container.clientHeight);
    camera.aspect = container.clientWidth / container.clientHeight;
    camera.updateProjectionMatrix();
}

function animate() {
    requestAnimationFrame(animate);

    if (controls) controls.update();

    renderer.render(scene, camera);
}

function updateDice(positions, quaternions) {
    if (positions.length !== dice.length || quaternions.length !== dice.length) {
        console.warn('Mismatch in dice count between C# and JavaScript.');
    }

    for (let i = 0; i < Math.min(dice.length, positions.length, quaternions.length); i++) {
        const die = dice[i];
        const pos = positions[i];
        const quat = quaternions[i];

        if (pos) {
            die.position.set(pos.x, pos.y, pos.z);
        }
        if (quat) {
            die.quaternion.set(quat.x, quat.y, quat.z, quat.w);
        }
    }
}

function hideDice() {
    for (let die of dice) {
        die.visible = false;
    }
}

function showDice() {
    for (let die of dice) {
        die.visible = true;
    }
}

export { initialize3DSceneForDice, initializeDice, updateDice };