const elementSpectra = [
    {
        name: "aluminium",
        lines: [
            [390.068, 0.05],
            [393.2, 0.006666666666666667],
            [394.4, 0.5],
            [396.152, 1.0],
            [399.586, 0.012222222222222223],
            [422.68100000000004, 0.03222222222222222],
            [458.582, 0.016666666666666666],
            [458.81899999999996, 0.012222222222222223],
            [466.68, 0.06111111111111111],
            [489.87600000000003, 0.012222222222222223],
            [490.27700000000004, 0.012222222222222223],
            [528.021, 0.016666666666666666],
            [510.75200000000007, 0.0077777777777777776],
            [528.3770000000001, 0.03222222222222222],
            [528.585, 0.016666666666666666],
            [531.232, 0.012222222222222223],
            [531.607, 0.024444444444444446],
            [537.184, 0.016666666666666666],
            [555.706, 0.02],
            [555.795, 0.012222222222222223],
            [559.323, 0.05],
            [585.362, 0.012222222222222223],
            [597.194, 0.024444444444444446],
            [600.176, 0.03222222222222222],
            [600.188, 0.024444444444444446],
            [600.642, 0.05],
            [606.111, 0.016666666666666666],
            [606.8430000000001, 0.03222222222222222],
            [606.853, 0.012222222222222223],
            [607.323, 0.05],
            [618.1569999999999, 0.012222222222222223],
            [618.168, 0.016666666666666666],
            [618.228, 0.03222222222222222],
            [618.245, 0.024444444444444446],
            [618.342, 0.05],
            [620.152, 0.05],
            [620.17, 0.04],
            [622.618, 0.03222222222222222],
            [623.178, 0.04],
            [624.336, 0.05],
            [633.574, 0.05],
            [669.6020000000001, 0.04],
            [669.867, 0.025555555555555557],
            [708.397, 0.006666666666666667],
            [708.464, 0.0077777777777777776],
        ],
    },
    {
        name: "americium",
        lines: [
            [402.025, 0.01],
            [403.581, 0.01],
            [403.637, 0.05],
            [408.929, 0.5],
            [408.932, 0.01],
            [414.096, 0.01],
            [418.812, 0.1],
            [426.555, 0.1],
            [428.92600000000004, 0.5],
            [430.965, 0.02],
            [432.457, 0.2],
            [444.13599999999997, 0.2],
            [450.945, 0.5],
            [457.559, 0.5],
            [459.331, 0.1],
            [464.912, 0.01],
            [465.34499999999997, 0.01],
            [466.279, 0.5],
            [468.16499999999996, 0.2],
            [469.96999999999997, 0.2],
            [470.68, 0.1],
            [487.22200000000004, 0.2],
            [499.079, 0.02],
            [500.021, 0.01],
            [502.096, 0.1],
            [521.5989999999999, 0.02],
            [540.262, 0.1],
            [542.47, 0.1],
            [558.421, 0.1],
            [559.813, 0.1],
            [605.464, 1.0],
            [640.511, 0.1],
            [654.4159999999999, 0.05],
            [695.558, 0.05],
        ],
    },
    {
        name: "arsenic",
        lines: [
            [419.00820000000004, 0.8411764705882353],
            [419.73999999999995, 0.7235294117647059],
            [424.2982, 0.7235294117647059],
            [431.5657, 0.5882352941176471],
            [432.3867, 0.5882352941176471],
            [433.66400000000004, 0.5882352941176471],
            [435.21450000000004, 0.5882352941176471],
            [435.28639999999996, 0.5],
            [437.117, 0.4411764705882353],
            [442.7106, 0.7235294117647059],
            [443.1562, 0.7235294117647059],
            [445.8469, 0.8411764705882353],
            [446.10749999999996, 0.4],
            [446.6348, 0.8411764705882353],
            [447.446, 0.5882352941176471],
            [449.42299999999994, 0.9411764705882353],
            [450.7659, 1.0],
            [453.974, 0.7235294117647059],
            [454.3483, 0.8411764705882353],
            [460.24269999999996, 0.7235294117647059],
            [462.9787, 0.4],
            [470.7586, 0.4],
            [473.067, 0.4],
            [488.85569999999996, 0.4],
            [506.89799999999997, 0.11764705882352941],
            [510.558, 0.4],
            [510.755, 0.5882352941176471],
            [512.134, 0.11764705882352941],
            [514.163, 0.11764705882352941],
            [523.138, 0.5],
            [533.1229999999999, 0.5882352941176471],
            [540.813, 0.11764705882352941],
            [545.132, 0.1588235294117647],
            [549.7727, 0.4],
            [555.809, 0.5],
            [565.132, 0.5],
            [611.007, 0.5],
            [617.027, 0.5882352941176471],
            [651.174, 0.35294117647058826],
        ],
    },
    {
        name: "bismuth",
        lines: [
            [407.90999999999997, 0.06666666666666667],
            [409.71999999999997, 0.016666666666666666],
            [412.15299999999996, 0.23333333333333334],
            [412.186, 0.23333333333333334],
            [425.93999999999994, 0.125],
            [427.2, 0.041666666666666664],
            [430.16999999999996, 0.11666666666666667],
            [433.98, 0.02],
            [434.05, 0.041666666666666664],
            [437.93999999999994, 0.02],
            [447.68, 0.041666666666666664],
            [470.53000000000003, 0.1],
            [472.25200000000007, 1.0],
            [473.03000000000003, 0.05],
            [474.96999999999997, 0.03333333333333333],
            [490.82, 0.02],
            [491.66, 0.016666666666666666],
            [496.96999999999997, 0.02],
            [499.36, 0.03333333333333333],
            [509.16, 0.016666666666666666],
            [512.4300000000001, 0.08333333333333333],
            [514.4300000000001, 0.1],
            [520.15, 0.03333333333333333],
            [520.92, 0.125],
            [527.03, 0.06666666666666667],
            [539.78, 0.016666666666666666],
            [555.235, 0.016666666666666666],
            [559.941, 0.005],
            [565.52, 0.03333333333333333],
            [571.92, 0.06666666666666667],
            [574.255, 0.01],
            [581.83, 0.02],
            [586.02, 0.03333333333333333],
            [597.3, 0.03333333333333333],
            [605.9100000000001, 0.025],
            [612.8, 0.025],
            [613.482, 0.01],
            [647.573, 0.005],
            [647.624, 0.005],
            [649.77, 0.025],
            [657.72, 0.016666666666666666],
            [660.02, 0.06666666666666667],
            [680.86, 0.08333333333333333],
            [699.112, 0.006666666666666667],
            [422.46000000000004, 0.02],
            [432.78000000000003, 0.041666666666666664],
            [456.084, 0.05],
            [456.154, 0.05],
            [479.73999999999995, 0.06666666666666667],
            [507.93, 0.075],
            [662.3399999999999, 0.02],
            [434.2, 0.05],
            [534.7, 0.05],
        ],
    },
    {
        name: "beryllium",
        lines: [
            [425.305, 0.1],
            [425.37600000000003, 0.06],
            [436.066, 0.3],
            [436.099, 0.5],
            [440.794, 0.4],
            [457.26599999999996, 0.012],
            [467.33299999999997, 0.7],
            [467.342, 1.0],
            [470.937, 0.006],
            [482.816, 0.2],
            [484.916, 0.04],
            [485.822, 0.002],
            [508.775, 0.08],
            [521.812, 0.008],
            [521.833, 0.02],
            [525.586, 0.003],
            [527.028, 0.064],
            [527.081, 0.5],
            [540.304, 0.02],
            [541.021, 0.02],
            [622.911, 0.01],
            [627.943, 0.016],
            [627.973, 0.03],
            [647.354, 0.03],
            [654.789, 0.06],
            [655.836, 0.06],
            [656.452, 0.03],
            [663.644, 0.002],
            [675.672, 0.001],
            [675.713, 0.002],
            [678.6560000000001, 0.03],
            [688.422, 0.001],
            [688.444, 0.006],
            [698.275, 0.1],
            [715.4399999999999, 0.006],
            [715.4649999999999, 0.04],
            [720.913, 0.1],
            [740.12, 0.003],
            [740.143, 0.002],
        ],
    },
    {
        name: "berkelium",
        lines: [
            [414.713, 1.0],
            [418.96899999999994, 1.0],
            [419.74399999999997, 1.0],
            [432.95799999999997, 1.0],
            [435.15, 1.0],
            [436.36400000000003, 1.0],
            [442.30100000000004, 1.0],
            [446.646, 1.0],
            [468.57, 1.0],
            [476.53999999999996, 1.0],
            [505.67299999999994, 1.0],
            [511.82399999999996, 1.0],
            [513.553, 1.0],
            [517.0609999999999, 1.0],
            [519.755, 1.0],
            [521.2529999999999, 1.0],
            [527.1949999999999, 1.0],
            [539.203, 1.0],
            [539.424, 1.0],
            [540.462, 1.0],
            [544.963, 1.0],
            [546.7470000000001, 1.0],
            [548.458, 1.0],
            [551.222, 1.0],
            [553.793, 1.0],
            [555.6800000000001, 1.0],
            [555.7090000000001, 1.0],
            [558.121, 1.0],
            [565.654, 1.0],
            [565.903, 1.0],
            [570.2239999999999, 1.0],
            [591.071, 1.0],
        ],
    },
    {
        name: "bromine",
        lines: [
            [402.404, 0.005],
            [413.566, 0.005],
            [414.02, 0.005],
            [417.963, 0.006666666666666667],
            [419.34499999999997, 0.005],
            [422.389, 0.016666666666666666],
            [423.689, 0.005],
            [429.139, 0.005],
            [436.514, 0.03333333333333333],
            [436.56000000000006, 0.016666666666666666],
            [442.514, 0.025],
            [444.174, 0.16666666666666666],
            [447.26099999999997, 0.16666666666666666],
            [447.77200000000005, 0.3333333333333333],
            [449.04200000000003, 0.016666666666666666],
            [451.34399999999994, 0.05],
            [452.559, 0.25],
            [452.96000000000004, 0.005],
            [454.29200000000003, 0.008333333333333333],
            [457.57399999999996, 0.05],
            [460.13599999999997, 0.005],
            [461.45799999999997, 0.041666666666666664],
            [462.27, 0.005833333333333334],
            [464.20200000000006, 0.005],
            [465.198, 0.005],
            [467.87, 0.008333333333333333],
            [469.317, 0.006666666666666667],
            [470.485, 0.008333333333333333],
            [471.976, 0.005833333333333334],
            [472.03599999999994, 0.006666666666666667],
            [472.82, 0.005],
            [473.541, 0.005],
            [474.264, 0.006666666666666667],
            [475.22799999999995, 0.041666666666666664],
            [476.6, 0.005833333333333334],
            [477.93999999999994, 0.006666666666666667],
            [478.03100000000006, 0.06666666666666667],
            [478.51899999999995, 0.02666666666666667],
            [478.55, 0.008333333333333333],
            [480.233, 0.005],
            [481.66999999999996, 0.008333333333333333],
            [481.846, 0.005],
            [484.48100000000005, 0.005833333333333334],
            [484.875, 0.005833333333333334],
            [492.11199999999997, 0.006666666666666667],
            [492.879, 0.006666666666666667],
            [493.066, 0.0075],
            [494.55100000000004, 0.005],
            [497.976, 0.06666666666666667],
            [503.87399999999997, 0.005],
            [505.46400000000006, 0.005],
            [516.438, 0.006666666666666667],
            [518.001, 0.005],
            [518.235, 0.008333333333333333],
            [519.39, 0.005],
            [523.823, 0.008333333333333333],
            [527.268, 0.005],
            [530.4100000000001, 0.005833333333333334],
            [533.057, 0.006666666666666667],
            [533.205, 0.008333333333333333],
            [539.548, 0.02],
            [542.278, 0.006666666666666667],
            [542.499, 0.005833333333333334],
            [543.507, 0.005],
            [546.6220000000001, 0.02],
            [547.847, 0.005833333333333334],
            [548.879, 0.005],
            [549.5060000000001, 0.005],
            [550.669, 0.008333333333333333],
            [558.9939999999999, 0.005833333333333334],
            [571.871, 0.005],
            [583.078, 0.005],
            [585.208, 0.03],
            [594.048, 0.02666666666666667],
            [612.214, 0.04],
            [614.86, 0.6666666666666666],
            [616.174, 0.005],
            [617.739, 0.03333333333333333],
            [633.548, 0.025],
            [635.073, 1.0],
            [635.294, 0.006666666666666667],
            [641.0319999999999, 0.041666666666666664],
            [648.356, 0.03],
            [651.462, 0.016666666666666666],
            [654.457, 0.3333333333333333],
            [654.809, 0.025],
            [655.98, 0.8333333333333334],
            [657.1310000000001, 0.016666666666666666],
            [657.914, 0.03],
            [658.217, 0.3333333333333333],
            [662.047, 0.025],
            [663.162, 0.8333333333333334],
            [668.228, 0.3333333333333333],
            [669.213, 0.16666666666666666],
            [672.828, 0.13333333333333333],
            [676.0060000000001, 0.03333333333333333],
            [677.948, 0.03333333333333333],
            [678.674, 0.03666666666666667],
            [679.004, 0.10833333333333334],
            [679.1479999999999, 0.02666666666666667],
            [686.115, 0.03],
            [450.65500000000003, 0.005833333333333334],
            [451.974, 0.0033333333333333335],
            [517.587, 0.0025],
            [544.6800000000001, 0.0016666666666666668],
        ],
    },
    {
        name: "caesium",
        lines: [
            [404.71799999999996, 0.14],
            [405.396, 0.08],
            [406.796, 0.2],
            [406.877, 0.2],
            [407.336, 0.028],
            [411.929, 0.024],
            [412.121, 0.2],
            [413.2, 0.04],
            [415.12700000000007, 0.14],
            [415.861, 0.14],
            [419.32, 0.024],
            [421.313, 0.2],
            [422.11199999999997, 0.08],
            [423.21899999999994, 0.2],
            [423.441, 0.14],
            [424.197, 0.04],
            [427.174, 0.04],
            [427.71000000000004, 0.8],
            [428.83500000000004, 0.4],
            [429.2, 0.08],
            [430.064, 0.2],
            [430.794, 0.02],
            [432.758, 0.02],
            [433.024, 0.14],
            [436.328, 0.8],
            [437.302, 0.2],
            [438.44300000000004, 0.16],
            [438.87600000000003, 0.04],
            [439.691, 0.08],
            [439.95, 0.14],
            [440.38500000000005, 0.14],
            [440.525, 0.4],
            [441.021, 0.14],
            [442.40500000000003, 0.04],
            [443.571, 0.14],
            [444.4, 0.04],
            [445.34399999999994, 0.08],
            [445.76800000000003, 0.08],
            [445.918, 0.08],
            [449.366, 0.04],
            [450.15200000000004, 0.4],
            [450.671, 0.08],
            [450.683, 0.04],
            [451.55, 0.04],
            [452.285, 0.06],
            [452.672, 0.4],
            [453.89399999999995, 0.32],
            [455.5276, 0.16],
            [456.698, 0.06],
            [457.179, 0.06],
            [459.3169, 0.08],
            [460.37600000000003, 1.0],
            [460.99899999999997, 0.04],
            [461.613, 0.06],
            [462.309, 0.14],
            [464.651, 0.2],
            [465.654, 0.06],
            [467.02799999999996, 0.14],
            [469.561, 0.04],
            [470.179, 0.2],
            [473.29799999999994, 0.14],
            [473.966, 0.14],
            [474.913, 0.04],
            [476.36199999999997, 0.2],
            [478.63599999999997, 0.08],
            [483.01599999999996, 0.32],
            [487.00200000000007, 0.32],
            [495.284, 0.32],
            [497.259, 0.2],
            [504.38, 0.32],
            [505.27, 0.2],
            [505.98699999999997, 0.2],
            [508.177, 0.04],
            [509.66, 0.16],
            [522.7, 0.6],
            [524.937, 0.32],
            [527.404, 0.16],
            [530.661, 0.14],
            [534.9159999999999, 0.14],
            [537.097, 0.32],
            [541.9689999999999, 0.2],
            [546.5944000000001, 0.002],
            [550.2884, 0.002],
            [556.302, 0.4],
            [563.5212, 0.0044],
            [566.4018, 0.012],
            [574.5724, 0.0032],
            [581.418, 0.14],
            [583.116, 0.2],
            [583.8835, 0.002],
            [584.5141, 0.012],
            [592.5649999999999, 0.2],
            [601.049, 0.032],
            [603.4089, 0.012],
            [612.862, 0.16],
            [621.3100000000001, 0.048],
            [621.7599, 0.006],
            [635.4555, 0.02],
            [641.954, 0.04],
            [643.1969, 0.006],
            [647.2623, 0.006],
            [649.553, 0.08],
            [653.644, 0.08],
            [658.6020000000001, 0.012],
            [658.6510000000001, 0.08],
            [662.866, 0.014],
            [672.3284, 0.08],
            [682.4652, 0.04],
            [687.0455, 0.04],
            [695.552, 0.16],
            [697.3297, 0.08],
            [698.3491, 0.014],
        ],
    },
    {
        name: "carbon",
        lines: [
            [391.898, 0.57],
            [392.069, 0.8],
            [407.452, 0.25],
            [407.585, 0.35],
            [426.7, 0.8],
            [426.726, 1.0],
            [477.175, 0.2],
            [493.20500000000004, 0.2],
            [505.217, 0.2],
            [513.294, 0.35],
            [513.328, 0.35],
            [514.3489999999999, 0.35],
            [514.516, 0.57],
            [515.109, 0.4],
            [538.034, 0.3],
            [564.807, 0.25],
            [566.2470000000001, 0.35],
            [588.9770000000001, 0.57],
            [589.159, 0.35],
            [600.113, 0.2],
            [600.603, 0.25],
            [600.7180000000001, 0.11],
            [601.068, 0.15],
            [601.322, 0.3],
            [601.484, 0.25],
            [657.8050000000001, 0.8],
            [658.288, 0.57],
            [658.761, 0.2],
            [678.39, 0.25],
            [711.318, 0.25],
            [711.519, 0.25],
            [711.563, 0.25],
            [711.699, 0.2],
            [711.99, 0.35],
        ],
    },
    {
        name: "californium",
        lines: [
            [403.54499999999996, 1.0],
            [409.912, 1.0],
            [424.238, 1.0],
            [432.90299999999996, 1.0],
            [433.52200000000005, 1.0],
            [517.396, 1.0],
            [517.908, 1.0],
            [521.924, 1.0],
            [527.9010000000001, 1.0],
            [532.009, 1.0],
            [533.913, 1.0],
            [540.888, 1.0],
            [572.605, 1.0],
            [662.283, 1.0],
            [663.126, 1.0],
            [667.79, 1.0],
            [689.4590000000001, 1.0],
            [692.71, 1.0],
        ],
    },
    {
        name: "chlorine",
        lines: [
            [410.479, 0.00020202020202020202],
            [413.25, 0.10101010101010101],
            [420.967, 0.0006565656565656566],
            [422.642, 0.000505050505050505],
            [426.45799999999997, 0.0006060606060606061],
            [436.32700000000006, 0.00101010101010101],
            [436.95, 0.00101010101010101],
            [437.293, 0.050505050505050504],
            [437.98999999999995, 0.00101010101010101],
            [438.975, 0.00101010101010101],
            [439.03999999999996, 0.0009090909090909091],
            [440.303, 0.0009090909090909091],
            [443.849, 0.00101010101010101],
            [447.53000000000003, 0.0009090909090909091],
            [448.991, 0.015151515151515152],
            [452.61899999999997, 0.00101010101010101],
            [460.09799999999996, 0.0008080808080808081],
            [462.3938, 0.00040404040404040404],
            [465.404, 0.000505050505050505],
            [466.1208, 0.0008080808080808081],
            [469.1523, 0.00045454545454545455],
            [472.1255, 0.00040404040404040404],
            [474.0729, 0.00045454545454545455],
            [476.86499999999995, 0.043434343434343436],
            [478.13199999999995, 0.13131313131313133],
            [479.45500000000004, 1.0],
            [481.00600000000003, 0.29292929292929293],
            [481.947, 0.16161616161616163],
            [489.677, 0.8181818181818182],
            [490.47799999999995, 0.47474747474747475],
            [491.77299999999997, 0.26262626262626265],
            [499.54799999999994, 0.10101010101010101],
            [507.826, 0.26262626262626265],
            [509.97889999999995, 0.00030303030303030303],
            [521.794, 0.5656565656565656],
            [522.136, 0.23232323232323232],
            [539.212, 0.15151515151515152],
            [542.323, 1.0],
            [542.351, 0.10101010101010101],
            [544.337, 0.1919191919191919],
            [544.421, 0.10101010101010101],
            [545.702, 0.05656565656565657],
            [553.2162000000001, 0.00040404040404040404],
            [579.6305, 0.000505050505050505],
            [579.9914, 0.00045454545454545455],
            [585.6742, 0.00030303030303030303],
            [594.858, 0.00101010101010101],
            [601.9812, 0.000505050505050505],
            [608.261, 0.00035353535353535354],
            [609.4689999999999, 0.01919191919191919],
            [611.443, 0.0016161616161616162],
            [614.0245, 0.00202020202020202],
            [619.4757, 0.0016161616161616162],
            [639.866, 0.0016161616161616162],
            [643.4833, 0.0015151515151515152],
            [653.143, 0.0015151515151515152],
            [666.167, 0.014141414141414142],
            [667.8430000000001, 0.0015151515151515152],
            [668.6020000000001, 0.013131313131313131],
            [671.341, 0.012121212121212121],
            [684.029, 0.0015151515151515152],
            [693.2903, 0.0030303030303030303],
            [698.1886000000001, 0.0030303030303030303],
        ],
    },
    {
        name: "cobalt",
        lines: [
            [402.09000000000003, 0.07954545454545454],
            [403.614, 0.0022727272727272726],
            [403.73699999999997, 0.004545454545454545],
            [404.002, 0.0009090909090909091],
            [404.539, 0.08409090909090909],
            [405.023, 0.0011363636363636363],
            [405.24, 0.0022727272727272726],
            [406.273, 0.004545454545454545],
            [406.45, 0.00045454545454545455],
            [406.637, 0.07954545454545454],
            [407.434, 0.0011363636363636363],
            [409.239, 0.18863636363636363],
            [409.657, 0.00022727272727272727],
            [411.054, 0.125],
            [411.87700000000007, 0.6363636363636364],
            [412.13199999999995, 1.0],
            [413.088, 0.0006818181818181819],
            [414.51300000000003, 0.0006818181818181819],
            [416.067, 0.0006818181818181819],
            [418.113, 0.00022727272727272727],
            [419.071, 0.020454545454545454],
            [420.861, 0.00022727272727272727],
            [424.425, 0.006818181818181818],
            [427.233, 0.0018181818181818182],
            [428.825, 0.004545454545454545],
            [432.88599999999997, 0.0006818181818181819],
            [438.42600000000004, 0.00045454545454545455],
            [439.69399999999996, 0.00045454545454545455],
            [441.39099999999996, 0.0006818181818181819],
            [446.956, 0.020454545454545454],
            [448.25, 0.0022727272727272726],
            [448.912, 0.00045454545454545455],
            [449.74399999999997, 0.0009090909090909091],
            [450.054, 0.0022727272727272726],
            [453.096, 0.15681818181818183],
            [453.322, 0.00045454545454545455],
            [454.966, 0.020454545454545454],
            [455.929, 0.00022727272727272727],
            [456.559, 0.031818181818181815],
            [456.92600000000004, 0.00022727272727272727],
            [458.16, 0.04318181818181818],
            [461.63, 0.0011363636363636363],
            [462.938, 0.02727272727272727],
            [466.066, 0.005681818181818182],
            [466.341, 0.019318181818181818],
            [479.28599999999994, 0.025],
            [483.116, 0.0022727272727272726],
            [484.02700000000004, 0.022727272727272728],
            [486.788, 0.03409090909090909],
            [496.418, 0.01818181818181818],
            [497.005, 0.0022727272727272726],
            [499.047, 0.0022727272727272726],
            [499.59799999999996, 0.004545454545454545],
            [514.674, 0.007954545454545454],
            [521.271, 0.011363636363636364],
            [523.022, 0.011363636363636364],
            [523.521, 0.010227272727272727],
            [524.793, 0.011363636363636364],
            [526.63, 0.005909090909090909],
            [526.649, 0.010227272727272727],
            [526.8520000000001, 0.005909090909090909],
            [528.0649999999999, 0.010227272727272727],
            [530.106, 0.005909090909090909],
            [534.271, 0.011363636363636364],
            [534.339, 0.005909090909090909],
            [535.205, 0.011363636363636364],
            [535.348, 0.005909090909090909],
            [536.958, 0.007954545454545454],
            [548.3340000000001, 0.010227272727272727],
            [553.077, 0.003863636363636364],
            [564.722, 0.003863636363636364],
            [599.188, 0.003863636363636364],
            [608.2439999999999, 0.003863636363636364],
            [628.263, 0.003863636363636364],
            [645.024, 0.010227272727272727],
            [645.5, 0.004772727272727273],
            [656.342, 0.003409090909090909],
            [663.2439999999999, 0.003409090909090909],
            [681.4939999999999, 0.003181818181818182],
            [687.24, 0.003181818181818182],
        ],
    },
    {
        name: "curium",
        lines: [
            [401.617, 1.0],
            [403.17600000000004, 1.0],
            [404.829, 1.0],
            [404.96500000000003, 1.0],
            [411.329, 1.0],
            [412.971, 1.0],
            [420.76599999999996, 1.0],
            [421.162, 1.0],
            [426.645, 1.0],
            [429.3, 1.0],
            [433.082, 1.0],
            [434.56899999999996, 1.0],
            [444.77700000000004, 1.0],
            [445.916, 1.0],
            [460.84, 1.0],
            [584.607, 1.0],
            [595.241, 1.0],
            [605.89, 1.0],
            [624.335, 1.0],
            [637.671, 1.0],
            [651.016, 1.0],
            [655.441, 1.0],
            [664.017, 1.0],
            [666.325, 1.0],
            [668.687, 1.0],
            [670.6850000000001, 1.0],
            [672.668, 1.0],
            [679.3149999999999, 1.0],
        ],
    },
    {
        name: "dysprosium",
        lines: [
            [400.04499999999996, 0.5],
            [400.584, 0.02625],
            [400.607, 0.02],
            [401.129, 0.03375],
            [401.382, 0.03375],
            [401.46999999999997, 0.03375],
            [402.371, 0.023125],
            [402.778, 0.02625],
            [402.832, 0.0325],
            [403.24699999999996, 0.0325],
            [403.365, 0.02625],
            [403.632, 0.02625],
            [404.198, 0.02],
            [404.597, 0.75],
            [405.056, 0.1],
            [405.514, 0.0325],
            [407.312, 0.15625],
            [407.796, 0.4625],
            [408.534, 0.023125],
            [409.61, 0.024375],
            [410.33000000000004, 0.24375],
            [410.387, 0.05375],
            [411.134, 0.09375],
            [412.463, 0.030625],
            [412.82399999999996, 0.024375],
            [412.912, 0.021875],
            [412.942, 0.061875],
            [413.035, 0.021875],
            [413.38500000000005, 0.024375],
            [414.15, 0.029375],
            [414.31000000000006, 0.075],
            [414.60600000000005, 0.061875],
            [416.797, 0.35625],
            [417.19300000000004, 0.023125],
            [418.372, 0.058125],
            [418.68199999999996, 0.75],
            [419.09399999999994, 0.02],
            [419.16400000000004, 0.1375],
            [419.48400000000004, 0.425],
            [419.51899999999995, 0.02],
            [419.802, 0.05],
            [420.13, 0.0425],
            [420.224, 0.0425],
            [420.50600000000003, 0.014375],
            [420.654, 0.023125],
            [421.12399999999997, 0.0275],
            [421.172, 1.0],
            [421.31800000000004, 0.1125],
            [421.51599999999996, 0.23125],
            [421.809, 0.275],
            [422.111, 0.275],
            [422.221, 0.03375],
            [422.51599999999996, 0.16875],
            [423.20200000000006, 0.0425],
            [423.985, 0.0425],
            [424.591, 0.0275],
            [425.633, 0.0275],
            [427.669, 0.015625],
            [429.49300000000005, 0.023125],
            [430.863, 0.0625],
            [432.58599999999996, 0.02],
            [435.84399999999994, 0.0125],
            [437.424, 0.02],
            [437.476, 0.02],
            [440.938, 0.03375],
            [444.45799999999997, 0.009375],
            [444.96999999999997, 0.04625],
            [445.56000000000006, 0.006875],
            [446.814, 0.015625],
            [452.758, 0.00625],
            [454.166, 0.00625],
            [456.509, 0.00875],
            [457.77799999999996, 0.02625],
            [458.936, 0.13125],
            [461.226, 0.061875],
            [461.383, 0.003125],
            [461.48199999999997, 0.003125],
            [461.726, 0.00375],
            [462.003, 0.00875],
            [466.27200000000005, 0.003125],
            [466.466, 0.006875],
            [467.36, 0.0053125],
            [468.203, 0.003125],
            [468.975, 0.003125],
            [469.86800000000005, 0.0059375],
            [472.122, 0.0053125],
            [472.713, 0.004375],
            [473.184, 0.010625],
            [474.573, 0.0025],
            [475.49899999999997, 0.00375],
            [476.004, 0.003125],
            [477.19399999999996, 0.00375],
            [477.48, 0.003125],
            [477.579, 0.0075],
            [478.692, 0.0046875],
            [479.129, 0.0059375],
            [480.064, 0.0018125],
            [480.794, 0.003125],
            [481.02799999999996, 0.0025],
            [481.28000000000003, 0.003125],
            [481.904, 0.0046875],
            [482.496, 0.0053125],
            [482.88800000000003, 0.0046875],
            [482.968, 0.003125],
            [483.238, 0.004375],
            [483.375, 0.0021875],
            [484.175, 0.0046875],
            [485.62399999999997, 0.0025],
            [486.805, 0.0025],
            [487.593, 0.0025],
            [488.01599999999996, 0.0053125],
            [488.45500000000004, 0.0025],
            [488.808, 0.0059375],
            [488.933, 0.0025],
            [489.01000000000005, 0.0046875],
            [489.36800000000005, 0.003125],
            [489.924, 0.0015],
            [491.64099999999996, 0.0034375],
            [492.22200000000004, 0.003125],
            [492.316, 0.0040625],
            [495.73400000000004, 0.03],
            [495.959, 0.0015],
            [497.35699999999997, 0.00175],
            [498.552, 0.0025],
            [500.387, 0.003125],
            [500.428, 0.0034375],
            [501.06000000000006, 0.0015],
            [501.79799999999994, 0.0015],
            [502.212, 0.004375],
            [502.40299999999996, 0.001875],
            [502.454, 0.0015],
            [502.787, 0.0025],
            [503.3, 0.003125],
            [504.26300000000003, 0.01],
            [504.725, 0.0015],
            [505.021, 0.003125],
            [505.33500000000004, 0.001875],
            [505.546, 0.0015],
            [507.06800000000004, 0.0059375],
            [507.767, 0.0075],
            [509.038, 0.005],
            [511.032, 0.005],
            [512.004, 0.008125],
            [513.5020000000001, 0.001875],
            [513.96, 0.011875],
            [516.103, 0.0025],
            [516.412, 0.0025],
            [516.534, 0.003125],
            [516.9689999999999, 0.006875],
            [517.29, 0.00125],
            [518.53, 0.005],
            [518.845, 0.0025],
            [519.286, 0.018125],
            [519.766, 0.0059375],
            [524.694, 0.003125],
            [525.988, 0.004375],
            [526.056, 0.008125],
            [526.33, 0.0034375],
            [526.711, 0.0040625],
            [527.225, 0.003125],
            [527.529, 0.003125],
            [527.97, 0.003125],
            [528.207, 0.0034375],
            [528.499, 0.00175],
            [529.7819999999999, 0.0025],
            [530.158, 0.01],
            [530.902, 0.0025],
            [532.4689999999999, 0.003125],
            [533.743, 0.0015],
            [534.03, 0.0040625],
            [535.211, 0.001875],
            [536.8199999999999, 0.001875],
            [538.563, 0.00125],
            [538.958, 0.0053125],
            [539.557, 0.0025],
            [539.826, 0.00125],
            [539.993, 0.0015],
            [540.419, 0.003125],
            [541.913, 0.005],
            [542.332, 0.004375],
            [542.427, 0.001875],
            [542.67, 0.0025],
            [544.3340000000001, 0.001875],
            [545.111, 0.0059375],
            [545.547, 0.001875],
            [546.9100000000001, 0.0015],
            [549.683, 0.00175],
            [550.279, 0.0015],
            [550.652, 0.00175],
            [551.5409999999999, 0.0015],
            [552.801, 0.001875],
            [554.7270000000001, 0.0040625],
            [560.0649999999999, 0.0025],
            [560.553, 0.0015],
            [561.323, 0.001875],
            [562.749, 0.00125],
            [563.95, 0.00625],
            [564.5989999999999, 0.0034375],
            [565.201, 0.005],
            [568.558, 0.0015],
            [569.367, 0.00175],
            [569.4100000000001, 0.0015],
            [569.454, 0.00175],
            [569.8720000000001, 0.00175],
            [570.2909999999999, 0.0015],
            [571.846, 0.004375],
            [572.5840000000001, 0.00175],
            [572.864, 0.0034375],
            [573.8729999999999, 0.0015],
            [574.02, 0.003125],
            [574.553, 0.0034375],
            [575.048, 0.0015],
            [575.879, 0.0015],
            [583.201, 0.005],
            [583.385, 0.0034375],
            [583.486, 0.0025],
            [584.441, 0.00175],
            [584.5649999999999, 0.0015],
            [584.8050000000001, 0.0025],
            [585.556, 0.0025],
            [586.8109999999999, 0.0034375],
            [591.516, 0.0025],
            [592.456, 0.00125],
            [594.58, 0.004375],
            [596.446, 0.003125],
            [597.449, 0.0075],
            [598.486, 0.0015],
            [598.856, 0.00875],
            [600.575, 0.0015],
            [600.654, 0.0015],
            [600.697, 0.0015],
            [600.894, 0.001875],
            [601.082, 0.0040625],
            [601.726, 0.0015],
            [603.098, 0.0015],
            [604.249, 0.0015],
            [605.818, 0.0015],
            [608.5060000000001, 0.001875],
            [608.826, 0.00875],
            [612.7149999999999, 0.0015],
            [613.364, 0.0015],
            [615.828, 0.0015],
            [616.8430000000001, 0.00625],
            [619.6229999999999, 0.00125],
            [625.909, 0.016875],
            [626.036, 0.001875],
            [634.332, 0.000875],
            [638.6800000000001, 0.0025],
            [639.6600000000001, 0.0015],
            [642.192, 0.003125],
            [643.655, 0.0008125],
            [646.083, 0.0005],
            [646.858, 0.000625],
            [647.491, 0.0006875],
            [648.359, 0.00125],
            [648.659, 0.00175],
            [655.802, 0.00125],
            [657.937, 0.01],
            [659.414, 0.000875],
            [664.337, 0.0009375],
            [665.836, 0.001375],
            [666.164, 0.0018125],
            [666.786, 0.0046875],
            [670.0640000000001, 0.000625],
            [674.793, 0.0018125],
            [675.762, 0.000625],
            [676.589, 0.0028125],
            [681.8199999999999, 0.00075],
            [683.542, 0.01125],
            [685.296, 0.005],
            [685.646, 0.001375],
            [688.883, 0.001375],
            [689.797, 0.0009375],
            [689.932, 0.0040625],
            [690.653, 0.001375],
            [692.955, 0.0009375],
            [695.028, 0.0018125],
            [695.142, 0.0006875],
            [695.808, 0.0025],
            [698.2439999999999, 0.0008125],
            [699.13, 0.0008125],
            [699.8100000000001, 0.0028125],
        ],
    },
    {
        name: "einsteinium",
        lines: [
            [407.771, 0.03],
            [408.224, 1.0],
            [410.759, 0.3],
            [417.69399999999996, 0.1],
            [449.625, 0.01],
            [463.166, 0.01],
            [465.08599999999996, 0.03],
            [478.99300000000005, 0.1],
            [480.217, 0.03],
            [480.221, 0.1],
            [495.829, 0.3],
            [505.20799999999997, 1.0],
            [510.293, 0.1],
            [515.582, 0.01],
            [516.174, 1.0],
            [520.4399999999999, 1.0],
            [561.551, 0.3],
            [653.971, 0.01],
        ],
    },
    {
        name: "fluorine",
        lines: [
            [402.473, 0.0048],
            [402.50100000000003, 0.0044],
            [402.549, 0.0046],
            [408.39099999999996, 0.0032],
            [410.30699999999996, 0.0038],
            [410.322, 0.0034],
            [410.351, 0.004],
            [410.371, 0.0036],
            [410.387, 0.0034],
            [410.916, 0.0034],
            [411.654, 0.0032],
            [411.921, 0.003],
            [420.715, 0.0028],
            [422.51599999999996, 0.0034],
            [424.412, 0.003],
            [424.62299999999993, 0.004],
            [424.639, 0.0038],
            [424.659, 0.0036],
            [424.677, 0.0034],
            [424.684, 0.0032],
            [427.53599999999994, 0.0034],
            [427.753, 0.0032],
            [427.89300000000003, 0.0032],
            [429.91700000000003, 0.004],
            [444.65299999999996, 0.0032],
            [444.672, 0.0034],
            [444.71899999999994, 0.0036],
            [473.438, 0.0028],
            [485.939, 0.0034],
            [493.326, 0.0032],
            [496.06499999999994, 0.00012],
            [500.2, 0.0028],
            [517.325, 0.003],
            [523.0409999999999, 0.0003],
            [527.9010000000001, 0.00024],
            [554.052, 0.00036],
            [555.243, 0.00024],
            [557.733, 0.0002],
            [558.927, 0.0032],
            [562.4060000000001, 0.0004],
            [562.693, 0.00024],
            [565.915, 0.0003],
            [566.7529999999999, 0.0008],
            [567.167, 0.0018],
            [568.914, 0.00036],
            [570.082, 0.0005],
            [570.731, 0.0005],
            [595.015, 0.00024],
            [595.919, 0.0005],
            [596.528, 0.0014],
            [599.443, 0.001],
            [601.583, 0.003],
            [603.804, 0.0016],
            [604.754, 0.018],
            [608.011, 0.002],
            [614.976, 0.016],
            [621.087, 0.008],
            [623.9649999999999, 0.26],
            [624.79, 0.0028],
            [634.851, 0.2],
            [641.365, 0.16],
            [656.9689999999999, 0.009],
            [658.039, 0.006],
            [665.0409999999999, 0.008],
            [669.048, 0.036],
            [670.828, 0.008],
            [677.3979999999999, 0.14],
            [679.553, 0.03],
            [683.426, 0.18],
            [685.603, 1.0],
            [687.022, 0.16],
            [690.2479999999999, 0.3],
            [690.982, 0.12],
            [696.635, 0.08],
            [703.7470000000001, 0.9],
        ],
    },
    {
        name: "gadolinium",
        lines: [
            [400.12600000000003, 0.09791666666666667],
            [400.494, 0.05416666666666667],
            [400.83299999999997, 0.06666666666666667],
            [400.89099999999996, 0.0625],
            [401.38, 0.0625],
            [401.558, 0.041666666666666664],
            [401.725, 0.0625],
            [401.771, 0.08958333333333333],
            [401.973, 0.0625],
            [402.233, 0.0625],
            [402.31399999999996, 0.22916666666666666],
            [402.335, 0.16875],
            [402.761, 0.04583333333333333],
            [402.815, 0.22916666666666666],
            [403.088, 0.17916666666666667],
            [403.349, 0.14583333333333334],
            [403.54, 0.07083333333333333],
            [403.684, 0.05416666666666667],
            [403.733, 0.2916666666666667],
            [403.79, 0.14583333333333334],
            [404.371, 0.08541666666666667],
            [404.50100000000003, 0.3333333333333333],
            [404.684, 0.05625],
            [404.709, 0.05625],
            [404.91999999999996, 0.05625],
            [404.943, 0.2708333333333333],
            [404.986, 0.4583333333333333],
            [405.037, 0.05625],
            [405.329, 0.16875],
            [405.364, 0.5416666666666666],
            [405.472, 0.16875],
            [405.822, 0.5416666666666666],
            [405.988, 0.13541666666666666],
            [406.13, 0.05625],
            [406.259, 0.13541666666666666],
            [406.339, 0.3958333333333333],
            [406.35900000000004, 0.1125],
            [406.604, 0.05416666666666667],
            [406.835, 0.10833333333333334],
            [406.87399999999997, 0.05416666666666667],
            [407.029, 0.15625],
            [407.32, 0.13541666666666666],
            [407.37600000000003, 0.0625],
            [407.844, 0.2708333333333333],
            [407.87, 0.5833333333333334],
            [408.37, 0.10833333333333334],
            [408.556, 0.3125],
            [408.769, 0.05416666666666667],
            [409.041, 0.13541666666666666],
            [409.271, 0.22916666666666666],
            [409.37199999999996, 0.05416666666666667],
            [409.448, 0.05416666666666667],
            [409.861, 0.5416666666666666],
            [409.89, 0.10833333333333334],
            [410.026, 0.13541666666666666],
            [411.14399999999995, 0.08125],
            [413.037, 0.4583333333333333],
            [413.14799999999997, 0.05625],
            [413.22799999999995, 0.22916666666666666],
            [413.416, 0.15625],
            [413.71000000000004, 0.08541666666666667],
            [414.88599999999997, 0.058333333333333334],
            [416.27299999999997, 0.1125],
            [416.309, 0.058333333333333334],
            [416.716, 0.058333333333333334],
            [417.554, 0.5],
            [418.425, 0.5],
            [419.078, 0.4583333333333333],
            [419.10699999999997, 0.15625],
            [419.163, 0.15625],
            [419.76800000000003, 0.09375],
            [420.486, 0.12291666666666666],
            [421.2, 0.2708333333333333],
            [421.50200000000007, 0.20208333333333334],
            [421.71999999999997, 0.13541666666666666],
            [422.503, 0.06666666666666667],
            [422.58500000000004, 1.0],
            [422.71400000000006, 0.04583333333333333],
            [422.98, 0.04583333333333333],
            [423.878, 0.13541666666666666],
            [424.657, 0.041666666666666664],
            [425.17299999999994, 0.3541666666666667],
            [425.337, 0.17916666666666667],
            [425.361, 0.13541666666666666],
            [426.012, 0.16875],
            [426.209, 0.3333333333333333],
            [426.66, 0.13541666666666666],
            [426.7, 0.09791666666666667],
            [427.41700000000003, 0.0625],
            [428.049, 0.18958333333333333],
            [428.582, 0.08958333333333333],
            [428.61199999999997, 0.0625],
            [429.608, 0.1125],
            [429.717, 0.04583333333333333],
            [429.929, 0.08958333333333333],
            [430.634, 0.22916666666666666],
            [430.929, 0.05416666666666667],
            [431.384, 0.375],
            [431.43999999999994, 0.10833333333333334],
            [431.605, 0.10833333333333334],
            [432.052, 0.07708333333333334],
            [432.111, 0.15625],
            [432.55699999999996, 0.5416666666666666],
            [432.712, 0.3958333333333333],
            [432.95799999999997, 0.07708333333333334],
            [433.061, 0.07083333333333333],
            [433.13800000000003, 0.05],
            [434.128, 0.09375],
            [434.218, 0.18958333333333333],
            [434.43, 0.20833333333333334],
            [434.646, 0.4583333333333333],
            [434.662, 0.18958333333333333],
            [434.73100000000005, 0.04583333333333333],
            [436.97700000000003, 0.0625],
            [437.383, 0.20208333333333334],
            [439.206, 0.058333333333333334],
            [440.186, 0.2916666666666667],
            [440.314, 0.10833333333333334],
            [440.66700000000003, 0.05416666666666667],
            [440.825, 0.05416666666666667],
            [440.925, 0.04583333333333333],
            [441.116, 0.10833333333333334],
            [441.416, 0.17916666666666667],
            [441.47299999999996, 0.14583333333333334],
            [441.90299999999996, 0.07083333333333333],
            [442.241, 0.2916666666666667],
            [443.063, 0.22916666666666666],
            [443.61, 0.05],
            [446.474, 0.0625],
            [446.65500000000003, 0.0625],
            [446.70799999999997, 0.10833333333333334],
            [447.413, 0.14583333333333334],
            [447.61199999999997, 0.17916666666666667],
            [447.88, 0.04583333333333333],
            [448.10600000000005, 0.058333333333333334],
            [448.33299999999997, 0.04583333333333333],
            [448.46999999999997, 0.04583333333333333],
            [448.68999999999994, 0.058333333333333334],
            [449.713, 0.10416666666666667],
            [449.73199999999997, 0.04583333333333333],
            [450.621, 0.08958333333333333],
            [450.633, 0.029166666666666667],
            [451.45, 0.029166666666666667],
            [451.966, 0.22916666666666666],
            [452.282, 0.0625],
            [452.412, 0.03125],
            [453.78100000000006, 0.18958333333333333],
            [454.00200000000007, 0.04583333333333333],
            [454.203, 0.0625],
            [454.8, 0.05],
            [455.808, 0.025],
            [457.38100000000003, 0.027083333333333334],
            [457.591, 0.05416666666666667],
            [457.959, 0.058333333333333334],
            [458.129, 0.08541666666666667],
            [458.253, 0.027083333333333334],
            [458.30699999999996, 0.08541666666666667],
            [458.69899999999996, 0.03333333333333333],
            [459.698, 0.04583333333333333],
            [459.791, 0.06666666666666667],
            [459.89, 0.08541666666666667],
            [460.105, 0.07083333333333333],
            [460.293, 0.05],
            [461.45, 0.10833333333333334],
            [462.442, 0.029166666666666667],
            [463.66400000000004, 0.08958333333333333],
            [463.9, 0.022916666666666665],
            [464.004, 0.035416666666666666],
            [464.6, 0.035416666666666666],
            [464.764, 0.035416666666666666],
            [464.85900000000004, 0.035416666666666666],
            [465.354, 0.08958333333333333],
            [467.087, 0.029166666666666667],
            [467.918, 0.035416666666666666],
            [468.004, 0.05416666666666667],
            [468.33299999999997, 0.08958333333333333],
            [468.812, 0.029166666666666667],
            [469.433, 0.14583333333333334],
            [469.549, 0.035416666666666666],
            [469.742, 0.08958333333333333],
            [470.313, 0.035416666666666666],
            [470.97799999999995, 0.041666666666666664],
            [472.146, 0.022916666666666665],
            [472.84700000000004, 0.03125],
            [473.26000000000005, 0.04583333333333333],
            [473.575, 0.05416666666666667],
            [474.36499999999995, 0.08541666666666667],
            [474.582, 0.022916666666666665],
            [475.87, 0.06666666666666667],
            [476.07399999999996, 0.022916666666666665],
            [476.38199999999995, 0.027083333333333334],
            [476.724, 0.09791666666666667],
            [478.192, 0.0375],
            [478.462, 0.0625],
            [478.675, 0.022916666666666665],
            [480.105, 0.029166666666666667],
            [480.745, 0.04583333333333333],
            [482.169, 0.06666666666666667],
            [483.526, 0.027083333333333334],
            [484.81000000000006, 0.022916666666666665],
            [486.259, 0.022916666666666665],
            [486.50200000000007, 0.035416666666666666],
            [487.15, 0.025],
            [493.412, 0.058333333333333334],
            [493.861, 0.04583333333333333],
            [495.247, 0.022916666666666665],
            [495.879, 0.027083333333333334],
            [501.082, 0.013541666666666667],
            [501.174, 0.011458333333333333],
            [501.504, 0.15625],
            [502.313, 0.011458333333333333],
            [503.129, 0.013541666666666667],
            [503.909, 0.015625],
            [505.088, 0.013541666666666667],
            [507.37399999999997, 0.011458333333333333],
            [508.28000000000003, 0.011458333333333333],
            [509.225, 0.019791666666666666],
            [509.60600000000005, 0.013541666666666667],
            [509.838, 0.027083333333333334],
            [510.09399999999994, 0.011458333333333333],
            [510.34499999999997, 0.18958333333333333],
            [510.89099999999996, 0.0375],
            [512.556, 0.025],
            [513.028, 0.013541666666666667],
            [513.559, 0.013541666666666667],
            [513.604, 0.015625],
            [514.0840000000001, 0.017708333333333333],
            [514.15, 0.015625],
            [514.268, 0.015625],
            [515.5840000000001, 0.17916666666666667],
            [515.676, 0.011458333333333333],
            [515.848, 0.015625],
            [516.37, 0.015625],
            [516.454, 0.011458333333333333],
            [517.6279999999999, 0.03958333333333333],
            [518.7239999999999, 0.011458333333333333],
            [518.788, 0.011458333333333333],
            [519.108, 0.011458333333333333],
            [519.777, 0.08541666666666667],
            [521.049, 0.011458333333333333],
            [521.7479999999999, 0.017708333333333333],
            [521.9399999999999, 0.058333333333333334],
            [522.03, 0.015625],
            [523.393, 0.027083333333333334],
            [524.687, 0.013541666666666667],
            [525.118, 0.06666666666666667],
            [525.214, 0.025],
            [525.475, 0.017708333333333333],
            [525.58, 0.029166666666666667],
            [526.8779999999999, 0.013541666666666667],
            [527.2909999999999, 0.011458333333333333],
            [528.2479999999999, 0.011458333333333333],
            [528.308, 0.058333333333333334],
            [530.167, 0.058333333333333334],
            [530.2760000000001, 0.04583333333333333],
            [530.67, 0.011458333333333333],
            [530.73, 0.058333333333333334],
            [532.15, 0.027083333333333334],
            [532.178, 0.058333333333333334],
            [532.732, 0.022916666666666665],
            [532.83, 0.013541666666666667],
            [533.33, 0.035416666666666666],
            [533.7529999999999, 0.011458333333333333],
            [534.3, 0.0625],
            [534.513, 0.017708333333333333],
            [534.568, 0.015625],
            [534.867, 0.041666666666666664],
            [535.038, 0.0625],
            [535.326, 0.05],
            [536.1659999999999, 0.011458333333333333],
            [536.538, 0.019791666666666666],
            [536.992, 0.019791666666666666],
            [537.063, 0.03125],
            [538.95, 0.017708333333333333],
            [541.3199999999999, 0.017708333333333333],
            [541.569, 0.017708333333333333],
            [545.346, 0.013541666666666667],
            [558.368, 0.011458333333333333],
            [559.1850000000001, 0.011458333333333333],
            [561.7909999999999, 0.03958333333333333],
            [562.955, 0.013541666666666667],
            [563.225, 0.022916666666666665],
            [564.324, 0.05416666666666667],
            [568.089, 0.011458333333333333],
            [569.6220000000001, 0.08125],
            [570.135, 0.019791666666666666],
            [570.942, 0.013541666666666667],
            [573.386, 0.025],
            [574.636, 0.017708333333333333],
            [575.417, 0.017708333333333333],
            [577.6020000000001, 0.015625],
            [579.138, 0.05],
            [579.6800000000001, 0.013541666666666667],
            [580.292, 0.011458333333333333],
            [580.772, 0.011458333333333333],
            [580.922, 0.011458333333333333],
            [581.585, 0.011458333333333333],
            [581.951, 0.013541666666666667],
            [584.047, 0.011458333333333333],
            [585.163, 0.04583333333333333],
            [585.524, 0.011458333333333333],
            [585.6220000000001, 0.058333333333333334],
            [586.073, 0.011458333333333333],
            [587.726, 0.013541666666666667],
            [588.646, 0.011458333333333333],
            [590.4069999999999, 0.011458333333333333],
            [590.456, 0.022916666666666665],
            [591.145, 0.035416666666666666],
            [591.355, 0.013541666666666667],
            [591.677, 0.011458333333333333],
            [593.029, 0.017708333333333333],
            [593.684, 0.017708333333333333],
            [593.771, 0.013541666666666667],
            [594.095, 0.011458333333333333],
            [594.278, 0.011458333333333333],
            [595.1600000000001, 0.011458333333333333],
            [595.6479999999999, 0.011458333333333333],
            [597.725, 0.017708333333333333],
            [598.802, 0.022916666666666665],
            [599.908, 0.017708333333333333],
            [600.096, 0.013541666666666667],
            [600.187, 0.015625],
            [600.457, 0.011458333333333333],
            [600.871, 0.011458333333333333],
            [602.113, 0.011458333333333333],
            [608.0649999999999, 0.011458333333333333],
            [611.4069999999999, 0.08958333333333333],
            [618.042, 0.011458333333333333],
            [618.268, 0.022916666666666665],
            [620.086, 0.022916666666666665],
            [621.171, 0.022916666666666665],
            [622.0930000000001, 0.022916666666666665],
            [623.162, 0.011458333333333333],
            [624.1659999999999, 0.015625],
            [625.212, 0.011458333333333333],
            [626.264, 0.011458333333333333],
            [627.3, 0.009375],
            [628.973, 0.017708333333333333],
            [629.287, 0.00625],
            [630.515, 0.015625],
            [630.911, 0.00625],
            [631.7189999999999, 0.005625],
            [633.135, 0.008333333333333333],
            [633.375, 0.0035416666666666665],
            [633.634, 0.0035416666666666665],
            [634.665, 0.005625],
            [635.172, 0.005625],
            [636.323, 0.0035416666666666665],
            [638.095, 0.008333333333333333],
            [638.2189999999999, 0.0035416666666666665],
            [640.855, 0.004583333333333333],
            [642.242, 0.004583333333333333],
            [642.452, 0.0035416666666666665],
            [647.029, 0.003958333333333334],
            [648.011, 0.003125],
            [653.8149999999999, 0.008333333333333333],
            [654.925, 0.004583333333333333],
            [656.478, 0.011458333333333333],
            [656.8, 0.0020833333333333333],
            [657.38, 0.0020833333333333333],
            [659.1600000000001, 0.00625],
            [659.342, 0.003125],
            [661.004, 0.0020833333333333333],
            [663.4359999999999, 0.010416666666666666],
            [664.008, 0.007291666666666667],
            [664.2760000000001, 0.0020833333333333333],
            [664.3979999999999, 0.00625],
            [664.6850000000001, 0.0020833333333333333],
            [665.355, 0.0020833333333333333],
            [667.956, 0.0020833333333333333],
            [668.1229999999999, 0.007291666666666667],
            [669.286, 0.0020833333333333333],
            [670.418, 0.0020833333333333333],
            [671.8140000000001, 0.002916666666666667],
            [672.783, 0.0035416666666666665],
            [673.073, 0.017708333333333333],
            [675.267, 0.010416666666666666],
            [675.391, 0.002916666666666667],
            [678.339, 0.002916666666666667],
            [678.633, 0.005416666666666667],
            [678.7180000000001, 0.0020833333333333333],
            [681.456, 0.0025],
            [681.649, 0.005416666666666667],
            [682.0899999999999, 0.0035416666666666665],
            [682.825, 0.020833333333333332],
            [684.6600000000001, 0.007291666666666667],
            [685.713, 0.00625],
            [686.425, 0.003125],
            [688.763, 0.004375],
            [690.073, 0.002916666666666667],
            [691.6569999999999, 0.020833333333333332],
            [692.062, 0.004375],
            [692.499, 0.003125],
            [692.649, 0.004375],
            [694.598, 0.0035416666666666665],
            [695.774, 0.003125],
            [695.924, 0.003125],
            [696.433, 0.002916666666666667],
            [697.1659999999999, 0.003125],
            [697.635, 0.0025],
            [697.827, 0.0020833333333333333],
            [698.086, 0.005416666666666667],
            [698.589, 0.010416666666666666],
            [698.875, 0.0020833333333333333],
            [699.192, 0.015625],
            [699.318, 0.004375],
            [699.676, 0.0125],
            [512.4060000000001, 0.0625],
            [534.795, 0.375],
            [536.596, 0.625],
            [541.262, 0.22916666666666666],
            [555.33, 0.8333333333333334],
            [558.788, 0.625],
            [565.8979999999999, 0.625],
            [578.696, 0.375],
            [586.2090000000001, 0.3125],
            [598.7850000000001, 0.3125],
        ],
    },
    {
        name: "germanium",
        lines: [
            [422.6562, 0.07],
            [468.5829, 0.01],
            [468.98699999999997, 0.075],
            [469.00200000000007, 0.05],
            [474.18059999999997, 1.0],
            [481.4608, 1.0],
            [482.4097, 0.05],
            [513.1752, 0.1],
            [517.8648000000001, 0.2],
            [519.4583, 0.003],
            [526.5892, 0.006],
            [551.3263, 0.006],
            [556.4741, 0.008],
            [560.701, 0.008],
            [561.6135, 0.006],
            [562.1426, 0.007],
            [565.596, 0.008],
            [566.4226, 0.006],
            [566.4842, 0.005],
            [569.1954, 0.009],
            [570.1776, 0.006],
            [571.7877000000001, 0.005],
            [580.1029000000001, 0.006],
            [580.2093, 0.009],
            [589.3389, 1.0],
            [602.1041, 0.5],
            [607.839, 0.15],
            [626.714, 0.05],
            [626.807, 0.15],
            [626.8340000000001, 0.1],
            [628.3452, 0.075],
            [633.6377, 0.1],
            [648.4181, 0.1],
            [655.7488000000001, 0.006],
            [678.051, 0.05],
            [417.896, 0.2],
            [424.541, 0.012],
            [426.08500000000004, 0.2],
            [429.171, 0.15],
            [467.436, 0.01],
            [501.688, 0.01],
            [513.475, 0.018],
            [522.937, 0.005],
            [525.661, 0.003],
        ],
    },
    {
        name: "helium",
        lines: [
            [396.473, 0.04],
            [400.927, 0.002],
            [402.619, 0.1],
            [402.636, 0.01],
            [412.082, 0.024],
            [412.099, 0.004],
            [414.37600000000003, 0.006],
            [438.793, 0.02],
            [443.755, 0.006],
            [447.14799999999997, 0.4],
            [447.168, 0.05],
            [468.53999999999996, 0.012],
            [468.57, 0.06],
            [471.31499999999994, 0.06],
            [471.338, 0.008],
            [492.19300000000004, 0.04],
            [501.56800000000004, 0.2],
            [504.774, 0.02],
            [541.152, 0.01],
            [587.562, 1.0],
            [587.597, 0.2],
            [656.01, 0.016],
            [667.8149999999999, 0.2],
            [686.7479999999999, 0.006],
            [706.519, 0.4],
            [706.571, 0.06],
        ],
    },
    {
        name: "hydrogen",
        lines: [
            [397.007, 0.044444444444444446],
            [410.174, 0.08333333333333333],
            [434.047, 0.16666666666666666],
            [486.133, 0.4444444444444444],
            [656.272, 0.6666666666666666],
            [656.2850000000001, 1.0],
        ],
    },
    {
        name: "iodine",
        lines: [
            [410.22299999999996, 0.02],
            [412.921, 0.02],
            [413.41499999999996, 0.01],
            [432.184, 0.05],
            [445.28599999999994, 0.03],
            [459.97700000000003, 0.02],
            [463.245, 0.03],
            [466.64799999999997, 0.05],
            [467.553, 0.1],
            [476.331, 0.025],
            [486.23199999999997, 0.1],
            [491.69399999999996, 0.02],
            [498.692, 0.1],
            [506.537, 0.04],
            [511.929, 1.0],
            [514.973, 0.02],
            [516.12, 0.3],
            [517.6189999999999, 0.03],
            [521.6270000000001, 0.06],
            [522.897, 0.05],
            [523.457, 0.1],
            [524.571, 0.3],
            [526.9359999999999, 0.05],
            [529.978, 0.04],
            [532.28, 0.04],
            [533.822, 1.0],
            [534.515, 0.5],
            [536.986, 0.1],
            [540.542, 0.08],
            [540.736, 0.08],
            [542.706, 0.06],
            [543.583, 0.3],
            [543.8, 0.1],
            [546.462, 0.2],
            [549.15, 0.08],
            [549.694, 0.1],
            [550.472, 0.1],
            [552.206, 0.06],
            [559.8520000000001, 0.06],
            [560.0319999999999, 0.1],
            [561.289, 0.15],
            [562.569, 1.0],
            [567.808, 0.1],
            [569.091, 0.2],
            [570.205, 0.05],
            [571.053, 0.4],
            [573.827, 0.1],
            [576.072, 0.1],
            [576.433, 0.1],
            [577.483, 0.05],
            [578.702, 0.05],
            [589.403, 0.2],
            [595.025, 0.5],
            [598.486, 0.03],
            [602.408, 0.2],
            [606.893, 0.05],
            [607.4979999999999, 0.2],
            [608.243, 0.1],
            [612.749, 0.2],
            [619.188, 0.08],
            [620.486, 0.1],
            [621.3100000000001, 0.05],
            [624.448, 0.08],
            [625.749, 0.09],
            [629.3979999999999, 0.1],
            [631.313, 0.05],
            [633.037, 0.08],
            [633.35, 0.04],
            [633.7850000000001, 0.2],
            [633.944, 0.1],
            [635.9159999999999, 0.05],
            [656.649, 0.1],
            [658.375, 0.2],
            [658.527, 0.1],
            [661.966, 0.5],
            [666.111, 0.05],
            [666.596, 0.06],
            [669.729, 0.05],
            [671.883, 0.03],
            [673.203, 0.04],
            [681.257, 0.4],
            [695.8779999999999, 0.1],
            [698.978, 0.05],
        ],
    },
    {
        name: "iron",
        lines: [
            [400.524, 0.1],
            [400.971, 0.015],
            [401.45300000000003, 0.02],
            [402.187, 0.025],
            [404.06399999999996, 0.0125],
            [404.581, 1.0],
            [406.35900000000004, 0.375],
            [406.698, 0.0125],
            [406.798, 0.0125],
            [407.174, 0.3],
            [407.663, 0.01],
            [410.07399999999996, 0.01],
            [410.74899999999997, 0.01],
            [411.854, 0.0375],
            [412.76000000000005, 0.01],
            [413.206, 0.1],
            [413.468, 0.02],
            [413.7, 0.01],
            [414.342, 0.05],
            [414.387, 0.2],
            [415.39, 0.01],
            [415.45, 0.0125],
            [415.68, 0.015],
            [417.274, 0.0125],
            [417.491, 0.015],
            [417.564, 0.0125],
            [417.759, 0.0125],
            [418.175, 0.03],
            [418.48900000000003, 0.0125],
            [418.704, 0.03],
            [418.78000000000003, 0.03],
            [419.14300000000003, 0.02],
            [419.532, 0.01],
            [419.83000000000004, 0.0375],
            [419.91, 0.01],
            [420.203, 0.075],
            [420.39799999999997, 0.01],
            [420.66999999999996, 0.02],
            [421.034, 0.02],
            [421.61800000000005, 0.1],
            [421.936, 0.025],
            [422.221, 0.0125],
            [422.596, 0.0125],
            [422.742, 0.05],
            [423.36, 0.025],
            [423.59399999999994, 0.0625],
            [423.88, 0.0125],
            [424.74300000000005, 0.0125],
            [425.012, 0.05],
            [425.079, 0.075],
            [425.832, 0.01],
            [426.047, 0.2],
            [427.11499999999995, 0.0625],
            [427.17600000000004, 0.3],
            [428.23999999999995, 0.3],
            [429.146, 0.02],
            [429.92299999999994, 0.0625],
            [430.78999999999996, 0.3],
            [431.508, 0.0375],
            [432.576, 0.375],
            [435.27299999999997, 0.02],
            [436.97700000000003, 0.02],
            [437.593, 0.2],
            [438.354, 0.75],
            [440.475, 0.3],
            [441.512, 0.075],
            [442.73, 0.15],
            [446.16499999999996, 0.1],
            [446.65500000000003, 0.03],
            [447.60200000000003, 0.02],
            [448.217, 0.02],
            [448.225, 0.05],
            [448.974, 0.0125],
            [452.861, 0.0125],
            [464.74300000000005, 0.0075],
            [473.677, 0.0075],
            [485.974, 0.0125],
            [487.13199999999995, 0.03],
            [487.21400000000006, 0.015],
            [487.821, 0.0075],
            [489.075, 0.025],
            [489.149, 0.0625],
            [490.331, 0.0075],
            [491.899, 0.0375],
            [492.05, 0.125],
            [495.76000000000005, 0.375],
            [500.186, 0.02],
            [500.191, 0.0045],
            [500.571, 0.0075],
            [500.61199999999997, 0.025],
            [501.207, 0.015],
            [501.49399999999997, 0.0075],
            [503.07700000000006, 0.00625],
            [504.17600000000004, 0.0375],
            [504.98199999999997, 0.0075],
            [505.163, 0.0075],
            [507.475, 0.00625],
            [510.073, 0.0045],
            [511.03599999999994, 0.0375],
            [513.3689999999999, 0.01],
            [513.925, 0.01],
            [513.946, 0.025],
            [515.191, 0.00625],
            [516.2270000000001, 0.0075],
            [516.6279999999999, 0.02],
            [516.749, 0.625],
            [516.889, 0.02],
            [517.1600000000001, 0.125],
            [519.145, 0.0125],
            [519.234, 0.02],
            [519.4939999999999, 0.05],
            [520.458, 0.0075],
            [521.518, 0.00625],
            [521.6270000000001, 0.0375],
            [521.6850000000001, 0.0045],
            [522.6859999999999, 0.015],
            [522.7149999999999, 0.25],
            [523.294, 0.0625],
            [526.0260000000001, 0.0045],
            [526.6560000000001, 0.025],
            [526.954, 0.3],
            [527.036, 0.2],
            [528.179, 0.0075],
            [528.362, 0.015],
            [530.23, 0.00625],
            [532.418, 0.0375],
            [532.804, 0.2],
            [532.853, 0.075],
            [533.29, 0.025],
            [533.993, 0.02],
            [534.1020000000001, 0.125],
            [536.487, 0.00625],
            [536.7470000000001, 0.01],
            [536.996, 0.0125],
            [537.149, 0.1],
            [538.337, 0.015],
            [539.317, 0.01],
            [539.713, 0.075],
            [540.412, 0.015],
            [540.577, 0.0625],
            [541.091, 0.0075],
            [541.52, 0.015],
            [542.4069999999999, 0.015],
            [542.783, 0.0075],
            [542.97, 0.0625],
            [543.452, 0.025],
            [544.687, 0.05],
            [545.545, 0.00625],
            [545.5609999999999, 0.03],
            [546.5930000000001, 0.004],
            [546.694, 0.005],
            [548.231, 0.004],
            [549.7520000000001, 0.00625],
            [550.146, 0.005],
            [550.62, 0.0045],
            [550.678, 0.0075],
            [556.962, 0.0075],
            [557.284, 0.015],
            [558.676, 0.03],
            [561.5640000000001, 0.05],
            [562.454, 0.005],
            [566.2520000000001, 0.0125],
            [576.299, 0.005],
            [586.235, 0.0075],
            [591.411, 0.0075],
            [598.696, 0.0075],
            [596.171, 0.0045],
            [596.24, 0.0075],
            [606.548, 0.01],
            [610.216, 0.0075],
            [613.661, 0.01],
            [613.769, 0.01],
            [614.7729999999999, 0.0075],
            [614.924, 0.005],
            [619.1560000000001, 0.01],
            [621.3430000000001, 0.0075],
            [621.928, 0.0075],
            [623.073, 0.01],
            [623.837, 0.005],
            [624.632, 0.005],
            [624.7560000000001, 0.02],
            [625.255, 0.0075],
            [639.36, 0.005],
            [640.0, 0.0075],
            [641.165, 0.005],
            [641.6899999999999, 0.005],
            [642.135, 0.005],
            [643.0840000000001, 0.0075],
            [644.643, 0.005],
            [645.638, 0.05],
            [649.4979999999999, 0.015],
            [651.605, 0.005],
            [654.624, 0.005],
            [659.2909999999999, 0.005],
            [667.799, 0.01],
        ],
    },
    {
        name: "lead",
        lines: [
            [401.96320000000003, 0.15789473684210525],
            [405.78069999999997, 1.0],
            [406.2136, 0.14736842105263157],
            [411.076, 5.2631578947368424e-5],
            [411.33500000000004, 4.210526315789474e-5],
            [415.282, 0.00010526315789473685],
            [415.7814, 0.00010526315789473685],
            [416.80330000000004, 0.10526315789473684],
            [424.21400000000006, 9.473684210526316e-5],
            [424.492, 0.0002105263157894737],
            [429.38199999999995, 7.368421052631579e-5],
            [429.66499999999996, 6.31578947368421e-5],
            [434.0413, 0.002105263157894737],
            [435.274, 0.00010526315789473685],
            [438.646, 0.0002105263157894737],
            [457.90510000000006, 0.00010526315789473685],
            [458.22700000000003, 0.00010526315789473685],
            [500.5416, 0.010526315789473684],
            [500.6572, 0.0010526315789473684],
            [504.258, 0.0005263157894736842],
            [507.058, 0.00010526315789473685],
            [507.453, 0.00010526315789473685],
            [507.63500000000005, 0.00010526315789473685],
            [508.94840000000005, 0.0005263157894736842],
            [509.00100000000003, 0.0002105263157894737],
            [510.7242, 0.00010526315789473685],
            [511.16400000000004, 0.00010526315789473685],
            [520.1437, 0.021052631578947368],
            [536.764, 0.00010526315789473685],
            [537.2099000000001, 0.00010526315789473685],
            [554.425, 0.00010526315789473685],
            [560.885, 0.0002105263157894737],
            [569.2346, 0.0004210526315789474],
            [589.5624, 0.002105263157894737],
            [600.1862, 0.021052631578947368],
            [600.958, 9.473684210526316e-5],
            [601.1667, 0.005263157894736842],
            [604.117, 8.421052631578948e-5],
            [605.9356, 0.005263157894736842],
            [607.574, 0.0004210526315789474],
            [608.1409, 0.0004210526315789474],
            [611.052, 0.0005263157894736842],
            [615.989, 0.00010526315789473685],
            [623.5265999999999, 0.0010526315789473684],
            [666.02, 0.0005263157894736842],
            [689.211, 0.00010526315789473685],
            [403.116, 3.157894736842105e-5],
            [409.454, 3.157894736842105e-5],
            [412.811, 2.105263157894737e-5],
            [427.26599999999996, 8.421052631578948e-5],
            [449.934, 6.31578947368421e-5],
            [457.121, 7.368421052631579e-5],
            [459.645, 1.0526315789473684e-5],
            [476.11199999999997, 6.31578947368421e-5],
            [479.85900000000004, 4.210526315789474e-5],
            [482.686, 1.0526315789473684e-5],
            [485.50600000000003, 2.105263157894737e-5],
            [506.512, 3.157894736842105e-5],
            [519.1560000000001, 4.210526315789474e-5],
            [552.397, 5.2631578947368424e-5],
            [577.941, 3.157894736842105e-5],
            [585.796, 6.31578947368421e-5],
        ],
    },
    {
        name: "lanthanum",
        lines: [
            [401.539, 0.03272727272727273],
            [402.588, 0.045454545454545456],
            [403.169, 0.509090909090909],
            [403.721, 0.025454545454545455],
            [404.291, 0.5454545454545454],
            [405.008, 0.05818181818181818],
            [406.033, 0.04],
            [406.479, 0.02909090909090909],
            [406.739, 0.15454545454545454],
            [407.671, 0.02],
            [407.735, 0.509090909090909],
            [407.918, 0.02181818181818182],
            [408.67199999999997, 1.0],
            [408.961, 0.03272727272727273],
            [409.954, 0.05090909090909091],
            [410.48699999999997, 0.02],
            [412.323, 0.8],
            [413.704, 0.02],
            [414.174, 0.1],
            [415.197, 0.2],
            [415.27799999999996, 0.04],
            [416.026, 0.01818181818181818],
            [418.73199999999997, 0.05090909090909091],
            [419.236, 0.05090909090909091],
            [419.65500000000003, 0.2727272727272727],
            [420.404, 0.04363636363636364],
            [421.75600000000003, 0.05454545454545454],
            [423.09499999999997, 0.03636363636363636],
            [423.838, 0.2909090909090909],
            [424.99899999999997, 0.025454545454545455],
            [426.35900000000004, 0.05818181818181818],
            [426.95, 0.08727272727272728],
            [427.564, 0.04363636363636364],
            [428.02700000000004, 0.05454545454545454],
            [428.697, 0.10909090909090909],
            [429.605, 0.10909090909090909],
            [430.044, 0.02181818181818182],
            [432.25100000000003, 0.08],
            [433.37399999999997, 0.8363636363636363],
            [435.43999999999994, 0.1],
            [436.467, 0.02],
            [437.197, 0.02],
            [437.584, 0.02],
            [437.81000000000006, 0.02],
            [438.34399999999994, 0.05090909090909091],
            [438.52, 0.01818181818181818],
            [441.82399999999996, 0.04],
            [442.317, 0.02909090909090909],
            [442.39, 0.02909090909090909],
            [442.755, 0.04727272727272727],
            [442.81000000000006, 0.01818181818181818],
            [442.98999999999995, 0.36363636363636365],
            [443.29799999999994, 0.02909090909090909],
            [443.80100000000004, 0.01818181818181818],
            [445.215, 0.01818181818181818],
            [445.58000000000004, 0.01818181818181818],
            [452.23699999999997, 0.15454545454545454],
            [452.53100000000006, 0.03090909090909091],
            [452.61199999999997, 0.07636363636363637],
            [455.846, 0.07272727272727272],
            [455.929, 0.02],
            [456.791, 0.02909090909090909],
            [457.00200000000007, 0.03636363636363636],
            [457.488, 0.07272727272727272],
            [458.00600000000003, 0.03636363636363636],
            [460.578, 0.02909090909090909],
            [461.33900000000006, 0.07454545454545454],
            [461.988, 0.07454545454545454],
            [464.52799999999996, 0.02],
            [465.55, 0.09818181818181818],
            [466.25100000000003, 0.06545454545454546],
            [466.37600000000003, 0.04181818181818182],
            [466.89099999999996, 0.03636363636363636],
            [467.183, 0.02909090909090909],
            [469.25, 0.04181818181818182],
            [470.328, 0.025454545454545455],
            [471.64399999999995, 0.03090909090909091],
            [471.99399999999997, 0.025454545454545455],
            [472.842, 0.04181818181818182],
            [474.02799999999996, 0.09090909090909091],
            [474.309, 0.07090909090909091],
            [474.87299999999993, 0.05818181818181818],
            [476.689, 0.02909090909090909],
            [480.404, 0.02909090909090909],
            [480.901, 0.02909090909090909],
            [482.40600000000006, 0.03636363636363636],
            [486.091, 0.05818181818181818],
            [489.992, 0.15454545454545454],
            [492.09799999999996, 0.18181818181818182],
            [492.179, 0.18181818181818182],
            [493.483, 0.025454545454545455],
            [494.64700000000005, 0.02],
            [494.97700000000003, 0.06727272727272728],
            [497.03900000000004, 0.06181818181818182],
            [498.683, 0.06727272727272728],
            [499.128, 0.025454545454545455],
            [499.947, 0.13090909090909092],
            [504.688, 0.025454545454545455],
            [505.05699999999996, 0.038181818181818185],
            [505.646, 0.03090909090909091],
            [510.62299999999993, 0.03636363636363636],
            [511.456, 0.08545454545454545],
            [512.299, 0.08545454545454545],
            [514.542, 0.08181818181818182],
            [515.674, 0.03272727272727273],
            [515.743, 0.03272727272727273],
            [515.8689999999999, 0.05272727272727273],
            [516.362, 0.02181818181818182],
            [517.731, 0.10545454545454545],
            [518.342, 0.15454545454545454],
            [518.822, 0.04727272727272727],
            [520.415, 0.03090909090909091],
            [521.1859999999999, 0.13090909090909092],
            [523.427, 0.09454545454545454],
            [525.346, 0.06181818181818182],
            [525.9390000000001, 0.02],
            [527.1189999999999, 0.06727272727272728],
            [529.0840000000001, 0.025454545454545455],
            [530.198, 0.06727272727272728],
            [530.262, 0.025454545454545455],
            [530.355, 0.03272727272727273],
            [534.067, 0.02],
            [535.786, 0.02],
            [537.7090000000001, 0.023636363636363636],
            [538.0989999999999, 0.025454545454545455],
            [545.515, 0.09090909090909091],
            [550.134, 0.08545454545454545],
            [560.25, 0.02],
            [563.1220000000001, 0.02909090909090909],
            [564.825, 0.04363636363636364],
            [565.772, 0.023636363636363636],
            [574.066, 0.03272727272727273],
            [574.441, 0.02909090909090909],
            [576.184, 0.02909090909090909],
            [576.9069999999999, 0.02909090909090909],
            [576.934, 0.06727272727272728],
            [578.924, 0.05818181818181818],
            [579.134, 0.08181818181818182],
            [579.758, 0.04],
            [580.578, 0.02909090909090909],
            [582.199, 0.025454545454545455],
            [593.062, 0.05818181818181818],
            [624.993, 0.13090909090909092],
            [626.23, 0.04727272727272727],
            [629.609, 0.03272727272727273],
            [632.039, 0.02909090909090909],
            [632.591, 0.02],
            [639.048, 0.03090909090909091],
            [639.423, 0.08181818181818182],
            [641.0989999999999, 0.038181818181818185],
            [645.5989999999999, 0.045454545454545456],
            [652.699, 0.02],
            [654.316, 0.023636363636363636],
            [657.851, 0.025454545454545455],
            [670.95, 0.03272727272727273],
            [677.426, 0.02181818181818182],
            [448.297, 0.03636363636363636],
            [449.90500000000003, 0.05454545454545454],
            [514.573, 0.0009090909090909091],
            [515.841, 0.0014545454545454545],
            [546.7810000000001, 0.001090909090909091],
            [549.1899999999999, 0.01],
            [551.172, 0.0003636363636363636],
            [551.819, 0.0001818181818181818],
            [552.954, 0.008181818181818182],
            [574.409, 0.0001818181818181818],
            [577.8140000000001, 0.03636363636363636],
            [581.345, 0.0003636363636363636],
            [587.563, 0.0005454545454545455],
            [588.862, 0.01],
            [593.271, 0.0005454545454545455],
            [601.711, 0.0003636363636363636],
            [605.5840000000001, 0.0036363636363636364],
            [611.925, 0.006363636363636364],
            [614.199, 0.02181818181818182],
            [622.0, 0.01],
            [634.821, 0.01090909090909091],
            [427.076, 0.36363636363636365],
            [454.98, 0.2727272727272727],
            [483.689, 0.09090909090909091],
        ],
    },
    {
        name: "lutetium",
        lines: [
            [405.445, 0.20303030303030303],
            [409.613, 0.022727272727272728],
            [410.74399999999997, 0.010606060606060607],
            [411.267, 0.02878787878787879],
            [412.24899999999997, 0.09393939393939393],
            [412.47299999999996, 0.9393939393939394],
            [413.179, 0.045454545454545456],
            [415.408, 0.1393939393939394],
            [415.89799999999997, 0.007272727272727273],
            [418.425, 0.48484848484848486],
            [427.75, 0.045454545454545456],
            [428.10299999999995, 0.07575757575757576],
            [429.59700000000004, 0.1],
            [430.957, 0.045454545454545456],
            [433.27200000000005, 0.022727272727272728],
            [434.198, 0.008787878787878787],
            [442.096, 0.019696969696969695],
            [443.04799999999994, 0.05757575757575758],
            [443.879, 0.010606060606060607],
            [445.081, 0.05757575757575758],
            [447.15500000000003, 0.015151515151515152],
            [449.88500000000005, 0.01818181818181818],
            [451.85699999999997, 1.0],
            [456.09499999999997, 0.007272727272727273],
            [457.53100000000006, 0.007272727272727273],
            [460.53900000000004, 0.025757575757575757],
            [464.547, 0.02878787878787879],
            [464.821, 0.030303030303030304],
            [464.88500000000005, 0.02878787878787879],
            [465.40299999999996, 0.019696969696969695],
            [465.802, 0.30303030303030304],
            [465.90299999999996, 0.025757575757575757],
            [466.175, 0.19090909090909092],
            [467.23100000000005, 0.09393939393939393],
            [468.416, 0.12727272727272726],
            [469.546, 0.08181818181818182],
            [470.8, 0.05757575757575758],
            [471.66999999999996, 0.00909090909090909],
            [472.08599999999996, 0.019696969696969695],
            [472.62, 0.019696969696969695],
            [473.5, 0.030303030303030304],
            [474.91099999999994, 0.022727272727272728],
            [476.422, 0.012121212121212121],
            [478.54200000000003, 0.045454545454545456],
            [481.505, 0.025757575757575757],
            [483.962, 0.015151515151515152],
            [486.53599999999994, 0.005454545454545455],
            [490.488, 0.1393939393939394],
            [494.23400000000004, 0.05454545454545454],
            [499.413, 0.24242424242424243],
            [500.11400000000003, 0.24242424242424243],
            [505.76000000000005, 0.016666666666666666],
            [513.405, 0.04242424242424243],
            [513.509, 0.8181818181818182],
            [517.011, 0.03939393939393939],
            [519.661, 0.051515151515151514],
            [520.647, 0.02727272727272727],
            [530.4399999999999, 0.012121212121212121],
            [534.912, 0.024242424242424242],
            [540.257, 0.15151515151515152],
            [542.1899999999999, 0.04242424242424243],
            [543.788, 0.030303030303030304],
            [545.357, 0.010606060606060607],
            [547.669, 0.6363636363636364],
            [566.489, 0.0027272727272727275],
            [571.3489999999999, 0.004242424242424243],
            [573.655, 0.16666666666666666],
            [577.54, 0.016666666666666666],
            [580.059, 0.024242424242424242],
            [586.079, 0.012121212121212121],
            [586.63, 0.0027272727272727275],
            [598.39, 0.20909090909090908],
            [599.713, 0.04242424242424243],
            [600.452, 0.42424242424242425],
            [604.1659999999999, 0.010606060606060607],
            [605.5029999999999, 0.13333333333333333],
            [614.071, 0.0033333333333333335],
            [615.9939999999999, 0.045454545454545456],
            [619.966, 0.048484848484848485],
            [622.187, 0.6363636363636364],
            [622.8140000000001, 0.010606060606060607],
            [623.536, 0.024242424242424242],
            [624.234, 0.048484848484848485],
            [624.88, 0.0048484848484848485],
            [634.5350000000001, 0.021212121212121213],
            [635.485, 0.005454545454545455],
            [636.579, 0.0027272727272727275],
            [636.6, 0.0048484848484848485],
            [644.114, 0.006666666666666667],
            [644.489, 0.0033333333333333335],
            [646.312, 0.3333333333333333],
            [647.767, 0.008787878787878787],
            [652.318, 0.016666666666666666],
            [661.1279999999999, 0.010606060606060607],
            [661.915, 0.0033333333333333335],
            [667.714, 0.0069696969696969695],
            [673.576, 0.0027272727272727275],
            [679.3770000000001, 0.00909090909090909],
            [682.659, 0.0033333333333333335],
            [691.731, 0.013636363636363636],
            [694.396, 0.0024242424242424242],
            [425.14399999999995, 0.06060606060606061],
            [427.191, 0.09090909090909091],
            [449.0, 0.06060606060606061],
            [495.64300000000003, 0.12121212121212122],
            [504.61199999999997, 0.0030303030303030303],
            [514.586, 0.045454545454545456],
            [541.942, 0.021212121212121213],
            [551.988, 0.01818181818181818],
            [552.6800000000001, 0.0015151515151515152],
            [574.871, 0.0015151515151515152],
            [578.646, 0.021212121212121213],
            [586.971, 0.024242424242424242],
            [588.976, 0.01818181818181818],
            [619.796, 0.09090909090909091],
            [619.813, 0.18181818181818182],
        ],
    },
    {
        name: "magnesium",
        lines: [
            [390.386, 0.01],
            [393.84000000000003, 0.015],
            [398.421, 0.0025],
            [398.675, 0.02],
            [405.469, 0.005],
            [405.75, 0.025],
            [407.506, 0.0075],
            [408.183, 0.005],
            [416.51000000000005, 0.01],
            [416.72700000000003, 0.0375],
            [435.191, 0.05],
            [435.453, 0.015],
            [438.038, 0.015],
            [438.46400000000006, 0.0225],
            [439.059, 0.025],
            [442.8, 0.02],
            [443.399, 0.0225],
            [443.649, 0.0125],
            [443.66, 0.01],
            [448.116, 0.035],
            [448.133, 0.03],
            [453.429, 0.015],
            [457.11, 0.07],
            [462.13, 0.0075],
            [470.299, 0.0175],
            [473.003, 0.025],
            [473.959, 0.015],
            [473.971, 0.0125],
            [485.11, 0.0175],
            [516.733, 0.1875],
            [517.268, 0.055],
            [518.361, 1.0],
            [526.421, 0.02],
            [526.437, 0.0175],
            [534.598, 0.0025],
            [540.154, 0.0225],
            [550.96, 0.005],
            [552.841, 0.015],
            [571.109, 0.075],
            [578.5310000000001, 0.0125],
            [578.556, 0.01],
            [591.643, 0.0175],
            [591.816, 0.015],
            [631.8720000000001, 0.025],
            [631.924, 0.0225],
            [631.949, 0.0175],
            [634.674, 0.025],
            [634.696, 0.0225],
            [654.597, 0.0275],
            [662.044, 0.0125],
            [662.057, 0.015],
            [663.083, 0.005],
            [678.145, 0.0175],
            [678.7850000000001, 0.02],
            [681.286, 0.0175],
            [681.927, 0.02],
            [689.49, 0.01],
            [696.54, 0.015],
            [706.0409999999999, 0.02],
        ],
    },
    {
        name: "manganese",
        lines: [
            [401.81, 0.05555555555555555],
            [402.644, 0.005555555555555556],
            [403.076, 1.0],
            [403.307, 0.7037037037037037],
            [403.44899999999996, 0.4074074074074074],
            [403.573, 0.05555555555555555],
            [403.873, 0.002037037037037037],
            [404.136, 0.2074074074074074],
            [404.51300000000003, 0.0077777777777777776],
            [404.87600000000003, 0.040740740740740744],
            [404.9, 0.002962962962962963],
            [405.173, 0.002037037037037037],
            [405.24699999999996, 0.0024074074074074076],
            [405.521, 0.005555555555555556],
            [405.554, 0.07037037037037037],
            [405.79499999999996, 0.0077777777777777776],
            [405.893, 0.040740740740740744],
            [405.93899999999996, 0.005555555555555556],
            [406.174, 0.027037037037037037],
            [406.353, 0.027037037037037037],
            [406.508, 0.002962962962962963],
            [406.8, 0.002962962962962963],
            [407.028, 0.01074074074074074],
            [407.924, 0.027037037037037037],
            [407.942, 0.027037037037037037],
            [408.294, 0.040740740740740744],
            [408.363, 0.040740740740740744],
            [408.994, 0.0024074074074074076],
            [410.53599999999994, 0.002037037037037037],
            [411.09, 0.007407407407407408],
            [413.11199999999997, 0.005555555555555556],
            [413.504, 0.0044444444444444444],
            [414.10600000000005, 0.002962962962962963],
            [414.753, 0.002037037037037037],
            [414.88, 0.002962962962962963],
            [417.66, 0.005555555555555556],
            [418.99899999999997, 0.0044444444444444444],
            [420.17600000000004, 0.0024074074074074076],
            [421.175, 0.0024074074074074076],
            [423.514, 0.013703703703703704],
            [423.529, 0.01888888888888889],
            [423.97200000000004, 0.007037037037037037],
            [424.617, 0.003703703703703704],
            [425.76599999999996, 0.01074074074074074],
            [426.592, 0.01074074074074074],
            [428.11, 0.01],
            [428.408, 0.0024074074074074076],
            [431.255, 0.0024074074074074076],
            [432.363, 0.001851851851851852],
            [437.495, 0.0016666666666666668],
            [438.16999999999996, 0.0016666666666666668],
            [441.188, 0.002037037037037037],
            [441.488, 0.012962962962962963],
            [441.97799999999995, 0.002037037037037037],
            [443.63500000000005, 0.0077777777777777776],
            [445.159, 0.02962962962962963],
            [445.3, 0.005925925925925926],
            [445.50100000000003, 0.004814814814814815],
            [445.532, 0.005925925925925926],
            [445.582, 0.004074074074074074],
            [445.704, 0.002037037037037037],
            [445.755, 0.0077777777777777776],
            [445.826, 0.01],
            [446.038, 0.002037037037037037],
            [446.108, 0.005555555555555556],
            [446.20200000000006, 0.01888888888888889],
            [446.468, 0.01074074074074074],
            [447.014, 0.007407407407407408],
            [447.279, 0.004814814814814815],
            [447.93999999999994, 0.0014814814814814814],
            [449.008, 0.006296296296296296],
            [449.89, 0.008888888888888889],
            [450.22200000000004, 0.008888888888888889],
            [460.53599999999994, 0.002962962962962963],
            [462.654, 0.002962962962962963],
            [467.169, 0.0012962962962962963],
            [470.116, 0.001851851851851852],
            [470.97200000000004, 0.005925925925925926],
            [472.74799999999993, 0.006666666666666667],
            [473.91099999999994, 0.004814814814814815],
            [475.404, 0.037037037037037035],
            [476.15299999999996, 0.006666666666666667],
            [476.238, 0.027777777777777776],
            [476.58599999999996, 0.011111111111111112],
            [476.64300000000003, 0.018518518518518517],
            [478.342, 0.03481481481481481],
            [482.35200000000003, 0.037037037037037035],
            [484.43199999999996, 0.000925925925925926],
            [496.588, 0.0012962962962962963],
            [500.491, 0.0007037037037037037],
            [507.479, 0.0011111111111111111],
            [511.794, 0.0022222222222222222],
            [515.089, 0.001851851851851852],
            [519.659, 0.001851851851851852],
            [525.5319999999999, 0.003148148148148148],
            [534.106, 0.005925925925925926],
            [534.988, 0.0007037037037037037],
            [537.763, 0.0035185185185185185],
            [539.467, 0.0035185185185185185],
            [539.949, 0.001851851851851852],
            [540.742, 0.0035185185185185185],
            [541.3689999999999, 0.0012962962962962963],
            [542.036, 0.003148148148148148],
            [543.255, 0.0012962962962962963],
            [545.7470000000001, 0.00044444444444444447],
            [547.0640000000001, 0.0022222222222222222],
            [548.14, 0.0014814814814814814],
            [550.587, 0.0011111111111111111],
            [551.677, 0.001851851851851852],
            [553.7760000000001, 0.0014814814814814814],
            [555.198, 0.0007777777777777777],
            [556.7760000000001, 0.0002962962962962963],
            [557.301, 0.00025925925925925926],
            [557.368, 0.0002962962962962963],
            [573.829, 0.00025925925925925926],
            [578.019, 0.00025925925925925926],
            [581.684, 0.00025925925925925926],
            [601.35, 0.005185185185185185],
            [601.664, 0.007407407407407408],
            [602.1800000000001, 0.01074074074074074],
            [638.467, 0.00025925925925925926],
            [644.097, 0.0006296296296296296],
            [649.171, 0.0008888888888888889],
            [694.2520000000001, 0.0005185185185185185],
            [698.996, 0.00044444444444444447],
            [507.91999999999996, 0.007407407407407408],
            [510.003, 0.005555555555555556],
            [511.703, 0.003703703703703704],
            [525.223, 0.003703703703703704],
            [536.559, 0.003703703703703704],
            [545.4069999999999, 0.005555555555555556],
            [547.4680000000001, 0.007407407407407408],
            [567.112, 0.003703703703703704],
            [594.665, 0.007407407407407408],
            [621.3109999999999, 0.003703703703703704],
            [623.121, 0.007407407407407408],
            [623.864, 0.003703703703703704],
            [627.371, 0.003703703703703704],
        ],
    },
    {
        name: "molybdenum",
        lines: [
            [405.601, 0.03461538461538462],
            [406.20799999999997, 0.1794871794871795],
            [406.988, 0.2948717948717949],
            [408.144, 0.16666666666666666],
            [408.438, 0.12051282051282051],
            [410.215, 0.03205128205128205],
            [410.747, 0.09358974358974359],
            [412.01000000000005, 0.08076923076923077],
            [414.355, 0.3717948717948718],
            [414.89399999999995, 0.029487179487179487],
            [415.52799999999996, 0.03205128205128205],
            [415.73999999999995, 0.02564102564102564],
            [417.82700000000006, 0.02564102564102564],
            [418.582, 0.06153846153846154],
            [418.832, 0.32051282051282054],
            [419.456, 0.03205128205128205],
            [423.259, 0.19230769230769232],
            [426.928, 0.03461538461538462],
            [427.691, 0.1141025641025641],
            [427.724, 0.15384615384615385],
            [428.86400000000003, 0.1794871794871795],
            [429.213, 0.08717948717948718],
            [429.321, 0.1141025641025641],
            [429.38800000000003, 0.046153846153846156],
            [432.61400000000003, 0.1076923076923077],
            [432.674, 0.03205128205128205],
            [435.034, 0.029487179487179487],
            [436.904, 0.029487179487179487],
            [438.16400000000004, 0.24358974358974358],
            [441.157, 0.32051282051282054],
            [442.36199999999997, 0.026923076923076925],
            [443.495, 0.12692307692307692],
            [444.21999999999997, 0.02564102564102564],
            [444.974, 0.04358974358974359],
            [445.736, 0.06153846153846154],
            [447.456, 0.08076923076923077],
            [449.128, 0.029487179487179487],
            [450.48999999999995, 0.015384615384615385],
            [451.215, 0.017948717948717947],
            [451.713, 0.029487179487179487],
            [452.434, 0.029487179487179487],
            [452.93999999999994, 0.015384615384615385],
            [453.68, 0.05128205128205128],
            [455.811, 0.014102564102564103],
            [457.65, 0.026923076923076925],
            [459.51599999999996, 0.021794871794871794],
            [460.988, 0.046153846153846156],
            [462.13800000000003, 0.01282051282051282],
            [462.64700000000005, 0.05897435897435897],
            [462.74799999999993, 0.01282051282051282],
            [466.276, 0.028205128205128206],
            [467.18999999999994, 0.016666666666666666],
            [468.822, 0.016666666666666666],
            [470.726, 0.08205128205128205],
            [470.822, 0.019230769230769232],
            [471.79200000000003, 0.028205128205128206],
            [472.91400000000004, 0.01282051282051282],
            [473.14399999999995, 0.08974358974358974],
            [475.03900000000004, 0.01282051282051282],
            [476.01899999999995, 0.09871794871794871],
            [477.634, 0.019230769230769232],
            [479.65200000000004, 0.01282051282051282],
            [481.925, 0.052564102564102565],
            [483.05100000000004, 0.052564102564102565],
            [486.8, 0.046153846153846156],
            [495.062, 0.014102564102564103],
            [495.754, 0.019230769230769232],
            [497.912, 0.026923076923076925],
            [499.991, 0.014102564102564103],
            [501.081, 0.002564102564102564],
            [501.46000000000004, 0.023076923076923078],
            [501.678, 0.0033333333333333335],
            [501.985, 0.002564102564102564],
            [502.9, 0.010256410256410256],
            [503.078, 0.008333333333333333],
            [503.89099999999996, 0.002948717948717949],
            [504.65200000000004, 0.0033333333333333335],
            [504.771, 0.01282051282051282],
            [505.5, 0.00641025641025641],
            [505.80699999999996, 0.004487179487179487],
            [505.988, 0.02564102564102564],
            [506.25200000000007, 0.004487179487179487],
            [506.46400000000006, 0.003717948717948718],
            [507.98699999999997, 0.004487179487179487],
            [508.00200000000007, 0.01282051282051282],
            [508.12600000000003, 0.004487179487179487],
            [509.09700000000004, 0.005128205128205128],
            [509.134, 0.004487179487179487],
            [509.216, 0.004487179487179487],
            [509.58900000000006, 0.005128205128205128],
            [509.66499999999996, 0.01282051282051282],
            [509.75200000000007, 0.016666666666666666],
            [509.803, 0.004487179487179487],
            [510.971, 0.016666666666666666],
            [511.497, 0.010256410256410256],
            [511.697, 0.004487179487179487],
            [512.383, 0.003717948717948718],
            [514.538, 0.019230769230769232],
            [514.739, 0.014102564102564103],
            [516.319, 0.010256410256410256],
            [516.7760000000001, 0.01282051282051282],
            [517.108, 0.020512820512820513],
            [517.294, 0.029487179487179487],
            [517.418, 0.020512820512820513],
            [519.144, 0.005128205128205128],
            [520.017, 0.014102564102564103],
            [520.074, 0.00641025641025641],
            [521.044, 0.0033333333333333335],
            [521.1859999999999, 0.00641025641025641],
            [521.9399999999999, 0.010256410256410256],
            [523.106, 0.008333333333333333],
            [523.236, 0.0033333333333333335],
            [523.426, 0.01282051282051282],
            [523.8199999999999, 0.05897435897435897],
            [524.088, 0.029487179487179487],
            [524.2810000000001, 0.014102564102564103],
            [524.551, 0.01282051282051282],
            [525.904, 0.019230769230769232],
            [526.017, 0.0020512820512820513],
            [526.114, 0.008333333333333333],
            [526.895, 0.002564102564102564],
            [527.1800000000001, 0.004487179487179487],
            [527.6279999999999, 0.004487179487179487],
            [527.9649999999999, 0.008333333333333333],
            [528.086, 0.026923076923076925],
            [528.384, 0.002564102564102564],
            [529.208, 0.007051282051282051],
            [529.346, 0.004487179487179487],
            [529.547, 0.007051282051282051],
            [530.626, 0.002564102564102564],
            [531.389, 0.007051282051282051],
            [531.504, 0.004487179487179487],
            [531.989, 0.002564102564102564],
            [532.447, 0.002564102564102564],
            [532.706, 0.004487179487179487],
            [535.235, 0.002564102564102564],
            [535.488, 0.010256410256410256],
            [535.551, 0.004487179487179487],
            [535.6479999999999, 0.008333333333333333],
            [536.056, 0.07179487179487179],
            [536.428, 0.014102564102564103],
            [536.711, 0.004487179487179487],
            [537.24, 0.004487179487179487],
            [538.8689999999999, 0.0033333333333333335],
            [539.452, 0.008333333333333333],
            [539.738, 0.004487179487179487],
            [540.047, 0.00641025641025641],
            [540.579, 0.004487179487179487],
            [540.639, 0.004487179487179487],
            [541.738, 0.005128205128205128],
            [542.6890000000001, 0.002948717948717949],
            [543.568, 0.007051282051282051],
            [543.775, 0.008333333333333333],
            [545.051, 0.005128205128205128],
            [545.646, 0.004487179487179487],
            [546.053, 0.0033333333333333335],
            [546.557, 0.002948717948717949],
            [547.5899999999999, 0.004487179487179487],
            [549.028, 0.004487179487179487],
            [549.217, 0.002564102564102564],
            [549.38, 0.0033333333333333335],
            [549.8489999999999, 0.0033333333333333335],
            [550.154, 0.00641025641025641],
            [550.187, 0.002948717948717949],
            [550.354, 0.0033333333333333335],
            [550.649, 1.0],
            [552.004, 0.002948717948717949],
            [552.0640000000001, 0.0033333333333333335],
            [552.652, 0.005128205128205128],
            [552.697, 0.005128205128205128],
            [553.3050000000001, 0.6666666666666666],
            [553.941, 0.005128205128205128],
            [554.312, 0.00641025641025641],
            [554.449, 0.005128205128205128],
            [555.6279999999999, 0.007051282051282051],
            [555.672, 0.0033333333333333335],
            [556.405, 0.002564102564102564],
            [556.862, 0.005128205128205128],
            [556.948, 0.0033333333333333335],
            [557.045, 0.32051282051282054],
            [557.519, 0.004487179487179487],
            [559.158, 0.002564102564102564],
            [560.2760000000001, 0.005128205128205128],
            [560.862, 0.002948717948717949],
            [560.923, 0.002948717948717949],
            [561.0930000000001, 0.01282051282051282],
            [561.307, 0.002948717948717949],
            [561.845, 0.002564102564102564],
            [561.938, 0.002948717948717949],
            [563.2470000000001, 0.04230769230769231],
            [563.486, 0.00641025641025641],
            [565.013, 0.029487179487179487],
            [567.363, 0.002948717948717949],
            [567.447, 0.007051282051282051],
            [567.789, 0.005128205128205128],
            [568.289, 0.004487179487179487],
            [568.914, 0.05897435897435897],
            [569.928, 0.002948717948717949],
            [570.572, 0.010256410256410256],
            [571.1800000000001, 0.002948717948717949],
            [572.274, 0.026923076923076925],
            [572.8770000000001, 0.002948717948717949],
            [572.9449999999999, 0.0033333333333333335],
            [575.14, 0.07948717948717948],
            [577.455, 0.002948717948717949],
            [577.9359999999999, 0.005128205128205128],
            [578.333, 0.002948717948717949],
            [579.1850000000001, 0.06666666666666667],
            [579.577, 0.002948717948717949],
            [580.046, 0.0033333333333333335],
            [580.267, 0.004487179487179487],
            [582.52, 0.002948717948717949],
            [583.559, 0.002948717948717949],
            [583.999, 0.002564102564102564],
            [584.886, 0.002564102564102564],
            [584.973, 0.007051282051282051],
            [585.152, 0.00641025641025641],
            [585.827, 0.06666666666666667],
            [586.138, 0.002564102564102564],
            [586.933, 0.00641025641025641],
            [587.659, 0.0033333333333333335],
            [588.833, 0.10512820512820513],
            [589.229, 0.002948717948717949],
            [589.338, 0.00641025641025641],
            [589.8779999999999, 0.002564102564102564],
            [590.147, 0.005128205128205128],
            [592.636, 0.005128205128205128],
            [592.888, 0.020512820512820513],
            [598.817, 0.005128205128205128],
            [602.549, 0.004487179487179487],
            [602.7270000000001, 0.0020512820512820513],
            [603.066, 0.16666666666666666],
            [604.783, 0.002564102564102564],
            [605.481, 0.002564102564102564],
            [607.958, 0.002564102564102564],
            [608.1270000000001, 0.001282051282051282],
            [610.187, 0.005128205128205128],
            [613.063, 0.001282051282051282],
            [619.766, 0.001282051282051282],
            [621.789, 0.002564102564102564],
            [626.427, 0.001282051282051282],
            [626.588, 0.0020512820512820513],
            [629.074, 0.0019230769230769232],
            [630.175, 0.0016666666666666668],
            [632.354, 0.0014102564102564104],
            [635.722, 0.005128205128205128],
            [638.911, 0.0020512820512820513],
            [639.112, 0.0014102564102564104],
            [640.107, 0.004487179487179487],
            [640.911, 0.0033333333333333335],
            [641.239, 0.001282051282051282],
            [642.437, 0.01282051282051282],
            [644.634, 0.002564102564102564],
            [647.12, 0.002564102564102564],
            [647.399, 0.002564102564102564],
            [649.313, 0.001282051282051282],
            [651.984, 0.002948717948717949],
            [661.12, 0.0019230769230769232],
            [661.913, 0.029487179487179487],
            [662.457, 0.001282051282051282],
            [665.038, 0.00641025641025641],
            [665.9680000000001, 0.0016666666666666668],
            [669.047, 0.002307692307692308],
            [673.3979999999999, 0.014102564102564103],
            [674.608, 0.002692307692307692],
            [674.6270000000001, 0.00641025641025641],
            [675.397, 0.004487179487179487],
            [676.35, 0.0016666666666666668],
            [679.988, 0.001282051282051282],
            [680.262, 0.001282051282051282],
            [681.203, 0.001282051282051282],
            [682.563, 0.0016666666666666668],
            [682.887, 0.002307692307692308],
            [683.888, 0.005128205128205128],
            [684.892, 0.0020512820512820513],
            [688.6279999999999, 0.002692307692307692],
            [689.236, 0.0020512820512820513],
            [689.801, 0.001282051282051282],
            [689.8979999999999, 0.001282051282051282],
            [690.8199999999999, 0.0016666666666666668],
            [691.4010000000001, 0.004487179487179487],
            [693.4100000000001, 0.0016666666666666668],
            [694.739, 0.001282051282051282],
            [696.0640000000001, 0.001282051282051282],
            [697.871, 0.0020512820512820513],
            [698.894, 0.0033333333333333335],
            [699.913, 0.0015384615384615385],
        ],
    },
    {
        name: "neodymium",
        lines: [
            [400.05, 0.07592592592592592],
            [400.402, 0.1],
            [400.743, 0.07592592592592592],
            [401.225, 0.6851851851851852],
            [401.27, 0.1],
            [401.881, 0.06851851851851852],
            [402.087, 0.18518518518518517],
            [402.134, 0.18518518518518517],
            [402.178, 0.18518518518518517],
            [402.3, 0.2222222222222222],
            [402.478, 0.06296296296296296],
            [403.04699999999997, 0.07592592592592592],
            [403.182, 0.2222222222222222],
            [403.812, 0.05],
            [404.08000000000004, 0.5555555555555556],
            [404.106, 0.037037037037037035],
            [404.35900000000004, 0.07592592592592592],
            [404.881, 0.07592592592592592],
            [405.115, 0.1574074074074074],
            [405.996, 0.1574074074074074],
            [406.10900000000004, 0.8703703703703703],
            [406.928, 0.2037037037037037],
            [407.512, 0.13148148148148148],
            [407.528, 0.08703703703703704],
            [407.762, 0.044444444444444446],
            [408.023, 0.08703703703703704],
            [408.582, 0.044444444444444446],
            [409.613, 0.05],
            [409.81800000000004, 0.040740740740740744],
            [410.659, 0.037037037037037035],
            [410.908, 0.25925925925925924],
            [410.946, 0.46296296296296297],
            [411.04799999999994, 0.09444444444444444],
            [411.383, 0.05555555555555555],
            [412.38800000000003, 0.07592592592592592],
            [413.33599999999996, 0.08703703703703704],
            [413.533, 0.09444444444444444],
            [415.608, 0.5555555555555556],
            [415.62600000000003, 0.09444444444444444],
            [416.05699999999996, 0.06296296296296296],
            [416.8, 0.07592592592592592],
            [417.561, 0.15],
            [417.73199999999997, 0.4444444444444444],
            [417.86400000000003, 0.037037037037037035],
            [417.959, 0.11851851851851852],
            [418.49799999999993, 0.046296296296296294],
            [420.56000000000006, 0.08703703703703704],
            [421.129, 0.08703703703703704],
            [422.025, 0.053703703703703705],
            [422.77299999999997, 0.08148148148148149],
            [423.238, 0.24074074074074073],
            [423.419, 0.046296296296296294],
            [423.524, 0.053703703703703705],
            [423.98400000000004, 0.053703703703703705],
            [424.738, 0.37037037037037035],
            [425.24399999999997, 0.1574074074074074],
            [425.429, 0.053703703703703705],
            [426.184, 0.07592592592592592],
            [426.671, 0.06296296296296296],
            [427.05600000000004, 0.044444444444444446],
            [427.279, 0.06296296296296296],
            [427.509, 0.06296296296296296],
            [428.24399999999997, 0.08703703703703704],
            [428.25699999999995, 0.044444444444444446],
            [428.45200000000006, 0.13148148148148148],
            [429.78000000000003, 0.05],
            [430.358, 1.0],
            [430.445, 0.06296296296296296],
            [430.77799999999996, 0.037037037037037035],
            [431.45200000000006, 0.08703703703703704],
            [432.576, 0.2037037037037037],
            [432.793, 0.09444444444444444],
            [433.87, 0.1],
            [435.129, 0.1259259259259259],
            [435.817, 0.1574074074074074],
            [436.63800000000003, 0.044444444444444446],
            [436.86400000000003, 0.06296296296296296],
            [437.49300000000005, 0.08703703703703704],
            [438.566, 0.13148148148148148],
            [439.066, 0.046296296296296294],
            [440.08299999999997, 0.1],
            [441.10600000000005, 0.09444444444444444],
            [444.639, 0.10740740740740741],
            [445.157, 0.25925925925925924],
            [445.19899999999996, 0.037037037037037035],
            [445.64, 0.05555555555555555],
            [446.299, 0.13703703703703704],
            [450.18199999999996, 0.07592592592592592],
            [450.659, 0.037037037037037035],
            [451.334, 0.03148148148148148],
            [451.63599999999997, 0.046296296296296294],
            [452.725, 0.022222222222222223],
            [454.12700000000007, 0.06296296296296296],
            [454.26099999999997, 0.06296296296296296],
            [455.61400000000003, 0.018518518518518517],
            [455.967, 0.03148148148148148],
            [456.322, 0.06296296296296296],
            [457.889, 0.037037037037037035],
            [457.93199999999996, 0.037037037037037035],
            [458.662, 0.018518518518518517],
            [459.70200000000006, 0.037037037037037035],
            [460.38199999999995, 0.018518518518518517],
            [460.98699999999997, 0.018518518518518517],
            [462.19399999999996, 0.05555555555555555],
            [462.79799999999994, 0.018518518518518517],
            [463.424, 0.09444444444444444],
            [464.11, 0.06296296296296296],
            [464.57700000000006, 0.046296296296296294],
            [464.64, 0.037037037037037035],
            [464.967, 0.05555555555555555],
            [465.47299999999996, 0.037037037037037035],
            [467.05600000000004, 0.024074074074074074],
            [468.07399999999996, 0.03148148148148148],
            [468.34499999999997, 0.05740740740740741],
            [468.404, 0.020370370370370372],
            [469.035, 0.020370370370370372],
            [469.64399999999995, 0.03518518518518519],
            [470.35699999999997, 0.024074074074074074],
            [470.654, 0.08703703703703704],
            [470.696, 0.025925925925925925],
            [470.971, 0.03518518518518519],
            [471.559, 0.03518518518518519],
            [471.90200000000004, 0.044444444444444446],
            [472.43500000000006, 0.03518518518518519],
            [473.177, 0.025925925925925925],
            [477.946, 0.022222222222222223],
            [478.941, 0.03148148148148148],
            [479.715, 0.022222222222222223],
            [481.134, 0.044444444444444446],
            [482.034, 0.025925925925925925],
            [482.54799999999994, 0.06481481481481481],
            [483.22799999999995, 0.024074074074074074],
            [484.90600000000006, 0.020370370370370372],
            [485.90200000000004, 0.05185185185185185],
            [486.674, 0.03518518518518519],
            [488.38100000000003, 0.06481481481481481],
            [488.91, 0.025925925925925925],
            [489.07, 0.040740740740740744],
            [489.10699999999997, 0.044444444444444446],
            [489.69300000000004, 0.05185185185185185],
            [490.15299999999996, 0.022222222222222223],
            [490.184, 0.03888888888888889],
            [490.203, 0.020370370370370372],
            [491.341, 0.03518518518518519],
            [491.437, 0.03148148148148148],
            [492.06800000000004, 0.06111111111111111],
            [492.453, 0.08703703703703704],
            [494.483, 0.04814814814814815],
            [495.47799999999995, 0.053703703703703705],
            [495.913, 0.053703703703703705],
            [496.139, 0.027777777777777776],
            [498.99399999999997, 0.046296296296296294],
            [503.35200000000003, 0.027777777777777776],
            [506.37299999999993, 0.020370370370370372],
            [507.659, 0.06666666666666667],
            [508.98400000000004, 0.027777777777777776],
            [509.28000000000003, 0.06666666666666667],
            [510.23900000000003, 0.03333333333333333],
            [510.521, 0.027777777777777776],
            [510.759, 0.06666666666666667],
            [512.379, 0.06296296296296296],
            [513.0600000000001, 0.1259259259259259],
            [513.233, 0.03148148148148148],
            [516.514, 0.03148148148148148],
            [518.117, 0.024074074074074074],
            [518.26, 0.022222222222222223],
            [519.145, 0.09259259259259259],
            [519.262, 0.11666666666666667],
            [520.012, 0.06111111111111111],
            [521.237, 0.05740740740740741],
            [521.323, 0.027777777777777776],
            [522.505, 0.024074074074074074],
            [522.8430000000001, 0.024074074074074074],
            [523.42, 0.08333333333333333],
            [523.979, 0.046296296296296294],
            [524.9590000000001, 0.13333333333333333],
            [525.082, 0.037037037037037035],
            [525.551, 0.06666666666666667],
            [526.948, 0.022222222222222223],
            [527.3430000000001, 0.10925925925925926],
            [527.688, 0.027777777777777776],
            [529.167, 0.020370370370370372],
            [529.317, 0.1259259259259259],
            [530.228, 0.02962962962962963],
            [530.647, 0.020370370370370372],
            [531.146, 0.040740740740740744],
            [531.982, 0.09259259259259259],
            [535.698, 0.03333333333333333],
            [536.147, 0.053703703703703705],
            [537.194, 0.027777777777777776],
            [538.5899999999999, 0.020370370370370372],
            [543.153, 0.02962962962962963],
            [545.112, 0.020370370370370372],
            [548.5699999999999, 0.03148148148148148],
            [550.147, 0.006481481481481481],
            [552.572, 0.008333333333333333],
            [553.382, 0.016666666666666666],
            [553.527, 0.010185185185185186],
            [554.324, 0.010185185185185186],
            [554.847, 0.010185185185185186],
            [556.117, 0.010185185185185186],
            [557.55, 0.005],
            [557.67, 0.005],
            [557.77, 0.005],
            [558.761, 0.005],
            [559.443, 0.044444444444444446],
            [560.143, 0.010185185185185186],
            [560.192, 0.008333333333333333],
            [562.054, 0.040740740740740744],
            [563.576, 0.012037037037037037],
            [563.954, 0.008333333333333333],
            [565.357, 0.006481481481481481],
            [566.887, 0.012962962962962963],
            [566.9770000000001, 0.012037037037037037],
            [567.597, 0.025925925925925925],
            [567.633, 0.010185185185185186],
            [568.853, 0.040740740740740744],
            [568.951, 0.0042592592592592595],
            [570.1569999999999, 0.005555555555555556],
            [570.2239999999999, 0.024074074074074074],
            [570.621, 0.014814814814814815],
            [570.828, 0.02962962962962963],
            [571.812, 0.014814814814814815],
            [572.683, 0.012037037037037037],
            [572.929, 0.018518518518518517],
            [573.455, 0.0042592592592592595],
            [574.086, 0.012962962962962963],
            [574.919, 0.010185185185185186],
            [574.966, 0.005],
            [576.733, 0.0042592592592592595],
            [577.612, 0.008333333333333333],
            [578.496, 0.008333333333333333],
            [578.822, 0.008333333333333333],
            [580.009, 0.008333333333333333],
            [580.402, 0.02962962962962963],
            [581.1569999999999, 0.014814814814814815],
            [581.389, 0.008333333333333333],
            [582.037, 0.005],
            [582.587, 0.012962962962962963],
            [582.674, 0.005555555555555556],
            [584.239, 0.014814814814814815],
            [584.466, 0.005555555555555556],
            [584.595, 0.0042592592592592595],
            [585.891, 0.010185185185185186],
            [586.708, 0.006481481481481481],
            [586.89, 0.005555555555555556],
            [587.104, 0.005],
            [588.329, 0.005555555555555556],
            [588.624, 0.0042592592592592595],
            [588.7909999999999, 0.005555555555555556],
            [592.1220000000001, 0.005],
            [595.587, 0.005],
            [599.476, 0.005555555555555556],
            [599.647, 0.005],
            [600.767, 0.008333333333333333],
            [603.1270000000001, 0.006481481481481481],
            [603.329, 0.005],
            [603.424, 0.008333333333333333],
            [606.603, 0.010185185185185186],
            [607.17, 0.005],
            [607.397, 0.005555555555555556],
            [613.347, 0.0042592592592592595],
            [614.928, 0.005],
            [615.5060000000001, 0.005],
            [615.783, 0.006481481481481481],
            [616.667, 0.0042592592592592595],
            [617.049, 0.006481481481481481],
            [617.859, 0.008333333333333333],
            [618.391, 0.005],
            [620.824, 0.005],
            [622.339, 0.008333333333333333],
            [622.65, 0.005],
            [623.85, 0.0042592592592592595],
            [624.408, 0.006481481481481481],
            [625.749, 0.0042592592592592595],
            [625.8729999999999, 0.005],
            [627.729, 0.0042592592592592595],
            [628.579, 0.005],
            [629.284, 0.0042592592592592595],
            [629.707, 0.0042592592592592595],
            [631.049, 0.010185185185185186],
            [634.1510000000001, 0.005],
            [638.207, 0.0042592592592592595],
            [638.52, 0.012037037037037037],
            [648.569, 0.006481481481481481],
            [663.014, 0.008333333333333333],
            [663.796, 0.006481481481481481],
            [665.057, 0.008333333333333333],
            [665.567, 0.005555555555555556],
            [673.779, 0.004629629629629629],
            [674.011, 0.007407407407407408],
            [674.254, 0.004629629629629629],
            [679.037, 0.005555555555555556],
            [680.4, 0.005555555555555556],
            [684.672, 0.004629629629629629],
            [690.043, 0.007407407407407408],
            [694.139, 0.0044444444444444444],
        ],
    },
    {
        name: "neptunium",
        lines: [
            [504.466, 0.1],
            [560.17, 0.1],
            [565.275, 0.1],
            [578.4390000000001, 0.1],
            [587.804, 0.1],
            [601.1220000000001, 0.1],
            [605.609, 0.1],
            [607.39, 0.1],
            [608.005, 0.1],
            [612.049, 0.1],
            [618.859, 0.1],
            [620.0, 0.1],
            [621.5899999999999, 0.1],
            [631.784, 0.1],
            [634.138, 0.1],
            [656.611, 0.1],
            [672.068, 0.1],
            [675.132, 0.1],
            [679.521, 0.1],
            [680.262, 0.1],
            [680.581, 0.1],
            [681.644, 0.1],
            [686.545, 0.1],
            [690.713, 0.1],
            [691.2909999999999, 0.1],
            [693.0310000000001, 0.3333333333333333],
            [696.363, 0.1],
            [697.2090000000001, 1.0],
        ],
    },
    {
        name: "nitrogen",
        lines: [
            [391.9, 0.36],
            [395.585, 0.45],
            [399.5, 1.0],
            [403.508, 0.36],
            [404.131, 0.55],
            [404.353, 0.36],
            [409.99399999999997, 0.14],
            [410.995, 0.185],
            [417.616, 0.285],
            [422.774, 0.285],
            [423.691, 0.285],
            [423.70500000000004, 0.22],
            [424.178, 0.45],
            [443.274, 0.285],
            [444.703, 0.65],
            [453.041, 0.36],
            [460.14799999999997, 0.55],
            [460.716, 0.45],
            [461.387, 0.36],
            [462.139, 0.45],
            [463.054, 0.87],
            [464.308, 0.55],
            [478.813, 0.285],
            [480.329, 0.45],
            [484.738, 0.18],
            [489.51099999999997, 0.285],
            [491.49399999999997, 0.16],
            [493.512, 0.21],
            [495.02299999999997, 0.16],
            [496.39799999999997, 0.35],
            [498.73699999999997, 0.285],
            [499.436, 0.45],
            [500.14799999999997, 0.65],
            [500.27, 0.36],
            [500.515, 0.87],
            [500.73199999999997, 0.55],
            [501.062, 0.45],
            [501.639, 0.36],
            [502.566, 0.36],
            [504.51000000000005, 0.55],
            [528.12, 0.185],
            [529.268, 0.14],
            [549.567, 0.45],
            [553.536, 0.285],
            [566.663, 0.65],
            [567.6020000000001, 0.55],
            [567.956, 0.87],
            [568.621, 0.45],
            [571.077, 0.45],
            [574.73, 0.285],
            [575.25, 0.7],
            [576.475, 0.24],
            [582.954, 0.265],
            [585.404, 0.235],
            [592.7810000000001, 0.36],
            [593.178, 0.55],
            [594.024, 0.285],
            [594.165, 0.65],
            [595.239, 0.285],
            [599.943, 0.16],
            [600.847, 0.21],
            [616.7760000000001, 0.285],
            [637.962, 0.36],
            [641.165, 0.185],
            [642.0640000000001, 0.21],
            [642.302, 0.21],
            [642.832, 0.21],
            [643.768, 0.185],
            [644.0939999999999, 0.235],
            [645.79, 0.185],
            [646.8439999999999, 0.3],
            [648.205, 0.75],
            [648.27, 0.36],
            [648.375, 0.3],
            [648.171, 0.265],
            [648.48, 0.325],
            [649.1220000000001, 0.16],
            [649.954, 0.21],
            [650.6310000000001, 0.185],
            [661.056, 0.75],
            [662.254, 0.185],
            [663.694, 0.185],
            [664.496, 0.235],
            [664.65, 0.185],
            [665.346, 0.235],
            [665.6510000000001, 0.21],
            [672.262, 0.185],
        ],
    },
    {
        name: "osmium",
        lines: [
            [400.402, 0.02040816326530612],
            [400.51599999999996, 0.030612244897959183],
            [401.826, 0.0326530612244898],
            [403.784, 0.02040816326530612],
            [404.192, 0.05714285714285714],
            [404.805, 0.0326530612244898],
            [406.669, 0.19591836734693877],
            [407.086, 0.05102040816326531],
            [407.156, 0.03877551020408163],
            [407.46799999999996, 0.04693877551020408],
            [409.182, 0.1],
            [410.03000000000003, 0.024489795918367346],
            [411.20200000000006, 0.24489795918367346],
            [412.46000000000004, 0.036734693877551024],
            [412.896, 0.036734693877551024],
            [413.578, 0.5102040816326531],
            [413.784, 0.030612244897959183],
            [417.25699999999995, 0.036734693877551024],
            [417.323, 0.24489795918367346],
            [417.563, 0.12653061224489795],
            [418.413, 0.024489795918367346],
            [418.991, 0.0653061224489796],
            [420.145, 0.036734693877551024],
            [420.206, 0.05102040816326531],
            [421.186, 0.24489795918367346],
            [421.38599999999997, 0.024489795918367346],
            [421.51599999999996, 0.02040816326530612],
            [423.346, 0.03469387755102041],
            [426.08500000000004, 1.0],
            [426.475, 0.02040816326530612],
            [426.96099999999996, 0.024489795918367346],
            [428.59, 0.02040816326530612],
            [429.395, 0.11428571428571428],
            [431.14, 0.11428571428571428],
            [432.625, 0.022448979591836733],
            [432.86800000000005, 0.06938775510204082],
            [433.875, 0.02040816326530612],
            [435.15299999999996, 0.02040816326530612],
            [436.567, 0.04285714285714286],
            [437.066, 0.022448979591836733],
            [439.486, 0.10612244897959183],
            [439.726, 0.0326530612244898],
            [440.274, 0.0326530612244898],
            [442.047, 1.0],
            [443.241, 0.02040816326530612],
            [443.63199999999995, 0.05918367346938776],
            [443.96400000000006, 0.02040816326530612],
            [444.735, 0.04693877551020408],
            [448.476, 0.024489795918367346],
            [454.866, 0.022448979591836733],
            [455.041, 0.11020408163265306],
            [455.13, 0.02857142857142857],
            [461.678, 0.03469387755102041],
            [463.183, 0.03469387755102041],
            [466.38199999999995, 0.02857142857142857],
            [479.399, 0.13673469387755102],
            [486.56000000000006, 0.022448979591836733],
            [503.183, 0.011224489795918367],
            [503.912, 0.009183673469387756],
            [507.288, 0.007142857142857143],
            [507.47700000000003, 0.007142857142857143],
            [507.909, 0.007142857142857143],
            [510.35, 0.018367346938775512],
            [511.081, 0.011224489795918367],
            [512.223, 0.004489795918367347],
            [514.554, 0.004489795918367347],
            [514.9739999999999, 0.02857142857142857],
            [515.201, 0.005714285714285714],
            [516.8979999999999, 0.005714285714285714],
            [519.3520000000001, 0.00816326530612245],
            [520.263, 0.05510204081632653],
            [520.323, 0.007142857142857143],
            [525.046, 0.004081632653061225],
            [525.582, 0.009183673469387756],
            [526.515, 0.011224489795918367],
            [528.389, 0.004081632653061225],
            [529.5649999999999, 0.004081632653061225],
            [529.8779999999999, 0.00816326530612245],
            [530.258, 0.002653061224489796],
            [533.6229999999999, 0.003673469387755102],
            [534.603, 0.0022448979591836735],
            [535.225, 0.002653061224489796],
            [537.679, 0.022448979591836733],
            [540.3430000000001, 0.0032653061224489797],
            [541.214, 0.002653061224489796],
            [541.634, 0.024489795918367346],
            [541.669, 0.009183673469387756],
            [541.751, 0.005714285714285714],
            [544.182, 0.0032653061224489797],
            [544.331, 0.011224489795918367],
            [544.693, 0.004489795918367347],
            [544.7760000000001, 0.0022448979591836735],
            [544.937, 0.004081632653061225],
            [545.3399999999999, 0.004081632653061225],
            [545.73, 0.004489795918367347],
            [547.0, 0.005714285714285714],
            [547.458, 0.002653061224489796],
            [547.513, 0.002653061224489796],
            [547.7270000000001, 0.001836734693877551],
            [548.1850000000001, 0.0032653061224489797],
            [550.933, 0.004489795918367347],
            [551.601, 0.001836734693877551],
            [552.353, 0.05510204081632653],
            [554.682, 0.004489795918367347],
            [554.979, 0.001836734693877551],
            [555.288, 0.002653061224489796],
            [556.062, 0.0022448979591836735],
            [558.066, 0.0032653061224489797],
            [558.444, 0.0163265306122449],
            [560.05, 0.0016326530612244899],
            [562.008, 0.007142857142857143],
            [563.741, 0.001836734693877551],
            [564.2560000000001, 0.004489795918367347],
            [564.525, 0.005714285714285714],
            [564.8979999999999, 0.0014285714285714286],
            [566.021, 0.001836734693877551],
            [567.438, 0.0014285714285714286],
            [568.088, 0.005714285714285714],
            [570.937, 0.0022448979591836735],
            [572.193, 0.03469387755102041],
            [573.789, 0.0016326530612244899],
            [573.972, 0.0016326530612244899],
            [576.505, 0.004489795918367347],
            [578.082, 0.03469387755102041],
            [580.0600000000001, 0.00816326530612245],
            [584.249, 0.0016326530612244899],
            [585.7760000000001, 0.022448979591836733],
            [586.0640000000001, 0.005714285714285714],
            [588.292, 0.0022448979591836735],
            [590.3979999999999, 0.0022448979591836735],
            [590.684, 0.0022448979591836735],
            [590.895, 0.0014285714285714286],
            [598.136, 0.0014285714285714286],
            [598.322, 0.0022448979591836735],
            [599.6, 0.013265306122448979],
            [601.579, 0.004081632653061225],
            [605.463, 0.0014285714285714286],
            [614.453, 0.004081632653061225],
            [615.803, 0.0022448979591836735],
            [622.77, 0.007142857142857143],
            [624.17, 0.0014285714285714286],
            [626.941, 0.004489795918367347],
            [627.4939999999999, 0.0022448979591836735],
            [628.683, 0.0022448979591836735],
            [639.886, 0.001836734693877551],
            [640.3149999999999, 0.004489795918367347],
            [644.813, 0.001836734693877551],
            [652.085, 0.0012244897959183673],
            [652.887, 0.0014285714285714286],
            [653.3140000000001, 0.0014285714285714286],
            [653.83, 0.0022448979591836735],
            [657.683, 0.0022448979591836735],
            [661.456, 0.0016326530612244899],
            [661.543, 0.0008163265306122449],
            [666.181, 0.0014285714285714286],
            [672.956, 0.005510204081632653],
            [679.153, 0.003673469387755102],
            [680.661, 0.002857142857142857],
            [687.87, 0.0010204081632653062],
            [690.158, 0.0008163265306122449],
            [695.6020000000001, 0.0022448979591836735],
            [698.495, 0.0012244897959183673],
        ],
    },
    {
        name: "platinum",
        lines: [
            [411.86899999999997, 1.0],
            [416.456, 0.7272727272727273],
            [419.24300000000005, 0.36363636363636365],
            [432.706, 0.16363636363636364],
            [439.183, 0.16363636363636364],
            [444.255, 0.7272727272727273],
            [444.555, 0.12727272727272726],
            [449.87600000000003, 0.22727272727272727],
            [452.09, 0.10909090909090909],
            [455.242, 0.3181818181818182],
            [487.953, 0.10909090909090909],
            [504.404, 0.12727272727272726],
            [505.948, 0.2727272727272727],
            [522.766, 0.3181818181818182],
            [530.1020000000001, 0.36363636363636365],
            [536.899, 0.10909090909090909],
            [539.079, 0.10909090909090909],
            [547.577, 0.12727272727272726],
            [547.85, 0.12727272727272726],
            [576.357, 0.05454545454545454],
            [584.012, 0.18181818181818182],
            [584.484, 0.07272727272727272],
            [602.604, 0.05454545454545454],
            [631.837, 0.06363636363636363],
            [632.658, 0.07272727272727272],
            [652.345, 0.08181818181818182],
            [671.042, 0.09090909090909091],
            [676.0020000000001, 0.18181818181818182],
            [684.26, 0.5454545454545454],
        ],
    },
    {
        name: "palladium",
        lines: [
            [408.73400000000004, 0.116],
            [416.98400000000004, 0.036],
            [421.29499999999996, 1.0],
            [447.35900000000004, 0.072],
            [478.81800000000004, 0.022],
            [481.75100000000003, 0.018],
            [487.543, 0.014],
            [511.081, 0.022],
            [511.70200000000006, 0.03],
            [516.384, 0.064],
            [523.486, 0.022],
            [529.563, 0.048],
            [531.257, 0.0072],
            [534.51, 0.006],
            [539.524, 0.014],
            [554.28, 0.022],
            [554.702, 0.014],
            [561.944, 0.0108],
            [564.269, 0.006],
            [565.542, 0.0056],
            [567.007, 0.03],
            [569.014, 0.0044],
            [569.509, 0.022],
            [573.661, 0.0072],
            [677.454, 0.0092],
            [678.452, 0.026],
            [683.342, 0.0016],
        ],
    },
    {
        name: "phosphorus",
        lines: [
            [417.84799999999996, 0.16666666666666666],
            [428.86, 0.3333333333333333],
            [438.535, 0.3333333333333333],
            [442.071, 0.6666666666666666],
            [445.246, 0.16666666666666666],
            [446.3, 0.25],
            [446.79799999999994, 0.2],
            [447.526, 0.3333333333333333],
            [449.924, 0.3333333333333333],
            [453.081, 0.2],
            [455.483, 0.2],
            [455.80699999999996, 0.2],
            [458.171, 0.2],
            [458.804, 0.8333333333333334],
            [458.986, 0.8333333333333334],
            [460.20799999999997, 1.0],
            [462.66999999999996, 0.5],
            [465.831, 0.5],
            [486.442, 0.3333333333333333],
            [492.71999999999997, 0.25],
            [494.35299999999995, 0.8333333333333334],
            [495.439, 0.5],
            [496.971, 0.5],
            [519.141, 0.25],
            [525.3520000000001, 0.5],
            [529.613, 0.6666666666666666],
            [531.607, 0.4166666666666667],
            [534.475, 0.5],
            [537.8199999999999, 0.4166666666666667],
            [538.688, 0.5],
            [540.972, 0.3333333333333333],
            [542.591, 0.6666666666666666],
            [545.074, 0.6666666666666666],
            [545.831, 0.16666666666666666],
            [546.12, 0.20833333333333334],
            [547.775, 0.3333333333333333],
            [548.355, 0.3333333333333333],
            [549.973, 0.3333333333333333],
            [550.7189999999999, 0.3333333333333333],
            [554.114, 0.3333333333333333],
            [558.327, 0.3333333333333333],
            [558.8340000000001, 0.4166666666666667],
            [572.771, 0.16666666666666666],
            [602.418, 0.8333333333333334],
            [603.404, 0.6666666666666666],
            [604.312, 0.8333333333333334],
            [605.55, 0.4166666666666667],
            [605.786, 0.16666666666666666],
            [608.7819999999999, 0.5833333333333334],
            [609.768, 0.25],
            [616.559, 0.5833333333333334],
            [619.9010000000001, 0.3],
            [623.229, 0.16666666666666666],
            [636.7270000000001, 0.3333333333333333],
            [643.5319999999999, 0.4166666666666667],
            [643.6310000000001, 0.21666666666666667],
            [645.999, 1.0],
            [650.346, 1.0],
            [650.797, 1.0],
            [671.328, 0.25],
            [671.742, 0.2],
        ],
    },
    {
        name: "plutonium",
        lines: [
            [410.196, 1.0],
            [410.59499999999997, 1.0],
            [411.10699999999997, 1.0],
            [411.491, 1.0],
            [412.812, 1.0],
            [412.99300000000005, 1.0],
            [413.30100000000004, 1.0],
            [413.59700000000004, 1.0],
            [414.004, 1.0],
            [414.12, 1.0],
            [415.10900000000004, 1.0],
            [415.145, 1.0],
            [415.546, 1.0],
            [415.939, 1.0],
            [416.77700000000004, 1.0],
            [417.09499999999997, 1.0],
            [417.828, 1.0],
            [418.98999999999995, 1.0],
            [419.00600000000003, 1.0],
            [419.62, 1.0],
            [420.64799999999997, 1.0],
            [420.823, 1.0],
            [422.187, 1.0],
            [422.41999999999996, 1.0],
            [422.97700000000003, 1.0],
            [425.476, 1.0],
            [426.188, 1.0],
            [426.97700000000003, 1.0],
            [427.334, 1.0],
            [428.117, 1.0],
            [428.908, 1.0],
            [433.718, 1.0],
            [435.271, 1.0],
            [436.741, 1.0],
            [437.991, 1.0],
            [438.535, 1.0],
            [439.39300000000003, 1.0],
            [440.48999999999995, 1.0],
            [444.16499999999996, 1.0],
            [446.854, 1.0],
            [447.279, 1.0],
            [449.378, 1.0],
            [450.491, 1.0],
            [453.61499999999995, 1.0],
            [473.53999999999996, 1.0],
            [498.934, 1.0],
            [526.986, 1.0],
            [538.1020000000001, 1.0],
            [549.85, 1.0],
            [551.072, 1.0],
            [553.759, 1.0],
            [554.962, 1.0],
            [559.054, 1.0],
            [559.233, 1.0],
            [571.239, 1.0],
            [577.0260000000001, 1.0],
            [583.905, 1.0],
            [598.335, 1.0],
            [601.278, 1.0],
            [619.28, 1.0],
            [630.466, 1.0],
            [644.975, 1.0],
            [648.671, 1.0],
            [648.886, 1.0],
            [648.889, 1.0],
            [653.527, 1.0],
            [654.421, 1.0],
            [660.895, 1.0],
            [667.272, 1.0],
            [678.466, 1.0],
            [688.016, 1.0],
            [689.138, 1.0],
        ],
    },
    {
        name: "potassium",
        lines: [
            [400.12399999999997, 0.35],
            [401.21, 0.25],
            [404.259, 0.3],
            [404.414, 0.9],
            [404.721, 0.85],
            [409.369, 0.25],
            [411.49899999999997, 0.3],
            [413.47200000000004, 0.35],
            [414.919, 0.35],
            [418.62399999999997, 0.4],
            [422.297, 0.35],
            [422.567, 0.35],
            [426.34, 0.35],
            [430.5, 0.35],
            [430.91, 0.35],
            [434.003, 0.25],
            [438.816, 0.35],
            [446.66499999999996, 0.25],
            [450.533, 0.3],
            [459.56499999999994, 0.25],
            [460.84499999999997, 0.4],
            [464.188, 0.5],
            [464.23699999999997, 0.55],
            [465.938, 0.25],
            [474.091, 0.2],
            [474.43500000000006, 0.3],
            [475.39300000000003, 0.25],
            [475.73900000000003, 0.35],
            [478.649, 0.25],
            [479.105, 0.35],
            [479.975, 0.3],
            [480.43500000000006, 0.4],
            [482.92299999999994, 0.45],
            [484.986, 0.35],
            [485.60900000000004, 0.4],
            [486.34799999999996, 0.4],
            [486.976, 0.45],
            [494.20200000000006, 0.4],
            [494.329, 0.3],
            [495.082, 0.45],
            [495.61499999999995, 0.45],
            [496.503, 0.5],
            [500.56000000000006, 0.4],
            [505.62700000000007, 0.35],
            [508.42299999999994, 0.5],
            [509.717, 0.55],
            [509.91999999999996, 0.55],
            [511.225, 0.6],
            [531.024, 0.25],
            [532.328, 0.6],
            [533.9689999999999, 0.65],
            [534.297, 0.6],
            [535.957, 0.7],
            [547.013, 0.3],
            [564.2729999999999, 0.25],
            [577.232, 0.2],
            [578.238, 0.8],
            [580.175, 0.85],
            [581.2149999999999, 0.75],
            [583.1890000000001, 0.85],
            [596.964, 0.1],
            [612.027, 0.4],
            [624.659, 0.3],
            [630.729, 0.35],
            [642.796, 0.25],
            [659.5, 0.1],
            [691.108, 0.95],
            [693.6279999999999, 0.6],
            [693.8770000000001, 1.0],
            [696.418, 0.35],
            [696.467, 0.6],
        ],
    },
    {
        name: "praseodymium",
        lines: [
            [400.017, 0.06888888888888889],
            [400.46999999999997, 0.0811111111111111],
            [400.869, 0.2111111111111111],
            [401.06, 0.06888888888888889],
            [401.539, 0.0811111111111111],
            [402.096, 0.06888888888888889],
            [402.271, 0.052222222222222225],
            [402.554, 0.04],
            [402.683, 0.025555555555555557],
            [402.9, 0.025555555555555557],
            [402.972, 0.04],
            [403.175, 0.0811111111111111],
            [403.24699999999996, 0.025555555555555557],
            [403.383, 0.10666666666666667],
            [403.433, 0.025555555555555557],
            [403.822, 0.025555555555555557],
            [403.84499999999997, 0.0811111111111111],
            [403.934, 0.052222222222222225],
            [404.481, 0.14444444444444443],
            [404.57, 0.025555555555555557],
            [404.663, 0.025555555555555557],
            [404.70799999999997, 0.03777777777777778],
            [405.113, 0.05],
            [405.488, 0.24444444444444444],
            [405.654, 0.24444444444444444],
            [405.88, 0.05],
            [406.222, 0.025555555555555557],
            [406.281, 0.37777777777777777],
            [406.88, 0.023333333333333334],
            [407.977, 0.05555555555555555],
            [408.098, 0.05555555555555555],
            [408.185, 0.08777777777777777],
            [408.334, 0.05555555555555555],
            [408.721, 0.022222222222222223],
            [409.68199999999996, 0.06222222222222222],
            [409.84, 0.042222222222222223],
            [410.072, 0.32222222222222224],
            [411.389, 0.03],
            [411.846, 0.18888888888888888],
            [412.91499999999996, 0.027777777777777776],
            [413.07700000000006, 0.03777777777777778],
            [413.361, 0.022222222222222223],
            [414.122, 0.16666666666666666],
            [414.311, 0.3],
            [414.65, 0.03],
            [414.84399999999994, 0.03],
            [415.65, 0.022222222222222223],
            [416.416, 0.18888888888888888],
            [416.804, 0.03],
            [416.945, 0.025555555555555557],
            [417.18199999999996, 0.06888888888888889],
            [417.225, 0.0811111111111111],
            [417.532, 0.027777777777777776],
            [417.562, 0.027777777777777776],
            [417.863, 0.022222222222222223],
            [417.939, 0.5777777777777777],
            [418.948, 0.2777777777777778],
            [419.16, 0.06222222222222222],
            [420.117, 0.03222222222222222],
            [420.672, 0.2777777777777778],
            [420.832, 0.05555555555555555],
            [421.186, 0.035555555555555556],
            [421.78100000000006, 0.035555555555555556],
            [422.293, 0.4222222222222222],
            [422.535, 0.4222222222222222],
            [423.311, 0.035555555555555556],
            [423.61499999999995, 0.035555555555555556],
            [424.00200000000007, 0.03],
            [424.101, 0.10666666666666667],
            [424.351, 0.03777777777777778],
            [424.76300000000003, 0.09333333333333334],
            [425.43999999999994, 0.05555555555555555],
            [426.378, 0.03],
            [426.909, 0.035555555555555556],
            [427.22700000000003, 0.08777777777777777],
            [428.00699999999995, 0.052222222222222225],
            [428.242, 0.08777777777777777],
            [429.89799999999997, 0.05],
            [430.361, 0.03222222222222222],
            [430.576, 0.16666666666666666],
            [432.355, 0.023333333333333334],
            [432.941, 0.03],
            [433.39700000000005, 0.14444444444444443],
            [433.57399999999996, 0.022222222222222223],
            [433.87, 0.04],
            [434.43, 0.06888888888888889],
            [434.74899999999997, 0.052222222222222225],
            [435.03999999999996, 0.03777777777777778],
            [435.491, 0.05],
            [435.979, 0.04555555555555556],
            [436.83299999999997, 0.13333333333333333],
            [437.162, 0.035555555555555556],
            [439.608, 0.03],
            [440.36, 0.01888888888888889],
            [440.512, 0.011111111111111112],
            [440.58299999999997, 0.04777777777777778],
            [440.88199999999995, 0.18888888888888888],
            [441.37700000000007, 0.04555555555555556],
            [441.904, 0.017777777777777778],
            [441.965, 0.021111111111111112],
            [442.122, 0.017777777777777778],
            [442.45799999999997, 0.017777777777777778],
            [442.913, 0.13333333333333333],
            [443.22799999999995, 0.012222222222222223],
            [444.983, 0.0811111111111111],
            [445.18999999999994, 0.015555555555555555],
            [445.468, 0.015555555555555555],
            [446.59700000000004, 0.011111111111111112],
            [446.866, 0.10666666666666667],
            [447.726, 0.015555555555555555],
            [449.646, 0.12222222222222222],
            [451.015, 0.08777777777777777],
            [451.758, 0.022222222222222223],
            [453.41499999999996, 0.03777777777777778],
            [453.592, 0.03777777777777778],
            [456.312, 0.022222222222222223],
            [461.20799999999997, 0.015555555555555555],
            [462.87399999999997, 0.03],
            [463.22799999999995, 0.015555555555555555],
            [463.56800000000004, 0.015555555555555555],
            [463.95500000000004, 0.022222222222222223],
            [464.349, 0.012222222222222223],
            [464.605, 0.015555555555555555],
            [465.15, 0.022222222222222223],
            [466.465, 0.015555555555555555],
            [467.209, 0.03],
            [468.78000000000003, 0.02],
            [469.57700000000006, 0.03222222222222222],
            [470.80699999999996, 0.015555555555555555],
            [470.95200000000006, 0.015555555555555555],
            [473.067, 0.02],
            [473.669, 0.027777777777777776],
            [474.416, 0.011111111111111112],
            [474.692, 0.016666666666666666],
            [476.27200000000005, 0.011111111111111112],
            [478.33500000000004, 0.012222222222222223],
            [490.69899999999996, 0.012222222222222223],
            [491.40200000000004, 0.015555555555555555],
            [492.46000000000004, 0.022222222222222223],
            [493.6, 0.015555555555555555],
            [493.974, 0.035555555555555556],
            [494.03000000000003, 0.017777777777777778],
            [495.137, 0.042222222222222223],
            [497.575, 0.012222222222222223],
            [501.85900000000004, 0.013333333333333334],
            [501.976, 0.022222222222222223],
            [502.696, 0.022222222222222223],
            [503.338, 0.011111111111111112],
            [503.441, 0.03],
            [504.383, 0.012222222222222223],
            [504.552, 0.035555555555555556],
            [505.34, 0.017777777777777778],
            [508.712, 0.02],
            [511.038, 0.04],
            [511.076, 0.06222222222222222],
            [512.952, 0.04555555555555556],
            [513.3439999999999, 0.03],
            [513.514, 0.03],
            [513.981, 0.011111111111111112],
            [515.23, 0.011111111111111112],
            [516.174, 0.022222222222222223],
            [517.39, 0.06888888888888889],
            [519.132, 0.022222222222222223],
            [519.443, 0.013333333333333334],
            [519.511, 0.016666666666666666],
            [519.5310000000001, 0.022222222222222223],
            [520.655, 0.04],
            [520.79, 0.016666666666666666],
            [521.905, 0.04],
            [522.011, 0.06222222222222222],
            [522.797, 0.012222222222222223],
            [525.973, 0.07555555555555556],
            [526.388, 0.02],
            [529.202, 0.03777777777777778],
            [529.262, 0.03777777777777778],
            [529.809, 0.025555555555555557],
            [532.2760000000001, 0.04777777777777778],
            [535.24, 0.022222222222222223],
            [550.15, 0.0017777777777777779],
            [550.879, 0.0044444444444444444],
            [550.915, 0.007222222222222222],
            [551.163, 0.0017777777777777779],
            [551.358, 0.006111111111111111],
            [551.512, 0.003111111111111111],
            [551.938, 0.0014444444444444444],
            [552.0310000000001, 0.0022222222222222222],
            [552.279, 0.005],
            [552.415, 0.003111111111111111],
            [552.591, 0.003111111111111111],
            [552.793, 0.0017777777777777779],
            [553.021, 0.0014444444444444444],
            [553.116, 0.005],
            [553.517, 0.016666666666666666],
            [553.837, 0.003111111111111111],
            [553.8779999999999, 0.0022222222222222222],
            [554.501, 0.006111111111111111],
            [554.833, 0.0022222222222222222],
            [555.342, 0.0012222222222222222],
            [556.146, 0.0024444444444444444],
            [556.206, 0.005],
            [556.552, 0.0014444444444444444],
            [556.691, 0.0014444444444444444],
            [557.183, 0.005],
            [557.461, 0.0012222222222222222],
            [557.8810000000001, 0.0012222222222222222],
            [558.235, 0.0014444444444444444],
            [558.402, 0.0012222222222222222],
            [559.492, 0.0024444444444444444],
            [559.729, 0.0024444444444444444],
            [560.13, 0.0014444444444444444],
            [560.5649999999999, 0.01],
            [560.668, 0.0014444444444444444],
            [560.893, 0.003111111111111111],
            [561.022, 0.006111111111111111],
            [562.0060000000001, 0.0012222222222222222],
            [562.0260000000001, 0.0022222222222222222],
            [562.1890000000001, 0.005],
            [562.3050000000001, 0.012222222222222223],
            [562.4449999999999, 0.01],
            [563.303, 0.0012222222222222222],
            [563.646, 0.0024444444444444444],
            [563.879, 0.006111111111111111],
            [564.037, 0.0017777777777777779],
            [564.316, 0.0017777777777777779],
            [564.5409999999999, 0.0024444444444444444],
            [565.423, 0.0038888888888888888],
            [565.984, 0.006111111111111111],
            [566.1569999999999, 0.0038888888888888888],
            [566.2189999999999, 0.0017777777777777779],
            [566.846, 0.007222222222222222],
            [566.955, 0.005],
            [566.999, 0.0038888888888888888],
            [567.414, 0.0017777777777777779],
            [567.703, 0.0017777777777777779],
            [568.1890000000001, 0.006111111111111111],
            [568.5600000000001, 0.0014444444444444444],
            [568.652, 0.0017777777777777779],
            [568.717, 0.0024444444444444444],
            [568.8439999999999, 0.007222222222222222],
            [568.921, 0.0024444444444444444],
            [569.097, 0.006111111111111111],
            [569.5899999999999, 0.0024444444444444444],
            [570.438, 0.0024444444444444444],
            [570.761, 0.007222222222222222],
            [571.163, 0.0044444444444444444],
            [571.383, 0.0024444444444444444],
            [571.608, 0.0017777777777777779],
            [571.908, 0.005],
            [571.963, 0.005],
            [572.838, 0.0012222222222222222],
            [573.188, 0.0044444444444444444],
            [574.774, 0.0012222222222222222],
            [574.795, 0.0012222222222222222],
            [575.302, 0.0024444444444444444],
            [575.617, 0.01],
            [575.9399999999999, 0.0017777777777777779],
            [576.02, 0.0024444444444444444],
            [576.9159999999999, 0.0024444444444444444],
            [576.979, 0.0017777777777777779],
            [577.316, 0.005],
            [577.591, 0.0012222222222222222],
            [577.729, 0.0017777777777777779],
            [577.928, 0.01],
            [578.528, 0.007222222222222222],
            [578.617, 0.007222222222222222],
            [578.829, 0.0017777777777777779],
            [578.892, 0.0017777777777777779],
            [579.086, 0.0017777777777777779],
            [579.136, 0.005],
            [579.295, 0.0024444444444444444],
            [581.058, 0.0044444444444444444],
            [581.355, 0.0017777777777777779],
            [581.517, 0.017777777777777778],
            [581.857, 0.006111111111111111],
            [582.062, 0.0044444444444444444],
            [582.136, 0.0017777777777777779],
            [582.259, 0.006111111111111111],
            [582.3720000000001, 0.01],
            [583.0939999999999, 0.005],
            [583.513, 0.0044444444444444444],
            [584.4649999999999, 0.0038888888888888888],
            [584.4979999999999, 0.0044444444444444444],
            [584.713, 0.007222222222222222],
            [585.0640000000001, 0.007222222222222222],
            [585.263, 0.005],
            [585.444, 0.0012222222222222222],
            [585.607, 0.005],
            [585.6899999999999, 0.006111111111111111],
            [585.9680000000001, 0.01],
            [586.883, 0.008888888888888889],
            [587.383, 0.0024444444444444444],
            [587.472, 0.0038888888888888888],
            [587.8100000000001, 0.0038888888888888888],
            [587.904, 0.0038888888888888888],
            [587.925, 0.008888888888888889],
            [588.472, 0.0038888888888888888],
            [589.223, 0.006111111111111111],
            [589.422, 0.0024444444444444444],
            [590.3109999999999, 0.0044444444444444444],
            [590.4449999999999, 0.005],
            [590.867, 0.0044444444444444444],
            [591.5310000000001, 0.0012222222222222222],
            [591.597, 0.0012222222222222222],
            [592.076, 0.0044444444444444444],
            [593.066, 0.0044444444444444444],
            [593.633, 0.0017777777777777779],
            [593.99, 0.017777777777777778],
            [594.072, 0.007222222222222222],
            [594.165, 0.0024444444444444444],
            [594.716, 0.0038888888888888888],
            [594.976, 0.0024444444444444444],
            [595.1270000000001, 0.006111111111111111],
            [595.176, 0.0022222222222222222],
            [595.925, 0.0014444444444444444],
            [596.2180000000001, 0.0022222222222222222],
            [596.3, 0.003111111111111111],
            [596.7819999999999, 0.012222222222222223],
            [597.6949999999999, 0.0014444444444444444],
            [597.888, 0.0014444444444444444],
            [598.1189999999999, 0.007222222222222222],
            [598.614, 0.0044444444444444444],
            [598.714, 0.005],
            [599.1270000000001, 0.0014444444444444444],
            [599.489, 0.0014444444444444444],
            [599.606, 0.0012222222222222222],
            [600.2439999999999, 0.0032222222222222222],
            [600.633, 0.01],
            [600.854, 0.0014444444444444444],
            [601.6479999999999, 0.006111111111111111],
            [601.78, 0.016666666666666666],
            [601.985, 0.003111111111111111],
            [602.572, 0.016666666666666666],
            [604.287, 0.0038888888888888888],
            [604.6659999999999, 0.006111111111111111],
            [604.926, 0.0038888888888888888],
            [605.004, 0.003111111111111111],
            [605.088, 0.0012222222222222222],
            [605.513, 0.015555555555555555],
            [606.7270000000001, 0.0014444444444444444],
            [608.581, 0.0014444444444444444],
            [608.616, 0.003111111111111111],
            [608.7520000000001, 0.007222222222222222],
            [609.038, 0.0022222222222222222],
            [609.309, 0.003111111111111111],
            [609.6279999999999, 0.002],
            [610.672, 0.0024444444444444444],
            [610.908, 0.002],
            [611.438, 0.007222222222222222],
            [611.802, 0.0024444444444444444],
            [612.2149999999999, 0.0024444444444444444],
            [614.1510000000001, 0.0038888888888888888],
            [614.823, 0.007222222222222222],
            [615.7819999999999, 0.0024444444444444444],
            [615.9100000000001, 0.0014444444444444444],
            [616.118, 0.021111111111111112],
            [616.538, 0.002],
            [616.5939999999999, 0.03],
            [618.234, 0.006111111111111111],
            [618.796, 0.0014444444444444444],
            [619.745, 0.0038888888888888888],
            [620.081, 0.0038888888888888888],
            [620.563, 0.0014444444444444444],
            [621.059, 0.0014444444444444444],
            [621.2729999999999, 0.0024444444444444444],
            [621.806, 0.002],
            [623.6800000000001, 0.0022222222222222222],
            [624.105, 0.0022222222222222222],
            [624.4350000000001, 0.005],
            [625.51, 0.0038888888888888888],
            [626.255, 0.0044444444444444444],
            [626.454, 0.002],
            [627.466, 0.0024444444444444444],
            [627.868, 0.0044444444444444444],
            [628.1279999999999, 0.012222222222222223],
            [628.902, 0.002],
            [629.801, 0.0012222222222222222],
            [630.205, 0.0012222222222222222],
            [630.235, 0.0038888888888888888],
            [630.405, 0.0017777777777777779],
            [630.5229999999999, 0.0038888888888888888],
            [631.813, 0.0012222222222222222],
            [632.236, 0.005],
            [634.388, 0.0024444444444444444],
            [634.711, 0.003111111111111111],
            [635.098, 0.002],
            [635.72, 0.0024444444444444444],
            [635.903, 0.006111111111111111],
            [636.362, 0.0012222222222222222],
            [637.761, 0.0017777777777777779],
            [637.859, 0.0017777777777777779],
            [638.957, 0.0012222222222222222],
            [639.199, 0.002],
            [639.318, 0.0044444444444444444],
            [639.796, 0.005],
            [641.069, 0.0011111111111111111],
            [641.1229999999999, 0.006111111111111111],
            [641.368, 0.0044444444444444444],
            [641.543, 0.0011111111111111111],
            [642.963, 0.005],
            [643.184, 0.005],
            [644.278, 0.0007777777777777777],
            [644.391, 0.001],
            [645.3439999999999, 0.0017777777777777779],
            [645.484, 0.001],
            [645.618, 0.001],
            [646.019, 0.001],
            [646.772, 0.002],
            [647.5260000000001, 0.001],
            [647.802, 0.0038888888888888888],
            [648.655, 0.005],
            [648.697, 0.001],
            [649.175, 0.0044444444444444444],
            [649.3489999999999, 0.001],
            [649.489, 0.0012222222222222222],
            [649.711, 0.0024444444444444444],
            [649.894, 0.002],
            [650.072, 0.0024444444444444444],
            [650.409, 0.001],
            [651.714, 0.0008888888888888889],
            [651.879, 0.0017777777777777779],
            [653.452, 0.0008888888888888889],
            [654.047, 0.0017777777777777779],
            [655.33, 0.0007777777777777777],
            [656.462, 0.0024444444444444444],
            [656.677, 0.005],
            [657.103, 0.0007777777777777777],
            [657.8, 0.0006666666666666666],
            [658.456, 0.0006666666666666666],
            [659.374, 0.001],
            [659.548, 0.0012222222222222222],
            [660.986, 0.0016666666666666668],
            [661.667, 0.006111111111111111],
            [661.8340000000001, 0.0012222222222222222],
            [663.1, 0.0007777777777777777],
            [663.206, 0.0014444444444444444],
            [664.712, 0.0015555555555555555],
            [665.683, 0.008333333333333333],
            [667.341, 0.006111111111111111],
            [667.3779999999999, 0.008333333333333333],
            [668.751, 0.0005555555555555556],
            [669.925, 0.00044444444444444447],
            [673.679, 0.0014444444444444444],
            [674.7090000000001, 0.0038888888888888888],
            [674.919, 0.002111111111111111],
            [678.499, 0.0007777777777777777],
            [679.86, 0.006111111111111111],
            [681.176, 0.0012222222222222222],
            [681.287, 0.001888888888888889],
            [681.404, 0.0014444444444444444],
            [681.761, 0.001],
            [682.76, 0.0038888888888888888],
            [683.05, 0.002111111111111111],
            [684.4390000000001, 0.001],
            [684.547, 0.001],
            [684.659, 0.001],
            [685.046, 0.001888888888888889],
            [685.277, 0.0012222222222222222],
            [687.044, 0.0012222222222222222],
            [688.466, 0.0007777777777777777],
            [689.271, 0.0008888888888888889],
            [697.038, 0.0008888888888888889],
            [698.012, 0.0008888888888888889],
            [414.246, 0.01],
            [414.448, 0.013333333333333334],
            [414.785, 0.01],
            [417.215, 0.01],
            [417.97700000000003, 0.016666666666666666],
            [418.418, 0.02],
            [419.701, 0.02666666666666667],
            [421.945, 0.013333333333333334],
            [423.145, 0.02],
            [427.50699999999995, 0.02],
            [428.63199999999995, 0.013333333333333334],
            [429.82700000000006, 0.01],
            [430.17299999999994, 0.01],
            [431.634, 0.01],
            [435.428, 0.006666666666666667],
            [437.98199999999997, 0.01],
            [438.14700000000005, 0.01],
            [440.471, 0.013333333333333334],
            [442.11, 0.013333333333333334],
            [443.18500000000006, 0.013333333333333334],
            [444.793, 0.016666666666666666],
            [445.014, 0.02],
            [445.1, 0.013333333333333334],
            [446.10200000000003, 0.013333333333333334],
            [446.18100000000004, 0.022222222222222223],
            [450.03100000000006, 0.03333333333333333],
            [461.20200000000006, 0.05],
            [462.51800000000003, 0.06666666666666667],
            [465.416, 0.013333333333333334],
            [471.37, 0.06666666666666667],
            [472.555, 0.03333333333333333],
            [472.821, 0.03],
            [474.71099999999996, 0.03333333333333333],
            [477.183, 0.03333333333333333],
            [477.53000000000003, 0.05],
            [485.73900000000003, 0.06666666666666667],
            [520.851, 0.016666666666666666],
            [526.168, 0.016666666666666666],
            [526.444, 0.1111111111111111],
            [528.47, 0.16666666666666666],
            [529.999, 0.16666666666666666],
            [534.0020000000001, 0.16666666666666666],
            [542.77, 0.011111111111111112],
            [558.174, 0.011111111111111112],
            [564.6800000000001, 0.016666666666666666],
            [576.527, 0.06666666666666667],
            [584.441, 0.16666666666666666],
            [594.798, 0.022222222222222223],
            [595.605, 0.7777777777777778],
            [599.894, 0.1],
            [605.301, 0.16666666666666666],
            [607.109, 0.1],
            [609.0020000000001, 1.0],
            [616.024, 0.5555555555555556],
            [616.1220000000001, 0.16666666666666666],
            [619.505, 0.011111111111111112],
            [619.563, 0.2222222222222222],
            [631.036, 0.022222222222222223],
            [636.165, 0.011111111111111112],
            [642.926, 0.03333333333333333],
            [644.4739999999999, 0.03333333333333333],
            [650.004, 0.06666666666666667],
            [650.149, 0.03333333333333333],
            [657.89, 0.022222222222222223],
            [661.646, 0.011111111111111112],
            [670.67, 0.06666666666666667],
            [672.763, 0.011111111111111112],
            [682.796, 0.022222222222222223],
            [685.463, 0.011111111111111112],
            [685.73, 0.022222222222222223],
            [686.6800000000001, 0.1111111111111111],
            [689.9060000000001, 0.1111111111111111],
            [690.3520000000001, 0.05555555555555555],
            [691.014, 0.7777777777777778],
            [693.455, 0.016666666666666666],
            [697.096, 0.05555555555555555],
            [697.983, 0.011111111111111112],
        ],
    },
    {
        name: "promethium",
        lines: [
            [400.996, 0.5],
            [401.272, 0.2],
            [401.41999999999996, 0.25],
            [401.934, 0.2],
            [402.82, 0.25],
            [404.536, 0.2],
            [405.154, 0.3],
            [405.52, 0.6],
            [405.656, 0.2],
            [407.584, 0.6],
            [408.531, 0.2],
            [408.61, 0.5],
            [414.046, 0.25],
            [418.57399999999996, 0.2],
            [419.29200000000003, 0.3],
            [419.46999999999997, 0.2],
            [422.215, 0.2],
            [426.43199999999996, 0.3],
            [428.437, 0.3],
            [429.77799999999996, 0.6],
            [430.389, 0.2],
            [430.564, 0.2],
            [431.88, 0.4],
            [432.592, 0.25],
            [433.20500000000004, 0.2],
            [433.654, 0.3],
            [433.74799999999993, 0.2],
            [434.212, 0.3],
            [434.77200000000005, 0.2],
            [436.392, 0.35],
            [436.96400000000006, 0.3],
            [438.188, 0.2],
            [438.849, 0.4],
            [438.87600000000003, 0.2],
            [440.942, 0.4],
            [441.247, 0.5],
            [441.796, 1.0],
            [443.25100000000003, 0.4],
            [443.58599999999996, 0.25],
            [443.65500000000003, 0.3],
            [443.86800000000005, 0.3],
            [444.541, 0.5],
            [444.68999999999994, 0.6],
            [445.395, 0.8],
            [445.997, 0.2],
            [446.816, 0.25],
            [447.14799999999997, 0.2],
            [447.323, 0.3],
            [447.746, 0.2],
            [447.858, 0.35],
            [448.16, 0.3],
            [448.505, 0.3],
            [449.05, 0.3],
            [449.20500000000004, 0.25],
            [450.015, 0.6],
            [450.033, 0.35],
            [450.684, 0.25],
            [450.938, 0.1],
            [451.35600000000005, 0.1],
            [451.73100000000005, 0.2],
            [452.332, 0.2],
            [452.52, 0.6],
            [452.61199999999997, 0.25],
            [452.67600000000004, 0.25],
            [452.77, 0.4],
            [452.921, 0.8],
            [454.00600000000003, 0.3],
            [454.142, 0.3],
            [454.175, 0.45],
            [454.408, 0.5],
            [454.517, 0.2],
            [454.97799999999995, 0.4],
            [455.40299999999996, 0.3],
            [455.463, 0.2],
            [455.534, 0.5],
            [455.60600000000005, 0.2],
            [455.703, 0.3],
            [455.921, 0.3],
            [456.483, 0.1],
            [456.814, 0.3],
            [457.037, 0.2],
            [457.215, 0.3],
            [457.52700000000004, 0.4],
            [457.828, 0.3],
            [457.841, 0.2],
            [457.948, 0.3],
            [458.11400000000003, 0.3],
            [458.549, 0.3],
            [459.38199999999995, 0.2],
            [459.582, 0.4],
            [459.755, 0.8],
            [460.025, 0.5],
            [460.296, 0.4],
            [460.459, 0.4],
            [460.566, 0.6],
            [460.985, 0.5],
            [461.587, 0.1],
            [461.70200000000006, 0.6],
            [461.84, 0.2],
            [461.849, 0.4],
            [461.975, 0.5],
            [462.157, 0.5],
            [462.331, 0.5],
            [462.36800000000005, 0.7],
            [462.441, 0.9],
            [462.529, 0.5],
            [462.76000000000005, 0.4],
            [463.093, 0.2],
            [463.34499999999997, 0.6],
            [464.096, 0.4],
            [464.33599999999996, 0.7],
            [464.37600000000003, 0.7],
            [464.59399999999994, 0.4],
            [464.703, 0.6],
            [465.04200000000003, 0.6],
            [465.052, 0.5],
            [465.341, 0.4],
            [465.45, 0.4],
            [465.505, 0.5],
            [465.938, 0.3],
            [466.079, 0.5],
            [466.326, 0.3],
            [466.346, 0.6],
            [466.51899999999995, 0.4],
            [467.12299999999993, 0.5],
            [467.17600000000004, 0.4],
            [467.442, 0.5],
            [467.746, 0.2],
            [467.79200000000003, 0.5],
            [467.809, 0.4],
            [468.29200000000003, 0.7],
            [469.68, 0.5],
            [469.951, 0.2],
            [472.206, 0.25],
            [472.706, 0.3],
            [472.83599999999996, 0.9],
            [472.86800000000005, 0.4],
            [473.427, 0.8],
            [473.799, 0.2],
            [473.908, 0.1],
            [473.97799999999995, 0.2],
            [474.51300000000003, 0.35],
            [475.77299999999997, 0.5],
            [475.9, 0.8],
            [476.25699999999995, 0.7],
            [477.346, 0.7],
            [478.129, 0.9],
            [479.459, 0.25],
            [479.543, 0.2],
            [479.89799999999997, 0.7],
            [480.13599999999997, 0.9],
            [480.954, 0.7],
            [481.196, 0.9],
            [481.712, 0.4],
            [482.77200000000005, 0.4],
            [483.76599999999996, 0.8],
            [483.892, 0.4],
            [483.962, 0.3],
            [484.401, 0.2],
            [485.27299999999997, 0.35],
            [486.062, 0.4],
            [486.07399999999996, 0.7],
            [486.53000000000003, 0.3],
            [486.572, 0.5],
            [486.98, 0.4],
            [487.242, 0.7],
            [488.70200000000006, 0.5],
            [489.25200000000007, 0.7],
            [490.03000000000003, 0.4],
            [490.428, 0.3],
            [491.828, 0.4],
            [493.299, 0.6],
            [495.946, 0.7],
            [497.14, 0.1],
            [499.71000000000004, 0.5],
            [503.08000000000004, 0.2],
            [505.831, 0.3],
            [506.735, 0.1],
            [508.052, 0.15],
            [508.93500000000006, 0.15],
            [509.242, 0.2],
            [509.483, 0.4],
            [509.61800000000005, 0.2],
            [509.73, 0.15],
            [510.07700000000006, 0.4],
            [512.147, 0.25],
            [512.734, 0.4],
            [512.975, 0.2],
            [514.513, 0.4],
            [514.63, 0.5],
            [515.386, 0.4],
            [516.971, 0.3],
            [517.158, 0.5],
            [519.405, 0.3],
            [520.809, 0.5],
            [521.596, 0.15],
            [522.512, 0.25],
            [523.626, 0.5],
            [523.6659999999999, 0.3],
            [524.633, 0.4],
            [526.242, 0.15],
            [527.0640000000001, 0.5],
            [529.392, 0.2],
            [530.886, 0.1],
            [531.858, 0.15],
            [541.045, 0.2],
            [542.454, 0.2],
            [542.479, 0.18],
            [542.904, 0.15],
            [546.764, 0.1],
            [549.545, 0.15],
            [551.642, 0.1],
            [553.496, 0.18],
            [553.738, 0.2],
            [554.608, 0.8],
            [555.688, 0.12],
            [555.839, 0.15],
            [556.173, 0.2],
            [557.6020000000001, 0.8],
            [564.129, 0.2],
            [573.081, 0.2],
            [576.816, 0.2],
            [577.699, 0.2],
            [582.393, 0.5],
            [586.879, 0.3],
            [587.5310000000001, 0.2],
            [587.876, 0.1],
            [589.976, 0.15],
            [590.471, 0.25],
            [590.5899999999999, 0.1],
            [591.496, 0.125],
            [592.717, 0.25],
            [593.966, 0.15],
            [594.649, 0.4],
            [595.642, 0.8],
            [595.669, 0.2],
            [596.008, 0.1],
            [596.3, 0.15],
            [596.789, 0.4],
            [597.973, 0.2],
            [598.482, 0.2],
            [598.713, 0.1],
            [599.712, 0.4],
            [602.711, 0.2],
            [603.0060000000001, 0.3],
            [603.132, 0.4],
            [604.339, 0.5],
            [605.257, 0.15],
            [606.7, 0.1],
            [606.9060000000001, 0.5],
            [607.64, 0.1],
            [608.5409999999999, 0.2],
            [610.021, 0.9],
            [610.64, 0.4],
            [611.49, 0.1],
            [615.176, 0.4],
            [615.953, 0.1],
            [616.316, 0.4],
            [618.452, 0.1],
            [620.891, 0.2],
            [622.964, 0.5],
            [623.779, 0.4],
            [626.325, 0.1],
            [627.269, 0.4],
            [628.606, 0.4],
            [630.829, 0.5],
            [631.42, 0.1],
            [632.384, 0.7],
            [639.0310000000001, 0.5],
            [642.964, 0.1],
            [643.193, 0.5],
            [643.6569999999999, 0.1],
            [648.761, 0.4],
            [651.034, 0.4],
            [651.725, 0.5],
            [651.943, 0.2],
            [652.045, 1.0],
            [654.22, 0.5],
            [655.848, 0.1],
            [658.639, 0.1],
            [659.229, 0.1],
            [659.8149999999999, 0.9],
            [659.866, 0.8],
            [660.637, 0.7],
            [662.5229999999999, 0.8],
            [662.554, 0.1],
            [664.981, 0.7],
            [665.905, 0.4],
            [666.125, 0.1],
            [666.168, 0.5],
            [666.376, 0.4],
            [666.751, 0.8],
            [667.7470000000001, 0.7],
            [668.089, 0.2],
            [668.5550000000001, 0.5],
            [668.568, 0.5],
            [669.009, 0.15],
            [670.033, 0.6],
            [670.6270000000001, 0.1],
            [671.467, 0.7],
            [671.726, 0.5],
            [672.071, 0.5],
            [672.75, 0.7],
            [674.371, 0.6],
            [674.991, 0.9],
            [675.048, 0.9],
            [675.645, 0.2],
            [677.229, 0.3],
            [677.8779999999999, 0.4],
            [678.309, 0.1],
            [679.687, 0.1],
            [681.168, 0.2],
            [683.33, 0.8],
            [684.837, 0.4],
            [685.858, 0.05],
        ],
    },
    {
        name: "protoactinium",
        lines: [
            [401.296, 0.3],
            [401.821, 0.3],
            [403.01599999999996, 0.3],
            [404.693, 0.3],
            [405.62, 1.0],
            [407.04, 1.0],
            [411.762, 0.3],
            [417.61800000000005, 0.3],
            [421.72299999999996, 1.0],
            [424.808, 1.0],
            [429.134, 0.3],
            [440.07700000000006, 0.3],
            [443.613, 0.3],
            [460.14300000000003, 0.3],
            [462.81899999999996, 0.3],
            [482.034, 0.3],
            [486.149, 0.3],
            [603.578, 0.3],
            [616.2560000000001, 0.3],
            [621.635, 0.3],
            [635.861, 0.3],
            [637.925, 0.3],
            [643.897, 0.3],
            [679.275, 0.3],
            [694.572, 1.0],
            [696.009, 0.3],
            [696.178, 0.3],
            [699.2729999999999, 0.3],
        ],
    },
    {
        name: "rhenium",
        lines: [
            [400.493, 0.027777777777777776],
            [402.296, 0.03888888888888889],
            [402.331, 0.027777777777777776],
            [402.963, 0.030555555555555555],
            [403.331, 0.06111111111111111],
            [403.74899999999997, 0.030555555555555555],
            [404.899, 0.05555555555555555],
            [408.143, 0.06666666666666667],
            [410.442, 0.03888888888888889],
            [411.08900000000006, 0.06666666666666667],
            [412.16400000000004, 0.05277777777777778],
            [413.342, 0.06666666666666667],
            [413.645, 0.5],
            [414.436, 0.19444444444444445],
            [414.996, 0.03888888888888889],
            [417.03999999999996, 0.044444444444444446],
            [418.28999999999996, 0.06111111111111111],
            [418.30600000000004, 0.06111111111111111],
            [422.108, 0.18055555555555555],
            [422.746, 1.0],
            [424.139, 0.041666666666666664],
            [425.76000000000005, 0.07222222222222222],
            [429.117, 0.03333333333333333],
            [430.43999999999994, 0.05555555555555555],
            [433.225, 0.05555555555555555],
            [435.79799999999994, 0.011111111111111112],
            [435.86899999999997, 0.10555555555555556],
            [436.758, 0.05277777777777778],
            [439.134, 0.03888888888888889],
            [439.438, 0.1],
            [440.64, 0.030555555555555555],
            [441.582, 0.05],
            [447.508, 0.041666666666666664],
            [447.83900000000006, 0.03333333333333333],
            [450.704, 0.03333333333333333],
            [451.331, 0.7222222222222222],
            [451.66400000000004, 0.07222222222222222],
            [452.27299999999997, 0.1388888888888889],
            [452.38800000000003, 0.03333333333333333],
            [452.995, 0.03333333333333333],
            [454.517, 0.027777777777777776],
            [458.06800000000004, 0.03333333333333333],
            [460.573, 0.03333333333333333],
            [462.13800000000003, 0.027777777777777776],
            [479.142, 0.05277777777777778],
            [488.91400000000004, 0.6111111111111112],
            [492.39, 0.06111111111111111],
            [505.85600000000005, 0.011111111111111112],
            [509.65, 0.019444444444444445],
            [512.0319999999999, 0.005555555555555556],
            [516.165, 0.006944444444444444],
            [517.889, 0.011111111111111112],
            [518.174, 0.005555555555555556],
            [523.431, 0.009722222222222222],
            [524.886, 0.013888888888888888],
            [527.095, 0.3611111111111111],
            [527.556, 0.4444444444444444],
            [527.824, 0.027777777777777776],
            [530.556, 0.008333333333333333],
            [531.728, 0.005555555555555556],
            [532.1279999999999, 0.009722222222222222],
            [532.746, 0.013888888888888888],
            [533.1899999999999, 0.005555555555555556],
            [533.2760000000001, 0.005555555555555556],
            [533.385, 0.005555555555555556],
            [536.948, 0.009722222222222222],
            [536.98, 0.013888888888888888],
            [537.71, 0.027777777777777776],
            [543.1899999999999, 0.006944444444444444],
            [543.703, 0.0038888888888888888],
            [544.792, 0.0038888888888888888],
            [546.0640000000001, 0.006944444444444444],
            [552.005, 0.0038888888888888888],
            [552.11, 0.006944444444444444],
            [553.268, 0.013888888888888888],
            [556.324, 0.013888888888888888],
            [557.347, 0.006944444444444444],
            [558.472, 0.006944444444444444],
            [560.721, 0.002777777777777778],
            [561.2270000000001, 0.0033333333333333335],
            [566.788, 0.027777777777777776],
            [571.143, 0.006944444444444444],
            [571.6949999999999, 0.005],
            [575.293, 0.030555555555555555],
            [577.683, 0.030555555555555555],
            [579.1600000000001, 0.005],
            [581.592, 0.002777777777777778],
            [583.431, 0.1527777777777778],
            [591.986, 0.002777777777777778],
            [594.324, 0.016666666666666666],
            [595.021, 0.002777777777777778],
            [596.9770000000001, 0.005],
            [598.999, 0.002777777777777778],
            [599.573, 0.005],
            [611.422, 0.008333333333333333],
            [614.581, 0.009722222222222222],
            [614.682, 0.013888888888888888],
            [620.324, 0.005],
            [621.797, 0.006944444444444444],
            [622.942, 0.008333333333333333],
            [624.324, 0.009722222222222222],
            [626.0020000000001, 0.009722222222222222],
            [627.137, 0.005],
            [627.876, 0.005],
            [628.641, 0.002777777777777778],
            [630.342, 0.002777777777777778],
            [630.77, 0.05555555555555555],
            [632.1899999999999, 0.05555555555555555],
            [635.075, 0.022222222222222223],
            [638.294, 0.0044444444444444444],
            [641.147, 0.0038888888888888888],
            [651.147, 0.013888888888888888],
            [651.525, 0.0038888888888888888],
            [654.491, 0.0033333333333333335],
            [657.711, 0.009722222222222222],
            [659.2520000000001, 0.011111111111111112],
            [660.519, 0.027777777777777776],
            [662.391, 0.008333333333333333],
            [663.725, 0.002777777777777778],
            [665.239, 0.0075],
            [668.328, 0.004166666666666667],
            [671.13, 0.0025],
            [675.1220000000001, 0.008333333333333333],
            [676.1189999999999, 0.001388888888888889],
            [681.341, 0.05],
            [682.99, 0.07222222222222222],
            [697.153, 0.02361111111111111],
        ],
    },
    {
        name: "rubidium",
        lines: [
            [402.94899999999996, 0.01888888888888889],
            [408.38800000000003, 0.027777777777777776],
            [410.428, 0.022222222222222223],
            [413.611, 0.01888888888888889],
            [419.308, 0.03888888888888889],
            [420.18, 0.011111111111111112],
            [421.553, 0.005555555555555556],
            [424.43999999999994, 1.0],
            [426.658, 0.005555555555555556],
            [427.025, 0.002777777777777778],
            [427.314, 0.16666666666666666],
            [428.797, 0.027777777777777776],
            [429.39700000000005, 0.016666666666666666],
            [430.62600000000003, 0.005555555555555556],
            [434.696, 0.011111111111111112],
            [437.712, 0.027777777777777776],
            [444.01000000000005, 0.0033333333333333335],
            [446.947, 0.011111111111111112],
            [449.392, 0.0044444444444444444],
            [451.904, 0.0077777777777777776],
            [453.034, 0.03333333333333333],
            [453.379, 0.005555555555555556],
            [454.07399999999996, 0.0044444444444444444],
            [457.177, 0.2222222222222222],
            [462.242, 0.03333333333333333],
            [463.189, 0.0038888888888888888],
            [464.85699999999997, 0.1111111111111111],
            [465.928, 0.005555555555555556],
            [473.04499999999996, 0.011111111111111112],
            [475.53000000000003, 0.011111111111111112],
            [475.782, 0.0044444444444444444],
            [477.59499999999997, 0.3333333333333333],
            [478.283, 0.05555555555555555],
            [485.534, 0.0033333333333333335],
            [488.559, 0.016666666666666666],
            [508.7987, 2.2222222222222223e-5],
            [513.2470999999999, 2.2222222222222223e-5],
            [515.0134, 0.00011111111111111112],
            [515.208, 0.1111111111111111],
            [516.458, 0.0033333333333333335],
            [516.5023, 1.1111111111111112e-5],
            [516.5142, 2.2222222222222223e-5],
            [516.9649999999999, 1.1111111111111112e-5],
            [519.5278000000001, 0.00016666666666666666],
            [523.3968, 2.2222222222222223e-5],
            [526.0033999999999, 0.00022222222222222223],
            [526.0228, 1.1111111111111112e-5],
            [527.051, 0.0022222222222222222],
            [532.238, 3.3333333333333335e-5],
            [536.2601, 0.00044444444444444447],
            [539.0568000000001, 4.4444444444444447e-5],
            [543.1532, 0.0008333333333333334],
            [543.183, 3.3333333333333335e-5],
            [551.255, 0.005555555555555556],
            [552.278, 0.05555555555555555],
            [557.8788, 6.666666666666667e-5],
            [563.5989999999999, 0.05555555555555555],
            [564.7774000000001, 0.00044444444444444447],
            [565.375, 0.00022222222222222223],
            [569.915, 0.03333333333333333],
            [572.4121, 0.0006666666666666666],
            [572.4613999999999, 3.3333333333333335e-5],
            [573.964, 0.0022222222222222222],
            [607.0755, 0.0008333333333333334],
            [613.527, 0.0022222222222222222],
            [615.9626000000001, 0.0003333333333333333],
            [619.908, 0.011111111111111112],
            [620.6309, 0.0008333333333333334],
            [626.9399999999999, 0.0033333333333333335],
            [629.8325, 0.0013333333333333333],
            [629.9224, 5.555555555555556e-5],
            [645.833, 0.1111111111111111],
            [655.562, 0.011111111111111112],
            [656.081, 0.05555555555555555],
            [677.507, 0.03333333333333333],
        ],
    },
    {
        name: "ruthenium",
        lines: [
            [402.216, 0.19736842105263158],
            [402.383, 0.07894736842105263],
            [403.921, 0.04078947368421053],
            [405.14, 0.18421052631578946],
            [405.40500000000003, 0.09342105263157895],
            [406.446, 0.04868421052631579],
            [406.761, 0.02631578947368421],
            [406.837, 0.1],
            [407.3, 0.02631578947368421],
            [407.673, 0.12894736842105264],
            [408.06, 0.7894736842105263],
            [408.543, 0.04078947368421053],
            [409.779, 0.12236842105263158],
            [410.174, 0.046052631578947366],
            [411.274, 0.25],
            [414.416, 0.2631578947368421],
            [414.57399999999996, 0.08552631578947369],
            [414.677, 0.034210526315789476],
            [416.75100000000003, 0.11447368421052631],
            [419.758, 0.07236842105263158],
            [419.88800000000003, 0.07236842105263158],
            [419.98999999999995, 1.0],
            [420.60200000000003, 0.19736842105263158],
            [421.206, 0.7105263157894737],
            [421.44399999999996, 0.1],
            [421.72700000000003, 0.12236842105263158],
            [422.06800000000004, 0.04868421052631579],
            [423.03100000000006, 0.07236842105263158],
            [424.105, 0.1],
            [424.30600000000004, 0.1],
            [424.67299999999994, 0.04868421052631579],
            [425.899, 0.04078947368421053],
            [428.433, 0.1],
            [429.328, 0.02894736842105263],
            [429.479, 0.034210526315789476],
            [429.593, 0.07236842105263158],
            [429.771, 0.4868421052631579],
            [430.76000000000005, 0.12236842105263158],
            [431.843, 0.04868421052631579],
            [431.98699999999997, 0.07236842105263158],
            [434.207, 0.07236842105263158],
            [434.96999999999997, 0.046052631578947366],
            [435.413, 0.09342105263157895],
            [436.121, 0.11447368421052631],
            [437.221, 0.3157894736842105],
            [438.53900000000004, 0.11447368421052631],
            [438.56499999999994, 0.17105263157894737],
            [439.044, 0.2236842105263158],
            [441.003, 0.21052631578947367],
            [442.146, 0.021052631578947368],
            [442.846, 0.04342105263157895],
            [443.976, 0.060526315789473685],
            [444.934, 0.05789473684210526],
            [446.004, 0.14473684210526316],
            [447.39300000000003, 0.025],
            [448.04499999999996, 0.019736842105263157],
            [449.814, 0.046052631578947366],
            [451.01000000000005, 0.015789473684210527],
            [451.689, 0.02894736842105263],
            [451.782, 0.02894736842105263],
            [452.09499999999997, 0.014473684210526316],
            [454.733, 0.02236842105263158],
            [454.785, 0.014473684210526316],
            [455.451, 0.7105263157894737],
            [455.99799999999993, 0.014473684210526316],
            [458.44399999999996, 0.2236842105263158],
            [459.11, 0.014473684210526316],
            [459.25200000000007, 0.019736842105263157],
            [459.908, 0.04342105263157895],
            [463.56899999999996, 0.02236842105263158],
            [464.509, 0.02631578947368421],
            [464.76099999999997, 0.09473684210526316],
            [465.43199999999996, 0.038157894736842106],
            [468.179, 0.038157894736842106],
            [468.40200000000004, 0.025],
            [469.01099999999997, 0.038157894736842106],
            [470.948, 0.18421052631578946],
            [473.133, 0.018421052631578946],
            [473.35200000000003, 0.015789473684210527],
            [475.784, 0.06578947368421052],
            [481.552, 0.034210526315789476],
            [484.456, 0.015789473684210527],
            [486.91499999999996, 0.07236842105263158],
            [489.56000000000006, 0.021052631578947368],
            [490.305, 0.06184210526315789],
            [490.78900000000004, 0.015789473684210527],
            [492.10699999999997, 0.034210526315789476],
            [493.843, 0.02368421052631579],
            [496.89, 0.021052631578947368],
            [498.035, 0.021052631578947368],
            [499.274, 0.015789473684210527],
            [501.12299999999993, 0.021052631578947368],
            [501.495, 0.011842105263157895],
            [502.61800000000005, 0.011842105263157895],
            [502.816, 0.008552631578947369],
            [504.035, 0.004605263157894736],
            [504.07399999999996, 0.004605263157894736],
            [504.73100000000005, 0.008552631578947369],
            [505.733, 0.05921052631578947],
            [506.264, 0.002763157894736842],
            [507.297, 0.011842105263157895],
            [507.63199999999995, 0.015789473684210527],
            [509.383, 0.02631578947368421],
            [510.707, 0.010526315789473684],
            [512.3729999999999, 0.003157894736842105],
            [512.726, 0.007236842105263158],
            [513.389, 0.008552631578947369],
            [513.655, 0.06973684210526315],
            [514.2760000000001, 0.02236842105263158],
            [514.7239999999999, 0.03289473684210526],
            [515.107, 0.014473684210526316],
            [515.3199999999999, 0.007236842105263158],
            [515.514, 0.06578947368421052],
            [516.0, 0.007236842105263158],
            [517.103, 0.12105263157894737],
            [519.5020000000001, 0.02368421052631579],
            [519.987, 0.010526315789473684],
            [520.212, 0.0059210526315789476],
            [521.3430000000001, 0.0059210526315789476],
            [522.355, 0.008552631578947369],
            [524.238, 0.005263157894736842],
            [525.167, 0.007236842105263158],
            [525.707, 0.005263157894736842],
            [526.647, 0.005263157894736842],
            [526.683, 0.005263157894736842],
            [528.082, 0.005263157894736842],
            [528.408, 0.017105263157894738],
            [529.116, 0.005263157894736842],
            [530.486, 0.010526315789473684],
            [530.927, 0.034210526315789476],
            [531.533, 0.0017105263157894738],
            [533.293, 0.005263157894736842],
            [533.47, 0.0059210526315789476],
            [533.5930000000001, 0.014473684210526316],
            [536.177, 0.017105263157894738],
            [537.784, 0.008552631578947369],
            [538.588, 0.008552631578947369],
            [540.104, 0.014473684210526316],
            [540.139, 0.005263157894736842],
            [541.886, 0.005263157894736842],
            [542.759, 0.007236842105263158],
            [543.921, 0.0034210526315789475],
            [545.271, 0.0017105263157894738],
            [545.482, 0.010526315789473684],
            [545.613, 0.011842105263157895],
            [547.518, 0.0017105263157894738],
            [547.9399999999999, 0.007236842105263158],
            [548.03, 0.0034210526315789475],
            [548.432, 0.010526315789473684],
            [548.464, 0.002368421052631579],
            [549.669, 0.0034210526315789475],
            [550.1020000000001, 0.0017105263157894738],
            [551.071, 0.017105263157894738],
            [551.237, 0.002631578947368421],
            [551.786, 0.0010526315789473684],
            [552.178, 0.0015789473684210526],
            [553.0989999999999, 0.0015789473684210526],
            [554.066, 0.003157894736842105],
            [555.652, 0.0015789473684210526],
            [555.975, 0.011842105263157895],
            [556.903, 0.0014473684210526317],
            [557.8399999999999, 0.002763157894736842],
            [560.3140000000001, 0.002763157894736842],
            [560.355, 0.0010526315789473684],
            [560.673, 0.0017105263157894738],
            [562.979, 0.0014473684210526317],
            [563.624, 0.038157894736842106],
            [564.1659999999999, 0.0014473684210526317],
            [564.956, 0.0009210526315789473],
            [565.33, 0.0009210526315789473],
            [566.52, 0.0014473684210526317],
            [567.963, 0.002105263157894737],
            [569.905, 0.02368421052631579],
            [572.482, 0.0017105263157894738],
            [572.573, 0.0017105263157894738],
            [574.5989999999999, 0.002105263157894737],
            [574.7470000000001, 0.002105263157894737],
            [575.202, 0.0014473684210526317],
            [575.683, 0.0014473684210526317],
            [576.792, 0.0014473684210526317],
            [580.4390000000001, 0.002105263157894737],
            [581.4979999999999, 0.008552631578947369],
            [582.806, 0.0010526315789473684],
            [583.321, 0.002105263157894737],
            [591.934, 0.007236842105263158],
            [592.145, 0.010526315789473684],
            [592.687, 0.002763157894736842],
            [593.238, 0.0034210526315789475],
            [593.665, 0.0010526315789473684],
            [595.115, 0.0010526315789473684],
            [597.338, 0.002763157894736842],
            [597.417, 0.0010526315789473684],
            [598.867, 0.002105263157894737],
            [599.365, 0.004605263157894736],
            [611.677, 0.002368421052631579],
            [619.942, 0.0034210526315789475],
            [622.52, 0.0034210526315789475],
            [628.449, 0.0011842105263157896],
            [629.522, 0.002368421052631579],
            [633.062, 0.0017105263157894738],
            [633.612, 0.0011842105263157896],
            [636.341, 0.0011842105263157896],
            [637.645, 0.0011842105263157896],
            [639.0229999999999, 0.002105263157894737],
            [641.757, 0.0010526315789473684],
            [644.484, 0.0034210526315789475],
            [649.644, 0.0010526315789473684],
            [652.874, 0.0014473684210526317],
            [656.045, 0.0005263157894736842],
            [659.374, 0.0005263157894736842],
            [661.8199999999999, 0.0011842105263157896],
            [666.3140000000001, 0.002763157894736842],
            [669.0, 0.007236842105263158],
            [670.7520000000001, 0.0014473684210526317],
            [671.83, 0.001973684210526316],
            [673.045, 0.001973684210526316],
            [675.654, 0.0009210526315789473],
            [676.6949999999999, 0.002763157894736842],
            [677.5020000000001, 0.003947368421052632],
            [678.723, 0.0017105263157894738],
            [681.351, 0.0010526315789473684],
            [682.388, 0.001973684210526316],
            [682.417, 0.002763157894736842],
            [683.152, 0.0009210526315789473],
            [691.1479999999999, 0.0034210526315789475],
            [692.323, 0.014473684210526316],
            [698.201, 0.0034210526315789475],
        ],
    },
    {
        name: "selenium",
        lines: [
            [407.02, 0.57],
            [417.53000000000003, 0.72],
            [418.09, 0.9],
            [432.87, 0.24],
            [433.03000000000003, 0.2],
            [438.28999999999996, 0.57],
            [444.6, 0.57],
            [444.91999999999996, 0.44],
            [446.76000000000005, 0.57],
            [473.08000000000004, 1.0],
            [473.9, 0.8],
            [474.21999999999997, 0.6],
            [484.06000000000006, 0.57],
            [484.5, 0.72],
            [522.75, 0.9],
            [530.54, 0.72],
            [536.55, 0.2],
            [536.99, 0.24],
            [537.4100000000001, 0.22],
            [552.24, 0.57],
            [556.6899999999999, 0.57],
            [586.63, 0.57],
            [605.6, 0.9],
            [632.5600000000001, 0.4],
            [644.42, 0.72],
            [649.05, 0.57],
            [653.5, 0.57],
            [683.13, 0.3],
            [699.069, 0.24],
            [699.1792, 0.2],
            [701.0809, 0.4],
            [404.66999999999996, 0.72],
            [408.32, 0.44],
            [416.91, 0.9],
            [463.78999999999996, 0.44],
            [630.38, 0.57],
        ],
    },
    {
        name: "silver",
        lines: [
            [405.548, 0.1],
            [408.34299999999996, 0.01],
            [408.591, 0.08],
            [418.54799999999994, 0.1],
            [421.096, 0.09],
            [421.282, 0.1],
            [431.10699999999997, 0.05],
            [439.62299999999993, 0.02],
            [447.604, 0.05],
            [455.6, 0.02],
            [461.56899999999996, 0.03],
            [462.004, 0.08],
            [462.046, 0.05],
            [466.84799999999996, 0.06],
            [467.76000000000005, 0.03],
            [478.84, 0.1],
            [479.62, 0.02],
            [484.782, 0.03],
            [487.41, 0.1],
            [488.821, 0.02],
            [491.75, 0.01],
            [493.575, 0.01],
            [499.28900000000004, 0.02],
            [502.735, 0.08],
            [512.35, 0.015],
            [520.908, 1.0],
            [533.362, 0.01],
            [546.55, 1.0],
            [547.155, 0.1],
            [547.538, 0.02],
            [554.567, 0.02],
            [555.958, 0.01],
            [566.734, 0.1],
            [608.3779999999999, 0.01],
            [626.85, 0.01],
            [662.108, 0.02],
        ],
    },
    {
        name: "sulfur",
        lines: [
            [390.2, 0.285],
            [393.33000000000004, 0.36],
            [412.08000000000004, 0.45],
            [414.23, 0.28],
            [414.51000000000005, 0.36],
            [415.31000000000006, 0.45],
            [416.27, 0.45],
            [469.41, 0.45],
            [469.53999999999996, 0.285],
            [469.62, 0.16],
            [471.62, 0.28],
            [481.55, 0.45],
            [492.41, 0.36],
            [492.53000000000003, 0.45],
            [499.35, 0.285],
            [542.86, 0.36],
            [543.28, 0.65],
            [545.38, 1.0],
            [547.36, 1.0],
            [550.97, 1.0],
            [556.49, 0.28],
            [560.61, 1.0],
            [564.0, 0.45],
            [564.03, 0.45],
            [564.7, 0.28],
            [565.99, 0.65],
            [566.47, 0.45],
            [570.61, 0.16],
            [581.92, 0.45],
            [605.27, 0.45],
            [628.64, 0.28],
            [628.71, 0.45],
            [630.55, 0.45],
            [631.27, 0.45],
            [638.49, 0.28],
            [639.73, 0.28],
            [639.8, 0.28],
            [641.37, 0.36],
            [674.36, 0.16],
            [674.88, 0.285],
            [675.72, 0.45],
        ],
    },
    {
        name: "strontium",
        lines: [
            [403.038, 0.02],
            [403.238, 0.004615384615384616],
            [407.771, 0.7076923076923077],
            [416.18, 0.003076923076923077],
            [421.552, 0.49230769230769234],
            [430.54499999999996, 0.005230769230769231],
            [443.804, 0.005384615384615384],
            [460.733, 1.0],
            [472.22799999999995, 0.04923076923076923],
            [474.192, 0.033846153846153845],
            [478.43199999999996, 0.021538461538461538],
            [481.188, 0.07384615384615385],
            [483.20799999999997, 0.055384615384615386],
            [485.504, 0.007692307692307693],
            [486.87, 0.009230769230769232],
            [487.24899999999997, 0.046153846153846156],
            [487.60600000000005, 0.009230769230769232],
            [487.63199999999995, 0.03076923076923077],
            [489.198, 0.015384615384615385],
            [496.226, 0.12307692307692308],
            [496.794, 0.02],
            [515.607, 0.012307692307692308],
            [522.22, 0.021538461538461538],
            [522.511, 0.03076923076923077],
            [522.927, 0.03076923076923077],
            [523.855, 0.043076923076923075],
            [525.6899999999999, 0.07384615384615385],
            [532.982, 0.005384615384615384],
            [545.0840000000001, 0.023076923076923078],
            [548.0840000000001, 0.1076923076923077],
            [548.612, 0.016923076923076923],
            [550.417, 0.05384615384615385],
            [552.183, 0.04],
            [553.481, 0.03076923076923077],
            [554.005, 0.03076923076923077],
            [554.336, 0.0038461538461538464],
            [597.01, 0.003076923076923077],
            [634.575, 0.0038461538461538464],
            [636.394, 0.0038461538461538464],
            [636.996, 0.005384615384615384],
            [638.075, 0.015384615384615385],
            [638.65, 0.013846153846153847],
            [638.824, 0.009230769230769232],
            [640.847, 0.13846153846153847],
            [644.668, 0.0038461538461538464],
            [646.579, 0.0038461538461538464],
            [650.4, 0.08461538461538462],
            [654.679, 0.015384615384615385],
            [655.0260000000001, 0.026153846153846153],
            [661.726, 0.046153846153846156],
            [664.354, 0.012307692307692308],
            [679.105, 0.027692307692307693],
            [687.838, 0.07384615384615385],
            [689.259, 0.018461538461538463],
        ],
    },
    {
        name: "terbium",
        lines: [
            [400.219, 0.11666666666666667],
            [400.259, 0.3233333333333333],
            [400.54699999999997, 0.6333333333333333],
            [401.004, 0.1],
            [401.275, 0.25333333333333335],
            [401.326, 0.11],
            [401.914, 0.12333333333333334],
            [402.04699999999997, 0.18],
            [402.288, 0.07333333333333333],
            [402.477, 0.12333333333333334],
            [403.166, 0.17333333333333334],
            [403.228, 0.29],
            [403.303, 0.7],
            [403.62199999999996, 0.11666666666666667],
            [403.886, 0.07],
            [405.18600000000004, 0.1],
            [405.287, 0.1],
            [405.412, 0.14333333333333334],
            [406.037, 0.13666666666666666],
            [406.087, 0.07333333333333333],
            [406.158, 0.43333333333333335],
            [406.389, 0.07333333333333333],
            [406.62199999999996, 0.13],
            [407.522, 0.08666666666666667],
            [408.12399999999997, 0.13],
            [408.65999999999997, 0.07],
            [409.219, 0.07],
            [409.437, 0.08666666666666667],
            [409.44899999999996, 0.08666666666666667],
            [410.39, 0.08666666666666667],
            [410.537, 0.21666666666666667],
            [411.25, 0.1],
            [411.992, 0.08666666666666667],
            [414.351, 0.09333333333333334],
            [414.441, 0.36666666666666664],
            [415.85299999999995, 0.11666666666666667],
            [416.909, 0.08],
            [416.93199999999996, 0.08],
            [417.105, 0.08],
            [417.26000000000005, 0.08],
            [417.282, 0.08],
            [417.34700000000004, 0.08666666666666667],
            [418.621, 0.08],
            [418.716, 0.1],
            [419.674, 0.13],
            [420.1, 0.15],
            [420.37399999999997, 0.21666666666666667],
            [420.649, 0.2],
            [421.35, 0.1],
            [421.442, 0.1],
            [421.509, 0.16],
            [421.75600000000003, 0.1],
            [421.916, 0.08666666666666667],
            [422.428, 0.08666666666666667],
            [422.645, 0.16],
            [423.189, 0.08666666666666667],
            [423.282, 0.16],
            [423.535, 0.1],
            [425.524, 0.12333333333333334],
            [425.823, 0.16],
            [426.366, 0.08666666666666667],
            [426.634, 0.21666666666666667],
            [426.96899999999994, 0.11],
            [427.521, 0.07333333333333333],
            [427.85200000000003, 0.25333333333333335],
            [428.51300000000003, 0.1],
            [428.96999999999997, 0.1],
            [429.83599999999996, 0.12333333333333334],
            [429.98999999999995, 0.1],
            [430.29499999999996, 0.08],
            [430.718, 0.08],
            [431.04200000000003, 0.15],
            [431.15600000000006, 0.1],
            [431.325, 0.12333333333333334],
            [431.883, 0.7333333333333333],
            [432.22299999999996, 0.2],
            [432.58299999999997, 0.2],
            [432.64300000000003, 1.0],
            [432.89, 0.08],
            [433.212, 0.2],
            [433.64300000000003, 0.29],
            [433.764, 0.2],
            [433.841, 0.5666666666666667],
            [434.062, 0.23333333333333334],
            [434.253, 0.14333333333333334],
            [435.32, 0.14333333333333334],
            [435.60900000000004, 0.09333333333333334],
            [435.68100000000004, 0.29],
            [436.01599999999996, 0.09333333333333334],
            [436.73, 0.07333333333333333],
            [437.20200000000006, 0.07333333333333333],
            [438.245, 0.11],
            [438.823, 0.1],
            [439.091, 0.08666666666666667],
            [441.62700000000007, 0.06666666666666667],
            [442.01899999999995, 0.04666666666666667],
            [442.31000000000006, 0.11666666666666667],
            [443.27200000000005, 0.03666666666666667],
            [443.61199999999997, 0.08],
            [443.938, 0.03666666666666667],
            [444.804, 0.08],
            [446.76899999999995, 0.03666666666666667],
            [449.30699999999996, 0.14333333333333334],
            [450.904, 0.015],
            [451.15200000000004, 0.05],
            [451.296, 0.015],
            [451.43100000000004, 0.025],
            [451.97200000000004, 0.015],
            [452.50100000000003, 0.015],
            [452.976, 0.015],
            [453.183, 0.015],
            [453.413, 0.015],
            [453.71400000000006, 0.015],
            [453.72299999999996, 0.015],
            [454.907, 0.03666666666666667],
            [454.97200000000004, 0.015],
            [455.04499999999996, 0.03666666666666667],
            [455.646, 0.03666666666666667],
            [456.224, 0.018333333333333333],
            [456.36899999999997, 0.03666666666666667],
            [456.485, 0.01],
            [457.31899999999996, 0.018333333333333333],
            [457.86899999999997, 0.07],
            [458.48400000000004, 0.021666666666666667],
            [459.15600000000006, 0.021666666666666667],
            [459.238, 0.015],
            [460.41, 0.015],
            [461.196, 0.01],
            [461.592, 0.015],
            [461.74899999999997, 0.009],
            [461.936, 0.01],
            [462.63199999999995, 0.025],
            [462.69399999999996, 0.03166666666666667],
            [463.207, 0.021666666666666667],
            [463.659, 0.021666666666666667],
            [463.69899999999996, 0.01],
            [464.1, 0.028333333333333332],
            [464.198, 0.07],
            [464.53100000000006, 0.08666666666666667],
            [464.72299999999996, 0.02666666666666667],
            [465.838, 0.02],
            [465.87299999999993, 0.006666666666666667],
            [466.279, 0.02666666666666667],
            [466.54499999999996, 0.016666666666666666],
            [466.93999999999994, 0.013333333333333334],
            [467.68999999999994, 0.02666666666666667],
            [468.187, 0.023333333333333334],
            [468.25200000000007, 0.016666666666666666],
            [468.279, 0.008333333333333333],
            [468.863, 0.02666666666666667],
            [469.311, 0.02666666666666667],
            [469.33900000000006, 0.01],
            [470.241, 0.06666666666666667],
            [470.794, 0.03666666666666667],
            [471.60699999999997, 0.013333333333333334],
            [472.816, 0.013333333333333334],
            [473.41999999999996, 0.02],
            [473.99300000000005, 0.02666666666666667],
            [474.78000000000003, 0.023333333333333334],
            [475.253, 0.13666666666666666],
            [475.84399999999994, 0.013333333333333334],
            [476.01899999999995, 0.013333333333333334],
            [476.23699999999997, 0.01],
            [476.447, 0.008333333333333333],
            [477.83599999999996, 0.011666666666666667],
            [477.88, 0.011666666666666667],
            [478.678, 0.06],
            [478.991, 0.013333333333333334],
            [480.187, 0.01],
            [481.37700000000007, 0.03333333333333333],
            [483.759, 0.02],
            [484.03900000000004, 0.008333333333333333],
            [484.26899999999995, 0.01],
            [484.48900000000003, 0.01],
            [485.48100000000005, 0.01],
            [485.654, 0.006666666666666667],
            [485.887, 0.01],
            [487.55699999999996, 0.02666666666666667],
            [487.61199999999997, 0.008333333333333333],
            [488.11499999999995, 0.02666666666666667],
            [489.433, 0.009666666666666667],
            [491.59, 0.03166666666666667],
            [492.409, 0.011666666666666667],
            [492.683, 0.011666666666666667],
            [492.89300000000003, 0.016666666666666666],
            [493.179, 0.021666666666666667],
            [497.099, 0.009666666666666667],
            [497.142, 0.009666666666666667],
            [497.304, 0.009666666666666667],
            [498.01599999999996, 0.009666666666666667],
            [498.05600000000004, 0.009666666666666667],
            [499.38199999999995, 0.028333333333333332],
            [499.584, 0.016666666666666666],
            [499.79499999999996, 0.018333333333333333],
            [500.61, 0.009666666666666667],
            [502.216, 0.016666666666666666],
            [502.424, 0.009666666666666667],
            [502.465, 0.009666666666666667],
            [503.312, 0.016666666666666666],
            [504.206, 0.016666666666666666],
            [505.43, 0.018333333333333333],
            [506.579, 0.018333333333333333],
            [507.825, 0.03666666666666667],
            [508.005, 0.008],
            [508.111, 0.008],
            [508.912, 0.025],
            [508.966, 0.008],
            [510.10900000000004, 0.008],
            [510.85600000000005, 0.008],
            [511.83900000000006, 0.011666666666666667],
            [512.018, 0.008],
            [513.169, 0.016666666666666666],
            [514.108, 0.016666666666666666],
            [514.758, 0.016666666666666666],
            [516.427, 0.008],
            [517.013, 0.009666666666666667],
            [517.0609999999999, 0.008],
            [517.6510000000001, 0.016666666666666666],
            [517.9970000000001, 0.016666666666666666],
            [518.4590000000001, 0.016666666666666666],
            [518.613, 0.028333333333333332],
            [518.848, 0.016666666666666666],
            [519.886, 0.016666666666666666],
            [520.277, 0.011666666666666667],
            [520.455, 0.013333333333333334],
            [520.797, 0.013333333333333334],
            [521.428, 0.013333333333333334],
            [522.199, 0.013333333333333334],
            [522.812, 0.04],
            [523.511, 0.013333333333333334],
            [524.871, 0.025],
            [526.211, 0.025],
            [527.5029999999999, 0.008],
            [528.105, 0.025],
            [530.472, 0.021666666666666667],
            [530.819, 0.009666666666666667],
            [530.946, 0.009666666666666667],
            [531.923, 0.03666666666666667],
            [533.104, 0.011666666666666667],
            [533.79, 0.021666666666666667],
            [533.859, 0.011666666666666667],
            [534.783, 0.008],
            [535.488, 0.05333333333333334],
            [536.972, 0.025],
            [537.598, 0.025],
            [540.206, 0.009666666666666667],
            [541.365, 0.009666666666666667],
            [541.62, 0.009666666666666667],
            [542.4100000000001, 0.016666666666666666],
            [542.643, 0.009666666666666667],
            [544.338, 0.011666666666666667],
            [545.7, 0.009666666666666667],
            [545.981, 0.018333333333333333],
            [547.034, 0.009666666666666667],
            [548.145, 0.008],
            [550.961, 0.018333333333333333],
            [551.454, 0.016666666666666666],
            [552.412, 0.021666666666666667],
            [552.562, 0.008],
            [556.5930000000001, 0.011666666666666667],
            [563.88, 0.009666666666666667],
            [568.574, 0.009666666666666667],
            [568.6479999999999, 0.013333333333333334],
            [574.758, 0.028333333333333332],
            [576.266, 0.008],
            [578.518, 0.008],
            [579.5640000000001, 0.025],
            [580.313, 0.025],
            [581.536, 0.021666666666666667],
            [584.297, 0.009666666666666667],
            [585.107, 0.021666666666666667],
            [587.062, 0.021666666666666667],
            [589.884, 0.011666666666666667],
            [590.24, 0.008],
            [590.471, 0.011666666666666667],
            [592.078, 0.021666666666666667],
            [593.938, 0.016666666666666666],
            [594.017, 0.011666666666666667],
            [595.117, 0.008],
            [596.734, 0.025],
            [603.897, 0.009666666666666667],
            [603.938, 0.009666666666666667],
            [610.429, 0.008],
            [629.243, 0.008],
            [633.168, 0.011666666666666667],
            [633.491, 0.008],
            [644.687, 0.008],
            [651.868, 0.011666666666666667],
            [657.404, 0.008],
            [658.182, 0.011666666666666667],
            [660.717, 0.01],
            [667.794, 0.03],
            [670.261, 0.013333333333333334],
            [670.679, 0.006666666666666667],
            [678.512, 0.01],
            [679.458, 0.043333333333333335],
            [687.418, 0.013333333333333334],
            [689.637, 0.018333333333333333],
            [689.995, 0.015],
            [690.198, 0.013333333333333334],
        ],
    },
    {
        name: "technetium",
        lines: [
            [400.469, 0.01],
            [400.714, 0.016666666666666666],
            [401.2, 0.03333333333333333],
            [401.668, 0.013333333333333334],
            [401.722, 0.02],
            [402.076, 0.06666666666666667],
            [403.163, 0.6666666666666666],
            [403.925, 0.03333333333333333],
            [404.178, 0.006666666666666667],
            [404.911, 0.3333333333333333],
            [405.195, 0.016666666666666666],
            [405.318, 0.006666666666666667],
            [405.608, 0.006666666666666667],
            [408.354, 0.013333333333333334],
            [408.871, 0.3333333333333333],
            [409.369, 0.006666666666666667],
            [409.567, 0.5],
            [411.02200000000005, 0.03333333333333333],
            [411.508, 0.3333333333333333],
            [411.927, 0.02],
            [412.422, 0.26666666666666666],
            [412.82700000000006, 0.03333333333333333],
            [413.912, 0.01],
            [413.985, 0.02666666666666667],
            [414.12700000000007, 0.013333333333333334],
            [414.495, 0.2],
            [414.508, 0.1],
            [414.762, 0.006666666666666667],
            [416.561, 0.3333333333333333],
            [416.742, 0.016666666666666666],
            [416.968, 0.03333333333333333],
            [417.02700000000004, 0.13333333333333333],
            [417.253, 0.16666666666666666],
            [417.628, 0.03333333333333333],
            [418.651, 0.02666666666666667],
            [421.861, 0.01],
            [423.81899999999996, 0.3333333333333333],
            [426.22700000000003, 0.6666666666666666],
            [426.26899999999995, 0.03333333333333333],
            [427.497, 0.02666666666666667],
            [427.89, 0.02666666666666667],
            [429.706, 1.0],
            [433.686, 0.013333333333333334],
            [435.849, 0.013333333333333334],
            [435.92600000000004, 0.006666666666666667],
            [442.959, 0.03333333333333333],
            [448.15299999999996, 0.03333333333333333],
            [448.706, 0.1],
            [449.503, 0.013333333333333334],
            [451.59799999999996, 0.03333333333333333],
            [452.284, 0.3333333333333333],
            [453.953, 0.06666666666666667],
            [454.209, 0.013333333333333334],
            [455.21999999999997, 0.013333333333333334],
            [455.285, 0.02666666666666667],
            [455.70500000000004, 0.03333333333333333],
            [456.454, 0.06666666666666667],
            [457.84499999999997, 0.03333333333333333],
            [459.33500000000004, 0.03333333333333333],
            [460.916, 0.01],
            [461.686, 0.03333333333333333],
            [462.26899999999995, 0.006666666666666667],
            [462.496, 0.01],
            [463.05699999999996, 0.03333333333333333],
            [463.31499999999994, 0.006666666666666667],
            [463.75, 0.1],
            [464.328, 0.016666666666666666],
            [464.83299999999997, 0.06666666666666667],
            [466.021, 0.06666666666666667],
            [466.93, 0.06666666666666667],
            [467.217, 0.013333333333333334],
            [467.89, 0.006666666666666667],
            [468.936, 0.013333333333333334],
            [469.428, 0.01],
            [470.692, 0.03333333333333333],
            [471.422, 0.006666666666666667],
            [471.77700000000004, 0.06666666666666667],
            [471.90200000000004, 0.016666666666666666],
            [471.928, 0.13333333333333333],
            [473.651, 0.006666666666666667],
            [474.061, 0.3333333333333333],
            [474.96099999999996, 0.016666666666666666],
            [475.27200000000005, 0.03333333333333333],
            [476.236, 0.006666666666666667],
            [477.154, 0.13333333333333333],
            [477.389, 0.006666666666666667],
            [478.392, 0.006666666666666667],
            [478.56000000000006, 0.016666666666666666],
            [479.04799999999994, 0.006666666666666667],
            [479.162, 0.008333333333333333],
            [479.99799999999993, 0.01],
            [480.56899999999996, 0.0033333333333333335],
            [480.942, 0.0033333333333333335],
            [481.679, 0.016666666666666666],
            [482.07399999999996, 0.3333333333333333],
            [483.13500000000005, 0.01],
            [483.437, 0.03333333333333333],
            [483.53900000000004, 0.03333333333333333],
            [484.13599999999997, 0.0033333333333333335],
            [485.35900000000004, 0.6666666666666666],
            [485.721, 0.0033333333333333335],
            [486.21899999999994, 0.0033333333333333335],
            [486.67299999999994, 0.3333333333333333],
            [487.07700000000006, 0.006666666666666667],
            [488.87, 0.0033333333333333335],
            [489.088, 0.005],
            [489.192, 0.26666666666666666],
            [489.24899999999997, 0.005],
            [490.851, 0.03333333333333333],
            [490.957, 0.06666666666666667],
            [491.302, 0.016666666666666666],
            [491.46999999999997, 0.005],
            [492.067, 0.006666666666666667],
            [492.36, 0.01],
            [494.80600000000004, 0.013333333333333334],
            [497.634, 0.16666666666666666],
            [499.5, 0.013333333333333334],
            [500.267, 0.006666666666666667],
            [500.57399999999996, 0.0033333333333333335],
            [501.45200000000006, 0.006666666666666667],
            [502.62399999999997, 0.016666666666666666],
            [502.679, 0.01],
            [502.78900000000004, 0.005],
            [503.245, 0.0026666666666666666],
            [505.52700000000004, 0.01],
            [505.83299999999997, 0.002],
            [506.06899999999996, 0.016666666666666666],
            [509.07399999999996, 0.0026666666666666666],
            [509.628, 0.16666666666666666],
            [510.32399999999996, 0.006666666666666667],
            [510.43199999999996, 0.016666666666666666],
            [510.98100000000005, 0.006666666666666667],
            [512.0600000000001, 0.0033333333333333335],
            [513.926, 0.016666666666666666],
            [515.063, 0.016666666666666666],
            [516.181, 0.06666666666666667],
            [517.481, 0.06666666666666667],
            [520.6560000000001, 0.0033333333333333335],
            [522.5550000000001, 0.006666666666666667],
            [526.022, 0.006666666666666667],
            [526.144, 0.006666666666666667],
            [527.551, 0.03333333333333333],
            [528.507, 0.02666666666666667],
            [530.5310000000001, 0.0033333333333333335],
            [531.496, 0.013333333333333334],
            [532.02, 0.02],
            [533.479, 0.006666666666666667],
            [535.348, 0.016666666666666666],
            [535.663, 0.006666666666666667],
            [535.865, 0.01],
            [536.014, 0.006666666666666667],
            [537.52, 0.016666666666666666],
            [542.3050000000001, 0.005],
            [544.74, 0.006666666666666667],
            [545.1899999999999, 0.016666666666666666],
            [545.595, 0.0033333333333333335],
            [547.196, 0.01],
            [548.301, 0.0023333333333333335],
            [548.537, 0.002],
            [550.6890000000001, 0.0026666666666666666],
            [552.411, 0.005],
            [552.823, 0.0033333333333333335],
            [554.194, 0.006666666666666667],
            [554.363, 0.0026666666666666666],
            [555.053, 0.0033333333333333335],
            [558.902, 0.1],
            [560.223, 0.006666666666666667],
            [562.045, 0.06666666666666667],
            [562.9939999999999, 0.01],
            [564.213, 0.05],
            [564.4939999999999, 0.02666666666666667],
            [565.6, 0.0033333333333333335],
            [567.2149999999999, 0.002],
            [568.73, 0.006666666666666667],
            [568.905, 0.006666666666666667],
            [572.5310000000001, 0.023333333333333334],
            [577.147, 0.016666666666666666],
            [579.4649999999999, 0.0033333333333333335],
            [579.985, 0.0026666666666666666],
            [581.424, 0.0033333333333333335],
            [583.1479999999999, 0.006666666666666667],
            [583.633, 0.005],
            [592.336, 0.005],
            [592.447, 0.03333333333333333],
            [592.629, 0.006666666666666667],
            [593.193, 0.02],
            [603.236, 0.002],
            [604.799, 0.002],
            [606.509, 0.006666666666666667],
            [608.5229999999999, 0.02666666666666667],
            [609.9390000000001, 0.01],
            [610.296, 0.016666666666666666],
            [612.068, 0.03333333333333333],
            [613.08, 0.03333333333333333],
            [613.223, 0.005],
            [618.47, 0.0033333333333333335],
            [619.266, 0.02666666666666667],
            [624.418, 0.02],
            [631.2180000000001, 0.0033333333333333335],
            [635.486, 0.0033333333333333335],
            [635.673, 0.0033333333333333335],
            [638.987, 0.0026666666666666666],
            [640.883, 0.0033333333333333335],
            [645.5899999999999, 0.03333333333333333],
            [646.193, 0.02],
            [647.027, 0.0033333333333333335],
            [649.168, 0.006666666666666667],
            [652.682, 0.006666666666666667],
            [657.924, 0.005],
            [662.557, 0.016666666666666666],
            [667.366, 0.01],
            [668.71, 0.0033333333333333335],
            [678.6, 0.0026666666666666666],
            [679.863, 0.0023333333333333335],
            [685.6899999999999, 0.002],
        ],
    },
    {
        name: "tellurium",
        lines: [
            [400.652, 0.25],
            [401.169, 0.1],
            [402.973, 0.15],
            [404.717, 0.2],
            [404.88800000000003, 0.15],
            [407.348, 0.075],
            [410.104, 0.15],
            [412.73199999999997, 0.35],
            [416.355, 0.15],
            [416.97700000000003, 0.5],
            [417.929, 0.15],
            [421.13100000000003, 0.125],
            [422.573, 0.4],
            [424.64700000000005, 0.15],
            [425.11499999999995, 0.1],
            [426.111, 0.5],
            [426.436, 0.15],
            [427.343, 0.3],
            [428.58500000000004, 0.4],
            [432.09, 0.2],
            [436.128, 0.15],
            [436.4, 0.75],
            [437.712, 0.15],
            [438.51000000000005, 0.375],
            [439.6, 0.3],
            [447.863, 0.85],
            [453.707, 0.4],
            [455.77799999999996, 0.5],
            [463.062, 0.35],
            [464.11199999999997, 0.5],
            [465.437, 0.9],
            [468.691, 1.0],
            [469.63800000000003, 0.5],
            [470.65299999999996, 0.5],
            [476.605, 0.5],
            [477.15600000000006, 0.35],
            [478.48699999999997, 0.5],
            [482.71400000000006, 0.5],
            [483.128, 0.75],
            [484.28999999999996, 0.75],
            [486.512, 0.65],
            [486.62399999999997, 1.0],
            [488.52200000000005, 0.4],
            [490.44399999999996, 0.4],
            [496.188, 0.3],
            [500.082, 0.3],
            [508.3, 0.04],
            [514.87, 0.035],
            [544.984, 0.25],
            [548.795, 0.25],
            [557.635, 0.75],
            [564.926, 0.75],
            [566.62, 0.5],
            [570.812, 1.0],
            [573.35, 0.035],
            [575.585, 0.75],
            [578.9100000000001, 0.04],
            [593.615, 0.25],
            [597.4680000000001, 0.5],
            [627.35, 0.04],
            [634.97, 0.04],
            [636.713, 0.25],
            [640.5899999999999, 0.04],
            [645.67, 0.035],
            [661.3399999999999, 0.04],
            [664.858, 0.05],
            [666.02, 0.04],
            [669.0, 0.04],
            [679.0, 0.05],
            [683.76, 0.1],
            [685.47, 0.1],
        ],
    },
    {
        name: "thulium",
        lines: [
            [402.423, 0.022],
            [404.447, 0.038],
            [409.419, 1.0],
            [410.584, 0.95],
            [413.26899999999995, 0.012],
            [413.83299999999997, 0.11],
            [414.91400000000004, 0.012],
            [415.86, 0.012],
            [418.762, 0.88],
            [419.992, 0.052],
            [420.37299999999993, 0.6],
            [420.6, 0.022],
            [422.267, 0.038],
            [424.215, 0.3],
            [427.171, 0.027],
            [429.83599999999996, 0.015],
            [435.99300000000005, 0.27],
            [438.64300000000003, 0.14],
            [439.442, 0.02],
            [439.596, 0.012],
            [439.65, 0.014],
            [443.73999999999995, 0.0055],
            [444.274, 0.008],
            [444.758, 0.005],
            [445.40299999999996, 0.012],
            [445.99899999999997, 0.008],
            [446.79799999999994, 0.005],
            [448.12600000000003, 0.054],
            [448.96999999999997, 0.008],
            [451.96000000000004, 0.015],
            [452.25699999999995, 0.026],
            [452.938, 0.018],
            [453.215, 0.008],
            [454.86, 0.011],
            [455.668, 0.004],
            [456.186, 0.004],
            [456.468, 0.008],
            [456.71099999999996, 0.004],
            [459.663, 0.0095],
            [459.90200000000004, 0.027],
            [460.129, 0.0035],
            [460.343, 0.0055],
            [460.485, 0.004],
            [461.39700000000005, 0.005],
            [461.447, 0.004],
            [461.59399999999994, 0.03],
            [461.90600000000006, 0.0035],
            [462.172, 0.004],
            [462.633, 0.008],
            [462.65600000000006, 0.0095],
            [462.697, 0.004],
            [463.42600000000004, 0.011],
            [464.296, 0.004],
            [464.312, 0.0095],
            [464.45799999999997, 0.0035],
            [465.509, 0.012],
            [466.66999999999996, 0.0035],
            [467.19899999999996, 0.0035],
            [467.51000000000005, 0.0035],
            [467.53100000000006, 0.008],
            [467.78599999999994, 0.004],
            [468.192, 0.016],
            [468.51099999999997, 0.007],
            [469.111, 0.012],
            [472.42600000000004, 0.011],
            [473.334, 0.068],
            [475.075, 0.0035],
            [475.98999999999995, 0.007],
            [478.992, 0.0027],
            [480.74799999999993, 0.0027],
            [480.86800000000005, 0.0035],
            [481.35, 0.0035],
            [482.69899999999996, 0.0027],
            [482.89700000000005, 0.0027],
            [483.12, 0.008],
            [483.575, 0.0035],
            [485.17600000000004, 0.0027],
            [487.22799999999995, 0.0019],
            [487.919, 0.0027],
            [489.16400000000004, 0.0027],
            [490.974, 0.0024],
            [492.383, 0.0055],
            [495.718, 0.014],
            [497.087, 0.004],
            [497.12600000000003, 0.0027],
            [497.512, 0.004],
            [497.89, 0.005],
            [498.06800000000004, 0.004],
            [498.93199999999996, 0.0055],
            [499.379, 0.0027],
            [499.47200000000004, 0.0019],
            [495.718, 0.014],
            [497.087, 0.004],
            [497.12600000000003, 0.0027],
            [497.512, 0.004],
            [497.89, 0.005],
            [498.06800000000004, 0.004],
            [498.93199999999996, 0.0055],
            [499.379, 0.0027],
            [499.47200000000004, 0.0019],
            [500.10200000000003, 0.0035],
            [500.159, 0.0027],
            [500.97700000000003, 0.016],
            [501.456, 0.0035],
            [501.787, 0.0027],
            [503.422, 0.016],
            [504.1, 0.0027],
            [504.35, 0.0022],
            [504.541, 0.0035],
            [506.04200000000003, 0.0027],
            [506.09, 0.015],
            [506.225, 0.0027],
            [506.588, 0.0027],
            [506.66700000000003, 0.008],
            [507.242, 0.0027],
            [507.63599999999997, 0.0027],
            [507.718, 0.0027],
            [508.509, 0.0035],
            [510.753, 0.004],
            [511.39700000000005, 0.0095],
            [511.45500000000004, 0.005],
            [512.067, 0.0022],
            [514.028, 0.0022],
            [514.9399999999999, 0.004],
            [518.268, 0.0019],
            [518.525, 0.004],
            [520.451, 0.0014],
            [521.338, 0.008],
            [522.823, 0.0022],
            [526.0930000000001, 0.0014],
            [526.734, 0.0024],
            [529.114, 0.004],
            [529.432, 0.004],
            [530.021, 0.0035],
            [530.269, 0.0035],
            [530.587, 0.0055],
            [530.712, 0.065],
            [532.299, 0.0016],
            [533.89, 0.0035],
            [534.649, 0.008],
            [537.298, 0.0027],
            [539.196, 0.0014],
            [540.046, 0.0027],
            [540.223, 0.0027],
            [540.598, 0.0014],
            [546.1949999999999, 0.0014],
            [546.414, 0.0014],
            [546.554, 0.0014],
            [550.03, 0.0016],
            [552.682, 0.0014],
            [552.8340000000001, 0.0024],
            [553.903, 0.0014],
            [556.6, 0.0027],
            [558.137, 0.0022],
            [558.665, 0.0014],
            [558.9939999999999, 0.0014],
            [560.664, 0.0014],
            [563.141, 0.027],
            [564.26, 0.004],
            [564.54, 0.0027],
            [565.83, 0.007],
            [567.5840000000001, 0.052],
            [568.359, 0.0014],
            [568.476, 0.004],
            [569.642, 0.0014],
            [570.9970000000001, 0.0035],
            [571.579, 0.0022],
            [573.3810000000001, 0.0014],
            [573.72, 0.0011],
            [575.802, 0.0027],
            [576.02, 0.0055],
            [576.429, 0.019],
            [577.882, 0.0005],
            [578.236, 0.0019],
            [578.446, 0.0022],
            [579.9970000000001, 0.0011],
            [581.1189999999999, 0.0014],
            [581.646, 0.0014],
            [583.876, 0.0035],
            [589.563, 0.024],
            [589.947, 0.0035],
            [590.1569999999999, 0.0024],
            [591.258, 0.0008],
            [593.17, 0.0011],
            [593.5899999999999, 0.0027],
            [597.126, 0.014],
            [597.5020000000001, 0.0027],
            [598.487, 0.0011],
            [602.544, 0.0019],
            [606.778, 0.0011],
            [613.153, 0.0016],
            [617.529, 0.0014],
            [618.141, 0.0014],
            [629.946, 0.0014],
            [635.266, 0.0027],
            [640.144, 0.0022],
            [643.0939999999999, 0.0008],
            [644.054, 0.0014],
            [646.0260000000001, 0.02],
            [649.0699999999999, 0.0014],
            [651.978, 0.0014],
            [657.554, 0.0008],
            [660.496, 0.0095],
            [662.725, 0.0008],
            [665.772, 0.0035],
            [665.864, 0.0011],
            [669.293, 0.0011],
            [672.136, 0.003],
            [672.634, 0.0009],
            [672.794, 0.0009],
            [673.922, 0.0018],
            [676.7479999999999, 0.0009],
            [677.793, 0.0009],
            [677.9770000000001, 0.011],
            [678.2, 0.0014],
            [678.8520000000001, 0.0018],
            [682.027, 0.0013],
            [682.6949999999999, 0.0014],
            [682.912, 0.0014],
            [683.109, 0.0023],
            [684.426, 0.012],
            [684.576, 0.008],
            [685.412, 0.0018],
            [689.856, 0.0006],
            [691.586, 0.0006],
            [693.737, 0.001],
            [694.954, 0.0005],
            [697.669, 0.0005],
            [402.192, 0.02],
            [402.603, 0.02],
            [403.213, 0.07],
            [407.615, 0.01],
            [433.547, 0.02],
            [438.541, 0.05],
        ],
    },
    {
        name: "uranium",
        lines: [
            [400.406, 0.1590909090909091],
            [400.521, 0.19545454545454546],
            [401.772, 0.2590909090909091],
            [401.899, 0.13636363636363635],
            [404.275, 0.45454545454545453],
            [404.441, 0.23636363636363636],
            [404.761, 0.18636363636363637],
            [405.004, 0.7272727272727273],
            [405.191, 0.24545454545454545],
            [405.43, 0.13636363636363635],
            [405.819, 0.19545454545454546],
            [406.254, 0.4],
            [406.775, 0.23636363636363636],
            [407.11199999999997, 0.18636363636363637],
            [407.448, 0.13636363636363635],
            [407.669, 0.15],
            [408.06, 0.15],
            [409.01300000000003, 1.0],
            [409.303, 0.20909090909090908],
            [410.63800000000003, 0.17272727272727273],
            [411.61, 0.36818181818181817],
            [412.47299999999996, 0.18636363636363637],
            [412.834, 0.18636363636363637],
            [414.122, 0.20909090909090908],
            [415.39700000000005, 0.4],
            [415.66499999999996, 0.17272727272727273],
            [416.36800000000005, 0.1590909090909091],
            [417.159, 0.6363636363636364],
            [418.927, 0.13636363636363635],
            [422.23699999999997, 0.1590909090909091],
            [424.16700000000003, 0.45454545454545453],
            [424.437, 0.23636363636363636],
            [434.169, 0.3090909090909091],
            [435.57399999999996, 0.19545454545454546],
            [436.20500000000004, 0.19545454545454546],
            [439.35900000000004, 0.15],
            [447.233, 0.2727272727272727],
            [451.52799999999996, 0.10909090909090909],
            [454.363, 0.2818181818181818],
            [462.021, 0.13636363636363635],
            [462.707, 0.10909090909090909],
            [463.162, 0.09545454545454546],
            [464.66, 0.1],
            [466.68500000000006, 0.06363636363636363],
            [467.14, 0.045454545454545456],
            [468.907, 0.07727272727272727],
            [470.25100000000003, 0.045454545454545456],
            [472.27200000000005, 0.07272727272727272],
            [473.159, 0.05454545454545454],
            [502.738, 0.07727272727272727],
            [511.724, 0.031818181818181815],
            [516.0319999999999, 0.03636363636363636],
            [516.414, 0.025],
            [518.457, 0.025],
            [520.431, 0.020454545454545454],
            [524.775, 0.020454545454545454],
            [525.704, 0.020454545454545454],
            [528.038, 0.031818181818181815],
            [538.6189999999999, 0.025],
            [547.5699999999999, 0.03636363636363636],
            [548.0260000000001, 0.031818181818181815],
            [548.12, 0.031818181818181815],
            [548.2529999999999, 0.020454545454545454],
            [549.295, 0.07272727272727272],
            [552.7819999999999, 0.031818181818181815],
            [556.417, 0.031818181818181815],
            [558.159, 0.020454545454545454],
            [562.078, 0.025],
            [578.059, 0.031818181818181815],
            [579.853, 0.031818181818181815],
            [583.6020000000001, 0.020454545454545454],
            [583.768, 0.025],
            [591.539, 0.10454545454545454],
            [597.15, 0.025],
            [597.632, 0.045454545454545456],
            [599.731, 0.020454545454545454],
            [601.738, 0.012727272727272728],
            [605.174, 0.025],
            [606.722, 0.020454545454545454],
            [607.729, 0.04090909090909091],
            [608.734, 0.012727272727272728],
            [617.1859999999999, 0.01818181818181818],
            [617.539, 0.015909090909090907],
            [628.018, 0.012727272727272728],
            [635.929, 0.012727272727272728],
            [637.246, 0.025],
            [637.8520000000001, 0.012727272727272728],
            [639.277, 0.012727272727272728],
            [639.542, 0.04090909090909091],
            [644.9159999999999, 0.05],
            [646.4979999999999, 0.015909090909090907],
            [682.692, 0.04090909090909091],
            [687.674, 0.015909090909090907],
        ],
    },
    {
        name: "xenon",
        lines: [
            [390.791, 0.03333333333333333],
            [403.759, 0.03333333333333333],
            [405.746, 0.06666666666666667],
            [409.889, 0.03333333333333333],
            [415.804, 0.06666666666666667],
            [418.01000000000005, 0.3333333333333333],
            [419.31499999999994, 0.16666666666666666],
            [420.84799999999996, 0.1],
            [420.947, 0.03333333333333333],
            [421.372, 0.1],
            [421.56000000000006, 0.03333333333333333],
            [422.3, 0.1],
            [423.825, 0.13333333333333333],
            [424.538, 0.16666666666666666],
            [425.157, 0.03333333333333333],
            [429.64, 0.16666666666666666],
            [431.05100000000004, 0.16666666666666666],
            [433.052, 0.3333333333333333],
            [436.91999999999996, 0.06666666666666667],
            [437.378, 0.03333333333333333],
            [439.32, 0.16666666666666666],
            [439.57700000000006, 0.16666666666666666],
            [440.688, 0.06666666666666667],
            [441.60699999999997, 0.05],
            [444.813, 0.16666666666666666],
            [446.21899999999994, 0.3333333333333333],
            [448.08599999999996, 0.16666666666666666],
            [452.186, 0.03333333333333333],
            [473.41499999999996, 0.2],
            [479.262, 0.05],
            [480.70200000000006, 0.16666666666666666],
            [482.971, 0.13333333333333333],
            [484.329, 0.1],
            [491.651, 0.16666666666666666],
            [492.31499999999994, 0.16666666666666666],
            [497.171, 0.06666666666666667],
            [497.271, 0.13333333333333333],
            [498.87700000000007, 0.1],
            [499.117, 0.03333333333333333],
            [502.828, 0.06666666666666667],
            [504.492, 0.06666666666666667],
            [508.062, 0.3333333333333333],
            [512.242, 0.1],
            [512.5699999999999, 0.03333333333333333],
            [517.882, 0.03333333333333333],
            [518.804, 0.1],
            [519.137, 0.13333333333333333],
            [519.21, 0.03333333333333333],
            [526.044, 0.16666666666666666],
            [526.1949999999999, 0.16666666666666666],
            [529.222, 0.6666666666666666],
            [530.927, 0.1],
            [531.387, 0.3333333333333333],
            [533.933, 0.6666666666666666],
            [536.3199999999999, 0.06666666666666667],
            [536.807, 0.06666666666666667],
            [537.239, 0.16666666666666666],
            [539.28, 0.03333333333333333],
            [541.915, 1.0],
            [543.896, 0.26666666666666666],
            [544.545, 0.1],
            [545.045, 0.06666666666666667],
            [546.039, 0.13333333333333333],
            [547.261, 0.3333333333333333],
            [549.486, 0.03333333333333333],
            [552.553, 0.06666666666666667],
            [553.107, 0.2],
            [556.662, 0.03333333333333333],
            [561.667, 0.1],
            [565.938, 0.1],
            [566.7560000000001, 0.2],
            [567.091, 0.05],
            [569.575, 0.03333333333333333],
            [569.961, 0.06666666666666667],
            [571.61, 0.06666666666666667],
            [572.691, 0.16666666666666666],
            [575.103, 0.16666666666666666],
            [575.865, 0.1],
            [577.639, 0.1],
            [581.596, 0.03333333333333333],
            [582.389, 0.1],
            [582.48, 0.05],
            [587.5020000000001, 0.03333333333333333],
            [589.329, 0.1],
            [589.499, 0.03333333333333333],
            [590.513, 0.06666666666666667],
            [593.417, 0.03333333333333333],
            [594.553, 0.16666666666666666],
            [597.113, 0.1],
            [597.646, 0.6666666666666666],
            [600.892, 0.06666666666666667],
            [603.62, 0.3333333333333333],
            [605.115, 0.6666666666666666],
            [609.35, 0.2],
            [609.759, 0.5],
            [610.143, 0.13333333333333333],
            [611.508, 0.03333333333333333],
            [614.645, 0.03333333333333333],
            [617.83, 0.05],
            [617.966, 0.04],
            [618.242, 0.1],
            [619.4069999999999, 0.16666666666666666],
            [619.826, 0.03333333333333333],
            [622.0020000000001, 0.03333333333333333],
            [627.082, 0.16666666666666666],
            [627.754, 0.13333333333333333],
            [628.441, 0.03333333333333333],
            [628.601, 0.03333333333333333],
            [630.086, 0.08333333333333333],
            [631.806, 0.16666666666666666],
            [634.396, 0.13333333333333333],
            [635.635, 0.2],
            [637.528, 0.06666666666666667],
            [639.799, 0.03333333333333333],
            [646.97, 0.1],
            [647.284, 0.05],
            [648.7760000000001, 0.04],
            [649.8720000000001, 0.03333333333333333],
            [650.418, 0.06666666666666667],
            [651.283, 0.1],
            [652.865, 0.06666666666666667],
            [653.316, 0.03333333333333333],
            [659.501, 0.3333333333333333],
            [659.556, 0.03333333333333333],
            [659.725, 0.13333333333333333],
            [659.884, 0.03333333333333333],
            [666.892, 0.05],
            [669.432, 0.1],
            [672.801, 0.06666666666666667],
            [678.871, 0.05],
            [679.037, 0.03333333333333333],
            [680.574, 0.3333333333333333],
            [682.732, 0.06666666666666667],
            [687.211, 0.03333333333333333],
            [688.216, 0.1],
            [691.022, 0.02666666666666667],
            [692.553, 0.03333333333333333],
            [694.211, 0.26666666666666666],
            [697.618, 0.03333333333333333],
            [699.088, 0.6666666666666666],
            [708.2149999999999, 0.05],
        ],
    },
    {
        name: "zinc",
        lines: [
            [411.321, 0.01],
            [429.288, 0.025],
            [429.83299999999997, 0.025],
            [462.98100000000005, 0.035],
            [468.014, 0.3],
            [472.215, 0.4],
            [481.053, 0.4],
            [491.162, 0.8],
            [492.40299999999996, 0.5],
            [506.866, 0.007],
            [506.95799999999997, 0.015],
            [518.198, 0.2],
            [530.865, 0.008],
            [531.024, 0.007],
            [531.1020000000001, 0.007],
            [577.21, 0.004],
            [577.55, 0.004],
            [577.711, 0.01],
            [589.433, 0.5],
            [602.118, 0.5],
            [610.249, 0.5],
            [611.153, 0.1],
            [621.461, 0.5],
            [623.79, 0.008],
            [623.917, 0.008],
            [636.234, 1.0],
            [647.918, 0.01],
            [692.832, 0.015],
            [693.847, 0.008],
            [694.3199999999999, 0.003],
        ],
    },
    {
        name: "ytterbium",
        lines: [
            [402.81399999999996, 0.8333333333333334],
            [403.303, 0.004166666666666667],
            [400.736, 0.020833333333333332],
            [405.228, 0.029166666666666667],
            [407.728, 0.035416666666666666],
            [408.96799999999996, 0.18333333333333332],
            [411.925, 0.05],
            [413.509, 0.029166666666666667],
            [414.907, 0.19583333333333333],
            [417.456, 0.05],
            [418.081, 0.14166666666666666],
            [421.85600000000005, 0.0625],
            [423.197, 0.05],
            [427.774, 0.029166666666666667],
            [430.59700000000004, 0.05],
            [431.695, 0.029166666666666667],
            [439.36899999999997, 0.025],
            [443.021, 0.025],
            [443.919, 0.18333333333333332],
            [448.242, 0.035416666666666666],
            [451.51599999999996, 0.035416666666666666],
            [455.358, 0.014583333333333334],
            [456.395, 0.035416666666666666],
            [457.621, 0.26666666666666666],
            [458.236, 0.08333333333333333],
            [458.921, 0.029166666666666667],
            [459.08299999999997, 0.058333333333333334],
            [459.83599999999996, 0.016666666666666666],
            [468.38100000000003, 0.014583333333333334],
            [468.427, 0.016666666666666666],
            [472.608, 0.07916666666666666],
            [478.187, 0.07083333333333333],
            [478.66099999999994, 0.07083333333333333],
            [481.64300000000003, 0.014583333333333334],
            [482.024, 0.016666666666666666],
            [483.696, 0.014583333333333334],
            [483.746, 0.016666666666666666],
            [485.11499999999995, 0.007083333333333333],
            [489.46000000000004, 0.016666666666666666],
            [491.236, 0.01125],
            [493.55, 0.29583333333333334],
            [493.72200000000004, 0.01],
            [496.68999999999994, 0.058333333333333334],
            [500.95200000000006, 0.01],
            [506.73, 0.007083333333333333],
            [506.78000000000003, 0.0125],
            [506.91400000000004, 0.029166666666666667],
            [507.434, 0.09166666666666666],
            [507.674, 0.020833333333333332],
            [513.598, 0.008333333333333333],
            [514.702, 0.005833333333333334],
            [518.415, 0.008333333333333333],
            [519.608, 0.025],
            [521.1600000000001, 0.035416666666666666],
            [524.051, 0.014583333333333334],
            [524.411, 0.041666666666666664],
            [525.749, 0.016666666666666666],
            [527.704, 0.0625],
            [527.953, 0.014583333333333334],
            [530.0939999999999, 0.007083333333333333],
            [533.515, 0.07083333333333333],
            [534.566, 0.0125],
            [534.722, 0.025],
            [535.129, 0.0125],
            [535.295, 0.0625],
            [535.864, 0.0125],
            [536.366, 0.0125],
            [538.984, 0.007083333333333333],
            [543.271, 0.005833333333333334],
            [544.927, 0.016666666666666666],
            [547.85, 0.005833333333333334],
            [548.192, 0.025],
            [550.549, 0.016666666666666666],
            [552.454, 0.007083333333333333],
            [553.905, 0.035416666666666666],
            [555.647, 1.0],
            [556.2090000000001, 0.014583333333333334],
            [556.8109999999999, 0.008333333333333333],
            [558.636, 0.008333333333333333],
            [558.845, 0.016666666666666666],
            [565.198, 0.025],
            [568.653, 0.002916666666666667],
            [571.999, 0.09166666666666666],
            [574.991, 0.004166666666666667],
            [575.589, 0.004166666666666667],
            [577.1659999999999, 0.01125],
            [580.3439999999999, 0.004166666666666667],
            [581.941, 0.004166666666666667],
            [583.399, 0.014583333333333334],
            [583.714, 0.014583333333333334],
            [585.451, 0.01125],
            [589.721, 0.0033333333333333335],
            [590.836, 0.008333333333333333],
            [598.933, 0.007083333333333333],
            [599.1510000000001, 0.016666666666666666],
            [605.288, 0.004166666666666667],
            [605.457, 0.004166666666666667],
            [615.257, 0.025],
            [624.697, 0.0125],
            [627.478, 0.025],
            [630.8149999999999, 0.005833333333333334],
            [640.0350000000001, 0.014583333333333334],
            [641.7909999999999, 0.014583333333333334],
            [643.2729999999999, 0.008333333333333333],
            [646.3149999999999, 0.007083333333333333],
            [648.9060000000001, 0.14166666666666666],
            [664.355, 0.008333333333333333],
            [666.7819999999999, 0.075],
            [667.817, 0.00625],
            [672.761, 0.010416666666666666],
            [676.87, 0.010416666666666666],
            [679.96, 0.2875],
            [693.405, 0.0075],
            [699.988, 0.008333333333333333],
            [407.45300000000003, 0.008333333333333333],
            [409.067, 0.008333333333333333],
            [409.823, 0.008333333333333333],
            [412.10600000000005, 0.00625],
            [415.004, 0.004166666666666667],
            [415.311, 0.00625],
            [416.27200000000005, 0.041666666666666664],
            [417.29499999999996, 0.025],
            [419.434, 0.0125],
            [419.495, 0.041666666666666664],
            [419.87399999999997, 0.004166666666666667],
            [421.36400000000003, 0.125],
            [422.08299999999997, 0.004166666666666667],
            [423.10699999999997, 0.00625],
            [428.96400000000006, 0.008333333333333333],
            [430.11400000000003, 0.016666666666666666],
            [430.401, 0.008333333333333333],
            [435.08000000000004, 0.00625],
            [438.00699999999995, 0.004166666666666667],
            [451.758, 0.041666666666666664],
            [463.91400000000004, 0.016666666666666666],
            [483.49300000000005, 0.004166666666666667],
            [505.49399999999997, 0.00625],
            [525.6850000000001, 0.008333333333333333],
            [533.154, 0.008333333333333333],
            [574.083, 0.00625],
            [594.902, 0.004166666666666667],
            [597.3050000000001, 0.008333333333333333],
            [605.585, 0.016666666666666666],
            [621.422, 0.041666666666666664],
            [632.8520000000001, 0.08333333333333333],
            [636.588, 0.004166666666666667],
            [637.833, 0.0625],
            [646.633, 0.010416666666666666],
            [698.515, 0.008333333333333333],
        ],
    },
    {
        name: "zirconium",
        lines: [
            [400.76, 0.08695652173913043],
            [401.225, 0.08695652173913043],
            [402.398, 0.17391304347826086],
            [402.492, 0.3347826086956522],
            [402.71999999999997, 0.43043478260869567],
            [402.895, 0.10434782608695652],
            [402.96799999999996, 0.17391304347826086],
            [403.004, 0.21304347826086956],
            [403.589, 0.17391304347826086],
            [404.222, 0.10434782608695652],
            [404.358, 0.26521739130434785],
            [404.456, 0.21304347826086956],
            [404.56100000000004, 0.17391304347826086],
            [404.867, 0.26521739130434785],
            [405.033, 0.08695652173913043],
            [405.048, 0.08695652173913043],
            [405.50300000000004, 0.3347826086956522],
            [405.571, 0.2608695652173913],
            [406.153, 0.14347826086956522],
            [406.416, 0.6521739130434783],
            [407.27, 0.8695652173913043],
            [407.493, 0.13478260869565217],
            [407.653, 0.08695652173913043],
            [407.831, 0.10434782608695652],
            [408.12199999999996, 0.8695652173913043],
            [410.84, 0.08695652173913043],
            [412.146, 0.17391304347826086],
            [414.91999999999996, 0.5217391304347826],
            [415.264, 0.08695652173913043],
            [415.62399999999997, 0.12608695652173912],
            [416.121, 0.17391304347826086],
            [416.63599999999997, 0.17391304347826086],
            [418.332, 0.08695652173913043],
            [418.75600000000003, 0.28695652173913044],
            [419.476, 0.17391304347826086],
            [419.909, 0.26521739130434785],
            [420.146, 0.26521739130434785],
            [420.89799999999997, 0.26521739130434785],
            [421.188, 0.08695652173913043],
            [421.38599999999997, 0.17391304347826086],
            [422.776, 0.8695652173913043],
            [423.60600000000005, 0.08695652173913043],
            [423.93100000000004, 0.8695652173913043],
            [424.034, 0.3347826086956522],
            [424.12, 0.3347826086956522],
            [424.169, 0.5217391304347826],
            [426.802, 0.13478260869565217],
            [428.21999999999997, 0.2391304347826087],
            [429.479, 0.2391304347826087],
            [430.28900000000004, 0.13478260869565217],
            [434.113, 0.2391304347826087],
            [434.78900000000004, 0.43478260869565216],
            [435.974, 0.12608695652173912],
            [436.081, 0.13478260869565217],
            [436.645, 0.15217391304347827],
            [437.97799999999995, 0.10434782608695652],
            [441.304, 0.08260869565217391],
            [442.046, 0.10434782608695652],
            [442.724, 0.05217391304347826],
            [443.149, 0.06956521739130435],
            [444.3, 0.06086956521739131],
            [445.74300000000005, 0.04782608695652174],
            [446.691, 0.04782608695652174],
            [447.03100000000006, 0.04782608695652174],
            [447.05600000000004, 0.08260869565217391],
            [449.697, 0.08695652173913043],
            [450.712, 0.2391304347826087],
            [453.575, 0.26521739130434785],
            [454.22200000000004, 0.21304347826086956],
            [455.30100000000004, 0.08695652173913043],
            [455.51300000000003, 0.08695652173913043],
            [455.552, 0.06086956521739131],
            [457.552, 0.21304347826086956],
            [458.229, 0.043478260869565216],
            [459.055, 0.06086956521739131],
            [460.25699999999995, 0.15217391304347827],
            [460.442, 0.06086956521739131],
            [462.64099999999996, 0.09130434782608696],
            [463.39799999999997, 0.30434782608695654],
            [464.483, 0.09130434782608696],
            [468.342, 0.11304347826086956],
            [468.78000000000003, 1.0],
            [468.84499999999997, 0.2217391304347826],
            [470.779, 0.04782608695652174],
            [471.008, 0.8260869565217391],
            [471.192, 0.06956521739130435],
            [471.762, 0.05217391304347826],
            [471.912, 0.09130434782608696],
            [473.233, 0.13043478260869565],
            [473.948, 0.6086956521739131],
            [476.27799999999996, 0.08260869565217391],
            [477.23100000000005, 0.3782608695652174],
            [478.492, 0.09130434782608696],
            [478.867, 0.06956521739130435],
            [480.587, 0.11304347826086956],
            [480.947, 0.06086956521739131],
            [481.504, 0.08260869565217391],
            [481.563, 0.30434782608695654],
            [482.429, 0.12173913043478261],
            [482.804, 0.08260869565217391],
            [483.878, 0.04782608695652174],
            [485.13599999999997, 0.09130434782608696],
            [486.60600000000005, 0.06956521739130435],
            [488.12399999999997, 0.04782608695652174],
            [488.36, 0.04782608695652174],
            [499.476, 0.043478260869565216],
            [501.146, 0.013043478260869565],
            [504.658, 0.10869565217391304],
            [506.03900000000004, 0.03695652173913044],
            [506.491, 0.1565217391304348],
            [506.52200000000005, 0.04782608695652174],
            [507.026, 0.043478260869565216],
            [507.39799999999997, 0.03260869565217391],
            [507.825, 0.20434782608695654],
            [508.526, 0.03695652173913044],
            [511.22700000000003, 0.021739130434782608],
            [511.524, 0.06086956521739131],
            [512.042, 0.021739130434782608],
            [513.3399999999999, 0.03695652173913044],
            [515.545, 0.13043478260869565],
            [515.8, 0.08695652173913043],
            [515.867, 0.015217391304347827],
            [516.0989999999999, 0.03260869565217391],
            [516.596, 0.03695652173913044],
            [517.899, 0.007391304347826087],
            [518.37, 0.043478260869565216],
            [518.703, 0.013043478260869565],
            [519.1600000000001, 0.043478260869565216],
            [520.115, 0.043478260869565216],
            [520.9300000000001, 0.03695652173913044],
            [522.493, 0.03695652173913044],
            [524.347, 0.013043478260869565],
            [527.741, 0.05217391304347826],
            [528.005, 0.03260869565217391],
            [529.482, 0.02608695652173913],
            [529.679, 0.05217391304347826],
            [530.197, 0.02608695652173913],
            [531.14, 0.04782608695652174],
            [532.126, 0.010869565217391304],
            [533.0840000000001, 0.009565217391304347],
            [533.8430000000001, 0.0052173913043478265],
            [535.009, 0.013043478260869565],
            [535.0350000000001, 0.013043478260869565],
            [535.0899999999999, 0.010869565217391304],
            [535.192, 0.010869565217391304],
            [536.2560000000001, 0.03260869565217391],
            [536.335, 0.0052173913043478265],
            [536.9390000000001, 0.007391304347826087],
            [538.237, 0.008695652173913044],
            [538.514, 0.11739130434782609],
            [538.665, 0.013043478260869565],
            [539.118, 0.007391304347826087],
            [539.588, 0.007391304347826087],
            [540.513, 0.010869565217391304],
            [540.762, 0.03695652173913044],
            [541.393, 0.007391304347826087],
            [542.1859999999999, 0.008695652173913044],
            [542.636, 0.006521739130434782],
            [542.842, 0.010869565217391304],
            [543.7760000000001, 0.010869565217391304],
            [544.0409999999999, 0.006521739130434782],
            [544.857, 0.015217391304347827],
            [547.492, 0.004347826086956522],
            [547.74, 0.004347826086956522],
            [547.833, 0.015217391304347827],
            [548.083, 0.015217391304347827],
            [548.116, 0.004347826086956522],
            [548.609, 0.013043478260869565],
            [550.212, 0.06086956521739131],
            [550.787, 0.010869565217391304],
            [551.711, 0.013043478260869565],
            [551.8050000000001, 0.004347826086956522],
            [552.841, 0.03260869565217391],
            [553.23, 0.008695652173913044],
            [553.746, 0.01956521739130435],
            [554.5319999999999, 0.021739130434782608],
            [555.175, 0.009565217391304347],
            [555.317, 0.010869565217391304],
            [561.211, 0.0052173913043478265],
            [562.014, 0.05217391304347826],
            [562.353, 0.015217391304347827],
            [562.902, 0.010869565217391304],
            [562.958, 0.010869565217391304],
            [566.451, 0.06956521739130435],
            [566.6279999999999, 0.008695652173913044],
            [568.0899999999999, 0.05217391304347826],
            [568.542, 0.006521739130434782],
            [570.889, 0.013043478260869565],
            [571.821, 0.03260869565217391],
            [573.5699999999999, 0.05217391304347826],
            [574.817, 0.015217391304347827],
            [577.857, 0.007391304347826087],
            [579.774, 0.06956521739130435],
            [584.732, 0.013043478260869565],
            [586.827, 0.021739130434782608],
            [586.95, 0.04782608695652174],
            [587.98, 0.14782608695652175],
            [588.562, 0.03695652173913044],
            [590.109, 0.021739130434782608],
            [590.861, 0.013043478260869565],
            [592.513, 0.06086956521739131],
            [593.52, 0.043478260869565216],
            [595.5350000000001, 0.04782608695652174],
            [597.78, 0.013043478260869565],
            [598.423, 0.043478260869565216],
            [599.537, 0.007391304347826087],
            [600.105, 0.021739130434782608],
            [602.536, 0.013043478260869565],
            [603.261, 0.03695652173913044],
            [604.585, 0.07391304347826087],
            [604.924, 0.043478260869565216],
            [606.284, 0.06086956521739131],
            [612.083, 0.021739130434782608],
            [612.191, 0.07391304347826087],
            [612.484, 0.03695652173913044],
            [612.7439999999999, 0.2956521739130435],
            [613.455, 0.14782608695652175],
            [614.046, 0.043478260869565216],
            [614.3199999999999, 0.19130434782608696],
            [615.5609999999999, 0.013043478260869565],
            [615.771, 0.03260869565217391],
            [616.02, 0.010869565217391304],
            [618.9399999999999, 0.015217391304347827],
            [619.296, 0.02608695652173913],
            [621.3050000000001, 0.03695652173913044],
            [621.4689999999999, 0.043478260869565216],
            [622.6510000000001, 0.07391304347826087],
            [625.726, 0.043478260869565216],
            [626.105, 0.021739130434782608],
            [626.706, 0.015217391304347827],
            [629.284, 0.01956521739130435],
            [629.966, 0.05217391304347826],
            [630.434, 0.006521739130434782],
            [631.302, 0.13043478260869565],
            [631.471, 0.013043478260869565],
            [632.135, 0.021739130434782608],
            [634.036, 0.009565217391304347],
            [634.51, 0.021739130434782608],
            [634.522, 0.03260869565217391],
            [637.856, 0.03260869565217391],
            [640.7, 0.015217391304347827],
            [641.239, 0.021739130434782608],
            [642.617, 0.0052173913043478265],
            [643.433, 0.015217391304347827],
            [644.574, 0.02608695652173913],
            [645.162, 0.008695652173913044],
            [645.763, 0.008695652173913044],
            [647.021, 0.04782608695652174],
            [647.379, 0.02608695652173913],
            [648.4350000000001, 0.004782608695652174],
            [648.964, 0.04782608695652174],
            [649.3100000000001, 0.009565217391304347],
            [650.326, 0.021739130434782608],
            [650.636, 0.021739130434782608],
            [650.8149999999999, 0.021739130434782608],
            [654.29, 0.013043478260869565],
            [655.054, 0.015217391304347827],
            [656.943, 0.013043478260869565],
            [657.6560000000001, 0.008695652173913044],
            [657.806, 0.013043478260869565],
            [659.199, 0.021739130434782608],
            [659.671, 0.004347826086956522],
            [659.884, 0.004347826086956522],
            [660.327, 0.021739130434782608],
            [662.056, 0.006521739130434782],
            [667.801, 0.004782608695652174],
            [668.818, 0.009565217391304347],
            [670.212, 0.004782608695652174],
            [670.961, 0.007391304347826087],
            [671.788, 0.011739130434782608],
            [675.2729999999999, 0.017391304347826087],
            [676.238, 0.03260869565217391],
            [676.9159999999999, 0.03695652173913044],
            [677.289, 0.011739130434782608],
            [678.7149999999999, 0.006521739130434782],
            [679.085, 0.015217391304347827],
            [682.8779999999999, 0.01956521739130435],
            [683.289, 0.01956521739130435],
            [684.533, 0.005652173913043478],
            [684.634, 0.007391304347826087],
            [684.697, 0.043478260869565216],
            [684.926, 0.011739130434782608],
            [685.2560000000001, 0.005652173913043478],
            [688.829, 0.05217391304347826],
            [690.059, 0.012608695652173913],
            [690.4359999999999, 0.008695652173913044],
            [690.737, 0.012608695652173913],
            [691.687, 0.008695652173913044],
            [693.238, 0.006956521739130435],
            [694.846, 0.012608695652173913],
            [695.384, 0.06521739130434782],
            [696.644, 0.02608695652173913],
            [697.591, 0.004347826086956522],
            [699.0840000000001, 0.06521739130434782],
            [699.432, 0.034782608695652174],
        ],
    },
    {
        name: "locatedata",
        lines: [],
    },
    {
        name: "actinium",
        lines: [
            [406.15999999999997, 0.13333333333333333],
            [408.844, 1.0],
            [416.84, 1.0],
            [417.99799999999993, 0.03333333333333333],
            [418.312, 0.006666666666666667],
            [419.43999999999994, 0.006666666666666667],
            [420.96899999999994, 0.1],
            [435.913, 0.1],
            [438.453, 0.006666666666666667],
            [438.64099999999996, 0.3333333333333333],
            [439.671, 0.006666666666666667],
            [446.27299999999997, 0.006666666666666667],
            [450.71999999999997, 0.3333333333333333],
            [460.54499999999996, 0.16666666666666666],
            [471.658, 0.0033333333333333335],
            [472.01599999999996, 0.13333333333333333],
            [481.22200000000004, 0.1],
            [494.51800000000003, 0.03333333333333333],
            [495.823, 0.03333333333333333],
            [496.087, 0.03333333333333333],
            [544.638, 0.05],
            [573.205, 0.1],
            [575.897, 0.13333333333333333],
            [591.085, 0.3333333333333333],
            [616.475, 0.2],
            [616.783, 0.06666666666666667],
            [624.283, 0.13333333333333333],
            [635.986, 0.006666666666666667],
            [669.1270000000001, 0.006666666666666667],
            [441.309, 0.6666666666666666],
            [456.98699999999997, 1.0],
            [519.321, 0.0026666666666666666],
        ],
    },
    {
        name: "antimony",
        lines: [
            [403.355, 1.0],
            [403.356, 0.1],
            [413.363, 0.1],
            [414.054, 0.075],
            [419.517, 0.075],
            [421.907, 0.1],
            [431.43199999999996, 0.1],
            [434.483, 0.06],
            [441.142, 0.06],
            [444.64799999999997, 0.06],
            [450.692, 0.06],
            [451.45, 0.075],
            [459.68999999999994, 0.15],
            [459.909, 0.1],
            [460.47700000000003, 0.075],
            [464.73199999999997, 0.15],
            [467.57399999999996, 0.1],
            [471.12600000000003, 0.2],
            [473.544, 0.06],
            [475.78100000000006, 0.1],
            [476.53599999999994, 0.1],
            [476.691, 0.06],
            [478.40299999999996, 0.15],
            [480.201, 0.1],
            [483.282, 0.1],
            [487.724, 0.1],
            [494.73999999999995, 0.075],
            [504.456, 0.075],
            [516.632, 0.06],
            [517.655, 0.075],
            [523.894, 0.1],
            [535.424, 0.1],
            [546.408, 0.075],
            [549.0319999999999, 0.15],
            [555.61, 0.2],
            [556.813, 0.075],
            [560.2189999999999, 0.15],
            [563.202, 0.5],
            [563.975, 0.15],
            [583.034, 0.3],
            [589.509, 0.075],
            [600.521, 0.5],
            [605.341, 0.1],
            [607.98, 0.15],
            [613.004, 0.25],
            [615.4939999999999, 0.1],
            [630.2760000000001, 0.06],
            [661.149, 0.1],
            [664.7439999999999, 0.15],
            [668.801, 0.075],
            [680.667, 0.03],
            [426.509, 0.2],
            [435.216, 0.25],
            [459.189, 0.15],
            [469.291, 0.15],
            [524.771, 0.005],
            [569.08, 0.005],
            [571.73, 0.005],
            [584.55, 0.015],
            [624.67, 0.025],
            [628.76, 0.015],
        ],
    },
    {
        name: "argon",
        lines: [
            [392.572, 0.0035003500350035003],
            [392.86199999999997, 0.005000500050005001],
            [393.255, 0.0025002500250025004],
            [394.61, 0.0070007000700070005],
            [394.75100000000003, 0.0007000700070007],
            [394.898, 0.0035003500350035003],
            [397.93600000000004, 0.002000200020002],
            [399.479, 0.0035003500350035003],
            [401.386, 0.005000500050005001],
            [403.381, 0.005000500050005001],
            [403.546, 0.002000200020002],
            [404.289, 0.015001500150015001],
            [404.442, 0.005000500050005001],
            [405.29200000000003, 0.010001000100010001],
            [407.201, 0.020002000200020003],
            [407.239, 0.0070007000700070005],
            [407.663, 0.0025002500250025004],
            [407.957, 0.0035003500350035003],
            [408.239, 0.0025002500250025004],
            [410.39099999999996, 0.015001500150015001],
            [413.172, 0.030003000300030003],
            [415.60900000000004, 0.0035003500350035003],
            [415.85900000000004, 0.040004000400040006],
            [416.418, 0.005000500050005001],
            [417.93, 0.0035003500350035003],
            [418.188, 0.005000500050005001],
            [419.071, 0.010001000100010001],
            [419.10299999999995, 0.005000500050005001],
            [419.832, 0.020002000200020003],
            [420.067, 0.040004000400040006],
            [421.867, 0.0025002500250025004],
            [422.264, 0.0025002500250025004],
            [422.69899999999996, 0.0025002500250025004],
            [422.816, 0.010001000100010001],
            [423.72200000000004, 0.010001000100010001],
            [425.11899999999997, 0.0025002500250025004],
            [425.936, 0.020002000200020003],
            [426.629, 0.010001000100010001],
            [426.65299999999996, 0.0070007000700070005],
            [427.217, 0.015001500150015001],
            [427.753, 0.05500550055005501],
            [428.28999999999996, 0.002000200020002],
            [430.01000000000005, 0.010001000100010001],
            [430.06499999999994, 0.0025002500250025004],
            [430.924, 0.0070007000700070005],
            [433.12, 0.020002000200020003],
            [433.203, 0.005000500050005001],
            [433.35600000000005, 0.010001000100010001],
            [433.534, 0.005000500050005001],
            [434.517, 0.0025002500250025004],
            [434.80600000000004, 0.08000800080008001],
            [435.221, 0.005000500050005001],
            [436.207, 0.0025002500250025004],
            [436.783, 0.005000500050005001],
            [437.075, 0.020002000200020003],
            [437.133, 0.0070007000700070005],
            [437.59499999999997, 0.005000500050005001],
            [437.967, 0.015001500150015001],
            [438.50600000000003, 0.005000500050005001],
            [440.01000000000005, 0.0070007000700070005],
            [440.099, 0.020002000200020003],
            [442.6, 0.040004000400040006],
            [443.01899999999995, 0.015001500150015001],
            [443.1, 0.005000500050005001],
            [443.384, 0.005000500050005001],
            [443.946, 0.002000200020002],
            [444.88800000000003, 0.0035003500350035003],
            [447.476, 0.010001000100010001],
            [448.18100000000004, 0.020002000200020003],
            [451.073, 0.010001000100010001],
            [452.23199999999997, 0.002000200020002],
            [453.055, 0.002000200020002],
            [454.505, 0.040004000400040006],
            [456.441, 0.002000200020002],
            [457.93500000000006, 0.040004000400040006],
            [458.98999999999995, 0.040004000400040006],
            [459.61, 0.0015001500150015],
            [460.957, 0.05500550055005501],
            [462.84399999999994, 0.0007000700070007],
            [463.72299999999996, 0.0035003500350035003],
            [465.78999999999996, 0.040004000400040006],
            [470.23199999999997, 0.0015001500150015],
            [472.159, 0.002000200020002],
            [472.687, 0.05500550055005501],
            [473.20500000000004, 0.005000500050005001],
            [473.591, 0.030003000300030003],
            [476.48699999999997, 0.08000800080008001],
            [480.60200000000003, 0.05500550055005501],
            [484.78100000000006, 0.015001500150015001],
            [486.591, 0.005000500050005001],
            [487.986, 0.08000800080008001],
            [488.904, 0.0070007000700070005],
            [490.475, 0.002000200020002],
            [493.321, 0.0035003500350035003],
            [496.508, 0.020002000200020003],
            [500.933, 0.005000500050005001],
            [501.716, 0.0070007000700070005],
            [506.204, 0.0070007000700070005],
            [509.05, 0.002000200020002],
            [514.178, 0.010001000100010001],
            [514.5310000000001, 0.0070007000700070005],
            [515.139, 0.0005000500050005],
            [516.229, 0.0015001500150015],
            [516.577, 0.0025002500250025004],
            [518.775, 0.002000200020002],
            [521.681, 0.002000200020002],
            [522.1270000000001, 0.0007000700070007],
            [542.135, 0.0005000500050005],
            [545.165, 0.001000100010001],
            [549.587, 0.0025002500250025004],
            [550.611, 0.0005000500050005],
            [555.87, 0.0025002500250025004],
            [557.254, 0.001000100010001],
            [560.673, 0.0035003500350035003],
            [565.0699999999999, 0.002000200020002],
            [573.952, 0.001000100010001],
            [583.426, 0.0005000500050005],
            [586.0310000000001, 0.001000100010001],
            [588.262, 0.0015001500150015],
            [588.858, 0.0025002500250025004],
            [591.2090000000001, 0.005000500050005001],
            [592.8810000000001, 0.0015001500150015],
            [594.267, 0.0005000500050005],
            [598.73, 0.0007000700070007],
            [599.9, 0.0005000500050005],
            [602.515, 0.0005000500050005],
            [603.213, 0.0070007000700070005],
            [604.322, 0.0035003500350035003],
            [605.272, 0.001000100010001],
            [605.937, 0.002000200020002],
            [609.88, 0.0007000700070007],
            [610.5640000000001, 0.001000100010001],
            [611.492, 0.010001000100010001],
            [614.544, 0.001000100010001],
            [617.017, 0.0007000700070007],
            [617.228, 0.015001500150015001],
            [617.3100000000001, 0.001000100010001],
            [621.25, 0.001000100010001],
            [621.5939999999999, 0.0005000500050005],
            [624.312, 0.0025002500250025004],
            [629.687, 0.0007000700070007],
            [630.766, 0.0015001500150015],
            [636.958, 0.0007000700070007],
            [638.472, 0.002000200020002],
            [641.6310000000001, 0.0070007000700070005],
            [648.308, 0.0025002500250025004],
            [653.8109999999999, 0.0015001500150015],
            [660.485, 0.0015001500150015],
            [663.822, 0.0025002500250025004],
            [663.9739999999999, 0.002000200020002],
            [664.37, 0.005000500050005001],
            [666.068, 0.0005000500050005],
            [666.405, 0.0005000500050005],
            [666.636, 0.0025002500250025004],
            [667.728, 0.010001000100010001],
            [668.429, 0.0035003500350035003],
            [675.283, 0.015001500150015001],
            [675.616, 0.0005000500050005],
            [676.661, 0.0015001500150015],
            [686.1270000000001, 0.002000200020002],
            [687.129, 0.015001500150015001],
            [687.958, 0.0005000500050005],
            [688.817, 0.001000100010001],
            [693.766, 0.005000500050005001],
            [695.1479999999999, 0.0007000700070007],
            [696.025, 0.0007000700070007],
            [696.543, 1.0],
            [703.025, 0.015001500150015001],
            [706.722, 1.0],
            [706.874, 0.010001000100010001],
            [710.7479999999999, 0.0025002500250025004],
            [712.582, 0.0025002500250025004],
            [714.704, 0.1000100010001],
            [715.884, 0.0015001500150015],
        ],
    },
    {
        name: "barium",
        lines: [
            [403.62600000000003, 0.004615384615384616],
            [408.377, 0.003076923076923077],
            [408.486, 0.00046153846153846153],
            [413.066, 0.023076923076923078],
            [413.24300000000005, 0.0003076923076923077],
            [416.6, 0.003076923076923077],
            [421.604, 0.007692307692307693],
            [426.79499999999996, 0.012307692307692308],
            [428.31000000000006, 0.0015384615384615385],
            [428.78000000000003, 0.004615384615384616],
            [429.76000000000005, 0.003076923076923077],
            [430.93199999999996, 0.012307692307692308],
            [432.3, 0.0003076923076923077],
            [432.573, 0.009230769230769232],
            [432.674, 0.003076923076923077],
            [432.962, 0.004615384615384616],
            [435.033, 0.0012307692307692308],
            [440.254, 0.0009230769230769231],
            [440.52299999999997, 0.006153846153846154],
            [443.189, 0.0006153846153846154],
            [448.89799999999997, 0.0009230769230769231],
            [449.36400000000003, 0.0007692307692307692],
            [450.592, 0.0006153846153846154],
            [450.963, 0.003076923076923077],
            [452.317, 0.0009230769230769231],
            [452.49300000000005, 0.002],
            [455.40299999999996, 1.0],
            [457.38500000000005, 0.0006153846153846154],
            [457.96400000000006, 0.0012307692307692308],
            [459.975, 0.00046153846153846153],
            [461.992, 0.0003076923076923077],
            [462.83299999999997, 0.0003846153846153846],
            [464.41, 0.004615384615384616],
            [467.36199999999997, 0.00046153846153846153],
            [469.162, 0.0005384615384615384],
            [470.043, 0.0003076923076923077],
            [470.89399999999995, 0.012307692307692308],
            [472.64399999999995, 0.0006153846153846154],
            [484.346, 0.012307692307692308],
            [484.71400000000006, 0.004615384615384616],
            [485.084, 0.003076923076923077],
            [487.765, 0.00046153846153846153],
            [489.997, 0.006153846153846154],
            [490.28999999999996, 0.00023076923076923076],
            [493.409, 0.3076923076923077],
            [494.735, 0.00012307692307692307],
            [495.715, 0.015384615384615385],
            [499.78100000000006, 0.004615384615384616],
            [501.3, 0.015384615384615385],
            [515.9939999999999, 0.0003076923076923077],
            [526.703, 0.0003076923076923077],
            [536.135, 0.012307692307692308],
            [539.1600000000001, 0.015384615384615385],
            [542.105, 0.003076923076923077],
            [542.455, 0.0015384615384615385],
            [542.879, 0.003076923076923077],
            [548.03, 0.004615384615384616],
            [551.905, 0.003076923076923077],
            [553.548, 0.015384615384615385],
            [562.04, 0.0003076923076923077],
            [568.018, 0.00015384615384615385],
            [577.762, 0.006153846153846154],
            [578.418, 0.012307692307692308],
            [580.0229999999999, 0.0015384615384615385],
            [580.569, 0.0003076923076923077],
            [582.6279999999999, 0.002307692307692308],
            [585.368, 0.043076923076923075],
            [590.764, 0.00023076923076923076],
            [597.17, 0.0015384615384615385],
            [598.125, 0.012307692307692308],
            [599.7090000000001, 0.0015384615384615385],
            [599.985, 0.004615384615384616],
            [601.947, 0.0015384615384615385],
            [606.312, 0.003076923076923077],
            [611.078, 0.004615384615384616],
            [613.583, 0.006153846153846154],
            [614.172, 0.3076923076923077],
            [634.168, 0.002307692307692308],
            [637.891, 0.007692307692307693],
            [645.085, 0.0013846153846153845],
            [648.2909999999999, 0.002307692307692308],
            [649.6899999999999, 0.18461538461538463],
            [649.876, 0.004615384615384616],
            [652.731, 0.002307692307692308],
            [659.533, 0.046153846153846156],
            [665.4100000000001, 0.002307692307692308],
            [667.527, 0.023076923076923078],
            [669.384, 0.027692307692307693],
            [676.962, 0.015384615384615385],
            [686.569, 0.009230769230769232],
            [686.7850000000001, 0.004615384615384616],
            [687.409, 0.015384615384615385],
            [405.371, 0.00027692307692307695],
            [469.74399999999997, 0.00023076923076923076],
            [504.95500000000004, 0.00015384615384615385],
            [509.754, 0.00015384615384615385],
            [510.225, 0.0001846153846153846],
            [513.454, 0.00015384615384615385],
            [599.8, 0.00015384615384615385],
            [610.199, 0.0002],
            [637.711, 0.00015384615384615385],
            [638.376, 0.00015384615384615385],
            [652.617, 0.00012307692307692307],
        ],
    },
    {
        name: "boron",
        lines: [
            [412.19300000000004, 0.7916666666666666],
            [419.479, 0.3055555555555556],
            [424.29799999999994, 0.1111111111111111],
            [424.361, 0.19444444444444445],
            [447.21000000000004, 0.3055555555555556],
            [447.285, 0.3055555555555556],
            [448.70500000000004, 0.6111111111111112],
            [449.77299999999997, 1.0],
            [478.421, 0.19444444444444445],
            [494.038, 0.3055555555555556],
            [608.044, 0.3055555555555556],
            [628.547, 0.19444444444444445],
            [703.02, 0.19444444444444445],
            [703.1899999999999, 0.1111111111111111],
        ],
    },
    {
        name: "cadmium",
        lines: [
            [402.912, 0.05],
            [413.47700000000003, 0.1],
            [414.149, 0.025],
            [428.508, 0.05],
            [430.6672, 0.004],
            [441.241, 0.05],
            [441.29889999999995, 0.0015],
            [441.563, 0.5],
            [444.04499999999996, 0.015],
            [466.23519999999996, 0.004],
            [467.8149, 0.1],
            [474.46899999999994, 0.015],
            [479.99120000000005, 0.15],
            [488.172, 0.025],
            [502.55, 0.025],
            [508.5822, 0.5],
            [515.466, 0.003],
            [526.801, 0.05],
            [527.1600000000001, 0.05],
            [533.7479999999999, 0.5],
            [537.813, 0.5],
            [538.1890000000001, 0.1],
            [584.33, 0.02],
            [588.022, 0.025],
            [609.9141999999999, 0.15],
            [611.149, 0.05],
            [632.5166, 0.05],
            [633.0013, 0.015],
            [635.472, 0.2],
            [635.9979999999999, 0.25],
            [643.847, 1.0],
            [646.4939999999999, 0.2],
            [656.765, 0.0125],
            [672.578, 0.25],
            [675.919, 0.05],
            [677.8116, 0.015],
        ],
    },
    {
        name: "calcium",
        lines: [
            [392.348, 0.02608695652173913],
            [393.366, 1.0],
            [393.529, 0.0391304347826087],
            [394.604, 0.02608695652173913],
            [394.89, 0.06521739130434782],
            [395.70500000000004, 0.07391304347826087],
            [396.847, 0.9565217391304348],
            [397.257, 0.034782608695652174],
            [397.371, 0.0782608695652174],
            [409.71000000000004, 0.21739130434782608],
            [409.85299999999995, 0.06521739130434782],
            [409.85699999999997, 0.06521739130434782],
            [410.98199999999997, 0.2608695652173913],
            [411.02799999999996, 0.13043478260869565],
            [420.61800000000005, 0.17391304347826086],
            [422.00699999999995, 0.21739130434782608],
            [422.67299999999994, 0.21739130434782608],
            [424.046, 0.06521739130434782],
            [428.30100000000004, 0.10434782608695652],
            [428.936, 0.09565217391304348],
            [429.899, 0.09565217391304348],
            [430.253, 0.10869565217391304],
            [430.774, 0.1],
            [431.86499999999995, 0.09565217391304348],
            [435.508, 0.08695652173913043],
            [442.544, 0.10869565217391304],
            [443.496, 0.11304347826086956],
            [443.56899999999996, 0.10869565217391304],
            [445.47799999999995, 0.13043478260869565],
            [445.58900000000006, 0.12173913043478261],
            [445.66099999999994, 0.08695652173913043],
            [447.204, 0.08695652173913043],
            [447.92299999999994, 0.043478260869565216],
            [448.918, 0.08695652173913043],
            [452.69399999999996, 0.1],
            [457.855, 0.09565217391304348],
            [458.14, 0.1],
            [458.14700000000005, 0.1],
            [458.587, 0.10434782608695652],
            [458.596, 0.10434782608695652],
            [468.52700000000004, 0.08695652173913043],
            [471.674, 0.13043478260869565],
            [472.10299999999995, 0.17391304347826086],
            [479.997, 0.17391304347826086],
            [487.813, 0.10869565217391304],
            [500.14799999999997, 0.30434782608695654],
            [501.997, 0.34782608695652173],
            [502.11400000000003, 0.17391304347826086],
            [504.162, 0.1],
            [518.885, 0.10869565217391304],
            [526.171, 0.09565217391304348],
            [526.2239999999999, 0.1],
            [526.424, 0.09565217391304348],
            [526.556, 0.10434782608695652],
            [527.027, 0.10869565217391304],
            [528.527, 0.2608695652173913],
            [530.722, 0.30434782608695654],
            [533.919, 0.21739130434782608],
            [534.947, 0.11739130434782609],
            [551.298, 0.1],
            [558.197, 0.10869565217391304],
            [558.876, 0.11739130434782609],
            [559.012, 0.10434782608695652],
            [559.447, 0.11304347826086956],
            [559.8489999999999, 0.10869565217391304],
            [560.129, 0.10434782608695652],
            [560.2850000000001, 0.10434782608695652],
            [585.745, 0.13043478260869565],
            [592.272, 0.043478260869565216],
            [592.3689999999999, 0.043478260869565216],
            [610.272, 0.11739130434782609],
            [612.222, 0.12608695652173912],
            [616.129, 0.09565217391304348],
            [616.217, 0.13043478260869565],
            [616.376, 0.09565217391304348],
            [616.644, 0.10434782608695652],
            [616.9060000000001, 0.11304347826086956],
            [616.956, 0.12173913043478261],
            [643.9069999999999, 0.15217391304347827],
            [644.981, 0.13043478260869565],
            [645.5600000000001, 0.09565217391304348],
            [645.687, 0.34782608695652173],
            [646.257, 0.14782608695652175],
            [647.1659999999999, 0.12608695652173912],
            [649.3779999999999, 0.1391304347826087],
            [649.9649999999999, 0.12173913043478261],
            [657.278, 0.1],
            [671.769, 0.13043478260869565],
            [714.8149999999999, 0.14347826086956522],
        ],
    },
    {
        name: "cerium",
        lines: [
            [400.156, 0.023],
            [400.377, 0.091],
            [400.56399999999996, 0.037],
            [400.759, 0.021],
            [401.239, 0.27],
            [401.49, 0.091],
            [401.588, 0.025],
            [401.904, 0.02],
            [402.227, 0.024],
            [402.44899999999996, 0.084],
            [402.515, 0.024],
            [402.841, 0.084],
            [403.034, 0.025],
            [403.134, 0.084],
            [403.767, 0.034],
            [404.076, 0.21],
            [404.258, 0.091],
            [404.521, 0.023],
            [404.634, 0.062],
            [405.143, 0.021],
            [405.19899999999996, 0.021],
            [405.351, 0.07],
            [405.49899999999997, 0.045],
            [406.222, 0.028],
            [406.294, 0.023],
            [406.728, 0.028],
            [406.884, 0.042],
            [407.181, 0.11],
            [407.29200000000003, 0.027],
            [407.348, 0.18],
            [407.37399999999997, 0.021],
            [407.571, 0.15],
            [407.585, 0.15],
            [407.62399999999997, 0.021],
            [407.74699999999996, 0.042],
            [407.832, 0.053],
            [407.852, 0.027],
            [408.044, 0.027],
            [408.12199999999996, 0.067],
            [408.323, 0.091],
            [408.523, 0.045],
            [408.736, 0.025],
            [408.885, 0.023],
            [410.177, 0.045],
            [410.5, 0.025],
            [410.742, 0.051],
            [411.038, 0.02],
            [411.139, 0.025],
            [411.537, 0.042],
            [411.701, 0.025],
            [411.729, 0.02],
            [411.759, 0.02],
            [411.814, 0.077],
            [411.90200000000004, 0.025],
            [411.979, 0.031],
            [411.988, 0.031],
            [412.08299999999997, 0.045],
            [412.32399999999996, 0.051],
            [412.349, 0.051],
            [412.387, 0.098],
            [412.479, 0.051],
            [412.73699999999997, 0.098],
            [412.774, 0.025],
            [412.80699999999996, 0.02],
            [413.071, 0.053],
            [413.11, 0.048],
            [413.38, 0.27],
            [413.544, 0.027],
            [413.747, 0.027],
            [413.765, 0.2],
            [413.81000000000006, 0.027],
            [413.83500000000004, 0.021],
            [414.23999999999995, 0.077],
            [414.44899999999996, 0.039],
            [414.5, 0.067],
            [414.62299999999993, 0.048],
            [414.89, 0.028],
            [414.979, 0.042],
            [414.99399999999997, 0.098],
            [415.091, 0.042],
            [415.197, 0.14],
            [415.313, 0.023],
            [415.90299999999996, 0.045],
            [416.35200000000003, 0.031],
            [416.561, 0.13],
            [416.688, 0.062],
            [416.78000000000003, 0.025],
            [416.97700000000003, 0.032],
            [416.988, 0.032],
            [417.66999999999996, 0.034],
            [418.108, 0.034],
            [418.533, 0.034],
            [418.66, 0.35],
            [418.73199999999997, 0.053],
            [419.309, 0.056],
            [419.328, 0.037],
            [419.387, 0.037],
            [419.634, 0.063],
            [419.8, 0.028],
            [419.867, 0.028],
            [419.872, 0.084],
            [420.12399999999997, 0.024],
            [420.294, 0.091],
            [420.941, 0.027],
            [421.404, 0.037],
            [421.759, 0.031],
            [422.26000000000005, 0.15],
            [422.775, 0.077],
            [423.174, 0.039],
            [423.421, 0.024],
            [423.60200000000003, 0.02],
            [423.992, 0.098],
            [424.27200000000005, 0.039],
            [424.58900000000006, 0.031],
            [424.59799999999996, 0.031],
            [424.672, 0.039],
            [424.86800000000005, 0.11],
            [425.337, 0.039],
            [425.579, 0.062],
            [426.343, 0.02],
            [427.01899999999995, 0.062],
            [427.072, 0.039],
            [427.88599999999997, 0.02],
            [428.537, 0.028],
            [428.866, 0.02],
            [428.94399999999996, 0.02],
            [428.99399999999997, 0.2],
            [429.60699999999997, 0.02],
            [429.66700000000003, 0.15],
            [429.678, 0.042],
            [429.936, 0.059],
            [430.033, 0.077],
            [430.514, 0.042],
            [430.672, 0.077],
            [430.974, 0.039],
            [432.072, 0.056],
            [433.04499999999996, 0.031],
            [433.271, 0.031],
            [433.62299999999993, 0.024],
            [433.77700000000004, 0.098],
            [433.93100000000004, 0.034],
            [434.979, 0.07],
            [435.271, 0.056],
            [436.466, 0.091],
            [437.38199999999995, 0.035],
            [437.592, 0.053],
            [438.217, 0.091],
            [438.684, 0.07],
            [438.80100000000004, 0.031],
            [439.166, 0.17],
            [439.879, 0.02],
            [439.91999999999996, 0.051],
            [441.064, 0.035],
            [441.076, 0.035],
            [441.68999999999994, 0.031],
            [441.878, 0.098],
            [442.36800000000005, 0.02],
            [442.707, 0.031],
            [442.79200000000003, 0.048],
            [442.84399999999994, 0.031],
            [442.927, 0.065],
            [444.439, 0.048],
            [444.46999999999997, 0.045],
            [444.934, 0.077],
            [445.073, 0.062],
            [446.021, 0.24],
            [446.11400000000003, 0.045],
            [446.341, 0.042],
            [446.754, 0.028],
            [447.12399999999997, 0.14],
            [447.27200000000005, 0.045],
            [447.936, 0.07],
            [448.39, 0.07],
            [448.691, 0.084],
            [449.785, 0.025],
            [450.64099999999996, 0.01],
            [451.58599999999996, 0.011],
            [451.959, 0.01],
            [452.308, 0.077],
            [452.735, 0.084],
            [452.84700000000004, 0.084],
            [453.24899999999997, 0.011],
            [453.907, 0.011],
            [453.975, 0.084],
            [454.496, 0.021],
            [455.13, 0.025],
            [456.02799999999996, 0.065],
            [456.096, 0.031],
            [456.236, 0.21],
            [456.584, 0.042],
            [457.22799999999995, 0.11],
            [458.25, 0.042],
            [459.11199999999997, 0.013],
            [459.39300000000003, 0.084],
            [460.64, 0.042],
            [462.48999999999995, 0.042],
            [462.816, 0.17],
            [463.23199999999997, 0.017],
            [465.05100000000004, 0.011],
            [465.429, 0.013],
            [466.95, 0.011],
            [468.01300000000003, 0.015],
            [468.46099999999996, 0.027],
            [471.4, 0.02],
            [471.48100000000005, 0.01],
            [472.509, 0.011],
            [473.35200000000003, 0.01],
            [473.72799999999995, 0.031],
            [473.953, 0.01],
            [474.717, 0.016],
            [475.784, 0.011],
            [476.87700000000007, 0.01],
            [477.39399999999995, 0.023],
            [482.255, 0.011],
            [484.77700000000004, 0.014],
            [488.246, 0.018],
            [494.34399999999994, 0.011],
            [497.15, 0.013],
            [499.463, 0.013],
            [500.91, 0.021],
            [501.177, 0.01],
            [502.287, 0.012],
            [503.77799999999996, 0.012],
            [504.08500000000004, 0.012],
            [504.40200000000004, 0.018],
            [507.178, 0.012],
            [507.535, 0.024],
            [507.968, 0.047],
            [511.27, 0.013],
            [511.717, 0.016],
            [512.957, 0.017],
            [514.757, 0.011],
            [514.999, 0.01],
            [515.9689999999999, 0.028],
            [516.1479999999999, 0.028],
            [517.455, 0.019],
            [518.746, 0.037],
            [519.1659999999999, 0.021],
            [521.192, 0.019],
            [522.346, 0.026],
            [522.975, 0.018],
            [523.292, 0.014],
            [524.592, 0.026],
            [526.571, 0.013],
            [527.423, 0.034],
            [529.6560000000001, 0.013],
            [532.808, 0.013],
            [533.054, 0.019],
            [535.353, 0.045],
            [539.3399999999999, 0.03],
            [539.764, 0.015],
            [540.923, 0.028],
            [542.038, 0.011],
            [544.924, 0.014],
            [546.837, 0.014],
            [547.229, 0.014],
            [551.208, 0.026],
            [555.625, 0.011],
            [556.4970000000001, 0.017],
            [556.597, 0.013],
            [559.588, 0.01],
            [560.1279999999999, 0.024],
            [565.514, 0.019],
            [566.996, 0.024],
            [567.775, 0.012],
            [569.294, 0.012],
            [569.699, 0.03],
            [569.923, 0.037],
            [571.903, 0.024],
            [577.312, 0.014],
            [578.8149999999999, 0.012],
            [581.292, 0.012],
            [594.086, 0.023],
            [600.018, 0.0011],
            [600.1899999999999, 0.0055],
            [600.586, 0.0055],
            [600.62, 0.0015],
            [600.682, 0.0055],
            [600.737, 0.0019],
            [601.342, 0.0075],
            [601.659, 0.0023],
            [602.42, 0.011],
            [602.716, 0.0015],
            [603.126, 0.0011],
            [603.358, 0.0023],
            [603.42, 0.0035],
            [603.441, 0.0023],
            [603.549, 0.0035],
            [604.339, 0.011],
            [604.542, 0.0028],
            [604.74, 0.0055],
            [605.1800000000001, 0.0019],
            [605.75, 0.0023],
            [605.8, 0.0035],
            [606.675, 0.0023],
            [606.946, 0.0019],
            [606.948, 0.0035],
            [607.2, 0.0035],
            [607.661, 0.0035],
            [607.716, 0.0017],
            [608.037, 0.0017],
            [608.1279999999999, 0.0017],
            [608.886, 0.0019],
            [608.896, 0.0019],
            [609.319, 0.0035],
            [609.8340000000001, 0.0045],
            [609.98, 0.0011],
            [610.874, 0.0028],
            [611.856, 0.0015],
            [611.89, 0.0017],
            [612.367, 0.0045],
            [613.2, 0.0019],
            [613.2180000000001, 0.0019],
            [613.545, 0.0011],
            [613.903, 0.0023],
            [614.292, 0.0015],
            [614.336, 0.0035],
            [614.643, 0.0023],
            [614.784, 0.0019],
            [615.172, 0.0023],
            [615.982, 0.0019],
            [616.214, 0.0019],
            [616.545, 0.0019],
            [617.528, 0.0019],
            [618.617, 0.0035],
            [618.797, 0.0015],
            [619.5229999999999, 0.0015],
            [619.553, 0.0019],
            [619.8050000000001, 0.0019],
            [620.8979999999999, 0.0035],
            [621.682, 0.0011],
            [622.894, 0.0035],
            [622.913, 0.0019],
            [623.245, 0.0023],
            [623.745, 0.0028],
            [623.871, 0.0013],
            [624.187, 0.0011],
            [624.2909999999999, 0.0013],
            [625.365, 0.0015],
            [625.799, 0.0013],
            [626.427, 0.0015],
            [627.205, 0.0045],
            [627.647, 0.0015],
            [629.558, 0.0035],
            [629.951, 0.0028],
            [630.021, 0.0023],
            [630.664, 0.0013],
            [631.001, 0.0035],
            [633.54, 0.0015],
            [633.721, 0.0011],
            [634.0699999999999, 0.0013],
            [634.395, 0.0035],
            [637.111, 0.0035],
            [638.684, 0.0028],
            [639.302, 0.0023],
            [639.516, 0.0011],
            [642.529, 0.0011],
            [643.007, 0.0035],
            [643.4390000000001, 0.0019],
            [643.64, 0.0023],
            [644.612, 0.0019],
            [645.803, 0.0035],
            [646.688, 0.0019],
            [646.739, 0.0028],
            [647.3720000000001, 0.0035],
            [649.097, 0.0017],
            [650.327, 0.0011],
            [650.716, 0.0011],
            [651.359, 0.0023],
            [651.731, 0.0019],
            [655.17, 0.0019],
            [655.5649999999999, 0.0045],
            [657.9100000000001, 0.0023],
            [660.635, 0.0015],
            [660.6859999999999, 0.0015],
            [661.206, 0.0022],
            [662.3, 0.001],
            [662.893, 0.003],
            [665.089, 0.0013],
            [665.272, 0.0022],
            [666.141, 0.001],
            [666.559, 0.0013],
            [667.554, 0.001],
            [668.6600000000001, 0.0015],
            [670.066, 0.0026],
            [670.427, 0.0035],
            [670.452, 0.0013],
            [670.604, 0.001],
            [672.871, 0.0015],
            [672.957, 0.0015],
            [674.47, 0.0015],
            [674.6899999999999, 0.001],
            [677.428, 0.003],
            [677.559, 0.0035],
            [677.828, 0.001],
            [680.7810000000001, 0.0018],
            [680.882, 0.001],
            [681.823, 0.0015],
            [682.973, 0.001],
            [684.725, 0.0013],
            [685.655, 0.0012],
            [689.366, 0.001],
            [689.845, 0.001],
            [692.481, 0.003],
            [693.9449999999999, 0.001],
            [697.35, 0.0019],
            [698.382, 0.001],
            [698.6020000000001, 0.003],
            [416.942, 0.05],
            [419.16999999999996, 0.03],
            [419.483, 0.05],
            [421.326, 0.03],
            [421.713, 0.03],
            [428.47700000000003, 0.04],
            [430.471, 0.03],
            [434.63500000000005, 0.06],
            [438.997, 0.04],
            [444.832, 0.06],
            [448.52700000000004, 0.05],
            [452.192, 0.1],
            [453.573, 0.1],
            [457.68999999999994, 0.03],
            [462.76000000000005, 0.05],
            [476.60699999999997, 0.03],
            [497.645, 0.05],
            [565.097, 0.05],
            [566.42, 0.1],
            [569.108, 0.05],
            [571.059, 0.03],
            [574.947, 0.05],
            [594.983, 0.05],
            [596.222, 0.2],
            [596.271, 0.05],
            [597.956, 0.04],
            [598.3399999999999, 0.1],
            [600.263, 0.3],
            [603.254, 1.0],
            [606.091, 1.0],
            [606.179, 0.05],
            [609.735, 0.05],
            [609.887, 0.05],
            [613.51, 0.05],
            [628.779, 0.03],
            [630.816, 0.05],
            [634.175, 0.03],
            [694.4939999999999, 0.1],
        ],
    },
    {
        name: "chromium",
        lines: [
            [406.694, 0.00425],
            [407.486, 0.00175],
            [407.606, 0.002],
            [407.709, 0.002],
            [407.768, 0.002],
            [410.48699999999997, 0.002],
            [410.95799999999997, 0.002],
            [412.061, 0.002],
            [412.18199999999996, 0.002],
            [412.216, 0.00175],
            [412.33900000000006, 0.002],
            [412.65200000000004, 0.007],
            [412.73, 0.00175],
            [412.764, 0.002],
            [413.13599999999997, 0.002],
            [415.27799999999996, 0.0015],
            [415.38199999999995, 0.006],
            [416.142, 0.00425],
            [416.36199999999997, 0.007],
            [416.552, 0.0035],
            [416.98400000000004, 0.002],
            [417.02, 0.00175],
            [417.27700000000004, 0.002],
            [417.48, 0.0085],
            [417.59399999999994, 0.0015],
            [417.92600000000004, 0.0085],
            [418.48999999999995, 0.00175],
            [418.63599999999997, 0.0015],
            [419.01300000000003, 0.00175],
            [419.12700000000007, 0.00425],
            [419.366, 0.00425],
            [419.495, 0.0035],
            [419.72299999999996, 0.002],
            [419.85200000000003, 0.00425],
            [420.35900000000004, 0.003],
            [420.447, 0.002],
            [420.83599999999996, 0.00175],
            [420.937, 0.0055],
            [420.976, 0.002],
            [421.13500000000005, 0.002],
            [421.63599999999997, 0.002],
            [421.76300000000003, 0.00425],
            [422.157, 0.002],
            [422.27299999999997, 0.002],
            [423.896, 0.002],
            [424.07, 0.003],
            [425.43500000000006, 1.0],
            [425.55, 0.0035],
            [426.13500000000005, 0.003],
            [426.314, 0.0055],
            [427.10600000000005, 0.0015],
            [427.291, 0.002],
            [427.48, 0.8],
            [428.03999999999996, 0.00425],
            [428.97200000000004, 0.5],
            [429.196, 0.002],
            [429.576, 0.00425],
            [429.774, 0.0035],
            [430.05100000000004, 0.00175],
            [430.11800000000005, 0.0025],
            [430.54499999999996, 0.0015],
            [431.96400000000006, 0.00175],
            [432.508, 0.003],
            [433.75699999999995, 0.039],
            [433.945, 0.055],
            [433.97200000000004, 0.019],
            [434.01300000000003, 0.003],
            [434.451, 0.095],
            [434.683, 0.0035],
            [435.105, 0.019],
            [435.177, 0.115],
            [435.963, 0.0285],
            [436.313, 0.0035],
            [437.128, 0.0265],
            [437.325, 0.0035],
            [437.416, 0.0055],
            [437.533, 0.0035],
            [438.111, 0.0025],
            [438.49799999999993, 0.0265],
            [438.75, 0.003],
            [439.175, 0.0035],
            [440.35, 0.003],
            [441.03000000000003, 0.0012],
            [441.10900000000004, 0.003],
            [441.225, 0.00175],
            [441.387, 0.0025],
            [442.428, 0.003],
            [442.85, 0.0012],
            [443.049, 0.0025],
            [443.218, 0.0025],
            [445.854, 0.0055],
            [445.974, 0.0015],
            [446.53599999999994, 0.0015],
            [448.288, 0.0015],
            [448.805, 0.002],
            [448.947, 0.0025],
            [449.23100000000005, 0.003],
            [449.686, 0.033],
            [449.87299999999993, 0.0025],
            [450.03000000000003, 0.0035],
            [450.111, 0.0025],
            [450.179, 0.0011],
            [450.68500000000006, 0.0012],
            [451.18999999999994, 0.00475],
            [451.437, 0.0006],
            [451.453, 0.00175],
            [452.611, 0.0012],
            [452.64700000000005, 0.019],
            [452.73400000000004, 0.0035],
            [452.985, 0.0012],
            [453.07399999999996, 0.019],
            [453.515, 0.0025],
            [453.572, 0.012],
            [453.979, 0.002],
            [454.05, 0.012],
            [454.072, 0.012],
            [454.10699999999997, 0.00175],
            [454.151, 0.00095],
            [454.262, 0.0012],
            [454.462, 0.007],
            [454.534, 0.0012],
            [454.596, 0.03],
            [455.617, 0.0025],
            [455.866, 0.0011],
            [456.41700000000003, 0.00095],
            [456.55100000000004, 0.006],
            [456.96400000000006, 0.00475],
            [457.168, 0.006],
            [457.512, 0.0011],
            [458.00600000000003, 0.018],
            [458.61400000000003, 0.0012],
            [459.139, 0.018],
            [459.559, 0.0035],
            [460.01000000000005, 0.0025],
            [460.075, 0.024],
            [460.10200000000003, 0.0025],
            [461.337, 0.012],
            [461.61400000000003, 0.03],
            [461.95500000000004, 0.0035],
            [462.196, 0.00425],
            [462.24899999999997, 0.0035],
            [462.276, 0.0012],
            [462.61899999999997, 0.0275],
            [463.218, 0.0012],
            [463.718, 0.002],
            [463.77700000000004, 0.0025],
            [463.95200000000006, 0.0025],
            [464.617, 0.08],
            [464.68100000000004, 0.0012],
            [464.813, 0.0012],
            [464.887, 0.0012],
            [464.946, 0.00175],
            [465.216, 0.042],
            [465.474, 0.00175],
            [465.61899999999997, 0.00095],
            [466.33299999999997, 0.002],
            [466.383, 0.0035],
            [466.48, 0.00475],
            [466.59, 0.00175],
            [466.622, 0.0011],
            [466.651, 0.0035],
            [466.934, 0.0025],
            [468.054, 0.002],
            [468.087, 0.00095],
            [468.937, 0.0035],
            [469.395, 0.003],
            [469.515, 0.0012],
            [469.706, 0.003],
            [469.846, 0.012],
            [470.061, 0.00175],
            [470.804, 0.0095],
            [471.843, 0.012],
            [472.31000000000006, 0.0025],
            [472.442, 0.0025],
            [472.715, 0.0025],
            [472.97200000000004, 0.0012],
            [473.071, 0.006],
            [473.735, 0.007],
            [474.53100000000006, 0.00095],
            [475.20799999999997, 0.0035],
            [475.611, 0.017],
            [476.429, 0.0025],
            [476.663, 0.0011],
            [476.78599999999994, 0.0015],
            [478.93199999999996, 0.0095],
            [479.25100000000003, 0.00475],
            [480.10299999999995, 0.006],
            [482.938, 0.0055],
            [483.686, 0.0007],
            [486.12, 0.00085],
            [486.184, 0.0035],
            [487.08000000000004, 0.007],
            [488.578, 0.00175],
            [488.596, 0.00095],
            [488.701, 0.0065],
            [488.85299999999995, 0.00095],
            [490.32399999999996, 0.00175],
            [492.22700000000003, 0.013],
            [493.633, 0.0055],
            [495.48100000000005, 0.0055],
            [496.49300000000005, 0.00175],
            [501.332, 0.003],
            [505.18999999999994, 0.00085],
            [506.591, 0.00085],
            [506.771, 0.002],
            [507.29200000000003, 0.002],
            [511.075, 0.0015],
            [511.313, 0.00085],
            [512.346, 0.00085],
            [513.9649999999999, 0.0025],
            [514.467, 0.0007],
            [516.6229999999999, 0.0035],
            [517.743, 0.00175],
            [518.4590000000001, 0.0035],
            [519.2, 0.0035],
            [519.3489999999999, 0.0006],
            [519.644, 0.00425],
            [520.019, 0.00175],
            [520.452, 0.265],
            [520.604, 0.42],
            [520.8439999999999, 0.55],
            [521.413, 0.00095],
            [522.175, 0.0015],
            [522.4939999999999, 0.00425],
            [522.6890000000001, 0.0006],
            [523.897, 0.00095],
            [524.3399999999999, 0.0015],
            [524.7560000000001, 0.0145],
            [525.492, 0.003],
            [525.513, 0.003],
            [526.175, 0.00095],
            [526.415, 0.0265],
            [526.516, 0.0015],
            [526.572, 0.009],
            [527.201, 0.00175],
            [527.3439999999999, 0.0015],
            [527.517, 0.00475],
            [527.569, 0.00175],
            [527.603, 0.0035],
            [528.029, 0.00095],
            [528.7189999999999, 0.0005],
            [529.669, 0.017],
            [529.736, 0.0035],
            [529.827, 0.033],
            [530.075, 0.00425],
            [530.421, 0.00085],
            [531.288, 0.0012],
            [531.8779999999999, 0.0012],
            [532.8340000000001, 0.017],
            [532.917, 0.0035],
            [532.972, 0.00085],
            [534.044, 0.0007],
            [534.476, 0.0005],
            [534.581, 0.039],
            [534.832, 0.019],
            [538.698, 0.0015],
            [538.757, 0.0011],
            [539.039, 0.0005],
            [540.0609999999999, 0.002],
            [540.5, 0.0011],
            [540.979, 0.07],
            [544.241, 0.0006],
            [546.397, 0.00095],
            [548.05, 0.00095],
            [562.864, 0.0012],
            [564.236, 0.00035],
            [564.937, 0.0006],
            [566.404, 0.0012],
            [568.12, 0.00035],
            [568.2479999999999, 0.00035],
            [569.473, 0.0012],
            [569.833, 0.002],
            [570.231, 0.0012],
            [571.264, 0.0006],
            [571.278, 0.0012],
            [571.982, 0.00035],
            [574.643, 0.00035],
            [575.3689999999999, 0.00035],
            [578.12, 0.0006],
            [578.181, 0.0003],
            [578.3109999999999, 0.0012],
            [578.393, 0.0015],
            [578.5, 0.0012],
            [578.582, 0.00095],
            [578.799, 0.003],
            [579.1, 0.009],
            [633.01, 0.00175],
            [636.287, 0.0011],
            [666.108, 0.00095],
            [666.926, 0.00055],
            [688.162, 0.00025],
            [688.238, 0.0005],
            [688.303, 0.00105],
            [692.413, 0.00135],
            [692.52, 0.00085],
            [697.848, 0.0015],
            [697.982, 0.00055],
        ],
    },
    {
        name: "copper",
        lines: [
            [400.3476, 0.056],
            [402.26300000000003, 0.5],
            [403.2647, 0.04],
            [404.34839999999997, 0.24],
            [404.37510000000003, 0.2],
            [406.264, 0.8],
            [406.8106, 0.048],
            [413.1363, 0.2],
            [414.3017, 0.08],
            [415.36229999999995, 0.12],
            [416.11400000000003, 0.2],
            [416.42839999999995, 0.148],
            [417.1851, 0.16],
            [417.9512, 0.2],
            [421.1866, 0.2],
            [423.0449, 0.128],
            [425.56350000000003, 0.08],
            [427.51099999999997, 0.38],
            [427.99620000000004, 0.12],
            [429.247, 0.2],
            [436.537, 0.16],
            [444.48310000000004, 0.04],
            [450.60020000000003, 0.16],
            [451.6049, 0.06],
            [454.1032, 0.06],
            [455.592, 0.2],
            [459.6906, 0.04],
            [464.9271, 0.048],
            [465.11199999999997, 0.8],
            [466.1363, 0.048],
            [467.1702, 0.128],
            [467.3577, 0.12],
            [468.19939999999997, 0.18],
            [475.8433, 0.04],
            [481.2948, 0.16],
            [485.1262, 0.048],
            [485.4988, 0.12],
            [487.3304, 0.04],
            [490.1427, 0.06],
            [490.9734, 0.4],
            [491.8376, 0.2],
            [492.6424, 0.08],
            [493.1698, 0.36],
            [494.3026, 0.048],
            [495.3724, 0.28],
            [498.55060000000003, 0.2],
            [500.68010000000004, 0.16],
            [500.9851, 0.14],
            [501.262, 0.16],
            [502.12790000000007, 0.14],
            [503.9016, 0.08],
            [504.7348, 0.12],
            [505.17929999999996, 0.36],
            [505.89099999999996, 0.16],
            [506.54589999999996, 0.2],
            [506.7094, 0.18],
            [507.23019999999997, 0.14],
            [508.8277, 0.18],
            [509.3816, 0.168],
            [510.0067, 0.14],
            [510.554, 0.6],
            [512.4476, 0.1],
            [515.324, 0.8],
            [515.8093, 0.04],
            [518.3367000000001, 0.04],
            [521.8199999999999, 1.0],
            [526.9991, 0.04],
            [527.6524999999999, 0.04],
            [529.2520000000001, 0.66],
            [536.8383, 0.04],
            [570.024, 0.6],
            [578.213, 0.6],
            [580.5989, 0.06],
            [583.3515, 0.04],
            [589.7971, 0.08],
            [593.7577, 0.048],
            [594.1196, 0.16],
            [599.326, 0.04],
            [600.012, 0.26],
            [602.3264, 0.04],
            [607.2218, 0.1],
            [608.0343, 0.06],
            [609.999, 0.06],
            [610.7412, 0.064],
            [611.4493, 0.12],
            [615.0384, 0.24],
            [615.4222, 0.3],
            [617.2037, 0.2],
            [618.6884, 0.22],
            [618.8676, 0.16],
            [619.8091999999999, 0.12],
            [620.4261, 0.188],
            [620.8457000000001, 0.18],
            [621.6939, 0.3],
            [621.9844, 0.28],
            [626.1848, 0.2],
            [627.3349000000001, 0.4],
            [628.8696, 0.14],
            [630.1009, 0.36],
            [630.5971999999999, 0.22],
            [631.2492, 0.16],
            [632.6466, 0.048],
            [637.3268, 0.16],
            [637.784, 0.3],
            [640.3384, 0.16],
            [642.3884, 0.34],
            [644.2965, 0.08],
            [644.8559, 0.3],
            [646.6246, 0.068],
            [647.0168, 0.38],
            [648.1437, 0.3],
            [648.4421, 0.16],
            [651.7317, 0.088],
            [653.0083, 0.16],
            [655.1286, 0.048],
            [657.708, 0.08],
            [662.4292, 0.3],
            [664.1396, 0.32],
            [666.0962000000001, 0.18],
            [677.0362, 0.04],
            [680.6216000000001, 0.12],
            [680.9647, 0.16],
            [682.3202, 0.128],
            [684.4157, 0.1],
            [686.8791, 0.128],
            [687.2230999999999, 0.108],
            [687.9404000000001, 0.108],
            [693.7553, 0.088],
            [695.2871, 0.06],
            [697.7572, 0.06],
        ],
    },
    {
        name: "erbium",
        lines: [
            [400.40500000000003, 0.00575],
            [400.796, 0.35],
            [400.818, 0.00575],
            [400.916, 0.007],
            [401.258, 0.0275],
            [401.557, 0.00875],
            [402.05100000000004, 0.075],
            [402.15500000000003, 0.01125],
            [404.30100000000004, 0.00575],
            [404.696, 0.025],
            [404.834, 0.007],
            [404.94899999999996, 0.005],
            [405.54699999999997, 0.0235],
            [405.951, 0.01375],
            [405.978, 0.01725],
            [407.788, 0.0105],
            [408.12399999999997, 0.01375],
            [408.76300000000003, 0.0875],
            [409.29, 0.00525],
            [409.81000000000006, 0.0275],
            [410.05600000000004, 0.00875],
            [411.63599999999997, 0.008],
            [411.855, 0.008],
            [413.15, 0.015],
            [414.291, 0.01375],
            [415.111, 0.1725],
            [418.99799999999993, 0.007],
            [419.07, 0.025],
            [420.532, 0.00325],
            [421.843, 0.035],
            [422.099, 0.005],
            [423.02, 0.008],
            [423.47799999999995, 0.0035],
            [425.19399999999996, 0.005],
            [427.64799999999997, 0.0035],
            [428.65600000000006, 0.01725],
            [429.89099999999996, 0.008],
            [430.16, 0.008],
            [430.38100000000003, 0.0035],
            [431.99399999999997, 0.00275],
            [432.88100000000003, 0.00325],
            [433.13599999999997, 0.00275],
            [434.092, 0.0035],
            [434.834, 0.00475],
            [436.939, 0.00275],
            [438.217, 0.004],
            [438.46999999999997, 0.0075],
            [438.64, 0.0075],
            [440.317, 0.0025],
            [440.934, 0.02025],
            [441.87, 0.0045],
            [441.96099999999996, 0.01425],
            [442.25100000000003, 0.00275],
            [442.457, 0.008],
            [442.677, 0.00925],
            [443.76599999999996, 0.00275],
            [445.924, 0.0025],
            [447.35, 0.0025],
            [449.639, 0.00325],
            [450.075, 0.005],
            [452.274, 0.00325],
            [456.326, 0.004],
            [460.66099999999994, 0.025],
            [463.088, 0.004],
            [464.06000000000006, 0.00275],
            [466.544, 0.00275],
            [467.316, 0.00775],
            [467.562, 0.01425],
            [467.90600000000006, 0.00375],
            [472.26899999999995, 0.00575],
            [472.90500000000003, 0.00375],
            [475.15200000000004, 0.00325],
            [475.965, 0.00425],
            [482.035, 0.00475],
            [485.74399999999997, 0.0035],
            [487.209, 0.00375],
            [490.008, 0.00525],
            [493.41099999999994, 0.00525],
            [494.436, 0.00325],
            [495.174, 0.0045],
            [497.642, 0.00325],
            [500.725, 0.00625],
            [502.83299999999997, 0.0035],
            [502.89099999999996, 0.003],
            [503.59399999999994, 0.005],
            [504.20500000000004, 0.00525],
            [504.38599999999997, 0.00325],
            [504.48900000000003, 0.00325],
            [507.759, 0.00325],
            [512.456, 0.003],
            [512.741, 0.00325],
            [513.153, 0.003],
            [513.383, 0.00325],
            [516.4770000000001, 0.00425],
            [517.278, 0.00325],
            [518.89, 0.004],
            [520.652, 0.00375],
            [521.2909999999999, 0.0015],
            [521.513, 0.00075],
            [521.826, 0.00075],
            [522.934, 0.001125],
            [525.5930000000001, 0.0035],
            [525.647, 0.00055],
            [525.702, 0.000675],
            [526.4770000000001, 0.000875],
            [527.2909999999999, 0.002],
            [527.771, 0.001375],
            [527.934, 0.000675],
            [530.23, 0.001125],
            [533.306, 0.001375],
            [533.333, 0.000675],
            [533.423, 0.000675],
            [534.394, 0.00055],
            [534.45, 0.00075],
            [534.806, 0.00225],
            [535.047, 0.001125],
            [536.885, 0.000875],
            [539.587, 0.000875],
            [541.463, 0.0015],
            [542.2810000000001, 0.00045],
            [545.13, 0.00045],
            [545.427, 0.000875],
            [545.662, 0.0045],
            [546.243, 0.000875],
            [546.832, 0.00225],
            [547.7470000000001, 0.00045],
            [548.597, 0.002],
            [549.7439999999999, 0.000675],
            [551.6020000000001, 0.000675],
            [559.346, 0.002],
            [560.114, 0.001125],
            [560.9939999999999, 0.001125],
            [561.182, 0.0015],
            [562.201, 0.00175],
            [562.653, 0.002],
            [563.62, 0.00075],
            [564.036, 0.00225],
            [564.142, 0.00055],
            [565.863, 0.00055],
            [566.495, 0.00175],
            [566.544, 0.001125],
            [567.548, 0.001375],
            [569.553, 0.00035],
            [571.087, 0.000675],
            [571.7479999999999, 0.001375],
            [571.955, 0.00175],
            [572.697, 0.001375],
            [573.3430000000001, 0.00055],
            [573.6560000000001, 0.00055],
            [573.694, 0.00055],
            [573.919, 0.0025],
            [574.0609999999999, 0.000875],
            [574.865, 0.0015],
            [575.2529999999999, 0.001375],
            [575.763, 0.00175],
            [576.28, 0.00725],
            [576.992, 0.00175],
            [578.2819999999999, 0.001125],
            [578.466, 0.00175],
            [579.115, 0.00055],
            [580.079, 0.00175],
            [580.61, 0.00055],
            [582.679, 0.01075],
            [583.5840000000001, 0.001125],
            [585.007, 0.0025],
            [585.5310000000001, 0.003],
            [587.235, 0.0035],
            [588.114, 0.003],
            [588.63, 0.000675],
            [590.208, 0.000675],
            [590.606, 0.001375],
            [590.924, 0.001125],
            [593.35, 0.000875],
            [594.637, 0.00055],
            [596.868, 0.001375],
            [597.549, 0.000675],
            [600.679, 0.000875],
            [600.875, 0.00055],
            [601.483, 0.001375],
            [601.574, 0.000875],
            [602.2560000000001, 0.00175],
            [603.212, 0.00055],
            [604.563, 0.00055],
            [604.8140000000001, 0.00055],
            [605.485, 0.001125],
            [606.125, 0.00175],
            [607.645, 0.0015],
            [611.601, 0.000875],
            [612.5319999999999, 0.000875],
            [617.0060000000001, 0.00075],
            [618.321, 0.000675],
            [622.1020000000001, 0.009],
            [623.0899999999999, 0.000875],
            [626.2560000000001, 0.001375],
            [626.793, 0.001125],
            [626.887, 0.0015],
            [627.4939999999999, 0.000875],
            [628.6859999999999, 0.00075],
            [629.942, 0.001125],
            [630.8770000000001, 0.00325],
            [632.613, 0.001375],
            [634.716, 0.00055],
            [638.819, 0.001125],
            [643.2529999999999, 0.00055],
            [648.587, 0.000675],
            [649.235, 0.001375],
            [654.1569999999999, 0.00055],
            [658.348, 0.0015],
            [660.111, 0.00175],
            [672.191, 0.000675],
            [675.987, 0.00175],
            [676.292, 0.00055],
            [677.337, 0.000675],
            [679.092, 0.000875],
            [682.544, 0.00055],
            [682.598, 0.00055],
            [684.8100000000001, 0.00175],
            [686.513, 0.001375],
            [687.9979999999999, 0.000675],
            [400.96999999999997, 0.001],
            [408.858, 5e-5],
            [428.81800000000004, 0.025],
            [429.00600000000003, 1.0],
            [433.82399999999996, 0.0075],
            [438.686, 0.5],
            [461.293, 0.00075],
            [473.55600000000004, 0.375],
            [478.312, 0.05],
            [487.60699999999997, 0.0002],
            [590.33, 0.2],
        ],
    },
    {
        name: "europium",
        lines: [
            [400.371, 0.001],
            [401.169, 0.003],
            [401.758, 0.0025],
            [403.919, 0.002],
            [407.82399999999996, 0.00075],
            [408.538, 0.002],
            [409.68, 0.00125],
            [410.688, 0.001],
            [411.204, 0.0015],
            [411.93, 0.00075],
            [412.72799999999995, 0.00125],
            [412.96999999999997, 0.55],
            [413.659, 0.0005],
            [413.707, 0.0006666666666666666],
            [414.10200000000003, 0.0005],
            [414.172, 0.001],
            [415.15200000000004, 0.0005],
            [415.16400000000004, 0.00075],
            [415.77200000000005, 0.0005],
            [417.28000000000003, 0.0018333333333333333],
            [417.51599999999996, 0.0005],
            [418.22200000000004, 0.0018333333333333333],
            [419.53599999999994, 0.0006666666666666666],
            [419.61800000000005, 0.0006666666666666666],
            [420.505, 1.0],
            [422.108, 0.00075],
            [422.38800000000003, 0.0006666666666666666],
            [422.73999999999995, 0.0015],
            [422.933, 0.00125],
            [423.245, 0.00125],
            [423.75100000000003, 0.0015],
            [423.86899999999997, 0.00075],
            [424.474, 0.00075],
            [424.706, 0.00075],
            [425.38, 0.00075],
            [427.024, 0.0005],
            [429.87299999999993, 0.0025],
            [432.936, 0.0015],
            [432.997, 0.00125],
            [433.061, 0.001],
            [433.11800000000005, 0.0006666666666666666],
            [433.76800000000003, 0.0015],
            [435.509, 0.004],
            [436.157, 0.00045],
            [436.947, 0.0009166666666666666],
            [437.21999999999997, 0.00075],
            [438.317, 0.00125],
            [438.788, 0.0015],
            [440.52700000000004, 0.00035],
            [440.707, 0.0009166666666666666],
            [441.966, 0.0003],
            [443.48100000000005, 0.002],
            [443.55600000000004, 0.23333333333333334],
            [446.497, 0.00125],
            [448.515, 0.0004],
            [452.25699999999995, 0.05],
            [453.559, 0.00075],
            [459.40299999999996, 0.18333333333333332],
            [460.26300000000003, 0.00035],
            [462.72200000000004, 0.16333333333333333],
            [466.188, 0.13833333333333334],
            [471.35900000000004, 0.0005],
            [474.05, 0.00045],
            [479.259, 0.00075],
            [482.93, 0.0006666666666666666],
            [483.033, 0.001],
            [484.047, 0.0006666666666666666],
            [484.96400000000006, 0.001],
            [486.762, 0.0018333333333333333],
            [488.40500000000003, 0.0006666666666666666],
            [489.468, 0.0015],
            [490.08599999999996, 0.001],
            [490.718, 0.0025],
            [491.14, 0.003],
            [495.35200000000003, 0.0009166666666666666],
            [496.021, 0.0009166666666666666],
            [496.255, 0.0009166666666666666],
            [497.576, 0.00075],
            [501.317, 0.003],
            [502.291, 0.0028333333333333335],
            [502.954, 0.0018333333333333333],
            [503.355, 0.0015],
            [506.79499999999996, 0.00125],
            [509.26899999999995, 0.00125],
            [509.64399999999995, 0.0015],
            [511.437, 0.0028333333333333335],
            [512.4770000000001, 0.0015],
            [512.9100000000001, 0.0028333333333333335],
            [513.008, 0.0015],
            [513.3520000000001, 0.0035],
            [516.007, 0.0045],
            [516.67, 0.0035],
            [519.374, 0.001],
            [519.985, 0.0033333333333333335],
            [520.096, 0.0018333333333333333],
            [520.644, 0.002],
            [521.51, 0.0125],
            [522.3489999999999, 0.005],
            [523.924, 0.002],
            [526.64, 0.0033333333333333335],
            [527.196, 0.0065],
            [527.2479999999999, 0.0018333333333333333],
            [528.2819999999999, 0.0025],
            [528.725, 0.0009166666666666666],
            [528.925, 0.001],
            [529.126, 0.002],
            [529.368, 0.001],
            [529.464, 0.002],
            [530.385, 0.0015],
            [535.0409999999999, 0.0005],
            [535.169, 0.00125],
            [535.284, 0.0006666666666666666],
            [535.51, 0.0015],
            [535.761, 0.009],
            [536.083, 0.001],
            [536.161, 0.002],
            [537.694, 0.0018333333333333333],
            [539.294, 0.002],
            [540.277, 0.0075],
            [540.533, 0.00075],
            [541.1859999999999, 0.00075],
            [542.107, 0.0009166666666666666],
            [542.694, 0.0015],
            [544.356, 0.0006666666666666666],
            [545.1510000000001, 0.006333333333333333],
            [545.294, 0.004333333333333333],
            [545.762, 0.0006666666666666666],
            [547.232, 0.0015],
            [548.865, 0.002],
            [549.52, 0.00075],
            [550.083, 0.00025],
            [551.052, 0.002],
            [552.663, 0.0005],
            [553.325, 0.0005],
            [554.254, 0.0005],
            [554.7439999999999, 0.0033333333333333335],
            [557.033, 0.0025],
            [557.714, 0.0033333333333333335],
            [557.963, 0.00125],
            [558.0029999999999, 0.002],
            [558.624, 0.0015],
            [558.683, 0.00125],
            [559.225, 0.0003],
            [559.98, 0.0003],
            [560.586, 0.0003],
            [561.8810000000001, 0.0006666666666666666],
            [562.2439999999999, 0.001],
            [563.254, 0.00125],
            [564.58, 0.0035],
            [565.111, 0.00025],
            [567.385, 0.001],
            [568.11, 0.00045],
            [568.424, 0.00045],
            [573.087, 0.001],
            [573.9, 0.001],
            [576.52, 0.0055],
            [578.3689999999999, 0.003],
            [579.272, 0.00025],
            [580.027, 0.001],
            [581.874, 0.0028333333333333335],
            [583.098, 0.01],
            [584.577, 0.00045],
            [586.097, 0.00045],
            [586.4770000000001, 0.00025],
            [587.298, 0.0015],
            [589.5310000000001, 0.00025],
            [590.297, 0.00045],
            [590.9939999999999, 0.0002],
            [591.574, 0.00125],
            [592.53, 0.0002],
            [592.652, 0.00045],
            [594.272, 0.00075],
            [595.3489999999999, 0.00045],
            [595.384, 0.00045],
            [595.428, 0.0005],
            [596.376, 0.0015],
            [596.607, 0.0055],
            [596.71, 0.008],
            [596.8430000000001, 0.00025],
            [597.169, 0.0005],
            [597.275, 0.0028333333333333335],
            [598.047, 0.00025],
            [598.3140000000001, 0.00045],
            [598.3779999999999, 0.00045],
            [599.283, 0.004],
            [600.4359999999999, 0.001],
            [600.5609999999999, 0.00025],
            [601.22, 0.001],
            [601.2560000000001, 0.0018333333333333333],
            [601.558, 0.001],
            [601.8149999999999, 0.007],
            [602.3149999999999, 0.001],
            [602.9, 0.0028333333333333335],
            [604.466, 0.001],
            [604.951, 0.007],
            [605.736, 0.0023333333333333335],
            [607.558, 0.0015],
            [607.738, 0.0005],
            [608.384, 0.004],
            [609.9350000000001, 0.004],
            [610.8149999999999, 0.001],
            [611.8779999999999, 0.002],
            [612.467, 0.001],
            [617.3050000000001, 0.0055],
            [617.876, 0.0018333333333333333],
            [618.813, 0.004333333333333333],
            [619.507, 0.0023333333333333335],
            [620.76, 0.00025],
            [623.051, 0.00025],
            [623.3729999999999, 0.0015],
            [625.047, 0.0009166666666666666],
            [626.225, 0.004],
            [626.6949999999999, 0.0009166666666666666],
            [628.595, 0.00025],
            [629.134, 0.001],
            [629.9770000000001, 0.0028333333333333335],
            [630.341, 0.003833333333333333],
            [631.3779999999999, 0.0004],
            [631.858, 0.00025],
            [633.582, 0.00125],
            [635.004, 0.002],
            [635.589, 0.001],
            [636.925, 0.001],
            [638.2729999999999, 0.0009166666666666666],
            [638.386, 0.00125],
            [640.0930000000001, 0.002],
            [640.611, 0.0006666666666666666],
            [641.004, 0.003],
            [641.132, 0.0023333333333333335],
            [642.829, 0.0009166666666666666],
            [643.764, 0.013833333333333333],
            [643.993, 0.0003],
            [645.796, 0.002],
            [647.0699999999999, 0.0002],
            [648.302, 0.0003],
            [650.155, 0.00075],
            [651.9590000000001, 0.001],
            [652.272, 0.00025],
            [654.912, 0.00013333333333333334],
            [656.787, 0.00125],
            [659.379, 0.00075],
            [660.355, 0.0003],
            [664.511, 0.023333333333333334],
            [668.521, 0.0004333333333333333],
            [669.396, 0.0015833333333333333],
            [670.106, 0.00011666666666666667],
            [671.045, 0.0002],
            [674.488, 0.0005],
            [678.254, 0.0005],
            [678.7479999999999, 0.00023333333333333333],
            [680.272, 0.0023333333333333335],
            [681.606, 0.0005833333333333334],
            [683.4300000000001, 0.00018333333333333334],
            [684.0930000000001, 0.00028333333333333335],
            [684.483, 0.00028333333333333335],
            [684.704, 0.00023333333333333333],
            [686.454, 0.006],
            [689.821, 0.00035],
            [690.367, 0.001],
            [691.017, 0.00023333333333333333],
            [691.482, 0.0005],
            [483.79799999999994, 0.00016666666666666666],
            [666.635, 0.0008333333333333334],
        ],
    },
    {
        name: "gallium",
        lines: [
            [403.299, 0.005],
            [417.204, 0.005],
            [425.111, 0.002],
            [425.116, 0.0075],
            [425.404, 0.005],
            [425.564, 0.002],
            [425.57, 0.0025],
            [425.57700000000006, 0.005],
            [426.2, 0.02],
            [521.821, 0.0015],
            [533.83, 0.0005],
            [535.3489999999999, 0.001],
            [536.0600000000001, 0.001],
            [536.35, 0.0005],
            [541.6800000000001, 0.0015],
            [542.1600000000001, 0.0005],
            [542.5600000000001, 0.0005],
            [633.42, 0.005],
            [639.6560000000001, 1.0],
            [641.3439999999999, 0.5],
            [641.9399999999999, 0.0025],
            [645.63, 0.0015],
            [438.06899999999996, 0.05],
            [438.17600000000004, 0.075],
            [486.3, 0.05],
            [499.378, 0.075],
            [580.828, 0.005],
            [584.825, 0.01],
            [599.351, 0.0075],
        ],
    },
    {
        name: "gold",
        lines: [
            [401.257, 0.0033333333333333335],
            [401.607, 0.011111111111111112],
            [404.09299999999996, 0.4444444444444444],
            [405.279, 0.03333333333333333],
            [406.507, 0.7777777777777778],
            [407.635, 0.011111111111111112],
            [408.32800000000003, 0.0033333333333333335],
            [408.40999999999997, 0.1111111111111111],
            [410.16999999999996, 0.03333333333333333],
            [412.85900000000004, 0.03333333333333333],
            [420.113, 0.03333333333333333],
            [422.788, 0.03333333333333333],
            [424.18, 0.1111111111111111],
            [431.51099999999997, 0.2222222222222222],
            [436.104, 0.03333333333333333],
            [442.061, 0.011111111111111112],
            [443.72700000000003, 0.13333333333333333],
            [448.825, 0.2777777777777778],
            [460.75100000000003, 1.0],
            [462.05600000000004, 0.1111111111111111],
            [466.392, 0.0011111111111111111],
            [466.39700000000005, 0.0033333333333333335],
            [469.46899999999994, 0.011111111111111112],
            [476.017, 0.0033333333333333335],
            [479.258, 0.5555555555555556],
            [481.16, 0.1111111111111111],
            [482.296, 0.011111111111111112],
            [495.082, 0.03333333333333333],
            [506.459, 0.03333333333333333],
            [510.884, 0.03333333333333333],
            [514.7439999999999, 0.1111111111111111],
            [523.0260000000001, 0.3333333333333333],
            [526.176, 0.1111111111111111],
            [565.577, 0.1111111111111111],
            [572.136, 0.1111111111111111],
            [583.737, 0.3333333333333333],
            [586.293, 0.1111111111111111],
            [595.696, 0.3333333333333333],
            [596.268, 0.03333333333333333],
            [627.817, 0.6666666666666666],
            [656.268, 0.1111111111111111],
        ],
    },
    {
        name: "hafnium",
        lines: [
            [403.227, 0.16363636363636364],
            [404.796, 0.09090909090909091],
            [406.284, 0.20909090909090908],
            [406.621, 0.12727272727272726],
            [408.335, 0.16363636363636364],
            [409.316, 0.4909090909090909],
            [410.42299999999994, 0.1],
            [410.658, 0.12727272727272726],
            [411.35299999999995, 0.1],
            [411.86, 0.1],
            [412.78000000000003, 0.13636363636363635],
            [414.576, 0.12727272727272726],
            [416.236, 0.13636363636363635],
            [416.26899999999995, 0.1],
            [417.434, 1.0],
            [419.09499999999997, 0.10909090909090909],
            [420.658, 0.14545454545454545],
            [420.96999999999997, 0.17272727272727273],
            [422.808, 0.15454545454545454],
            [423.24399999999997, 0.15454545454545454],
            [425.20799999999997, 0.10909090909090909],
            [426.09799999999996, 0.15454545454545454],
            [426.33900000000006, 0.18181818181818182],
            [427.285, 0.15454545454545454],
            [429.479, 0.2909090909090909],
            [431.814, 0.10909090909090909],
            [433.02700000000004, 0.14545454545454545],
            [433.666, 0.16363636363636364],
            [435.05100000000004, 0.13636363636363635],
            [435.633, 0.22727272727272727],
            [436.78999999999996, 0.1],
            [437.09700000000004, 0.16363636363636364],
            [441.735, 0.10909090909090909],
            [441.791, 0.14545454545454545],
            [443.804, 0.18181818181818182],
            [445.73400000000004, 0.12727272727272726],
            [446.11800000000005, 0.12727272727272726],
            [454.093, 0.12727272727272726],
            [456.59399999999994, 0.22727272727272727],
            [459.88, 0.45454545454545453],
            [462.08599999999996, 0.20909090909090908],
            [465.51899999999995, 0.19090909090909092],
            [469.901, 0.10909090909090909],
            [478.274, 0.14545454545454545],
            [480.05, 0.2818181818181818],
            [485.924, 0.11818181818181818],
            [497.525, 0.10909090909090909],
            [501.82, 0.08636363636363636],
            [502.175, 0.013636363636363636],
            [504.082, 0.05],
            [504.745, 0.08636363636363636],
            [507.474, 0.05],
            [507.965, 0.02727272727272727],
            [509.38800000000003, 0.05],
            [511.213, 0.013636363636363636],
            [512.853, 0.017272727272727273],
            [515.796, 0.02727272727272727],
            [516.742, 0.05],
            [517.018, 0.06818181818181818],
            [518.1859999999999, 0.20909090909090908],
            [518.684, 0.02727272727272727],
            [518.775, 0.02727272727272727],
            [524.399, 0.1],
            [524.71, 0.05],
            [526.044, 0.022727272727272728],
            [526.495, 0.02727272727272727],
            [527.504, 0.05],
            [528.609, 0.02],
            [529.487, 0.10909090909090909],
            [529.806, 0.04090909090909091],
            [530.7819999999999, 0.02727272727272727],
            [530.9680000000001, 0.04090909090909091],
            [531.1600000000001, 0.05],
            [532.426, 0.01090909090909091],
            [534.63, 0.008181818181818182],
            [535.473, 0.1],
            [537.386, 0.1],
            [538.934, 0.03636363636363636],
            [539.136, 0.017272727272727273],
            [540.447, 0.017272727272727273],
            [542.402, 0.025454545454545455],
            [543.578, 0.01090909090909091],
            [543.874, 0.03636363636363636],
            [544.4069999999999, 0.012727272727272728],
            [545.292, 0.06818181818181818],
            [546.338, 0.02727272727272727],
            [549.73, 0.013636363636363636],
            [551.012, 0.013636363636363636],
            [551.045, 0.013636363636363636],
            [552.4350000000001, 0.017272727272727273],
            [553.802, 0.04090909090909091],
            [553.826, 0.025454545454545455],
            [555.0600000000001, 0.20909090909090908],
            [555.212, 0.20909090909090908],
            [557.586, 0.05],
            [560.077, 0.012727272727272728],
            [561.327, 0.08636363636363636],
            [561.4010000000001, 0.022727272727272728],
            [562.827, 0.007272727272727273],
            [565.083, 0.017272727272727273],
            [569.803, 0.03636363636363636],
            [571.328, 0.022727272727272728],
            [571.918, 0.14545454545454545],
            [572.016, 0.022727272727272728],
            [574.8720000000001, 0.01090909090909091],
            [576.7180000000001, 0.012727272727272728],
            [580.95, 0.01090909090909091],
            [581.7470000000001, 0.017272727272727273],
            [584.223, 0.022727272727272728],
            [584.587, 0.022727272727272728],
            [584.777, 0.017272727272727273],
            [588.366, 0.02],
            [592.647, 0.013636363636363636],
            [593.3689999999999, 0.05454545454545454],
            [597.428, 0.06818181818181818],
            [597.472, 0.022727272727272728],
            [597.866, 0.05454545454545454],
            [599.296, 0.022727272727272728],
            [601.679, 0.04090909090909091],
            [602.112, 0.025454545454545455],
            [604.319, 0.022727272727272728],
            [605.417, 0.022727272727272728],
            [609.867, 0.08636363636363636],
            [618.513, 0.08636363636363636],
            [621.0699999999999, 0.05],
            [621.682, 0.025454545454545455],
            [623.858, 0.04090909090909091],
            [624.895, 0.05454545454545454],
            [629.954, 0.02],
            [631.1850000000001, 0.022727272727272728],
            [631.833, 0.017272727272727273],
            [633.8100000000001, 0.02727272727272727],
            [638.019, 0.017272727272727273],
            [638.6229999999999, 0.05454545454545454],
            [640.952, 0.017272727272727273],
            [655.65, 0.013636363636363636],
            [658.723, 0.025454545454545455],
            [664.46, 0.04090909090909091],
            [664.706, 0.017272727272727273],
            [665.9399999999999, 0.01],
            [671.348, 0.02727272727272727],
            [675.461, 0.015454545454545455],
            [676.995, 0.01],
            [678.927, 0.07727272727272727],
            [681.894, 0.14545454545454545],
            [682.6560000000001, 0.013636363636363636],
            [685.007, 0.011818181818181818],
            [685.87, 0.031818181818181815],
            [691.14, 0.04090909090909091],
            [692.6189999999999, 0.00909090909090909],
            [697.9590000000001, 0.017272727272727273],
            [698.091, 0.019090909090909092],
        ],
    },
    {
        name: "holmium",
        lines: [
            [400.259, 0.017977528089887642],
            [400.339, 0.024719101123595506],
            [401.35, 0.012359550561797753],
            [401.41999999999996, 0.035955056179775284],
            [401.809, 0.017977528089887642],
            [402.276, 0.017977528089887642],
            [402.394, 0.017977528089887642],
            [402.539, 0.012359550561797753],
            [402.721, 0.035955056179775284],
            [402.886, 0.030337078651685393],
            [403.18, 0.020224719101123594],
            [403.762, 0.024719101123595506],
            [403.887, 0.024719101123595506],
            [404.081, 0.30337078651685395],
            [404.544, 0.6067415730337079],
            [404.752, 0.024719101123595506],
            [405.393, 0.9101123595505618],
            [405.448, 0.060674157303370786],
            [405.755, 0.030337078651685393],
            [406.031, 0.024719101123595506],
            [406.509, 0.19101123595505617],
            [406.757, 0.019101123595505618],
            [406.805, 0.08089887640449438],
            [407.183, 0.030337078651685393],
            [407.313, 0.030337078651685393],
            [407.351, 0.03258426966292135],
            [408.023, 0.01348314606741573],
            [408.367, 0.025842696629213482],
            [408.509, 0.015730337078651686],
            [408.735, 0.019101123595505618],
            [408.759, 0.02247191011235955],
            [409.164, 0.015730337078651686],
            [409.478, 0.01348314606741573],
            [410.02200000000005, 0.025842696629213482],
            [410.384, 1.0],
            [410.504, 0.01348314606741573],
            [410.65, 0.030337078651685393],
            [410.736, 0.011235955056179775],
            [410.86199999999997, 0.3258426966292135],
            [411.2, 0.033707865168539325],
            [411.27200000000005, 0.011235955056179775],
            [411.67299999999994, 0.030337078651685393],
            [412.02, 0.16853932584269662],
            [412.56499999999994, 0.14606741573033707],
            [412.716, 0.48314606741573035],
            [413.454, 0.033707865168539325],
            [413.622, 0.16853932584269662],
            [413.934, 0.014606741573033709],
            [414.21899999999994, 0.025842696629213482],
            [414.89700000000005, 0.03258426966292135],
            [415.26099999999997, 0.1101123595505618],
            [416.303, 0.9101123595505618],
            [417.22299999999996, 0.017977528089887642],
            [417.323, 0.2808988764044944],
            [419.43500000000006, 0.060674157303370786],
            [419.808, 0.011235955056179775],
            [420.321, 0.014606741573033709],
            [421.13, 0.011235955056179775],
            [422.229, 0.03258426966292135],
            [422.34700000000004, 0.03258426966292135],
            [422.704, 0.2247191011235955],
            [422.95200000000006, 0.043820224719101124],
            [423.12399999999997, 0.014606741573033709],
            [424.378, 0.03258426966292135],
            [425.44300000000004, 0.14606741573033707],
            [425.861, 0.014606741573033709],
            [426.40500000000003, 0.0550561797752809],
            [426.604, 0.033707865168539325],
            [427.363, 0.011235955056179775],
            [431.104, 0.02247191011235955],
            [433.064, 0.028089887640449437],
            [433.713, 0.033707865168539325],
            [434.684, 0.011235955056179775],
            [435.073, 0.14606741573033707],
            [435.67299999999994, 0.03258426966292135],
            [436.39300000000003, 0.015730337078651686],
            [437.91400000000004, 0.019101123595505618],
            [438.483, 0.020224719101123594],
            [440.055, 0.016853932584269662],
            [440.12399999999997, 0.01348314606741573],
            [440.32700000000006, 0.020224719101123594],
            [442.05600000000004, 0.02247191011235955],
            [444.463, 0.014606741573033709],
            [447.35900000000004, 0.011235955056179775],
            [447.764, 0.033707865168539325],
            [448.457, 0.01348314606741573],
            [451.082, 0.015730337078651686],
            [452.61400000000003, 0.011235955056179775],
            [453.008, 0.019101123595505618],
            [453.128, 0.019101123595505618],
            [453.16499999999996, 0.014606741573033709],
            [453.45799999999997, 0.019101123595505618],
            [456.25200000000007, 0.02247191011235955],
            [460.93199999999996, 0.01348314606741573],
            [461.337, 0.014606741573033709],
            [461.884, 0.011235955056179775],
            [462.822, 0.011235955056179775],
            [462.91, 0.03258426966292135],
            [464.97700000000003, 0.02247191011235955],
            [466.133, 0.014606741573033709],
            [467.462, 0.015730337078651686],
            [470.117, 0.007865168539325843],
            [470.169, 0.008988764044943821],
            [470.98400000000004, 0.014606741573033709],
            [471.139, 0.007303370786516854],
            [471.75200000000007, 0.014606741573033709],
            [472.872, 0.003932584269662922],
            [473.8, 0.003932584269662922],
            [474.204, 0.03258426966292135],
            [474.909, 0.003932584269662922],
            [475.14, 0.003932584269662922],
            [475.701, 0.011235955056179775],
            [476.23900000000003, 0.003932584269662922],
            [476.35699999999997, 0.003932584269662922],
            [477.74799999999993, 0.006179775280898876],
            [477.942, 0.0033707865168539327],
            [478.11899999999997, 0.007865168539325843],
            [478.29200000000003, 0.007303370786516854],
            [478.629, 0.006179775280898876],
            [479.14799999999997, 0.003932584269662922],
            [479.592, 0.003932584269662922],
            [479.887, 0.0050561797752808986],
            [481.29200000000003, 0.0030337078651685393],
            [483.23100000000005, 0.006179775280898876],
            [483.332, 0.0033707865168539327],
            [485.554, 0.0033707865168539327],
            [486.03900000000004, 0.0050561797752808986],
            [488.967, 0.0030337078651685393],
            [489.235, 0.0033707865168539327],
            [489.64399999999995, 0.003932584269662922],
            [490.69899999999996, 0.006179775280898876],
            [492.27299999999997, 0.0050561797752808986],
            [493.48900000000003, 0.006179775280898876],
            [493.901, 0.03258426966292135],
            [494.68, 0.0030337078651685393],
            [494.81800000000004, 0.0050561797752808986],
            [495.942, 0.007303370786516854],
            [496.10299999999995, 0.003932584269662922],
            [496.67299999999994, 0.006179775280898876],
            [496.721, 0.028089887640449437],
            [497.997, 0.024719101123595506],
            [498.896, 0.003932584269662922],
            [499.505, 0.010112359550561797],
            [501.242, 0.003932584269662922],
            [501.328, 0.006179775280898876],
            [502.65299999999996, 0.007303370786516854],
            [502.817, 0.0033707865168539327],
            [503.29499999999996, 0.006179775280898876],
            [503.76000000000005, 0.007303370786516854],
            [504.23699999999997, 0.014606741573033709],
            [504.47299999999996, 0.003932584269662922],
            [505.14399999999995, 0.0033707865168539327],
            [505.492, 0.0033707865168539327],
            [506.075, 0.003932584269662922],
            [507.434, 0.007303370786516854],
            [509.30699999999996, 0.008988764044943821],
            [512.7810000000001, 0.015730337078651686],
            [512.927, 0.006179775280898876],
            [514.259, 0.014606741573033709],
            [514.322, 0.012359550561797753],
            [514.9590000000001, 0.017977528089887642],
            [516.788, 0.010112359550561797],
            [518.211, 0.014606741573033709],
            [518.7850000000001, 0.006179775280898876],
            [519.011, 0.010112359550561797],
            [519.5229999999999, 0.0020224719101123597],
            [522.154, 0.0050561797752808986],
            [524.447, 0.003932584269662922],
            [525.182, 0.007303370786516854],
            [527.548, 0.006179775280898876],
            [530.125, 0.010112359550561797],
            [531.924, 0.003932584269662922],
            [531.9649999999999, 0.003932584269662922],
            [533.011, 0.008988764044943821],
            [535.999, 0.010112359550561797],
            [538.14, 0.006179775280898876],
            [538.456, 0.0033707865168539327],
            [538.4970000000001, 0.0033707865168539327],
            [539.385, 0.0020224719101123597],
            [540.317, 0.007865168539325843],
            [540.708, 0.011235955056179775],
            [541.362, 0.0015730337078651685],
            [543.4390000000001, 0.0017977528089887641],
            [543.587, 0.0020224719101123597],
            [544.539, 0.0033707865168539327],
            [544.98, 0.0020224719101123597],
            [545.1899999999999, 0.0033707865168539327],
            [545.4, 0.0015730337078651685],
            [549.857, 0.0033707865168539327],
            [550.451, 0.0033707865168539327],
            [551.556, 0.0030337078651685393],
            [551.645, 0.0020224719101123597],
            [553.433, 0.0033707865168539327],
            [555.3140000000001, 0.0030337078651685393],
            [556.0939999999999, 0.003932584269662922],
            [556.36, 0.003932584269662922],
            [556.652, 0.007865168539325843],
            [557.396, 0.0020224719101123597],
            [558.47, 0.003932584269662922],
            [559.11, 0.006179775280898876],
            [559.23, 0.006179775280898876],
            [560.71, 0.0033707865168539327],
            [561.364, 0.0030337078651685393],
            [562.64, 0.0050561797752808986],
            [562.76, 0.007303370786516854],
            [562.824, 0.0033707865168539327],
            [564.062, 0.006179775280898876],
            [565.5899999999999, 0.007865168539325843],
            [565.89, 0.007303370786516854],
            [565.958, 0.015730337078651686],
            [567.184, 0.007865168539325843],
            [567.47, 0.007303370786516854],
            [569.147, 0.015730337078651686],
            [569.63, 0.007865168539325843],
            [569.6569999999999, 0.015730337078651686],
            [573.402, 0.0030337078651685393],
            [573.64, 0.0050561797752808986],
            [573.924, 0.006179775280898876],
            [574.958, 0.0024719101123595504],
            [576.664, 0.0033707865168539327],
            [580.38, 0.0030337078651685393],
            [581.92, 0.0050561797752808986],
            [582.1899999999999, 0.0030337078651685393],
            [583.947, 0.0024719101123595504],
            [584.9399999999999, 0.0050561797752808986],
            [586.028, 0.015730337078651686],
            [586.442, 0.0030337078651685393],
            [587.085, 0.0050561797752808986],
            [587.96, 0.0030337078651685393],
            [588.299, 0.007865168539325843],
            [589.2560000000001, 0.003932584269662922],
            [590.429, 0.0024719101123595504],
            [592.176, 0.007865168539325843],
            [593.371, 0.0033707865168539327],
            [594.803, 0.007865168539325843],
            [595.598, 0.0050561797752808986],
            [597.2760000000001, 0.007865168539325843],
            [597.3520000000001, 0.010112359550561797],
            [598.143, 0.0024719101123595504],
            [598.29, 0.025842696629213482],
            [600.204, 0.006179775280898876],
            [600.533, 0.0030337078651685393],
            [602.143, 0.003932584269662922],
            [603.897, 0.0017977528089887641],
            [605.071, 0.0030337078651685393],
            [606.0310000000001, 0.0050561797752808986],
            [608.179, 0.01348314606741573],
            [613.36, 0.007865168539325843],
            [615.638, 0.003932584269662922],
            [615.658, 0.0030337078651685393],
            [619.168, 0.006179775280898876],
            [620.865, 0.007865168539325843],
            [623.417, 0.0020224719101123597],
            [625.575, 0.0050561797752808986],
            [630.536, 0.007865168539325843],
            [630.668, 0.0024719101123595504],
            [632.194, 0.0033707865168539327],
            [635.4350000000001, 0.0033707865168539327],
            [637.259, 0.0033707865168539327],
            [637.386, 0.0015730337078651685],
            [641.341, 0.0024719101123595504],
            [647.177, 0.0030337078651685393],
            [647.917, 0.0014606741573033708],
            [651.53, 0.0012359550561797752],
            [653.899, 0.0012359550561797752],
            [655.097, 0.007865168539325843],
            [656.008, 0.0016853932584269663],
            [660.058, 0.003932584269662922],
            [660.4939999999999, 0.029213483146067417],
            [660.7470000000001, 0.006179775280898876],
            [662.835, 0.0014606741573033708],
            [662.899, 0.01348314606741573],
            [663.2239999999999, 0.0016853932584269663],
            [665.298, 0.0010112359550561798],
            [666.2520000000001, 0.0016853932584269663],
            [668.046, 0.0021348314606741575],
            [668.162, 0.002696629213483146],
            [668.202, 0.0016853932584269663],
            [669.432, 0.006179775280898876],
            [672.234, 0.0016853932584269663],
            [674.505, 0.0044943820224719105],
            [676.674, 0.0014606741573033708],
            [677.4680000000001, 0.003146067415730337],
            [678.543, 0.006179775280898876],
            [679.37, 0.0014606741573033708],
            [681.104, 0.0014606741573033708],
            [682.038, 0.0016853932584269663],
            [682.164, 0.002696629213483146],
            [682.572, 0.001910112359550562],
            [682.662, 0.0008988764044943821],
            [685.297, 0.0008988764044943821],
            [686.585, 0.001910112359550562],
            [688.336, 0.0010112359550561798],
            [688.85, 0.0014606741573033708],
            [689.296, 0.0016853932584269663],
            [689.795, 0.001910112359550562],
            [690.38, 0.0016853932584269663],
            [691.347, 0.0016853932584269663],
            [691.67, 0.0010112359550561798],
            [693.949, 0.0044943820224719105],
            [695.039, 0.0050561797752808986],
            [695.53, 0.0014606741573033708],
            [697.67, 0.0021348314606741575],
            [698.511, 0.0011235955056179776],
            [699.438, 0.0010112359550561798],
        ],
    },
    {
        name: "indium",
        lines: [
            [400.466, 0.006666666666666667],
            [401.392, 0.0077777777777777776],
            [405.694, 0.02277777777777778],
            [410.17600000000004, 0.9444444444444444],
            [420.514, 0.0077777777777777776],
            [421.304, 0.005555555555555556],
            [421.966, 0.006111111111111111],
            [437.287, 0.008333333333333333],
            [450.078, 0.008333333333333333],
            [451.13100000000003, 1.0],
            [454.901, 0.006111111111111111],
            [457.08500000000004, 0.0077777777777777776],
            [457.802, 0.01],
            [457.84, 0.01],
            [461.608, 0.0077777777777777776],
            [461.717, 0.009444444444444445],
            [462.014, 0.013888888888888888],
            [462.07, 0.008333333333333333],
            [462.73, 0.009444444444444445],
            [463.704, 0.0077777777777777776],
            [463.816, 0.021111111111111112],
            [464.45799999999997, 0.012222222222222223],
            [465.562, 0.02],
            [465.674, 0.017777777777777778],
            [468.111, 0.010555555555555556],
            [468.48, 0.025],
            [487.837, 0.00016666666666666666],
            [490.706, 0.005],
            [492.49300000000005, 0.0038888888888888888],
            [497.37700000000007, 0.008333333333333333],
            [510.936, 0.0044444444444444444],
            [511.514, 0.005555555555555556],
            [511.73999999999995, 0.0077777777777777776],
            [512.08, 0.015],
            [512.175, 0.011111111111111112],
            [512.985, 0.0044444444444444444],
            [517.542, 0.013333333333333334],
            [518.444, 0.0077777777777777776],
            [525.432, 0.0016666666666666668],
            [526.274, 0.0006666666666666666],
            [530.9449999999999, 0.008333333333333333],
            [541.141, 0.0044444444444444444],
            [541.845, 0.0077777777777777776],
            [543.67, 0.012222222222222223],
            [549.75, 0.007222222222222222],
            [550.708, 0.0077777777777777776],
            [551.3, 0.017777777777777778],
            [552.328, 0.013888888888888888],
            [553.65, 0.007222222222222222],
            [555.545, 0.010555555555555556],
            [557.6899999999999, 0.013333333333333334],
            [563.67, 0.011111111111111112],
            [570.85, 0.008888888888888889],
            [570.991, 0.002777777777777778],
            [572.1800000000001, 0.005555555555555556],
            [572.768, 0.002777777777777778],
            [585.3149999999999, 0.011666666666666667],
            [590.3399999999999, 0.02722222222222222],
            [591.54, 0.014444444444444444],
            [591.8779999999999, 0.006666666666666667],
            [606.29, 0.007222222222222222],
            [609.595, 0.013888888888888888],
            [610.866, 0.011666666666666667],
            [611.5899999999999, 0.01],
            [612.87, 0.012777777777777779],
            [612.9399999999999, 0.013333333333333334],
            [613.21, 0.017777777777777778],
            [614.0, 0.008333333333333333],
            [614.323, 0.005],
            [614.8100000000001, 0.0077777777777777776],
            [614.95, 0.010555555555555556],
            [616.115, 0.0044444444444444444],
            [616.245, 0.01],
            [622.428, 0.005555555555555556],
            [622.83, 0.015555555555555555],
            [623.11, 0.0077777777777777776],
            [630.48, 0.015],
            [636.23, 0.01611111111111111],
            [646.9, 0.016666666666666666],
            [654.12, 0.011666666666666667],
            [675.188, 0.010555555555555556],
            [676.5899999999999, 0.01],
            [678.3720000000001, 0.005555555555555556],
            [684.7439999999999, 0.00044444444444444447],
            [689.15, 0.017777777777777778],
            [690.013, 0.00022222222222222223],
            [402.377, 0.005555555555555556],
            [403.232, 0.008333333333333333],
            [406.23, 0.002777777777777778],
            [407.15700000000004, 0.005555555555555556],
            [407.293, 0.005555555555555556],
            [425.26800000000003, 0.005555555555555556],
            [450.95799999999997, 0.0022222222222222222],
            [524.8770000000001, 0.011111111111111112],
            [564.515, 0.005555555555555556],
            [572.317, 0.0022222222222222222],
            [581.95, 0.005555555555555556],
            [619.772, 0.011111111111111112],
        ],
    },
    {
        name: "iridium",
        lines: [
            [402.00300000000004, 0.4864864864864865],
            [403.37600000000003, 0.9459459459459459],
            [404.008, 0.35135135135135137],
            [406.992, 1.0],
            [407.068, 0.40540540540540543],
            [409.261, 0.2702702702702703],
            [411.578, 0.3783783783783784],
            [412.79200000000003, 0.062162162162162166],
            [415.57, 0.07297297297297298],
            [416.604, 0.04054054054054054],
            [417.25600000000003, 0.24324324324324326],
            [418.247, 0.0945945945945946],
            [418.321, 0.04054054054054054],
            [418.566, 0.04864864864864865],
            [419.754, 0.062162162162162166],
            [421.776, 0.07297297297297298],
            [422.08000000000004, 0.03513513513513514],
            [425.91099999999994, 0.20270270270270271],
            [426.53000000000003, 0.07297297297297298],
            [426.81000000000006, 0.7027027027027027],
            [428.662, 0.062162162162162166],
            [430.16, 0.20270270270270271],
            [431.059, 0.14864864864864866],
            [431.15, 0.5945945945945946],
            [435.13, 0.04864864864864865],
            [435.25600000000003, 0.04864864864864865],
            [439.259, 0.04864864864864865],
            [439.947, 0.43243243243243246],
            [440.378, 0.17567567567567569],
            [442.62700000000007, 0.2972972972972973],
            [445.01800000000003, 0.04054054054054054],
            [447.84799999999996, 0.14864864864864866],
            [449.535, 0.043243243243243246],
            [449.60299999999995, 0.02972972972972973],
            [454.56800000000004, 0.14864864864864866],
            [454.84799999999996, 0.08108108108108109],
            [455.078, 0.03513513513513514],
            [456.809, 0.0945945945945946],
            [457.00200000000007, 0.04864864864864865],
            [460.448, 0.04864864864864865],
            [461.639, 0.20270270270270271],
            [465.61800000000005, 0.07027027027027027],
            [466.899, 0.04594594594594595],
            [470.88800000000003, 0.05675675675675676],
            [472.88599999999997, 0.13513513513513514],
            [473.186, 0.05675675675675676],
            [475.646, 0.07027027027027027],
            [475.796, 0.03513513513513514],
            [477.816, 0.17567567567567569],
            [479.567, 0.08108108108108109],
            [480.71400000000006, 0.02702702702702703],
            [480.947, 0.05675675675675676],
            [484.07700000000006, 0.02702702702702703],
            [484.538, 0.04594594594594595],
            [493.809, 0.13513513513513514],
            [497.04799999999994, 0.07027027027027027],
            [499.974, 0.06756756756756757],
            [500.274, 0.06756756756756757],
            [500.91700000000003, 0.04594594594594595],
            [501.49799999999993, 0.08108108108108109],
            [504.60600000000005, 0.04594594594594595],
            [512.366, 0.08108108108108109],
            [517.795, 0.05405405405405406],
            [523.892, 0.05945945945945946],
            [534.074, 0.032432432432432434],
            [536.432, 0.0945945945945946],
            [544.95, 0.20270270270270271],
            [545.45, 0.08108108108108109],
            [546.9399999999999, 0.01891891891891892],
            [562.004, 0.02702702702702703],
            [562.5550000000001, 0.12162162162162163],
            [582.855, 0.02702702702702703],
            [588.23, 0.02702702702702703],
            [588.736, 0.01891891891891892],
            [589.4060000000001, 0.0945945945945946],
            [602.61, 0.01891891891891892],
            [606.783, 0.032432432432432434],
            [611.067, 0.05405405405405406],
            [628.828, 0.032432432432432434],
            [633.444, 0.01891891891891892],
            [662.473, 0.013513513513513514],
            [668.608, 0.02702702702702703],
            [683.001, 0.013513513513513514],
            [692.988, 0.013513513513513514],
        ],
    },
    {
        name: "krypton",
        lines: [
            [390.618, 0.05],
            [392.008, 0.06666666666666667],
            [399.48400000000004, 0.03333333333333333],
            [399.779, 0.03333333333333333],
            [405.704, 0.1],
            [406.51300000000003, 0.1],
            [408.834, 0.16666666666666666],
            [409.87299999999993, 0.08333333333333333],
            [410.925, 0.03333333333333333],
            [414.512, 0.08333333333333333],
            [425.058, 0.05],
            [427.39700000000005, 0.3333333333333333],
            [428.297, 0.03333333333333333],
            [429.29200000000003, 0.2],
            [430.049, 0.06666666666666667],
            [431.78100000000006, 0.16666666666666666],
            [431.855, 0.13333333333333333],
            [431.95799999999997, 0.3333333333333333],
            [432.29799999999994, 0.05],
            [435.13599999999997, 0.03333333333333333],
            [435.54799999999994, 1.0],
            [436.264, 0.16666666666666666],
            [436.96899999999994, 0.06666666666666667],
            [437.61199999999997, 0.26666666666666666],
            [438.654, 0.1],
            [439.997, 0.06666666666666667],
            [442.51899999999995, 0.03333333333333333],
            [443.169, 0.16666666666666666],
            [443.68100000000004, 0.2],
            [445.392, 0.2],
            [446.36899999999997, 0.26666666666666666],
            [447.50100000000003, 0.26666666666666666],
            [448.988, 0.13333333333333333],
            [450.235, 0.2],
            [452.314, 0.13333333333333333],
            [455.66099999999994, 0.06666666666666667],
            [457.721, 0.26666666666666666],
            [458.29799999999994, 0.1],
            [459.28000000000003, 0.05],
            [461.529, 0.16666666666666666],
            [461.91700000000003, 0.3333333333333333],
            [463.389, 0.26666666666666666],
            [465.88800000000003, 0.6666666666666666],
            [468.03999999999996, 0.16666666666666666],
            [469.13, 0.03333333333333333],
            [469.436, 0.06666666666666667],
            [473.9, 1.0],
            [476.24399999999997, 0.1],
            [476.57399999999996, 0.3333333333333333],
            [481.17600000000004, 0.1],
            [482.51800000000003, 0.1],
            [483.20799999999997, 0.26666666666666666],
            [484.66099999999994, 0.23333333333333334],
            [485.71999999999997, 0.05],
            [494.559, 0.1],
            [502.23999999999995, 0.06666666666666667],
            [508.65200000000004, 0.08333333333333333],
            [512.573, 0.13333333333333333],
            [520.832, 0.16666666666666666],
            [530.866, 0.06666666666666667],
            [533.341, 0.16666666666666666],
            [546.817, 0.06666666666666667],
            [556.222, 0.16666666666666666],
            [557.029, 0.6666666666666666],
            [558.039, 0.02666666666666667],
            [564.956, 0.03333333333333333],
            [568.1890000000001, 0.13333333333333333],
            [569.0350000000001, 0.06666666666666667],
            [583.286, 0.03333333333333333],
            [587.091, 1.0],
            [599.222, 0.06666666666666667],
            [599.385, 0.02],
            [605.613, 0.02],
            [642.018, 0.1],
            [642.103, 0.03333333333333333],
            [645.629, 0.06666666666666667],
            [657.007, 0.05],
            [669.923, 0.02],
            [690.4680000000001, 0.03333333333333333],
        ],
    },
    {
        name: "lithium",
        lines: [
            [413.25600000000003, 0.011111111111111112],
            [413.262, 0.011111111111111112],
            [427.30699999999996, 0.005555555555555556],
            [427.313, 0.005555555555555556],
            [432.54200000000003, 0.001388888888888889],
            [432.547, 0.001388888888888889],
            [432.554, 0.0002777777777777778],
            [460.283, 0.003611111111111111],
            [460.28900000000004, 0.003611111111111111],
            [467.16499999999996, 0.0016666666666666668],
            [467.16999999999996, 0.0005555555555555556],
            [467.80600000000004, 0.0008333333333333334],
            [467.829, 0.0002777777777777778],
            [488.13199999999995, 0.0011111111111111111],
            [488.139, 0.0011111111111111111],
            [488.149, 0.0002777777777777778],
            [497.166, 0.0022222222222222222],
            [497.175, 0.0022222222222222222],
            [548.355, 0.16666666666666666],
            [548.5649999999999, 0.16666666666666666],
            [610.354, 0.08888888888888889],
            [610.365, 0.08888888888888889],
            [670.7760000000001, 1.0],
            [670.7909999999999, 1.0],
        ],
    },
    {
        name: "mercury",
        lines: [
            [403.62600000000003, 0.004615384615384616],
            [408.377, 0.003076923076923077],
            [408.486, 0.00046153846153846153],
            [413.066, 0.023076923076923078],
            [413.24300000000005, 0.0003076923076923077],
            [416.6, 0.003076923076923077],
            [421.604, 0.007692307692307693],
            [426.79499999999996, 0.012307692307692308],
            [428.31000000000006, 0.0015384615384615385],
            [428.78000000000003, 0.004615384615384616],
            [429.76000000000005, 0.003076923076923077],
            [430.93199999999996, 0.012307692307692308],
            [432.3, 0.0003076923076923077],
            [432.573, 0.009230769230769232],
            [432.674, 0.003076923076923077],
            [432.962, 0.004615384615384616],
            [435.033, 0.0012307692307692308],
            [440.254, 0.0009230769230769231],
            [440.52299999999997, 0.006153846153846154],
            [443.189, 0.0006153846153846154],
            [448.89799999999997, 0.0009230769230769231],
            [449.36400000000003, 0.0007692307692307692],
            [450.592, 0.0006153846153846154],
            [450.963, 0.003076923076923077],
            [452.317, 0.0009230769230769231],
            [452.49300000000005, 0.002],
            [455.40299999999996, 1.0],
            [457.38500000000005, 0.0006153846153846154],
            [457.96400000000006, 0.0012307692307692308],
            [459.975, 0.00046153846153846153],
            [461.992, 0.0003076923076923077],
            [462.83299999999997, 0.0003846153846153846],
            [464.41, 0.004615384615384616],
            [467.36199999999997, 0.00046153846153846153],
            [469.162, 0.0005384615384615384],
            [470.043, 0.0003076923076923077],
            [470.89399999999995, 0.012307692307692308],
            [472.64399999999995, 0.0006153846153846154],
            [484.346, 0.012307692307692308],
            [484.71400000000006, 0.004615384615384616],
            [485.084, 0.003076923076923077],
            [487.765, 0.00046153846153846153],
            [489.997, 0.006153846153846154],
            [490.28999999999996, 0.00023076923076923076],
            [493.409, 0.3076923076923077],
            [494.735, 0.00012307692307692307],
            [495.715, 0.015384615384615385],
            [499.78100000000006, 0.004615384615384616],
            [501.3, 0.015384615384615385],
            [515.9939999999999, 0.0003076923076923077],
            [526.703, 0.0003076923076923077],
            [536.135, 0.012307692307692308],
            [539.1600000000001, 0.015384615384615385],
            [542.105, 0.003076923076923077],
            [542.455, 0.0015384615384615385],
            [542.879, 0.003076923076923077],
            [548.03, 0.004615384615384616],
            [551.905, 0.003076923076923077],
            [553.548, 0.015384615384615385],
            [562.04, 0.0003076923076923077],
            [568.018, 0.00015384615384615385],
            [577.762, 0.006153846153846154],
            [578.418, 0.012307692307692308],
            [580.0229999999999, 0.0015384615384615385],
            [580.569, 0.0003076923076923077],
            [582.6279999999999, 0.002307692307692308],
            [585.368, 0.043076923076923075],
            [590.764, 0.00023076923076923076],
            [597.17, 0.0015384615384615385],
            [598.125, 0.012307692307692308],
            [599.7090000000001, 0.0015384615384615385],
            [599.985, 0.004615384615384616],
            [601.947, 0.0015384615384615385],
            [606.312, 0.003076923076923077],
            [611.078, 0.004615384615384616],
            [613.583, 0.006153846153846154],
            [614.172, 0.3076923076923077],
            [634.168, 0.002307692307692308],
            [637.891, 0.007692307692307693],
            [645.085, 0.0013846153846153845],
            [648.2909999999999, 0.002307692307692308],
            [649.6899999999999, 0.18461538461538463],
            [649.876, 0.004615384615384616],
            [652.731, 0.002307692307692308],
            [659.533, 0.046153846153846156],
            [665.4100000000001, 0.002307692307692308],
            [667.527, 0.023076923076923078],
            [669.384, 0.027692307692307693],
            [676.962, 0.015384615384615385],
            [686.569, 0.009230769230769232],
            [686.7850000000001, 0.004615384615384616],
            [687.409, 0.015384615384615385],
        ],
    },
    {
        name: "neon",
        lines: [
            [421.974, 0.3],
            [423.38500000000005, 0.2],
            [425.06499999999994, 0.24],
            [436.986, 0.24],
            [437.93999999999994, 0.14],
            [437.95500000000004, 0.3],
            [438.50600000000003, 0.2],
            [439.19899999999996, 0.4],
            [439.799, 0.3],
            [440.93, 0.3],
            [441.322, 0.2],
            [442.139, 0.2],
            [442.85200000000003, 0.2],
            [442.863, 0.2],
            [443.09, 0.3],
            [443.09399999999994, 0.3],
            [445.70500000000004, 0.24],
            [452.27200000000005, 0.2],
            [453.775, 0.02],
            [454.038, 0.02],
            [456.90600000000006, 0.2],
            [470.43999999999994, 0.03],
            [470.88599999999997, 0.024],
            [471.00699999999995, 0.02],
            [471.207, 0.02],
            [471.535, 0.03],
            [475.27299999999997, 0.02],
            [478.89300000000003, 0.024],
            [479.02200000000005, 0.02],
            [482.73400000000004, 0.02],
            [488.492, 0.02],
            [500.51599999999996, 0.008],
            [503.775, 0.02],
            [514.4939999999999, 0.02],
            [533.078, 0.05],
            [534.109, 0.04],
            [534.328, 0.016],
            [540.056, 0.12],
            [556.277, 0.01],
            [565.6659999999999, 0.02],
            [571.923, 0.01],
            [574.83, 0.024],
            [576.442, 0.16],
            [580.4449999999999, 0.024],
            [582.016, 0.08],
            [585.249, 1.0],
            [587.283, 0.2],
            [588.1899999999999, 0.2],
            [590.246, 0.12],
            [590.643, 0.12],
            [594.483, 0.2],
            [596.547, 0.2],
            [597.463, 0.2],
            [597.553, 0.24],
            [598.7909999999999, 0.16],
            [603.0, 0.2],
            [607.434, 0.2],
            [609.616, 0.16],
            [612.845, 0.12],
            [614.306, 0.2],
            [616.359, 0.24],
            [618.2149999999999, 0.5],
            [621.728, 0.3],
            [626.65, 0.3],
            [630.479, 0.12],
            [633.443, 0.2],
            [638.299, 0.24],
            [640.225, 0.4],
            [650.653, 0.3],
            [653.288, 0.12],
            [659.895, 0.3],
            [665.2090000000001, 0.14],
            [667.828, 0.18],
            [671.704, 0.04],
            [692.947, 0.2],
            [702.405, 0.18],
            [703.241, 0.2],
            [705.129, 0.1],
            [705.911, 0.16],
        ],
    },
    {
        name: "nickel",
        lines: [
            [440.15500000000003, 0.22],
            [445.904, 0.17],
            [446.246, 0.036],
            [447.04799999999994, 0.11],
            [459.253, 0.07],
            [460.037, 0.036],
            [460.5, 0.13],
            [460.62299999999993, 0.036],
            [464.866, 0.15],
            [468.622, 0.046],
            [471.442, 0.22],
            [471.578, 0.044],
            [475.65200000000004, 0.06],
            [476.395, 0.03],
            [478.654, 0.09],
            [480.7, 0.044],
            [482.90299999999996, 0.044],
            [483.11800000000005, 0.038],
            [485.541, 0.09],
            [486.62700000000007, 0.06],
            [487.34399999999994, 0.034],
            [490.441, 0.08],
            [491.83599999999996, 0.044],
            [493.58299999999997, 0.032],
            [498.01599999999996, 0.09],
            [498.413, 0.09],
            [499.20200000000006, 1.0],
            [500.034, 0.032],
            [501.246, 0.036],
            [501.759, 0.1],
            [503.537, 0.2],
            [504.88500000000005, 0.032],
            [508.052, 0.2],
            [508.111, 0.13],
            [508.408, 0.052],
            [509.93199999999996, 0.036],
            [509.995, 0.052],
            [511.53999999999996, 0.042],
            [512.938, 0.036],
            [513.708, 0.046],
            [514.277, 0.046],
            [514.6479999999999, 0.08],
            [515.576, 0.08],
            [516.866, 0.032],
            [517.6560000000001, 0.026],
            [543.587, 0.016],
            [547.691, 0.36],
            [551.0, 0.012],
            [557.8729999999999, 0.012],
            [558.786, 0.018],
            [559.228, 0.026],
            [561.479, 0.018],
            [562.533, 0.01],
            [564.97, 0.008],
            [566.402, 0.01],
            [568.22, 0.024],
            [569.5, 0.016],
            [570.956, 0.046],
            [571.1899999999999, 0.02],
            [571.509, 0.02],
            [575.4680000000001, 0.032],
            [576.085, 0.016],
            [585.7760000000001, 0.02],
            [589.288, 0.02],
            [610.812, 0.02],
            [617.681, 0.02],
            [619.118, 0.02],
            [625.636, 0.026],
            [631.466, 0.02],
            [664.364, 0.032],
            [676.777, 0.044],
            [677.232, 0.018],
            [691.456, 0.02],
        ],
    },
    {
        name: "niobium",
        lines: [
            [403.252, 0.06875],
            [403.95300000000003, 0.015625],
            [405.894, 1.0],
            [405.951, 0.013125],
            [406.079, 0.021875],
            [406.826, 0.013125],
            [407.973, 0.75],
            [408.486, 0.016875],
            [410.03999999999996, 0.0275],
            [410.092, 0.41875],
            [411.68999999999994, 0.019375],
            [412.38100000000003, 0.33125],
            [412.94300000000004, 0.041875],
            [412.99300000000005, 0.048125],
            [413.71000000000004, 0.14375],
            [413.94399999999996, 0.0275],
            [413.971, 0.16875],
            [414.321, 0.021875],
            [415.012, 0.054375],
            [415.258, 0.275],
            [416.34700000000004, 0.054375],
            [416.366, 0.275],
            [416.466, 0.25],
            [416.813, 0.21875],
            [418.44399999999996, 0.019375],
            [419.088, 0.075],
            [419.207, 0.054375],
            [419.509, 0.054375],
            [419.566, 0.08125],
            [419.851, 0.019375],
            [420.15200000000004, 0.021875],
            [420.53100000000006, 0.054375],
            [421.47299999999996, 0.021875],
            [421.794, 0.02625],
            [422.91499999999996, 0.02625],
            [425.544, 0.015625],
            [426.20500000000004, 0.048125],
            [426.60200000000003, 0.02625],
            [427.06899999999996, 0.018125],
            [428.69899999999996, 0.025],
            [429.96000000000004, 0.03625],
            [430.099, 0.03625],
            [430.956, 0.0075],
            [431.12700000000007, 0.024375],
            [431.245, 0.0075],
            [432.633, 0.021875],
            [432.738, 0.0075],
            [433.137, 0.024375],
            [434.282, 0.00875],
            [434.86499999999995, 0.00875],
            [434.90299999999996, 0.006875],
            [435.157, 0.018125],
            [436.843, 0.013125],
            [437.796, 0.00875],
            [438.83599999999996, 0.008125],
            [439.26899999999995, 0.01],
            [441.021, 0.020625],
            [441.94399999999996, 0.011875],
            [443.72200000000004, 0.014375],
            [444.718, 0.018125],
            [445.68, 0.00875],
            [445.742, 0.00875],
            [446.971, 0.00875],
            [447.129, 0.00875],
            [447.253, 0.00875],
            [450.304, 0.009375],
            [452.341, 0.033125],
            [454.68199999999996, 0.03],
            [456.453, 0.023125],
            [457.308, 0.045],
            [458.162, 0.03],
            [460.677, 0.075],
            [461.617, 0.010625],
            [463.01099999999997, 0.028125],
            [464.895, 0.028125],
            [464.927, 0.006875],
            [466.383, 0.028125],
            [466.62399999999997, 0.02125],
            [466.72200000000004, 0.015],
            [467.209, 0.03625],
            [467.537, 0.033125],
            [467.84799999999996, 0.006875],
            [468.514, 0.02],
            [470.61400000000003, 0.008125],
            [470.829, 0.01625],
            [471.35, 0.009375],
            [473.389, 0.006875],
            [474.96999999999997, 0.01375],
            [481.63800000000003, 0.006875],
            [484.837, 0.006875],
            [496.77799999999996, 0.008125],
            [497.314, 0.006875],
            [498.89700000000005, 0.011875],
            [500.09499999999997, 0.0053125],
            [500.225, 0.0040625],
            [501.32700000000006, 0.0025],
            [501.775, 0.014375],
            [501.951, 0.0025],
            [502.63599999999997, 0.009375],
            [503.01300000000003, 0.0025],
            [503.904, 0.013125],
            [504.796, 0.0025],
            [505.80100000000004, 0.010625],
            [505.93500000000006, 0.0040625],
            [506.525, 0.008125],
            [507.73999999999995, 0.0025],
            [507.896, 0.046875],
            [509.441, 0.0025],
            [509.53000000000003, 0.02625],
            [510.01599999999996, 0.010625],
            [512.03, 0.010625],
            [512.1800000000001, 0.0053125],
            [512.766, 0.0053125],
            [513.3340000000001, 0.0025],
            [513.475, 0.013125],
            [514.058, 0.0046875],
            [514.754, 0.0046875],
            [515.0640000000001, 0.0025],
            [515.263, 0.0046875],
            [516.033, 0.015625],
            [516.438, 0.015625],
            [518.0310000000001, 0.014375],
            [518.698, 0.006875],
            [518.92, 0.011875],
            [519.308, 0.010625],
            [519.5840000000001, 0.009375],
            [520.322, 0.0040625],
            [520.513, 0.0021875],
            [521.9100000000001, 0.0053125],
            [522.516, 0.0040625],
            [523.2810000000001, 0.009375],
            [523.743, 0.0053125],
            [524.039, 0.0018125],
            [525.162, 0.009375],
            [525.393, 0.0053125],
            [526.992, 0.003125],
            [527.153, 0.016875],
            [527.2479999999999, 0.0015625],
            [527.62, 0.008125],
            [527.943, 0.0018125],
            [528.5260000000001, 0.003125],
            [529.634, 0.0021875],
            [531.5550000000001, 0.003125],
            [531.701, 0.0010625],
            [531.86, 0.015625],
            [531.949, 0.003125],
            [533.487, 0.0046875],
            [533.681, 0.0015625],
            [534.08, 0.003125],
            [534.358, 0.0015625],
            [534.417, 0.02875],
            [535.074, 0.02125],
            [535.328, 0.0025],
            [535.5310000000001, 0.0015625],
            [535.5699999999999, 0.0025],
            [535.919, 0.0018125],
            [536.201, 0.0010625],
            [537.527, 0.0025],
            [538.134, 0.0025],
            [538.83, 0.0010625],
            [539.586, 0.0013125],
            [539.633, 0.0018125],
            [541.124, 0.0018125],
            [541.63, 0.0013125],
            [542.2439999999999, 0.0040625],
            [543.126, 0.0013125],
            [543.7270000000001, 0.006875],
            [544.831, 0.0011875],
            [545.6189999999999, 0.0011875],
            [545.804, 0.0025],
            [546.8100000000001, 0.0011875],
            [548.1, 0.0025],
            [548.309, 0.0008125],
            [548.3489999999999, 0.0011875],
            [549.106, 0.0008125],
            [549.953, 0.0010625],
            [550.458, 0.0025],
            [550.912, 0.0010625],
            [551.2819999999999, 0.0021875],
            [551.739, 0.0010625],
            [552.357, 0.003125],
            [554.147, 0.0015625],
            [555.135, 0.0053125],
            [556.3, 0.0018125],
            [557.144, 0.0010625],
            [557.616, 0.0021875],
            [557.829, 0.0021875],
            [558.697, 0.003125],
            [559.095, 0.0010625],
            [559.489, 0.0008125],
            [559.9590000000001, 0.0010625],
            [560.3520000000001, 0.0025],
            [560.393, 0.0008125],
            [562.826, 0.0015625],
            [562.917, 0.0040625],
            [563.542, 0.0021875],
            [564.211, 0.010625],
            [564.53, 0.0021875],
            [565.414, 0.0010625],
            [566.471, 0.008125],
            [566.563, 0.010625],
            [566.6859999999999, 0.0010625],
            [567.1020000000001, 0.0040625],
            [567.191, 0.0053125],
            [567.7470000000001, 0.0015625],
            [569.309, 0.0015625],
            [569.79, 0.0021875],
            [570.616, 0.0025],
            [570.6479999999999, 0.0053125],
            [570.933, 0.0018125],
            [571.559, 0.0010625],
            [571.635, 0.0040625],
            [572.566, 0.0015625],
            [572.919, 0.008125],
            [573.736, 0.0013125],
            [573.8199999999999, 0.0008125],
            [575.144, 0.0053125],
            [576.034, 0.006875],
            [576.499, 0.0040625],
            [577.108, 0.0018125],
            [577.607, 0.003125],
            [578.034, 0.0010625],
            [578.754, 0.0053125],
            [578.979, 0.0010625],
            [579.424, 0.003125],
            [580.403, 0.003125],
            [581.533, 0.0018125],
            [581.943, 0.006875],
            [582.062, 0.0021875],
            [583.49, 0.0046875],
            [583.8149999999999, 0.0015625],
            [583.864, 0.008125],
            [584.2470000000001, 0.003125],
            [584.609, 0.0010625],
            [586.647, 0.0040625],
            [587.47, 0.0021875],
            [587.779, 0.0010625],
            [589.3439999999999, 0.0025],
            [590.062, 0.011875],
            [590.38, 0.0025],
            [592.741, 0.0018125],
            [593.4159999999999, 0.0025],
            [595.77, 0.0025],
            [598.322, 0.009375],
            [598.608, 0.0040625],
            [599.793, 0.0053125],
            [602.975, 0.003125],
            [603.184, 0.003125],
            [604.55, 0.003125],
            [604.8720000000001, 0.0015625],
            [605.665, 0.0018125],
            [610.771, 0.0018125],
            [614.251, 0.0025],
            [614.813, 0.003125],
            [616.432, 0.003125],
            [621.306, 0.0018125],
            [622.196, 0.0046875],
            [625.176, 0.0025],
            [626.077, 0.0013125],
            [643.046, 0.0053125],
            [643.322, 0.003125],
            [649.784, 0.0010625],
            [654.461, 0.0040625],
            [657.473, 0.0009375],
            [659.1, 0.0011875],
            [660.616, 0.0011875],
            [660.728, 0.0011875],
            [661.415, 0.0021875],
            [662.698, 0.0011875],
            [666.0840000000001, 0.013125],
            [667.733, 0.009375],
            [670.12, 0.0040625],
            [672.362, 0.008125],
            [673.988, 0.0046875],
            [679.5310000000001, 0.0015625],
            [682.8109999999999, 0.0053125],
            [684.9350000000001, 0.0015625],
            [687.092, 0.0011875],
            [687.636, 0.0025],
            [690.289, 0.0015625],
            [690.807, 0.0021875],
            [691.832, 0.0025],
            [694.607, 0.0010625],
            [697.249, 0.0010625],
            [698.609, 0.0015625],
            [699.0319999999999, 0.0053125],
            [699.611, 0.0010625],
        ],
    },
    {
        name: "none",
        lines: [
            [400.0, 1.0],
            [700.0, 0.0],
        ],
    },
    {
        name: "oxygen",
        lines: [
            [391.196, 0.9183673469387755],
            [391.929, 0.32653061224489793],
            [394.729, 0.37755102040816324],
            [394.748, 0.32653061224489793],
            [394.759, 0.2857142857142857],
            [395.437, 0.4489795918367347],
            [395.461, 0.20408163265306123],
            [397.326, 0.9183673469387755],
            [398.21999999999997, 0.4489795918367347],
            [406.99, 0.32653061224489793],
            [407.216, 0.5816326530612245],
            [407.587, 0.9183673469387755],
            [408.39099999999996, 0.16326530612244897],
            [408.714, 0.10204081632653061],
            [408.927, 0.30612244897959184],
            [409.724, 0.22448979591836735],
            [410.5, 0.4489795918367347],
            [411.922, 0.5816326530612245],
            [413.28100000000006, 0.32653061224489793],
            [414.60600000000005, 0.10204081632653061],
            [415.33000000000004, 0.4489795918367347],
            [418.546, 0.5816326530612245],
            [418.979, 0.9183673469387755],
            [423.32700000000006, 0.16326530612244897],
            [425.37399999999997, 0.10204081632653061],
            [425.39799999999997, 0.10204081632653061],
            [427.547, 0.10204081632653061],
            [430.378, 0.10204081632653061],
            [431.71400000000006, 0.5816326530612245],
            [433.686, 0.32653061224489793],
            [434.55600000000004, 0.4489795918367347],
            [434.94300000000004, 0.5816326530612245],
            [436.68999999999994, 0.4489795918367347],
            [436.825, 0.20408163265306123],
            [439.59499999999997, 0.4489795918367347],
            [441.491, 0.9183673469387755],
            [441.698, 0.5816326530612245],
            [444.821, 0.32653061224489793],
            [445.238, 0.32653061224489793],
            [446.54499999999996, 0.10204081632653061],
            [446.628, 0.10204081632653061],
            [446.783, 0.10204081632653061],
            [446.941, 0.10204081632653061],
            [459.09700000000004, 0.7346938775510204],
            [459.617, 0.5816326530612245],
            [460.939, 0.16326530612244897],
            [463.88500000000005, 0.32653061224489793],
            [464.18100000000004, 0.7346938775510204],
            [464.91400000000004, 0.9183673469387755],
            [465.084, 0.32653061224489793],
            [466.16400000000004, 0.7346938775510204],
            [467.62299999999993, 0.5816326530612245],
            [469.921, 0.4489795918367347],
            [470.53599999999994, 0.5816326530612245],
            [492.46000000000004, 0.32653061224489793],
            [494.30600000000004, 0.4489795918367347],
            [532.9100000000001, 0.2755102040816326],
            [532.9680000000001, 0.32653061224489793],
            [533.074, 0.3877551020408163],
            [543.518, 0.1836734693877551],
            [543.578, 0.22448979591836735],
            [543.6859999999999, 0.2755102040816326],
            [557.734, 0.24489795918367346],
            [595.839, 0.32653061224489793],
            [595.858, 0.3877551020408163],
            [599.528, 0.16326530612244897],
            [604.6229999999999, 0.32653061224489793],
            [604.644, 0.3877551020408163],
            [604.649, 0.22448979591836735],
            [610.6270000000001, 0.20408163265306123],
            [615.598, 0.8163265306122449],
            [615.677, 0.9183673469387755],
            [615.818, 1.0],
            [625.683, 0.16326530612244897],
            [626.155, 0.20408163265306123],
            [636.634, 0.20408163265306123],
            [637.432, 0.20408163265306123],
            [645.36, 0.6530612244897959],
            [645.444, 0.7346938775510204],
            [645.598, 0.8163265306122449],
            [660.491, 0.16326530612244897],
            [665.383, 0.20408163265306123],
            [700.192, 0.7346938775510204],
            [700.223, 0.9183673469387755],
            [715.67, 0.42857142857142855],
        ],
    },
    {
        name: "polonium",
        lines: [
            [405.198, 0.16666666666666666],
            [417.052, 1.0],
            [423.613, 0.20833333333333334],
            [441.558, 0.16666666666666666],
            [449.321, 0.6666666666666666],
            [461.14399999999995, 0.2916666666666667],
            [486.712, 0.16666666666666666],
            [487.62399999999997, 0.3333333333333333],
            [494.68100000000004, 0.375],
            [532.323, 0.2916666666666667],
            [574.485, 0.25],
        ],
    },
    {
        name: "radium",
        lines: [
            [419.409, 0.08],
            [424.47200000000004, 0.08],
            [434.064, 1.0],
            [443.62700000000007, 0.2],
            [453.311, 0.3],
            [464.129, 0.08],
            [468.22799999999995, 1.0],
            [469.928, 0.08],
            [482.591, 1.0],
            [485.60699999999997, 0.1],
            [485.941, 0.1],
            [492.753, 0.1],
            [509.75600000000003, 0.1],
            [520.5930000000001, 0.1],
            [528.328, 0.1],
            [532.029, 0.1],
            [539.98, 0.1],
            [540.0229999999999, 0.2],
            [540.681, 0.2],
            [548.213, 0.08],
            [550.198, 0.1],
            [555.357, 0.1],
            [555.585, 0.2],
            [561.6659999999999, 0.1],
            [566.081, 0.5],
            [581.363, 0.2],
            [620.03, 0.3],
            [633.6899999999999, 0.1],
            [644.62, 0.2],
            [648.732, 0.2],
            [659.3340000000001, 0.1],
            [671.932, 0.1],
            [698.022, 0.2],
        ],
    },
    {
        name: "radon",
        lines: [
            [422.60600000000005, 0.1],
            [430.776, 0.8],
            [433.578, 0.07],
            [434.96000000000004, 1.0],
            [443.505, 0.4],
            [445.925, 0.5],
            [450.84799999999996, 0.5],
            [457.77200000000005, 0.5],
            [460.938, 0.5],
            [472.17600000000004, 0.3],
            [572.258, 0.06],
            [606.192, 0.1],
            [620.075, 0.06],
            [638.045, 0.06],
            [655.749, 0.1],
            [660.643, 0.1],
            [662.723, 0.15],
            [666.96, 0.06],
            [670.428, 0.08],
            [675.181, 0.2],
            [680.679, 0.06],
            [683.6949999999999, 0.08],
            [683.757, 0.08],
            [689.116, 0.1],
            [699.89, 0.1],
            [419.409, 0.08],
            [424.47200000000004, 0.08],
            [434.064, 1.0],
            [443.62700000000007, 0.2],
            [453.311, 0.3],
            [464.129, 0.08],
            [468.22799999999995, 1.0],
            [469.928, 0.08],
            [482.591, 1.0],
            [485.60699999999997, 0.1],
            [485.941, 0.1],
            [492.753, 0.1],
            [509.75600000000003, 0.1],
            [520.5930000000001, 0.1],
            [528.328, 0.1],
            [532.029, 0.1],
            [539.98, 0.1],
            [540.0229999999999, 0.2],
            [540.681, 0.2],
            [548.213, 0.08],
            [550.198, 0.1],
            [555.357, 0.1],
            [555.585, 0.2],
            [561.6659999999999, 0.1],
            [566.081, 0.5],
            [581.363, 0.2],
            [620.03, 0.3],
            [633.6899999999999, 0.1],
            [644.62, 0.2],
            [648.732, 0.2],
            [659.3340000000001, 0.1],
            [671.932, 0.1],
            [698.022, 0.2],
        ],
    },
    {
        name: "rhodium",
        lines: [
            [402.31399999999996, 0.02857142857142857],
            [404.841, 0.014285714285714285],
            [404.904, 0.0054761904761904765],
            [405.344, 0.009523809523809525],
            [405.634, 0.0054761904761904765],
            [407.757, 0.016666666666666666],
            [408.278, 0.13333333333333333],
            [408.428, 0.004523809523809524],
            [408.779, 0.010714285714285714],
            [408.85, 0.014285714285714285],
            [409.75200000000007, 0.03333333333333333],
            [410.74899999999997, 0.010714285714285714],
            [411.633, 0.016666666666666666],
            [411.968, 0.02857142857142857],
            [412.168, 0.2619047619047619],
            [412.887, 0.35714285714285715],
            [413.52700000000004, 0.5],
            [415.437, 0.05714285714285714],
            [419.65, 0.07857142857142857],
            [420.662, 0.016666666666666666],
            [421.11400000000003, 0.7857142857142857],
            [423.02, 0.006904761904761905],
            [424.44399999999996, 0.009523809523809525],
            [427.343, 0.014285714285714285],
            [427.86, 0.014285714285714285],
            [428.871, 0.19523809523809524],
            [429.677, 0.016666666666666666],
            [434.24399999999997, 0.0054761904761904765],
            [437.304, 0.010714285714285714],
            [437.48, 1.0],
            [437.992, 0.02261904761904762],
            [443.332, 0.0054761904761904765],
            [449.247, 0.008333333333333333],
            [450.378, 0.006904761904761905],
            [452.872, 0.0054761904761904765],
            [454.427, 0.0038095238095238095],
            [454.87299999999993, 0.008333333333333333],
            [455.16400000000004, 0.009523809523809525],
            [456.08900000000006, 0.004523809523809524],
            [456.51899999999995, 0.0038095238095238095],
            [456.9, 0.030952380952380953],
            [457.13100000000003, 0.0033333333333333335],
            [460.812, 0.006904761904761905],
            [461.991, 0.0033333333333333335],
            [464.31800000000004, 0.0054761904761904765],
            [467.503, 0.03571428571428571],
            [472.1, 0.004523809523809524],
            [474.51099999999997, 0.016666666666666666],
            [475.558, 0.002857142857142857],
            [481.049, 0.0054761904761904765],
            [484.24300000000005, 0.005],
            [484.399, 0.010714285714285714],
            [485.163, 0.014285714285714285],
            [496.371, 0.014285714285714285],
            [497.775, 0.014285714285714285],
            [497.918, 0.009523809523809525],
            [508.552, 0.0033333333333333335],
            [509.063, 0.016666666666666666],
            [512.069, 0.0054761904761904765],
            [513.076, 0.004523809523809524],
            [515.554, 0.014285714285714285],
            [515.7090000000001, 0.0033333333333333335],
            [515.8689999999999, 0.009523809523809525],
            [517.597, 0.014285714285714285],
            [517.7270000000001, 0.002857142857142857],
            [518.419, 0.008333333333333333],
            [519.3140000000001, 0.02261904761904762],
            [520.6949999999999, 0.0038095238095238095],
            [521.152, 0.0038095238095238095],
            [521.2729999999999, 0.004523809523809524],
            [521.479, 0.0038095238095238095],
            [522.266, 0.004523809523809524],
            [523.062, 0.004523809523809524],
            [523.716, 0.010714285714285714],
            [523.78, 0.002142857142857143],
            [526.927, 0.0033333333333333335],
            [528.012, 0.002619047619047619],
            [529.214, 0.0033333333333333335],
            [531.479, 0.0033333333333333335],
            [532.9739999999999, 0.009523809523809525],
            [533.108, 0.0033333333333333335],
            [534.931, 0.002142857142857143],
            [535.4399999999999, 0.030952380952380953],
            [535.647, 0.0054761904761904765],
            [537.9100000000001, 0.010714285714285714],
            [539.044, 0.02261904761904762],
            [540.473, 0.0054761904761904765],
            [542.4069999999999, 0.014285714285714285],
            [542.472, 0.004523809523809524],
            [542.545, 0.004523809523809524],
            [543.958, 0.002857142857142857],
            [544.136, 0.002857142857142857],
            [544.432, 0.002142857142857143],
            [544.5229999999999, 0.008333333333333333],
            [546.8109999999999, 0.0054761904761904765],
            [547.085, 0.008333333333333333],
            [547.612, 0.002857142857142857],
            [548.142, 0.002857142857142857],
            [548.423, 0.0038095238095238095],
            [550.4649999999999, 0.002142857142857143],
            [553.504, 0.006904761904761905],
            [554.458, 0.005],
            [559.942, 0.0380952380952381],
            [560.771, 0.0016666666666666668],
            [560.835, 0.0038095238095238095],
            [563.277, 0.0011904761904761906],
            [565.962, 0.002142857142857143],
            [568.638, 0.009523809523809525],
            [570.2470000000001, 0.002142857142857143],
            [572.73, 0.0014285714285714286],
            [579.266, 0.006904761904761905],
            [579.579, 0.002142857142857143],
            [580.3340000000001, 0.002142857142857143],
            [580.691, 0.009523809523809525],
            [582.184, 0.0014285714285714286],
            [583.158, 0.008333333333333333],
            [590.731, 0.0016666666666666668],
            [591.854, 0.002142857142857143],
            [594.146, 0.0016666666666666668],
            [598.36, 0.030952380952380953],
            [599.1189999999999, 0.002142857142857143],
            [610.272, 0.008333333333333333],
            [611.615, 0.0014285714285714286],
            [612.806, 0.0019047619047619048],
            [618.6890000000001, 0.0019047619047619048],
            [619.999, 0.0033333333333333335],
            [625.3720000000001, 0.0038095238095238095],
            [627.6659999999999, 0.0011904761904761906],
            [627.746, 0.0019047619047619048],
            [629.338, 0.0014285714285714286],
            [631.953, 0.006904761904761905],
            [641.472, 0.002857142857142857],
            [651.0409999999999, 0.0038095238095238095],
            [651.97, 0.004523809523809524],
            [662.78, 0.002142857142857143],
            [663.016, 0.004523809523809524],
            [675.235, 0.009523809523809525],
            [679.665, 0.002142857142857143],
            [682.733, 0.0030952380952380953],
            [685.768, 0.002619047619047619],
            [687.9939999999999, 0.004761904761904762],
            [696.567, 0.015476190476190477],
            [697.2909999999999, 0.0019047619047619048],
            [697.915, 0.0038095238095238095],
        ],
    },
    {
        name: "samarium",
        lines: [
            [400.748, 0.16206896551724137],
            [401.998, 0.09655172413793103],
            [402.323, 0.30344827586206896],
            [403.511, 0.25517241379310346],
            [404.168, 0.20344827586206896],
            [404.272, 0.25517241379310346],
            [404.29, 0.30344827586206896],
            [404.411, 0.08275862068965517],
            [404.505, 0.19310344827586207],
            [404.616, 0.15172413793103448],
            [404.716, 0.25517241379310346],
            [404.86199999999997, 0.07241379310344828],
            [404.981, 0.20344827586206896],
            [405.887, 0.15172413793103448],
            [406.354, 0.19310344827586207],
            [406.432, 0.09655172413793103],
            [406.45799999999997, 0.4827586206896552],
            [406.674, 0.2793103448275862],
            [406.83299999999997, 0.24482758620689654],
            [407.584, 0.2793103448275862],
            [407.665, 0.09655172413793103],
            [408.056, 0.08275862068965517],
            [408.26, 0.1413793103448276],
            [408.358, 0.09655172413793103],
            [408.44, 0.07586206896551724],
            [409.227, 0.3448275862068966],
            [409.40500000000003, 0.1],
            [410.413, 0.08275862068965517],
            [410.72799999999995, 0.2793103448275862],
            [410.93999999999994, 0.1413793103448276],
            [411.01899999999995, 0.09655172413793103],
            [411.39, 0.1413793103448276],
            [411.855, 0.6551724137931034],
            [412.13599999999997, 0.1413793103448276],
            [412.25100000000003, 0.09655172413793103],
            [412.396, 0.24482758620689654],
            [412.92299999999994, 0.09655172413793103],
            [413.514, 0.08620689655172414],
            [414.771, 0.1103448275862069],
            [414.983, 0.2793103448275862],
            [415.221, 0.41379310344827586],
            [415.33299999999997, 0.18275862068965518],
            [415.52200000000005, 0.19310344827586207],
            [416.948, 0.2793103448275862],
            [417.157, 0.1413793103448276],
            [417.802, 0.15172413793103448],
            [418.11, 0.18275862068965518],
            [418.33299999999997, 0.07241379310344828],
            [418.37600000000003, 0.18275862068965518],
            [418.813, 0.3448275862068966],
            [419.19300000000004, 0.1413793103448276],
            [419.945, 0.09310344827586207],
            [420.29200000000003, 0.22413793103448276],
            [420.305, 0.3793103448275862],
            [420.613, 0.22758620689655173],
            [420.662, 0.09310344827586207],
            [421.035, 0.22758620689655173],
            [422.066, 0.25517241379310346],
            [422.533, 0.3448275862068966],
            [422.96999999999997, 0.25517241379310346],
            [423.457, 0.21379310344827587],
            [423.674, 0.41379310344827586],
            [423.76599999999996, 0.1724137931034483],
            [424.46999999999997, 0.21379310344827587],
            [424.95500000000004, 0.07241379310344828],
            [425.178, 0.08620689655172414],
            [425.639, 0.7241379310344828],
            [425.858, 0.07241379310344828],
            [426.26800000000003, 0.4482758620689655],
            [426.508, 0.1724137931034483],
            [427.968, 0.41379310344827586],
            [427.99399999999997, 0.08275862068965517],
            [428.079, 0.7586206896551724],
            [428.221, 0.24482758620689654],
            [428.283, 0.16206896551724137],
            [428.35, 0.08275862068965517],
            [428.66400000000004, 0.1206896551724138],
            [429.218, 0.1206896551724138],
            [429.674, 0.5517241379310345],
            [430.49399999999997, 0.1103448275862069],
            [430.901, 0.30344827586206896],
            [431.285, 0.08275862068965517],
            [431.89399999999995, 0.6551724137931034],
            [431.953, 0.16206896551724137],
            [432.328, 0.20344827586206896],
            [432.446, 0.08275862068965517],
            [432.90200000000004, 0.6206896551724138],
            [433.00200000000007, 0.15172413793103448],
            [433.41499999999996, 0.4482758620689655],
            [433.61400000000003, 0.30344827586206896],
            [434.58599999999996, 0.19310344827586207],
            [434.78000000000003, 0.3793103448275862],
            [435.046, 0.19310344827586207],
            [435.21000000000004, 0.19310344827586207],
            [436.072, 0.19310344827586207],
            [436.10699999999997, 0.07586206896551724],
            [436.204, 0.2793103448275862],
            [436.291, 0.15172413793103448],
            [436.34499999999997, 0.07586206896551724],
            [436.803, 0.1724137931034483],
            [436.992, 0.07241379310344828],
            [437.346, 0.15172413793103448],
            [437.49799999999993, 0.1103448275862069],
            [437.82399999999996, 0.30344827586206896],
            [438.04200000000003, 0.18275862068965518],
            [438.429, 0.1],
            [439.08599999999996, 0.5517241379310345],
            [439.33500000000004, 0.07241379310344828],
            [439.73400000000004, 0.1],
            [440.117, 0.1413793103448276],
            [440.30600000000004, 0.2793103448275862],
            [440.33599999999996, 0.1413793103448276],
            [440.933, 0.1793103448275862],
            [441.158, 0.1],
            [441.758, 0.1310344827586207],
            [441.933, 0.16206896551724137],
            [442.053, 0.5172413793103449],
            [442.11400000000003, 0.3310344827586207],
            [442.434, 1.0],
            [442.966, 0.16206896551724137],
            [443.38800000000003, 0.5517241379310345],
            [443.43199999999996, 0.6206896551724138],
            [444.18100000000004, 0.18275862068965518],
            [444.22799999999995, 0.15172413793103448],
            [444.42600000000004, 0.24482758620689654],
            [444.515, 0.24482758620689654],
            [445.27299999999997, 0.4482758620689655],
            [445.29499999999996, 0.08620689655172414],
            [445.463, 0.41379310344827586],
            [445.85200000000003, 0.3448275862068966],
            [445.929, 0.08620689655172414],
            [446.73400000000004, 0.7586206896551724],
            [447.08900000000006, 0.2793103448275862],
            [447.24300000000005, 0.16206896551724137],
            [447.302, 0.21379310344827587],
            [447.866, 0.25517241379310346],
            [449.91099999999994, 0.12758620689655173],
            [449.948, 0.12758620689655173],
            [450.338, 0.08275862068965517],
            [450.505, 0.06206896551724138],
            [451.133, 0.041379310344827586],
            [451.183, 0.19310344827586207],
            [451.509, 0.15172413793103448],
            [451.963, 0.30344827586206896],
            [452.304, 0.15172413793103448],
            [452.39099999999996, 0.22413793103448276],
            [453.38, 0.1],
            [453.651, 0.09310344827586207],
            [453.79499999999996, 0.24482758620689654],
            [453.85299999999995, 0.05172413793103448],
            [454.01899999999995, 0.1],
            [454.206, 0.1310344827586207],
            [454.395, 0.2793103448275862],
            [454.483, 0.034482758620689655],
            [455.26599999999996, 0.1413793103448276],
            [455.445, 0.09310344827586207],
            [456.043, 0.08275862068965517],
            [456.621, 0.16206896551724137],
            [457.76899999999995, 0.20344827586206896],
            [458.158, 0.1],
            [458.17299999999994, 0.15172413793103448],
            [458.483, 0.19310344827586207],
            [459.18199999999996, 0.1],
            [459.354, 0.1310344827586207],
            [459.529, 0.19310344827586207],
            [459.674, 0.08275862068965517],
            [460.418, 0.07586206896551724],
            [460.651, 0.1],
            [461.544, 0.1],
            [461.56899999999996, 0.16206896551724137],
            [463.021, 0.05172413793103448],
            [464.224, 0.30344827586206896],
            [464.53999999999996, 0.1],
            [464.668, 0.1],
            [464.816, 0.08275862068965517],
            [464.94899999999996, 0.1310344827586207],
            [465.51300000000003, 0.05172413793103448],
            [466.35600000000005, 0.1],
            [466.93999999999994, 0.25517241379310346],
            [466.965, 0.21379310344827587],
            [467.075, 0.16206896551724137],
            [467.46000000000004, 0.3793103448275862],
            [467.691, 0.23448275862068965],
            [468.15500000000003, 0.07241379310344828],
            [468.718, 0.12758620689655173],
            [468.87299999999993, 0.12758620689655173],
            [469.363, 0.04482758620689655],
            [469.934, 0.041379310344827586],
            [470.43999999999994, 0.18275862068965518],
            [471.30600000000004, 0.09310344827586207],
            [471.526, 0.04482758620689655],
            [471.61, 0.2517241379310345],
            [471.707, 0.09310344827586207],
            [471.77200000000005, 0.07241379310344828],
            [471.83299999999997, 0.06551724137931035],
            [471.98400000000004, 0.09310344827586207],
            [472.60200000000003, 0.04482758620689655],
            [472.842, 0.2655172413793103],
            [474.56800000000004, 0.16206896551724137],
            [475.072, 0.05172413793103448],
            [476.02700000000004, 0.2517241379310345],
            [477.02, 0.03793103448275862],
            [477.41499999999996, 0.03793103448275862],
            [477.785, 0.06551724137931035],
            [478.31000000000006, 0.2],
            [478.58599999999996, 0.1206896551724138],
            [478.996, 0.05517241379310345],
            [479.158, 0.07931034482758621],
            [481.581, 0.1482758620689655],
            [482.957, 0.04482758620689655],
            [484.16999999999996, 0.33448275862068966],
            [484.421, 0.10689655172413794],
            [484.776, 0.04827586206896552],
            [484.832, 0.09310344827586207],
            [485.436, 0.041379310344827586],
            [488.37700000000007, 0.07241379310344828],
            [488.39700000000005, 0.2517241379310345],
            [490.497, 0.05862068965517241],
            [491.03999999999996, 0.21724137931034482],
            [491.325, 0.1206896551724138],
            [491.899, 0.1482758620689655],
            [492.404, 0.03793103448275862],
            [493.81000000000006, 0.041379310344827586],
            [494.863, 0.05862068965517241],
            [495.23699999999997, 0.041379310344827586],
            [496.19399999999996, 0.05862068965517241],
            [497.59799999999996, 0.05862068965517241],
            [502.84399999999994, 0.04827586206896552],
            [504.428, 0.13793103448275862],
            [505.276, 0.06896551724137931],
            [506.946, 0.05862068965517241],
            [507.12, 0.18620689655172415],
            [510.02200000000005, 0.05862068965517241],
            [510.309, 0.0896551724137931],
            [510.448, 0.04827586206896552],
            [511.66999999999996, 0.04827586206896552],
            [511.716, 0.17586206896551723],
            [512.214, 0.1206896551724138],
            [515.5029999999999, 0.12413793103448276],
            [517.274, 0.08620689655172414],
            [517.542, 0.16206896551724137],
            [520.059, 0.08620689655172414],
            [525.192, 0.0896551724137931],
            [527.14, 0.13793103448275862],
            [528.2909999999999, 0.08620689655172414],
            [532.0600000000001, 0.06551724137931035],
            [534.129, 0.03793103448275862],
            [536.836, 0.04827586206896552],
            [540.5229999999999, 0.04482758620689655],
            [545.3, 0.07586206896551724],
            [546.672, 0.04827586206896552],
            [549.3720000000001, 0.07931034482758621],
            [551.21, 0.027586206896551724],
            [551.609, 0.07931034482758621],
            [554.895, 0.017241379310344827],
            [555.04, 0.04827586206896552],
            [557.342, 0.015517241379310345],
            [558.8199999999999, 0.01206896551724138],
            [560.086, 0.017241379310344827],
            [562.179, 0.017241379310344827],
            [562.601, 0.02413793103448276],
            [564.4100000000001, 0.029310344827586206],
            [565.986, 0.04827586206896552],
            [569.673, 0.041379310344827586],
            [570.62, 0.029310344827586206],
            [571.0930000000001, 0.01206896551724138],
            [573.295, 0.017241379310344827],
            [574.335, 0.017241379310344827],
            [575.952, 0.015517241379310345],
            [577.3770000000001, 0.02413793103448276],
            [577.833, 0.020689655172413793],
            [577.924, 0.015517241379310345],
            [578.193, 0.015517241379310345],
            [578.698, 0.02413793103448276],
            [578.838, 0.020689655172413793],
            [580.052, 0.020689655172413793],
            [580.284, 0.022413793103448276],
            [581.489, 0.015517241379310345],
            [583.1020000000001, 0.015517241379310345],
            [583.637, 0.015517241379310345],
            [586.078, 0.01206896551724138],
            [586.779, 0.022413793103448276],
            [586.861, 0.015517241379310345],
            [587.106, 0.01206896551724138],
            [587.421, 0.017241379310344827],
            [589.739, 0.015517241379310345],
            [589.896, 0.017241379310344827],
            [593.89, 0.01206896551724138],
            [596.571, 0.022413793103448276],
            [596.882, 0.01206896551724138],
            [598.429, 0.01206896551724138],
            [604.5, 0.017241379310344827],
            [604.539, 0.015517241379310345],
            [607.0060000000001, 0.017241379310344827],
            [608.412, 0.015517241379310345],
            [609.14, 0.01206896551724138],
            [611.066, 0.015517241379310345],
            [615.956, 0.015517241379310345],
            [624.676, 0.015517241379310345],
            [625.654, 0.015517241379310345],
            [625.6659999999999, 0.015517241379310345],
            [626.728, 0.034482758620689655],
            [629.182, 0.017241379310344827],
            [630.706, 0.01206896551724138],
            [632.7470000000001, 0.02413793103448276],
            [642.664, 0.015517241379310345],
            [647.234, 0.015517241379310345],
            [648.452, 0.01206896551724138],
            [649.867, 0.01206896551724138],
            [654.2760000000001, 0.017241379310344827],
            [656.931, 0.04827586206896552],
            [657.067, 0.01206896551724138],
            [658.521, 0.013793103448275862],
            [658.972, 0.03793103448275862],
            [660.183, 0.013793103448275862],
            [660.456, 0.032758620689655175],
            [663.228, 0.013793103448275862],
            [667.1510000000001, 0.017241379310344827],
            [667.921, 0.02413793103448276],
            [669.355, 0.02413793103448276],
            [672.307, 0.013793103448275862],
            [673.184, 0.041379310344827586],
            [673.4060000000001, 0.02413793103448276],
            [673.481, 0.013793103448275862],
            [674.147, 0.01896551724137931],
            [677.861, 0.013793103448275862],
            [679.0, 0.020689655172413793],
            [679.42, 0.032758620689655175],
            [684.471, 0.01896551724137931],
            [685.603, 0.02586206896551724],
            [686.0930000000001, 0.041379310344827586],
            [686.2819999999999, 0.013793103448275862],
            [695.051, 0.010344827586206896],
            [695.529, 0.041379310344827586],
        ],
    },
    {
        name: "scandium",
        lines: [
            [401.44899999999996, 0.0265],
            [402.04, 1.0],
            [402.369, 1.0],
            [403.067, 0.011],
            [403.139, 0.007],
            [403.423, 0.005],
            [404.38, 0.011],
            [404.648, 0.01],
            [404.779, 0.135],
            [404.995, 0.006],
            [405.45500000000004, 0.275],
            [405.659, 0.011],
            [407.49699999999996, 0.008],
            [407.857, 0.008],
            [408.24, 0.305],
            [408.66700000000003, 0.01],
            [408.716, 0.02],
            [409.313, 0.002],
            [409.485, 0.00325],
            [409.83500000000004, 0.00275],
            [410.033, 0.00325],
            [413.3, 0.022],
            [414.03000000000003, 0.0265],
            [414.73999999999995, 0.00325],
            [415.236, 0.036],
            [415.47200000000004, 0.00275],
            [416.188, 0.0045],
            [416.51899999999995, 0.055],
            [417.15600000000006, 0.00325],
            [418.645, 0.00225],
            [418.762, 0.00325],
            [420.52, 0.00375],
            [421.23400000000004, 0.00325],
            [421.24899999999997, 0.00225],
            [421.61, 0.00375],
            [421.826, 0.0055],
            [421.97299999999996, 0.0055],
            [422.188, 0.002],
            [422.559, 0.0045],
            [423.19300000000004, 0.009],
            [423.361, 0.01],
            [423.782, 0.005],
            [423.805, 0.02],
            [423.957, 0.0045],
            [424.61199999999997, 0.005],
            [424.683, 0.75],
            [428.35600000000005, 0.00275],
            [429.47700000000003, 0.0145],
            [430.571, 0.0175],
            [431.409, 0.21],
            [432.07399999999996, 0.165],
            [432.50100000000003, 0.12],
            [434.85299999999995, 0.0014],
            [435.46099999999996, 0.009],
            [435.86400000000003, 0.0055],
            [435.908, 0.00275],
            [436.492, 0.0014],
            [437.446, 0.1],
            [438.48100000000005, 0.0065],
            [438.96000000000004, 0.00225],
            [440.037, 0.055],
            [441.55600000000004, 0.044],
            [442.066, 0.0014],
            [443.13599999999997, 0.00225],
            [454.255, 0.00325],
            [454.468, 0.0045],
            [455.724, 0.006],
            [457.399, 0.008],
            [459.294, 0.00325],
            [459.84499999999997, 0.00325],
            [460.47200000000004, 0.00275],
            [460.953, 0.00225],
            [460.995, 0.00225],
            [467.03999999999996, 0.0175],
            [468.049, 0.002],
            [469.829, 0.0025],
            [470.697, 0.006],
            [470.934, 0.006],
            [472.87700000000007, 0.01],
            [472.92299999999994, 0.0245],
            [473.23, 0.002],
            [473.41, 0.0295],
            [473.508, 0.003],
            [473.765, 0.0345],
            [474.10200000000003, 0.0395],
            [474.38100000000003, 0.06],
            [475.316, 0.01],
            [477.93500000000006, 0.011],
            [479.15, 0.0045],
            [482.72799999999995, 0.005],
            [483.367, 0.005],
            [483.94399999999996, 0.0085],
            [484.047, 0.002],
            [484.76800000000003, 0.004],
            [485.26800000000003, 0.004],
            [485.779, 0.007],
            [490.66700000000003, 0.004],
            [490.976, 0.0045],
            [492.284, 0.0045],
            [493.425, 0.0045],
            [493.57399999999996, 0.00225],
            [494.133, 0.0035],
            [495.40600000000006, 0.0085],
            [497.366, 0.006],
            [498.037, 0.0075],
            [498.34499999999997, 0.004],
            [499.192, 0.007],
            [501.83900000000006, 0.004],
            [502.014, 0.0035],
            [502.151, 0.004],
            [503.10200000000003, 0.0265],
            [503.274, 0.00275],
            [506.43199999999996, 0.0125],
            [506.88599999999997, 0.004],
            [507.02299999999997, 0.0265],
            [507.581, 0.0125],
            [508.15600000000006, 0.105],
            [508.372, 0.06],
            [508.555, 0.055],
            [508.695, 0.0375],
            [508.71400000000006, 0.0195],
            [508.98900000000003, 0.0135],
            [509.246, 0.00225],
            [509.67299999999994, 0.0195],
            [509.92299999999994, 0.031],
            [510.11199999999997, 0.0185],
            [510.90600000000006, 0.009],
            [511.28599999999994, 0.0075],
            [511.669, 0.016],
            [513.368, 0.0035],
            [517.106, 0.00225],
            [521.052, 0.0195],
            [521.1279999999999, 0.00225],
            [521.967, 0.014],
            [523.982, 0.0175],
            [525.833, 0.014],
            [528.4970000000001, 0.00175],
            [528.576, 0.0105],
            [530.194, 0.00175],
            [531.835, 0.0011],
            [533.177, 0.0035],
            [533.423, 0.0007],
            [533.941, 0.00475],
            [534.105, 0.006],
            [534.296, 0.00475],
            [534.9300000000001, 0.0175],
            [534.971, 0.006],
            [535.03, 0.003],
            [535.575, 0.0105],
            [535.61, 0.0265],
            [535.7189999999999, 0.0007],
            [537.5350000000001, 0.0135],
            [539.208, 0.0185],
            [541.612, 0.00225],
            [542.557, 0.00225],
            [542.941, 0.00225],
            [543.294, 0.00175],
            [543.323, 0.00275],
            [543.822, 0.00225],
            [543.903, 0.00275],
            [544.26, 0.00275],
            [544.62, 0.0135],
            [544.739, 0.0009],
            [545.134, 0.006],
            [545.521, 0.0015],
            [546.52, 0.0009],
            [546.8399999999999, 0.00275],
            [547.2189999999999, 0.003],
            [547.464, 0.0009],
            [548.199, 0.0375],
            [548.462, 0.0265],
            [551.422, 0.0285],
            [551.539, 0.0008],
            [552.05, 0.033],
            [552.606, 0.00225],
            [552.682, 0.033],
            [554.104, 0.00275],
            [554.64, 0.0015],
            [555.04, 0.0009],
            [555.225, 0.00025],
            [555.359, 0.00175],
            [556.11, 0.0008],
            [556.486, 0.0035],
            [557.124, 0.0009],
            [557.976, 0.00175],
            [560.419, 0.0011],
            [563.1020000000001, 0.0011],
            [564.098, 0.004],
            [564.636, 0.00225],
            [564.76, 0.0008],
            [564.956, 0.00275],
            [565.788, 0.0125],
            [565.8340000000001, 0.003],
            [566.716, 0.00275],
            [566.904, 0.0035],
            [567.181, 0.075],
            [568.42, 0.00475],
            [568.684, 0.06],
            [570.021, 0.055],
            [570.861, 0.0095],
            [571.175, 0.044],
            [571.728, 0.0115],
            [572.408, 0.009],
            [573.6850000000001, 0.00275],
            [576.4449999999999, 0.00275],
            [577.274, 0.00475],
            [577.5319999999999, 0.00275],
            [580.984, 0.0035],
            [581.1600000000001, 0.0035],
            [584.7729999999999, 0.00475],
            [584.9069999999999, 0.0035],
            [588.738, 0.0035],
            [591.804, 0.00175],
            [591.911, 0.0015],
            [592.8100000000001, 0.003],
            [596.149, 0.00175],
            [596.825, 0.003],
            [596.919, 0.00175],
            [598.842, 0.0045],
            [601.707, 0.031],
            [606.431, 0.0245],
            [607.265, 0.022],
            [607.9300000000001, 0.031],
            [610.187, 0.016],
            [610.993, 0.0185],
            [611.597, 0.0185],
            [614.87, 0.009],
            [615.393, 0.0075],
            [618.809, 0.0075],
            [619.29, 0.0075],
            [621.068, 0.031],
            [623.941, 0.0045],
            [623.978, 0.016],
            [624.563, 0.006],
            [624.996, 0.0055],
            [625.896, 0.0125],
            [626.225, 0.003],
            [627.6310000000001, 0.00275],
            [627.976, 0.00225],
            [630.0699999999999, 0.0009],
            [630.567, 0.0375],
            [630.99, 0.0013],
            [632.085, 0.0008],
            [634.483, 0.0013],
            [637.882, 0.003],
            [640.841, 0.00275],
            [641.335, 0.0045],
            [643.708, 0.0013],
            [644.624, 0.00275],
            [645.778, 0.0013],
            [648.54, 0.00175],
            [649.5899999999999, 0.0013],
            [652.562, 0.00275],
            [653.53, 0.0011],
            [655.784, 0.00225],
            [656.688, 0.00175],
            [657.585, 0.0009],
            [660.46, 0.003],
            [660.999, 0.0013],
            [661.794, 0.0009],
            [664.508, 0.0009],
            [665.442, 0.0011],
            [666.101, 0.0013],
            [670.048, 0.0009],
            [670.5930000000001, 0.0009],
            [673.787, 0.00325],
            [673.9399999999999, 0.00175],
            [681.708, 0.00175],
            [681.952, 0.0025],
            [682.954, 0.00145],
            [683.5029999999999, 0.0025],
            [696.312, 0.00025],
            [699.068, 0.00025],
        ],
    },
    {
        name: "silicon",
        lines: [
            [390.552, 0.3],
            [395.57399999999996, 0.01],
            [397.746, 0.01],
            [399.177, 0.015],
            [399.80100000000004, 0.01],
            [407.54499999999996, 0.02],
            [407.678, 0.015],
            [410.294, 0.07],
            [412.80699999999996, 0.3],
            [413.08900000000006, 0.5],
            [418.33500000000004, 0.01],
            [419.072, 0.1],
            [419.813, 0.05],
            [462.142, 0.1],
            [462.172, 0.15],
            [478.299, 0.05],
            [479.221, 0.035],
            [479.23199999999997, 0.08],
            [488.32, 0.015],
            [490.69899999999996, 0.02],
            [493.28000000000003, 0.02],
            [494.76099999999997, 0.03],
            [500.60600000000005, 0.04],
            [504.10299999999995, 1.0],
            [505.59799999999996, 1.0],
            [518.1899999999999, 0.1],
            [518.525, 0.1],
            [519.286, 0.2],
            [520.241, 0.5],
            [529.519, 0.03],
            [540.534, 0.1],
            [541.7239999999999, 0.015],
            [542.892, 0.015],
            [543.289, 0.015],
            [543.862, 0.1],
            [544.726, 0.02],
            [545.449, 0.015],
            [545.645, 0.1],
            [546.643, 0.5],
            [546.687, 0.5],
            [546.921, 0.1],
            [549.323, 0.04],
            [549.645, 0.2],
            [551.754, 0.035],
            [554.074, 0.1],
            [557.6659999999999, 0.15],
            [562.222, 0.03],
            [563.297, 0.1],
            [563.948, 0.2],
            [564.5609999999999, 0.09],
            [566.066, 0.15],
            [566.5550000000001, 0.08],
            [566.956, 1.0],
            [568.144, 0.03],
            [568.448, 0.12],
            [568.8810000000001, 0.3],
            [569.043, 0.1],
            [570.111, 0.09],
            [570.137, 0.2],
            [570.637, 0.1],
            [570.8399999999999, 0.16],
            [574.767, 0.045],
            [575.363, 0.045],
            [575.422, 0.045],
            [576.298, 0.045],
            [577.2149999999999, 0.07],
            [578.038, 0.07],
            [578.573, 0.03],
            [579.307, 0.09],
            [579.49, 0.03],
            [579.786, 0.1],
            [580.047, 0.15],
            [580.674, 0.2],
            [582.78, 0.03],
            [584.613, 0.05],
            [586.7479999999999, 0.01],
            [586.8399999999999, 0.3],
            [587.376, 0.04],
            [591.522, 0.15],
            [594.855, 0.2],
            [595.7560000000001, 0.5],
            [597.893, 0.5],
            [606.745, 0.01],
            [608.0060000000001, 0.02],
            [608.667, 0.01],
            [612.5020000000001, 0.09],
            [613.1569999999999, 0.085],
            [613.1850000000001, 0.09],
            [614.249, 0.1],
            [614.5020000000001, 0.1],
            [615.513, 0.16],
            [623.732, 0.16],
            [623.829, 0.04],
            [624.3810000000001, 0.125],
            [624.447, 0.125],
            [625.419, 0.18],
            [633.1949999999999, 0.045],
            [634.71, 1.0],
            [637.136, 1.0],
            [652.661, 0.045],
            [652.72, 0.045],
            [655.546, 0.045],
            [666.052, 0.05],
            [666.5, 0.015],
            [667.188, 0.1],
            [669.938, 0.02],
            [671.704, 0.05],
            [672.1850000000001, 0.1],
            [674.164, 0.03],
            [675.028, 0.02],
            [681.845, 0.03],
            [682.982, 0.05],
            [684.857, 0.03],
            [697.652, 0.08],
            [700.357, 0.18],
            [700.588, 0.18],
            [701.728, 0.03],
            [701.765, 0.09],
            [703.49, 0.25],
            [716.4689999999999, 0.07],
            [716.5550000000001, 0.2],
            [718.489, 0.07],
            [719.358, 0.065],
            [719.39, 0.03],
        ],
    },
    {
        name: "sodium",
        lines: [
            [408.137, 0.6666666666666666],
            [411.37, 1.0],
            [412.308, 0.8333333333333334],
            [423.326, 0.8333333333333334],
            [423.899, 0.006666666666666667],
            [424.037, 0.6666666666666666],
            [424.09, 0.8333333333333334],
            [424.20799999999997, 0.01],
            [427.36400000000003, 0.013333333333333334],
            [427.679, 0.016666666666666666],
            [428.784, 0.006666666666666667],
            [429.101, 0.01],
            [429.24799999999993, 0.8333333333333334],
            [429.28599999999994, 0.8333333333333334],
            [430.88100000000003, 0.8333333333333334],
            [430.904, 0.8333333333333334],
            [432.091, 0.8333333333333334],
            [432.14, 0.02],
            [432.462, 0.023333333333333334],
            [433.729, 0.8333333333333334],
            [434.149, 0.013333333333333334],
            [434.41099999999994, 0.8333333333333334],
            [434.474, 0.016666666666666666],
            [436.86, 0.6666666666666666],
            [437.52200000000005, 0.6666666666666666],
            [438.74899999999997, 0.6666666666666666],
            [439.003, 0.02666666666666667],
            [439.28100000000006, 0.8333333333333334],
            [439.334, 0.03],
            [440.512, 0.6666666666666666],
            [441.98900000000003, 0.02],
            [442.325, 0.023333333333333334],
            [444.66999999999996, 0.6666666666666666],
            [444.741, 0.6666666666666666],
            [445.474, 0.6666666666666666],
            [445.52299999999997, 0.6666666666666666],
            [445.721, 0.6666666666666666],
            [447.463, 0.6666666666666666],
            [447.88, 0.6666666666666666],
            [448.16700000000003, 0.6666666666666666],
            [449.015, 0.6666666666666666],
            [449.087, 0.6666666666666666],
            [449.418, 0.03333333333333333],
            [449.76599999999996, 0.03666666666666667],
            [449.962, 0.6666666666666666],
            [450.697, 0.6666666666666666],
            [451.921, 0.6666666666666666],
            [452.49799999999993, 0.6666666666666666],
            [453.332, 0.6666666666666666],
            [454.163, 0.023333333333333334],
            [454.51899999999995, 0.02666666666666667],
            [455.15299999999996, 0.6666666666666666],
            [459.092, 0.5333333333333333],
            [472.22299999999996, 0.5333333333333333],
            [473.11, 0.5333333333333333],
            [473.25, 0.3333333333333333],
            [474.16700000000003, 0.5333333333333333],
            [476.879, 0.5333333333333333],
            [478.879, 0.3333333333333333],
            [481.475, 0.16666666666666666],
            [483.526, 0.16666666666666666],
            [514.3109999999999, 0.2],
            [519.165, 0.3333333333333333],
            [520.333, 0.16666666666666666],
            [520.855, 0.26666666666666666],
            [539.063, 0.2],
            [540.046, 0.23333333333333334],
            [541.455, 0.3],
            [568.263, 0.016666666666666666],
            [568.8199999999999, 0.03],
            [588.995, 0.10666666666666667],
            [589.592, 0.05333333333333334],
            [617.525, 0.2],
            [619.926, 0.23333333333333334],
            [623.4680000000001, 0.23333333333333334],
            [626.001, 0.26666666666666666],
            [627.4739999999999, 0.26666666666666666],
            [631.08, 0.2],
            [635.283, 0.2],
            [635.8050000000001, 0.2],
            [636.115, 0.23333333333333334],
            [636.641, 0.23333333333333334],
            [637.891, 0.2],
            [647.529, 0.16666666666666666],
            [651.421, 0.3],
            [652.4680000000001, 0.26666666666666666],
            [653.0699999999999, 0.43333333333333335],
            [654.404, 0.43333333333333335],
            [654.575, 0.43333333333333335],
            [655.243, 0.26666666666666666],
        ],
    },
    {
        name: "tantalum",
        lines: [
            [400.684, 0.4222222222222222],
            [402.694, 0.4222222222222222],
            [402.994, 0.3111111111111111],
            [404.087, 0.26666666666666666],
            [406.14, 0.9111111111111111],
            [406.463, 0.4666666666666667],
            [406.724, 0.2222222222222222],
            [406.791, 0.6888888888888889],
            [410.50200000000007, 0.26666666666666666],
            [412.938, 0.4666666666666667],
            [413.62, 0.5111111111111111],
            [414.78900000000004, 0.5111111111111111],
            [417.521, 0.4666666666666667],
            [417.79200000000003, 0.2222222222222222],
            [418.11499999999995, 0.28888888888888886],
            [420.588, 0.6666666666666666],
            [420.64, 0.26666666666666666],
            [424.535, 0.28888888888888886],
            [426.826, 0.28888888888888886],
            [430.29799999999994, 0.35555555555555557],
            [435.514, 0.24444444444444444],
            [437.88199999999995, 0.2222222222222222],
            [438.60699999999997, 0.3333333333333333],
            [439.84499999999997, 0.24444444444444444],
            [440.25, 0.4],
            [441.57399999999996, 0.28888888888888886],
            [451.09799999999996, 0.8],
            [453.08500000000004, 0.4222222222222222],
            [455.195, 0.28888888888888886],
            [456.58500000000004, 0.37777777777777777],
            [457.43100000000004, 0.7555555555555555],
            [461.951, 0.5777777777777777],
            [466.91400000000004, 0.28888888888888886],
            [468.188, 1.0],
            [469.18999999999994, 0.28888888888888886],
            [474.01599999999996, 0.3333333333333333],
            [475.651, 0.4888888888888889],
            [476.89799999999997, 0.26666666666666666],
            [481.275, 0.4888888888888889],
            [492.01099999999997, 0.24444444444444444],
            [492.12700000000007, 0.2222222222222222],
            [492.6, 0.24444444444444444],
            [493.642, 0.3333333333333333],
            [503.73699999999997, 0.4444444444444444],
            [506.787, 0.2222222222222222],
            [511.584, 0.24444444444444444],
            [514.162, 0.2222222222222222],
            [514.3689999999999, 0.2222222222222222],
            [515.6560000000001, 0.7333333333333333],
            [521.274, 0.24444444444444444],
            [521.845, 0.24444444444444444],
            [534.105, 0.3111111111111111],
            [540.251, 0.4444444444444444],
            [541.919, 0.28888888888888886],
            [550.068, 0.04],
            [550.566, 0.044444444444444446],
            [551.6270000000001, 0.03333333333333333],
            [551.891, 0.2],
            [552.115, 0.02],
            [552.3979999999999, 0.022222222222222223],
            [552.836, 0.028888888888888888],
            [554.52, 0.022222222222222223],
            [554.832, 0.044444444444444446],
            [558.402, 0.06666666666666667],
            [559.875, 0.03333333333333333],
            [559.952, 0.06666666666666667],
            [560.55, 0.02],
            [561.771, 0.02],
            [562.068, 0.08888888888888889],
            [562.8199999999999, 0.028888888888888888],
            [563.571, 0.044444444444444446],
            [564.018, 0.08888888888888889],
            [564.591, 0.3333333333333333],
            [566.49, 0.28888888888888886],
            [568.825, 0.06666666666666667],
            [569.924, 0.08888888888888889],
            [570.431, 0.03333333333333333],
            [570.6279999999999, 0.05555555555555555],
            [571.524, 0.06666666666666667],
            [571.653, 0.017777777777777778],
            [574.671, 0.051111111111111114],
            [575.581, 0.06666666666666667],
            [576.161, 0.03333333333333333],
            [576.6560000000001, 0.05555555555555555],
            [576.7909999999999, 0.06666666666666667],
            [577.193, 0.022222222222222223],
            [577.677, 0.28888888888888886],
            [578.0020000000001, 0.05555555555555555],
            [578.071, 0.2],
            [581.11, 0.28888888888888886],
            [581.6510000000001, 0.05555555555555555],
            [584.394, 0.1],
            [584.9680000000001, 0.028888888888888888],
            [586.661, 0.03333333333333333],
            [587.736, 0.5333333333333333],
            [588.23, 0.28888888888888886],
            [590.191, 0.2],
            [591.6510000000001, 0.06666666666666667],
            [591.895, 0.2],
            [592.5899999999999, 0.03333333333333333],
            [593.062, 0.03333333333333333],
            [593.105, 0.051111111111111114],
            [593.168, 0.044444444444444446],
            [593.554, 0.04],
            [593.976, 0.28888888888888886],
            [594.402, 0.5333333333333333],
            [595.178, 0.05555555555555555],
            [596.013, 0.04],
            [599.723, 0.4222222222222222],
            [600.989, 0.05555555555555555],
            [601.5899999999999, 0.05555555555555555],
            [602.072, 0.2222222222222222],
            [604.539, 0.5555555555555556],
            [604.725, 0.2222222222222222],
            [605.37, 0.05555555555555555],
            [609.082, 0.06666666666666667],
            [609.206, 0.04],
            [610.158, 0.2222222222222222],
            [614.007, 0.05555555555555555],
            [614.456, 0.14444444444444443],
            [605.37, 0.05555555555555555],
            [609.082, 0.06666666666666667],
            [609.206, 0.04],
            [610.158, 0.2222222222222222],
            [614.007, 0.05555555555555555],
            [614.456, 0.14444444444444443],
            [615.254, 0.06666666666666667],
            [615.45, 0.28888888888888886],
            [615.884, 0.08888888888888889],
            [617.046, 0.03333333333333333],
            [618.966, 0.03333333333333333],
            [619.3109999999999, 0.03333333333333333],
            [620.837, 0.05555555555555555],
            [624.979, 0.08888888888888889],
            [625.668, 0.3333333333333333],
            [626.87, 0.3333333333333333],
            [627.8340000000001, 0.1111111111111111],
            [628.133, 0.14444444444444443],
            [628.736, 0.03333333333333333],
            [628.7909999999999, 0.08888888888888889],
            [628.934, 0.08888888888888889],
            [630.9060000000001, 0.1111111111111111],
            [630.958, 0.3333333333333333],
            [631.222, 0.05555555555555555],
            [632.508, 0.16666666666666666],
            [633.2909999999999, 0.1111111111111111],
            [634.117, 0.14444444444444443],
            [634.6020000000001, 0.06666666666666667],
            [635.616, 0.16666666666666666],
            [636.0840000000001, 0.14444444444444443],
            [637.306, 0.08888888888888889],
            [637.9069999999999, 0.03333333333333333],
            [638.9449999999999, 0.2],
            [639.221, 0.051111111111111114],
            [642.86, 0.14444444444444443],
            [643.079, 0.5555555555555556],
            [643.736, 0.028888888888888888],
            [644.461, 0.08888888888888889],
            [644.587, 0.06666666666666667],
            [645.036, 0.4444444444444444],
            [645.583, 0.044444444444444446],
            [645.992, 0.06666666666666667],
            [648.537, 0.8444444444444444],
            [650.243, 0.04],
            [650.552, 0.14444444444444443],
            [651.4390000000001, 0.2222222222222222],
            [651.61, 0.2222222222222222],
            [656.1600000000001, 0.05555555555555555],
            [656.426, 0.05555555555555555],
            [657.484, 0.2222222222222222],
            [658.513, 0.022222222222222223],
            [658.716, 0.03333333333333333],
            [661.1949999999999, 0.24444444444444444],
            [662.13, 0.16666666666666666],
            [666.2239999999999, 0.03333333333333333],
            [667.3729999999999, 0.2222222222222222],
            [667.553, 0.4],
            [668.4, 0.06666666666666667],
            [669.361, 0.03333333333333333],
            [670.646, 0.03333333333333333],
            [670.9390000000001, 0.05555555555555555],
            [671.444, 0.022222222222222223],
            [672.361, 0.03333333333333333],
            [674.073, 0.16666666666666666],
            [675.491, 0.08888888888888889],
            [675.585, 0.028888888888888888],
            [677.037, 0.028888888888888888],
            [677.174, 0.16666666666666666],
            [677.425, 0.08888888888888889],
            [678.899, 0.08888888888888889],
            [679.0060000000001, 0.028888888888888888],
            [679.927, 0.028888888888888888],
            [681.046, 0.08888888888888889],
            [681.325, 0.35555555555555557],
            [681.9359999999999, 0.044444444444444446],
            [682.496, 0.04],
            [683.2, 0.028888888888888888],
            [685.083, 0.03333333333333333],
            [686.513, 0.03333333333333333],
            [686.6229999999999, 0.4666666666666667],
            [687.527, 0.4],
            [687.749, 0.08888888888888889],
            [689.677, 0.03333333333333333],
            [690.0550000000001, 0.08888888888888889],
            [690.21, 0.3333333333333333],
            [692.738, 0.3111111111111111],
            [692.854, 0.3111111111111111],
            [693.933, 0.017777777777777778],
            [694.687, 0.044444444444444446],
            [695.126, 0.14444444444444443],
            [695.388, 0.1],
            [696.613, 0.4],
            [696.949, 0.017777777777777778],
            [697.1310000000001, 0.017777777777777778],
            [697.153, 0.02],
            [698.3520000000001, 0.051111111111111114],
            [699.522, 0.24444444444444444],
        ],
    },
    {
        name: "thallium",
        lines: [
            [422.305, 0.00044444444444444447],
            [427.49799999999993, 0.0011111111111111111],
            [430.68, 0.0022222222222222222],
            [435.98999999999995, 0.00011111111111111112],
            [449.07700000000006, 0.00044444444444444447],
            [473.70500000000004, 0.0011111111111111111],
            [498.13500000000005, 0.0008333333333333334],
            [507.854, 0.001388888888888889],
            [515.214, 0.001388888888888889],
            [518.1949999999999, 0.0003333333333333333],
            [518.3100000000001, 0.0003333333333333333],
            [535.046, 1.0],
            [538.485, 0.0008333333333333334],
            [540.992, 0.00038888888888888887],
            [541.097, 0.0005555555555555556],
            [594.948, 0.001388888888888889],
            [617.9979999999999, 0.0005555555555555556],
            [623.903, 0.00044444444444444447],
            [637.832, 0.0005555555555555556],
            [654.984, 0.0008888888888888889],
            [671.38, 0.0003333333333333333],
            [696.65, 0.0005555555555555556],
            [410.985, 0.00038888888888888887],
            [415.575, 0.00022222222222222223],
            [426.98100000000005, 0.0003333333333333333],
            [438.05699999999996, 0.00011111111111111112],
            [508.69899999999996, 0.00022222222222222223],
            [536.24, 0.00022222222222222223],
            [549.9399999999999, 0.00011111111111111112],
            [592.78, 0.0002777777777777778],
        ],
    },
    {
        name: "thorium",
        lines: [
            [400.33090000000004, 0.05714285714285714],
            [400.70210000000003, 0.05952380952380952],
            [400.821, 0.05238095238095238],
            [400.9056, 0.05238095238095238],
            [401.2495, 0.06666666666666667],
            [401.9129, 1.0],
            [402.5656, 0.05],
            [402.7009, 0.03333333333333333],
            [403.0842, 0.05952380952380952],
            [403.6047, 0.05952380952380952],
            [403.6565, 0.05714285714285714],
            [404.1204, 0.05714285714285714],
            [404.82869999999997, 0.013095238095238096],
            [405.0887, 0.02619047619047619],
            [405.9253, 0.03333333333333333],
            [406.3407, 0.05952380952380952],
            [406.9201, 0.07142857142857142],
            [406.9461, 0.023809523809523808],
            [407.5503, 0.013095238095238096],
            [408.1368, 0.02619047619047619],
            [408.5434, 0.02023809523809524],
            [408.652, 0.16666666666666666],
            [408.87260000000003, 0.016666666666666666],
            [409.4747, 0.16666666666666666],
            [410.0341, 0.03571428571428571],
            [410.533, 0.06428571428571428],
            [410.8421, 0.2],
            [411.2754, 0.05714285714285714],
            [411.57579999999996, 0.06666666666666667],
            [411.6713, 0.2619047619047619],
            [412.36, 0.007142857142857143],
            [412.7411, 0.047619047619047616],
            [413.10020000000003, 0.02619047619047619],
            [413.27529999999996, 0.08095238095238096],
            [413.4067, 0.047619047619047616],
            [414.02349999999996, 0.05238095238095238],
            [414.2701, 0.05952380952380952],
            [414.8182, 0.047619047619047616],
            [414.9986, 0.10714285714285714],
            [415.8535, 0.02619047619047619],
            [416.5766, 0.03333333333333333],
            [417.80600000000004, 0.14761904761904762],
            [417.9714, 0.05952380952380952],
            [418.4138, 0.007142857142857143],
            [419.3017, 0.030952380952380953],
            [420.889, 0.14761904761904762],
            [421.09229999999997, 0.030952380952380953],
            [421.48280000000005, 0.006666666666666667],
            [422.00649999999996, 0.013095238095238096],
            [422.7387, 0.013095238095238096],
            [423.08239999999995, 0.007142857142857143],
            [423.5463, 0.02023809523809524],
            [424.1094, 0.004761904761904762],
            [424.79889999999995, 0.007142857142857143],
            [425.3538, 0.02619047619047619],
            [425.6254, 0.016666666666666666],
            [426.03329999999994, 0.02619047619047619],
            [426.9942, 0.006666666666666667],
            [427.3357, 0.06666666666666667],
            [427.73130000000003, 0.11428571428571428],
            [428.2042, 0.16666666666666666],
            [428.8669, 0.006666666666666667],
            [429.7306, 0.013095238095238096],
            [429.9839, 0.02023809523809524],
            [430.71760000000006, 0.023809523809523808],
            [430.9991, 0.047619047619047616],
            [431.5254, 0.013095238095238096],
            [431.8416, 0.02619047619047619],
            [432.52740000000006, 0.007142857142857143],
            [433.0844, 0.006666666666666667],
            [433.7277, 0.030952380952380953],
            [434.22560000000004, 0.02023809523809524],
            [434.4326, 0.030952380952380953],
            [434.9072, 0.013095238095238096],
            [435.44840000000005, 0.013095238095238096],
            [435.9372, 0.02023809523809524],
            [436.593, 0.02023809523809524],
            [437.41229999999996, 0.02023809523809524],
            [438.186, 0.30952380952380953],
            [439.111, 0.2619047619047619],
            [439.29740000000004, 0.013095238095238096],
            [440.158, 0.013095238095238096],
            [440.8882, 0.02023809523809524],
            [441.2741, 0.05],
            [441.6845, 0.006666666666666667],
            [442.2048, 0.011904761904761904],
            [443.2963, 0.05952380952380952],
            [444.0866, 0.03333333333333333],
            [445.25649999999996, 0.005952380952380952],
            [445.8002, 0.02023809523809524],
            [446.5341, 0.05238095238095238],
            [447.52209999999997, 0.007142857142857143],
            [448.2169, 0.017857142857142856],
            [448.96639999999996, 0.011904761904761904],
            [449.89399999999995, 0.02619047619047619],
            [450.52160000000003, 0.013095238095238096],
            [451.0527, 0.06666666666666667],
            [452.11940000000004, 0.016666666666666666],
            [453.03190000000006, 0.005238095238095238],
            [453.5255, 0.009523809523809525],
            [454.5915, 0.007142857142857143],
            [455.58119999999997, 0.016666666666666666],
            [456.366, 0.009523809523809525],
            [457.0972, 0.015476190476190477],
            [458.84260000000006, 0.011904761904761904],
            [459.5421, 0.017857142857142856],
            [461.2554, 0.006190476190476191],
            [462.11629999999997, 0.007142857142857143],
            [463.1761, 0.03333333333333333],
            [463.1761, 0.03333333333333333],
            [464.1254, 0.007142857142857143],
            [465.1558, 0.03333333333333333],
            [466.3202, 0.0054761904761904765],
            [466.99840000000006, 0.011904761904761904],
            [467.6056, 0.015476190476190477],
            [468.61949999999996, 0.011904761904761904],
            [469.4091, 0.03333333333333333],
            [470.399, 0.011904761904761904],
            [471.2841, 0.004761904761904762],
            [472.3438, 0.02142857142857143],
            [472.91279999999995, 0.007142857142857143],
            [474.0529, 0.04523809523809524],
            [475.2414, 0.03333333333333333],
            [476.66, 0.0030952380952380953],
            [477.82939999999996, 0.011904761904761904],
            [478.6531, 0.004761904761904762],
            [478.9387, 0.009523809523809525],
            [480.8134, 0.010714285714285714],
            [481.9193, 0.004761904761904762],
            [482.28549999999996, 0.006190476190476191],
            [482.66999999999996, 0.009523809523809525],
            [483.1121, 0.010714285714285714],
            [484.0843, 0.011904761904761904],
            [484.8362, 0.007142857142857143],
            [485.2868, 0.0035714285714285713],
            [485.83329999999995, 0.009523809523809525],
            [486.31629999999996, 0.06666666666666667],
            [487.29170000000005, 0.009523809523809525],
            [487.87330000000003, 0.006190476190476191],
            [489.4955, 0.010714285714285714],
            [490.7209, 0.004761904761904762],
            [491.98159999999996, 0.05714285714285714],
            [492.77799999999996, 0.004285714285714286],
            [493.9642, 0.009523809523809525],
            [494.7575, 0.014285714285714285],
            [495.4659, 0.011904761904761904],
            [496.57309999999995, 0.007142857142857143],
            [497.59499999999997, 0.008333333333333333],
            [498.53720000000004, 0.005714285714285714],
            [500.2097, 0.011904761904761904],
            [500.2097, 0.011904761904761904],
            [501.5889, 0.011904761904761904],
            [501.7255, 0.06190476190476191],
            [502.9892, 0.004761904761904762],
            [503.92299999999994, 0.005714285714285714],
            [504.4719, 0.011904761904761904],
            [504.9796, 0.05714285714285714],
            [505.5347, 0.02023809523809524],
            [505.8562, 0.016666666666666666],
            [506.79740000000004, 0.02619047619047619],
            [508.14459999999997, 0.007142857142857143],
            [509.0051, 0.011904761904761904],
            [509.80429999999996, 0.011904761904761904],
            [510.113, 0.009523809523809525],
            [511.0867, 0.011904761904761904],
            [511.5044, 0.007142857142857143],
            [512.595, 0.002380952380952381],
            [513.4746, 0.004761904761904762],
            [514.3267, 0.02261904761904762],
            [514.8211, 0.02857142857142857],
            [515.1612, 0.011904761904761904],
            [515.4243, 0.011904761904761904],
            [515.8604, 0.02023809523809524],
            [516.073, 0.016666666666666666],
            [516.8922, 0.004761904761904762],
            [517.6961, 0.011904761904761904],
            [518.399, 0.008333333333333333],
            [519.0871, 0.011904761904761904],
            [519.5814, 0.011904761904761904],
            [519.88, 0.011904761904761904],
            [519.9164, 0.02261904761904762],
            [521.1229999999999, 0.011904761904761904],
            [521.6596, 0.02261904761904762],
            [521.8528, 0.011904761904761904],
            [521.911, 0.008333333333333333],
            [523.116, 0.02619047619047619],
            [523.3225, 0.02023809523809524],
            [523.3229, 0.02023809523809524],
            [524.7654, 0.02261904761904762],
            [525.5573, 0.002380952380952381],
            [525.836, 0.008333333333333333],
            [526.671, 0.002857142857142857],
            [527.7501, 0.016666666666666666],
            [528.1069, 0.0035714285714285713],
            [529.4397, 0.002380952380952381],
            [529.7743, 0.007142857142857143],
            [530.7466000000001, 0.007142857142857143],
            [531.2002, 0.008333333333333333],
            [531.7493999999999, 0.004761904761904762],
            [532.5145, 0.014285714285714285],
            [532.6976, 0.011904761904761904],
            [533.008, 0.004761904761904762],
            [534.3581, 0.014285714285714285],
            [535.1126, 0.0033333333333333335],
            [535.8707, 0.007142857142857143],
            [536.9281, 0.004761904761904762],
            [537.8836, 0.007142857142857143],
            [539.0466, 0.016666666666666666],
            [539.2572, 0.011904761904761904],
            [539.9175, 0.004761904761904762],
            [541.7486, 0.005714285714285714],
            [542.5678, 0.014285714285714285],
            [543.5893, 0.011904761904761904],
            [544.9479, 0.009523809523809525],
            [546.2615, 0.007142857142857143],
            [547.0759, 0.0035714285714285713],
            [548.4147, 0.005714285714285714],
            [549.6137, 0.002380952380952381],
            [550.4302, 0.004523809523809524],
            [550.9993999999999, 0.008333333333333333],
            [552.4584, 0.002857142857142857],
            [553.9262, 0.011904761904761904],
            [553.9911, 0.016666666666666666],
            [554.8176000000001, 0.008333333333333333],
            [555.8342, 0.011904761904761904],
            [556.4203, 0.014285714285714285],
            [557.3354, 0.009523809523809525],
            [558.7026, 0.014285714285714285],
            [559.5064, 0.005714285714285714],
            [560.4515, 0.011904761904761904],
            [561.5319999999999, 0.008333333333333333],
            [563.0296999999999, 0.0016666666666666668],
            [563.9746, 0.016666666666666666],
            [564.8991, 0.0016666666666666668],
            [565.7925, 0.002857142857142857],
            [566.7128, 0.0035714285714285713],
            [567.7053, 0.004761904761904762],
            [568.5192, 0.002380952380952381],
            [570.0917999999999, 0.015476190476190477],
            [570.7103, 0.02261904761904762],
            [572.0183, 0.011904761904761904],
            [573.2975, 0.007142857142857143],
            [574.2084, 0.005714285714285714],
            [574.9388, 0.007142857142857143],
            [576.0551, 0.016666666666666666],
            [577.3946, 0.0035714285714285713],
            [578.9645, 0.004761904761904762],
            [580.4141, 0.008333333333333333],
            [581.5422, 0.004523809523809524],
            [583.237, 0.002380952380952381],
            [584.5919, 0.002380952380952381],
            [585.4121, 0.0035714285714285713],
            [586.8372999999999, 0.0035714285714285713],
            [587.8933, 0.002380952380952381],
            [589.1451, 0.0019047619047619048],
            [589.9844, 0.0035714285714285713],
            [591.4386999999999, 0.004761904761904762],
            [592.5893, 0.004523809523809524],
            [593.7162000000001, 0.002380952380952381],
            [594.4648, 0.002380952380952381],
            [595.7587000000001, 0.0019047619047619048],
            [597.3665, 0.007142857142857143],
            [598.9044, 0.02023809523809524],
            [599.4129, 0.005714285714285714],
            [600.7072000000001, 0.005],
            [601.5426, 0.007142857142857143],
            [602.1036, 0.004047619047619047],
            [603.7698, 0.004047619047619047],
            [604.4431, 0.005714285714285714],
            [605.3381, 0.002380952380952381],
            [606.1536, 0.0011904761904761906],
            [607.7106, 0.007142857142857143],
            [608.7262, 0.007142857142857143],
            [609.9082999999999, 0.005714285714285714],
            [610.458, 0.007142857142857143],
            [611.2837000000001, 0.009523809523809525],
            [612.0557, 0.007142857142857143],
            [612.448, 0.002380952380952381],
            [615.1993, 0.0033333333333333335],
            [616.1354, 0.002380952380952381],
            [616.9822, 0.014285714285714285],
            [618.2622, 0.011904761904761904],
            [619.1906, 0.002857142857142857],
            [619.3858, 0.005714285714285714],
            [620.3493000000001, 0.002857142857142857],
            [622.4528, 0.002857142857142857],
            [623.4856, 0.005714285714285714],
            [624.0953999999999, 0.0019047619047619048],
            [625.7424, 0.002380952380952381],
            [626.1063, 0.005],
            [626.1418, 0.005],
            [627.4116, 0.011904761904761904],
            [627.4117, 0.011904761904761904],
            [627.9172, 0.007142857142857143],
            [629.1192, 0.0019047619047619048],
            [630.3251, 0.002380952380952381],
            [631.7185000000001, 0.0019047619047619048],
            [632.7278, 0.005],
            [634.286, 0.008333333333333333],
            [635.5911, 0.011904761904761904],
            [636.914, 0.0033333333333333335],
            [637.6931, 0.009523809523809525],
            [641.1899000000001, 0.007142857142857143],
            [641.3615, 0.005714285714285714],
            [643.7762, 0.0035714285714285713],
            [645.0005, 0.0035714285714285713],
            [645.7283, 0.014285714285714285],
            [646.2614, 0.011904761904761904],
            [646.6717, 0.0011904761904761906],
            [649.0738, 0.0033333333333333335],
            [650.1992, 0.0011904761904761906],
            [651.2364, 0.004761904761904762],
            [652.2044, 0.0011904761904761906],
            [653.1342, 0.011904761904761904],
            [655.4159999999999, 0.0014285714285714286],
            [655.8876, 0.0011904761904761906],
            [656.507, 0.0007142857142857143],
            [657.7215, 0.0011904761904761906],
            [658.3907, 0.005714285714285714],
            [658.854, 0.005714285714285714],
            [659.394, 0.005714285714285714],
            [660.5416, 0.005714285714285714],
            [661.9946, 0.005714285714285714],
            [661.9947, 0.005714285714285714],
            [664.4649999999999, 0.005],
            [665.8678, 0.0014285714285714286],
            [666.2269, 0.007142857142857143],
            [667.4697, 0.0014285714285714286],
            [668.3367000000001, 0.0007142857142857143],
            [669.2724000000001, 0.0019047619047619048],
            [669.7712, 0.0011904761904761906],
            [672.7459, 0.0038095238095238095],
            [673.5126, 0.0007142857142857143],
            [674.2884, 0.0011904761904761906],
            [675.6453, 0.004761904761904762],
            [676.5677, 0.0014285714285714286],
            [677.8313, 0.0035714285714285713],
            [678.0413, 0.0035714285714285713],
            [679.1236, 0.0014285714285714286],
            [679.8747000000001, 0.0007142857142857143],
            [680.9511, 0.0007142857142857143],
            [682.3509, 0.0011904761904761906],
            [682.9036, 0.002619047619047619],
            [683.4925000000001, 0.0033333333333333335],
            [686.2873, 0.0009523809523809524],
            [686.6367, 0.0011904761904761906],
            [687.4754, 0.0019047619047619048],
            [688.9303, 0.004761904761904762],
            [690.8988, 0.0007142857142857143],
            [691.1227, 0.005714285714285714],
            [691.6129, 0.0011904761904761906],
            [693.6652, 0.0011904761904761906],
            [694.3611, 0.008333333333333333],
            [695.4657, 0.0011904761904761906],
            [696.5947, 0.0035714285714285713],
            [698.1086, 0.0007142857142857143],
            [698.9656, 0.013095238095238096],
            [699.3037999999999, 0.005714285714285714],
            [455.573, 0.023809523809523808],
            [458.928, 0.011904761904761904],
            [537.613, 0.023809523809523808],
            [544.7180000000001, 0.011904761904761904],
            [624.295, 0.011904761904761904],
            [659.9390000000001, 0.011904761904761904],
            [493.709, 0.0004761904761904762],
            [495.25200000000007, 0.0009523809523809524],
            [542.038, 0.0007142857142857143],
            [671.187, 0.0004761904761904762],
            [674.037, 0.0007142857142857143],
            [690.116, 0.011904761904761904],
        ],
    },
    {
        name: "titanium",
        lines: [
            [400.24899999999997, 0.011666666666666667],
            [400.381, 0.011666666666666667],
            [400.597, 0.005833333333333334],
            [400.806, 0.011666666666666667],
            [400.893, 0.15833333333333333],
            [400.966, 0.03166666666666667],
            [401.239, 0.011666666666666667],
            [401.358, 0.03],
            [401.538, 0.011666666666666667],
            [401.62800000000004, 0.005833333333333334],
            [401.777, 0.02],
            [402.183, 0.023333333333333334],
            [402.457, 0.2],
            [402.514, 0.006666666666666667],
            [402.654, 0.03166666666666667],
            [402.748, 0.006666666666666667],
            [402.834, 0.006666666666666667],
            [403.05100000000004, 0.03166666666666667],
            [403.39099999999996, 0.006666666666666667],
            [403.491, 0.005],
            [403.58299999999997, 0.018333333333333333],
            [404.03200000000004, 0.005833333333333334],
            [405.502, 0.04833333333333333],
            [405.762, 0.014166666666666666],
            [405.81399999999996, 0.014166666666666666],
            [406.026, 0.06833333333333333],
            [406.42199999999997, 0.03333333333333333],
            [406.51, 0.03333333333333333],
            [407.847, 0.14],
            [407.972, 0.006666666666666667],
            [408.246, 0.04833333333333333],
            [409.91700000000003, 0.014166666666666666],
            [411.271, 0.03666666666666667],
            [412.217, 0.014166666666666666],
            [412.331, 0.006666666666666667],
            [412.35699999999997, 0.014166666666666666],
            [412.754, 0.021666666666666667],
            [412.91700000000003, 0.006666666666666667],
            [413.125, 0.006666666666666667],
            [413.729, 0.023333333333333334],
            [414.305, 0.014166666666666666],
            [415.096, 0.028333333333333332],
            [415.96400000000006, 0.014166666666666666],
            [416.36499999999995, 0.011666666666666667],
            [416.41400000000004, 0.005833333333333334],
            [416.63199999999995, 0.006666666666666667],
            [416.93500000000006, 0.014166666666666666],
            [417.10299999999995, 0.02],
            [417.18999999999994, 0.006666666666666667],
            [418.33000000000004, 0.005833333333333334],
            [418.61199999999997, 0.06],
            [418.86899999999997, 0.006666666666666667],
            [420.075, 0.011666666666666667],
            [420.346, 0.014166666666666666],
            [421.17299999999994, 0.005833333333333334],
            [422.479, 0.006666666666666667],
            [422.765, 0.006666666666666667],
            [423.78900000000004, 0.021666666666666667],
            [424.912, 0.014166666666666666],
            [425.604, 0.021666666666666667],
            [425.854, 0.011666666666666667],
            [426.16, 0.011666666666666667],
            [426.313, 0.055],
            [426.571, 0.005833333333333334],
            [426.622, 0.006666666666666667],
            [427.014, 0.011666666666666667],
            [427.24300000000005, 0.014166666666666666],
            [427.45799999999997, 0.04],
            [427.64300000000003, 0.02],
            [427.823, 0.02],
            [427.88100000000003, 0.005],
            [428.13800000000003, 0.018333333333333333],
            [428.271, 0.03666666666666667],
            [428.49899999999997, 0.02666666666666667],
            [428.601, 0.14833333333333334],
            [428.73999999999995, 0.14],
            [428.816, 0.005],
            [428.907, 0.15833333333333333],
            [429.02299999999997, 0.02],
            [429.09399999999994, 0.14],
            [429.11400000000003, 0.02],
            [429.412, 0.023333333333333334],
            [429.576, 0.14],
            [429.866, 0.3333333333333333],
            [429.92299999999994, 0.03333333333333333],
            [429.96400000000006, 0.03333333333333333],
            [430.005, 0.03333333333333333],
            [430.05600000000004, 0.48333333333333334],
            [430.10900000000004, 0.6833333333333333],
            [430.19300000000004, 0.014166666666666666],
            [430.592, 1.0],
            [430.78999999999996, 0.03],
            [430.85, 0.005833333333333334],
            [431.16499999999996, 0.006666666666666667],
            [431.287, 0.014166666666666666],
            [431.43500000000006, 0.014166666666666666],
            [431.48, 0.2],
            [431.86400000000003, 0.06],
            [432.166, 0.03],
            [432.51300000000003, 0.03166666666666667],
            [432.63599999999997, 0.02666666666666667],
            [433.48400000000004, 0.005],
            [433.79200000000003, 0.02666666666666667],
            [434.429, 0.004],
            [434.611, 0.011666666666666667],
            [435.40600000000006, 0.005833333333333334],
            [436.049, 0.015833333333333335],
            [436.89399999999995, 0.004],
            [436.968, 0.015833333333333335],
            [437.238, 0.01],
            [438.808, 0.005],
            [439.392, 0.028333333333333332],
            [439.504, 0.055],
            [439.97700000000003, 0.01],
            [440.428, 0.04],
            [440.48999999999995, 0.01],
            [440.56800000000004, 0.005],
            [441.654, 0.01],
            [441.72799999999995, 0.03666666666666667],
            [441.77200000000005, 0.01],
            [442.17600000000004, 0.02],
            [442.282, 0.02],
            [442.439, 0.004],
            [442.58299999999997, 0.005],
            [442.60600000000005, 0.02],
            [442.71000000000004, 0.14833333333333334],
            [443.00200000000007, 0.0035],
            [443.037, 0.014166666666666666],
            [443.128, 0.008333333333333333],
            [443.26000000000005, 0.005],
            [443.358, 0.004],
            [443.4, 0.028333333333333332],
            [443.659, 0.011666666666666667],
            [443.823, 0.005],
            [444.035, 0.021666666666666667],
            [444.12700000000007, 0.008333333333333333],
            [444.38, 0.03833333333333333],
            [444.427, 0.004],
            [444.91499999999996, 0.14],
            [445.049, 0.005],
            [445.09, 0.09166666666666666],
            [445.332, 0.14],
            [445.371, 0.04833333333333333],
            [445.533, 0.15833333333333333],
            [445.74300000000005, 0.18333333333333332],
            [446.209, 0.0035],
            [446.338, 0.011666666666666667],
            [446.354, 0.015833333333333335],
            [446.581, 0.04833333333333333],
            [446.85, 0.04],
            [447.12399999999997, 0.04],
            [447.485, 0.015833333333333335],
            [447.96999999999997, 0.015833333333333335],
            [448.059, 0.008333333333333333],
            [448.12600000000003, 0.08833333333333333],
            [448.26899999999995, 0.015833333333333335],
            [448.832, 0.0031666666666666666],
            [448.909, 0.043333333333333335],
            [449.255, 0.004],
            [449.50100000000003, 0.006666666666666667],
            [449.61499999999995, 0.04],
            [449.77299999999997, 0.004],
            [450.12700000000007, 0.03333333333333333],
            [450.378, 0.006666666666666667],
            [450.63599999999997, 0.0035],
            [451.117, 0.008333333333333333],
            [451.274, 0.13],
            [451.562, 0.0031666666666666666],
            [451.803, 0.16666666666666666],
            [451.87, 0.015833333333333335],
            [452.28000000000003, 0.16666666666666666],
            [452.73100000000005, 0.13],
            [453.32399999999996, 1.0],
            [453.39700000000005, 0.04],
            [453.47799999999995, 0.6],
            [453.558, 0.4],
            [453.592, 0.2],
            [453.605, 0.2],
            [453.72299999999996, 0.004],
            [453.91, 0.004],
            [454.46899999999994, 0.12],
            [454.87700000000007, 0.15833333333333333],
            [454.963, 0.04],
            [455.246, 0.15833333333333333],
            [455.508, 0.004],
            [455.549, 0.12],
            [455.78599999999994, 0.0031666666666666666],
            [455.811, 0.0031666666666666666],
            [455.992, 0.01],
            [456.26300000000003, 0.008333333333333333],
            [456.343, 0.005833333333333334],
            [456.37700000000007, 0.018333333333333333],
            [457.091, 0.005833333333333334],
            [457.198, 0.04],
            [458.584, 0.0031666666666666666],
            [458.995, 0.004],
            [459.92299999999994, 0.01],
            [460.937, 0.0035],
            [461.72700000000003, 0.15833333333333333],
            [461.95200000000006, 0.004],
            [462.309, 0.08],
            [462.934, 0.03166666666666667],
            [463.48699999999997, 0.008333333333333333],
            [463.788, 0.01],
            [463.937, 0.04],
            [463.967, 0.03666666666666667],
            [463.995, 0.03166666666666667],
            [464.51899999999995, 0.023333333333333334],
            [465.00200000000007, 0.02],
            [465.604, 0.004],
            [465.64700000000005, 0.12],
            [466.759, 0.14],
            [467.512, 0.011666666666666667],
            [468.192, 0.15833333333333333],
            [468.692, 0.0035],
            [469.08000000000004, 0.004],
            [469.134, 0.03166666666666667],
            [469.36800000000005, 0.006666666666666667],
            [469.69399999999996, 0.004],
            [469.87600000000003, 0.03166666666666667],
            [471.01899999999995, 0.02],
            [471.53000000000003, 0.004],
            [472.262, 0.010833333333333334],
            [472.317, 0.010833333333333334],
            [473.117, 0.009166666666666667],
            [473.343, 0.0075],
            [473.468, 0.003],
            [474.21099999999996, 0.0036666666666666666],
            [474.279, 0.028333333333333332],
            [474.76800000000003, 0.0036666666666666666],
            [475.812, 0.051666666666666666],
            [475.928, 0.051666666666666666],
            [476.633, 0.0075],
            [476.97700000000003, 0.004666666666666667],
            [477.826, 0.010833333333333334],
            [478.172, 0.0075],
            [479.24899999999997, 0.018333333333333333],
            [479.622, 0.0075],
            [479.79799999999994, 0.005833333333333334],
            [479.98, 0.018333333333333333],
            [480.51000000000005, 0.004666666666666667],
            [480.543, 0.018333333333333333],
            [496.858, 0.010833333333333334],
            [497.305, 0.0125],
            [497.535, 0.02],
            [497.774, 0.010833333333333334],
            [497.82, 0.02],
            [498.17299999999994, 0.9666666666666667],
            [498.91499999999996, 0.025],
            [499.10699999999997, 0.7666666666666667],
            [499.508, 0.005],
            [499.71000000000004, 0.023333333333333334],
            [499.951, 0.6666666666666666],
            [500.101, 0.03833333333333333],
            [500.721, 0.6],
            [500.965, 0.02],
            [501.33000000000004, 0.03833333333333333],
            [501.419, 0.5333333333333333],
            [501.617, 0.09666666666666666],
            [502.003, 0.14],
            [502.287, 0.14],
            [502.48400000000004, 0.09666666666666666],
            [502.558, 0.05],
            [503.591, 0.2],
            [503.64700000000005, 0.14],
            [503.84, 0.12333333333333334],
            [503.995, 0.2],
            [504.062, 0.0125],
            [504.35900000000004, 0.014166666666666666],
            [504.427, 0.005833333333333334],
            [504.541, 0.009166666666666667],
            [504.821, 0.004333333333333333],
            [505.287, 0.018333333333333333],
            [505.408, 0.0035],
            [506.21099999999996, 0.018333333333333333],
            [506.407, 0.005833333333333334],
            [506.466, 0.23333333333333334],
            [506.599, 0.015833333333333335],
            [506.83299999999997, 0.005833333333333334],
            [506.93500000000006, 0.010833333333333334],
            [507.14799999999997, 0.021666666666666667],
            [508.534, 0.006666666666666667],
            [510.94399999999996, 0.009166666666666667],
            [511.34399999999994, 0.03166666666666667],
            [512.042, 0.045],
            [512.915, 0.005],
            [514.547, 0.045],
            [514.7479999999999, 0.03833333333333333],
            [515.22, 0.035],
            [516.6859999999999, 0.0035],
            [518.87, 0.014166666666666666],
            [518.958, 0.005],
            [519.298, 0.21666666666666667],
            [519.404, 0.014166666666666666],
            [520.11, 0.010833333333333334],
            [520.608, 0.02],
            [520.787, 0.0125],
            [520.842, 0.010833333333333334],
            [521.039, 0.23333333333333334],
            [521.229, 0.010833333333333334],
            [521.971, 0.025],
            [522.269, 0.015833333333333335],
            [522.364, 0.014166666666666666],
            [522.432, 0.041666666666666664],
            [522.456, 0.015833333333333335],
            [522.495, 0.03166666666666667],
            [522.6560000000001, 0.010833333333333334],
            [523.858, 0.02],
            [524.615, 0.0035],
            [524.6569999999999, 0.009166666666666667],
            [524.731, 0.0125],
            [525.095, 0.0035],
            [525.211, 0.018333333333333333],
            [525.583, 0.0125],
            [525.999, 0.009166666666666667],
            [526.35, 0.009166666666666667],
            [526.598, 0.025],
            [528.239, 0.006666666666666667],
            [528.345, 0.023333333333333334],
            [528.4390000000001, 0.005833333333333334],
            [528.8810000000001, 0.004333333333333333],
            [529.579, 0.010833333333333334],
            [529.726, 0.02],
            [529.8439999999999, 0.010833333333333334],
            [533.681, 0.004333333333333333],
            [534.15, 0.0028333333333333335],
            [535.108, 0.0125],
            [536.665, 0.004333333333333333],
            [536.964, 0.009166666666666667],
            [538.918, 0.006666666666666667],
            [538.999, 0.009166666666666667],
            [539.6600000000001, 0.0028333333333333335],
            [539.7090000000001, 0.014166666666666666],
            [540.402, 0.005833333333333334],
            [540.961, 0.018333333333333333],
            [542.626, 0.006666666666666667],
            [542.915, 0.0125],
            [543.673, 0.004333333333333333],
            [543.832, 0.0028333333333333335],
            [544.664, 0.006666666666666667],
            [544.8340000000001, 0.0018333333333333333],
            [544.89, 0.005],
            [544.9159999999999, 0.0035],
            [545.365, 0.005833333333333334],
            [546.051, 0.009166666666666667],
            [547.121, 0.0125],
            [547.27, 0.005833333333333334],
            [547.355, 0.006666666666666667],
            [547.423, 0.014166666666666666],
            [547.446, 0.005],
            [547.771, 0.02],
            [548.143, 0.018333333333333333],
            [548.187, 0.0125],
            [548.8199999999999, 0.014166666666666666],
            [549.015, 0.025],
            [549.0840000000001, 0.004333333333333333],
            [550.39, 0.018333333333333333],
            [551.178, 0.006666666666666667],
            [551.2529999999999, 0.056666666666666664],
            [551.4350000000001, 0.045],
            [551.454, 0.05333333333333334],
            [553.049, 0.004333333333333333],
            [556.549, 0.018333333333333333],
            [557.9159999999999, 0.0021666666666666666],
            [558.298, 0.0035],
            [558.568, 0.005],
            [559.7850000000001, 0.010833333333333334],
            [562.928, 0.009166666666666667],
            [563.5840000000001, 0.0028333333333333335],
            [564.414, 0.041666666666666664],
            [564.858, 0.0125],
            [566.155, 0.004333333333333333],
            [566.216, 0.03166666666666667],
            [566.2909999999999, 0.0125],
            [567.342, 0.0035],
            [567.544, 0.021666666666666667],
            [567.9939999999999, 0.005],
            [568.947, 0.015833333333333335],
            [570.268, 0.0125],
            [570.823, 0.005833333333333334],
            [571.188, 0.010833333333333334],
            [571.392, 0.006666666666666667],
            [571.513, 0.015833333333333335],
            [571.6479999999999, 0.009166666666666667],
            [572.048, 0.005833333333333334],
            [573.951, 0.014166666666666666],
            [574.0020000000001, 0.006666666666666667],
            [574.1220000000001, 0.0031666666666666666],
            [575.284, 0.0035],
            [575.6859999999999, 0.0031666666666666666],
            [576.2270000000001, 0.006666666666666667],
            [576.635, 0.009166666666666667],
            [577.405, 0.0125],
            [578.078, 0.005],
            [578.598, 0.0125],
            [580.426, 0.010833333333333334],
            [581.496, 0.0035],
            [582.371, 0.006666666666666667],
            [584.118, 0.0035],
            [585.234, 0.0035],
            [586.646, 0.06666666666666667],
            [588.0310000000001, 0.010833333333333334],
            [588.868, 0.0035],
            [589.932, 0.03833333333333333],
            [590.333, 0.009166666666666667],
            [591.855, 0.02],
            [592.212, 0.025],
            [593.7819999999999, 0.0125],
            [594.176, 0.02],
            [595.317, 0.05],
            [596.5840000000001, 0.03333333333333333],
            [597.856, 0.045],
            [599.904, 0.056666666666666664],
            [599.9680000000001, 0.010833333333333334],
            [601.2729999999999, 0.0035],
            [606.463, 0.018333333333333333],
            [608.5229999999999, 0.02],
            [609.117, 0.02],
            [609.2810000000001, 0.006666666666666667],
            [609.867, 0.006666666666666667],
            [612.101, 0.005833333333333334],
            [612.6220000000001, 0.02],
            [613.838, 0.0031666666666666666],
            [614.6220000000001, 0.005],
            [614.9739999999999, 0.0035],
            [616.223, 0.005],
            [618.615, 0.005833333333333334],
            [621.528, 0.015833333333333335],
            [622.049, 0.0125],
            [622.141, 0.010833333333333334],
            [625.8100000000001, 0.06333333333333334],
            [625.87, 0.06333333333333334],
            [626.11, 0.05],
            [630.375, 0.010833333333333334],
            [631.2239999999999, 0.009166666666666667],
            [631.803, 0.004333333333333333],
            [633.61, 0.005],
            [636.635, 0.005833333333333334],
            [641.9100000000001, 0.0018333333333333333],
            [649.769, 0.0028333333333333335],
            [650.8140000000001, 0.0031666666666666666],
            [654.6279999999999, 0.009166666666666667],
            [655.423, 0.010833333333333334],
            [655.483, 0.0018333333333333333],
            [655.607, 0.0125],
            [656.562, 0.0031666666666666666],
            [657.518, 0.0023333333333333335],
            [659.911, 0.005833333333333334],
            [665.146, 0.003],
            [666.655, 0.003],
            [666.774, 0.0036666666666666666],
            [666.839, 0.0015],
            [667.7180000000001, 0.003],
            [669.121, 0.0036666666666666666],
            [671.668, 0.004333333333333333],
            [672.395, 0.0026666666666666666],
            [674.312, 0.013333333333333334],
            [674.552, 0.0036666666666666666],
            [684.464, 0.003],
            [686.039, 0.003],
            [686.147, 0.005833333333333334],
            [687.392, 0.0015],
            [691.319, 0.002],
            [693.3149999999999, 0.0023333333333333335],
            [694.37, 0.0023333333333333335],
            [699.663, 0.003833333333333333],
        ],
    },
    {
        name: "tungsten",
        lines: [
            [400.875, 1.0],
            [401.522, 0.06279069767441861],
            [401.652, 0.019767441860465116],
            [401.923, 0.02558139534883721],
            [402.212, 0.015116279069767442],
            [402.879, 0.020930232558139535],
            [403.68600000000004, 0.020930232558139535],
            [403.985, 0.01627906976744186],
            [404.428, 0.01627906976744186],
            [404.559, 0.1058139534883721],
            [406.479, 0.020930232558139535],
            [406.979, 0.01744186046511628],
            [406.995, 0.08488372093023255],
            [407.06100000000004, 0.03953488372093023],
            [407.193, 0.011627906976744186],
            [407.43600000000004, 0.5813953488372093],
            [408.296, 0.01744186046511628],
            [408.83299999999997, 0.015116279069767442],
            [409.569, 0.011627906976744186],
            [410.27, 0.11627906976744186],
            [410.975, 0.01744186046511628],
            [411.18199999999996, 0.011627906976744186],
            [411.805, 0.01744186046511628],
            [411.81899999999996, 0.011627906976744186],
            [412.08500000000004, 0.011627906976744186],
            [412.51599999999996, 0.011627906976744186],
            [412.68, 0.01744186046511628],
            [413.34799999999996, 0.011627906976744186],
            [413.746, 0.06279069767441861],
            [413.802, 0.01744186046511628],
            [414.225, 0.012790697674418604],
            [414.51599999999996, 0.01627906976744186],
            [414.59499999999997, 0.012790697674418604],
            [415.466, 0.018604651162790697],
            [417.053, 0.018604651162790697],
            [417.117, 0.05232558139534884],
            [420.43999999999994, 0.018604651162790697],
            [420.70500000000004, 0.02558139534883721],
            [421.538, 0.012790697674418604],
            [421.937, 0.029069767441860465],
            [422.204, 0.012790697674418604],
            [423.434, 0.01744186046511628],
            [424.14399999999995, 0.03372093023255814],
            [424.436, 0.06279069767441861],
            [425.93500000000006, 0.03372093023255814],
            [426.029, 0.023255813953488372],
            [426.33000000000004, 0.023255813953488372],
            [426.938, 0.16279069767441862],
            [426.97700000000003, 0.012790697674418604],
            [427.45500000000004, 0.02558139534883721],
            [427.549, 0.018604651162790697],
            [427.674, 0.018604651162790697],
            [428.23400000000004, 0.012790697674418604],
            [428.601, 0.012790697674418604],
            [429.41, 0.012790697674418604],
            [429.46099999999996, 0.47674418604651164],
            [430.21099999999996, 0.2558139534883721],
            [430.687, 0.018604651162790697],
            [430.764, 0.012790697674418604],
            [433.213, 0.012790697674418604],
            [434.7, 0.011627906976744186],
            [435.517, 0.01744186046511628],
            [436.18100000000004, 0.011627906976744186],
            [436.47799999999995, 0.01744186046511628],
            [436.59499999999997, 0.011627906976744186],
            [437.25200000000007, 0.01744186046511628],
            [437.84799999999996, 0.023255813953488372],
            [438.485, 0.020930232558139535],
            [440.395, 0.011627906976744186],
            [440.828, 0.023255813953488372],
            [441.21899999999994, 0.015116279069767442],
            [443.68999999999994, 0.018604651162790697],
            [446.049, 0.01627906976744186],
            [446.634, 0.01627906976744186],
            [446.674, 0.01627906976744186],
            [448.419, 0.07441860465116279],
            [450.48400000000004, 0.018604651162790697],
            [451.288, 0.015116279069767442],
            [451.325, 0.013953488372093023],
            [454.354, 0.01744186046511628],
            [454.64700000000005, 0.01744186046511628],
            [455.18199999999996, 0.01744186046511628],
            [457.064, 0.01627906976744186],
            [458.87299999999993, 0.019767441860465116],
            [459.99399999999997, 0.01627906976744186],
            [460.98900000000003, 0.01627906976744186],
            [461.33000000000004, 0.018604651162790697],
            [464.253, 0.011627906976744186],
            [465.742, 0.015116279069767442],
            [465.98699999999997, 0.07441860465116279],
            [468.05100000000004, 0.07441860465116279],
            [469.372, 0.011627906976744186],
            [475.754, 0.01627906976744186],
            [484.38100000000003, 0.09186046511627907],
            [488.68999999999994, 0.044186046511627906],
            [498.259, 0.02558139534883721],
            [500.61499999999995, 0.03837209302325582],
            [501.53000000000003, 0.02558139534883721],
            [505.328, 0.09534883720930233],
            [505.46000000000004, 0.02441860465116279],
            [506.912, 0.02441860465116279],
            [507.174, 0.013953488372093023],
            [522.466, 0.08953488372093023],
            [550.049, 0.0031395348837209304],
            [550.3439999999999, 0.0031395348837209304],
            [550.861, 0.0011627906976744186],
            [551.4680000000001, 0.02558139534883721],
            [553.138, 0.0017441860465116279],
            [553.772, 0.0017441860465116279],
            [556.809, 0.0015116279069767441],
            [560.431, 0.0015116279069767441],
            [563.1270000000001, 0.0012790697674418604],
            [563.194, 0.0031395348837209304],
            [564.837, 0.007558139534883721],
            [566.072, 0.004069767441860465],
            [567.4390000000001, 0.0031395348837209304],
            [567.6600000000001, 0.0015116279069767441],
            [567.6899999999999, 0.0017441860465116279],
            [569.779, 0.0017441860465116279],
            [573.509, 0.006395348837209302],
            [574.924, 0.0015116279069767441],
            [575.61, 0.0012790697674418604],
            [579.306, 0.0015116279069767441],
            [579.649, 0.0015116279069767441],
            [580.485, 0.005232558139534884],
            [580.605, 0.0015116279069767441],
            [583.361, 0.0015116279069767441],
            [583.897, 0.0015116279069767441],
            [584.527, 0.0019767441860465114],
            [585.158, 0.0032558139534883722],
            [585.661, 0.0012790697674418604],
            [586.463, 0.0025581395348837207],
            [587.422, 0.0012790697674418604],
            [588.021, 0.0015116279069767441],
            [589.161, 0.0015116279069767441],
            [590.12, 0.0015116279069767441],
            [590.264, 0.004651162790697674],
            [592.858, 0.0015116279069767441],
            [594.757, 0.006395348837209302],
            [595.396, 0.0015116279069767441],
            [595.6189999999999, 0.0015116279069767441],
            [596.083, 0.0031395348837209304],
            [596.586, 0.006395348837209302],
            [597.251, 0.0031395348837209304],
            [597.886, 0.002325581395348837],
            [598.382, 0.002325581395348837],
            [600.9010000000001, 0.0015116279069767441],
            [601.278, 0.006395348837209302],
            [602.152, 0.004651162790697674],
            [602.832, 0.002325581395348837],
            [604.331, 0.002325581395348837],
            [604.992, 0.0015116279069767441],
            [606.508, 0.0015116279069767441],
            [608.144, 0.0025581395348837207],
            [611.1659999999999, 0.0015116279069767441],
            [611.552, 0.0015116279069767441],
            [612.825, 0.0025581395348837207],
            [614.394, 0.0015116279069767441],
            [615.3720000000001, 0.002325581395348837],
            [615.487, 0.002325581395348837],
            [620.351, 0.002325581395348837],
            [625.428, 0.002325581395348837],
            [628.588, 0.0031395348837209304],
            [629.202, 0.005232558139534884],
            [630.321, 0.002325581395348837],
            [638.647, 0.0015116279069767441],
            [640.421, 0.004069767441860465],
            [644.512, 0.004651162790697674],
            [650.8050000000001, 0.0012790697674418604],
            [653.239, 0.0017441860465116279],
            [653.8109999999999, 0.0015116279069767441],
            [656.3199999999999, 0.0015116279069767441],
            [657.393, 0.002325581395348837],
            [660.713, 0.0012790697674418604],
            [660.905, 0.0012790697674418604],
            [661.162, 0.0019767441860465114],
            [662.174, 0.0012790697674418604],
            [667.842, 0.0015116279069767441],
            [669.308, 0.0017441860465116279],
            [674.6560000000001, 0.0005813953488372093],
            [676.4449999999999, 0.0005813953488372093],
            [680.5310000000001, 0.0008139534883720931],
            [681.492, 0.0010465116279069768],
            [682.027, 0.0010465116279069768],
            [682.8430000000001, 0.0009302325581395349],
            [685.374, 0.00046511627906976747],
            [687.601, 0.00046511627906976747],
            [690.829, 0.0005813953488372093],
            [693.423, 0.0010465116279069768],
            [696.412, 0.0009302325581395349],
            [698.427, 0.0015116279069767441],
            [699.327, 0.0009302325581395349],
            [699.4060000000001, 0.00046511627906976747],
        ],
    },
    {
        name: "vanadium",
        lines: [
            [400.571, 0.014166666666666666],
            [402.339, 0.01],
            [403.183, 0.01],
            [403.563, 0.0125],
            [404.264, 0.01],
            [405.096, 0.03],
            [405.135, 0.03],
            [405.707, 0.023333333333333334],
            [405.78200000000004, 0.010833333333333334],
            [406.393, 0.019166666666666665],
            [407.154, 0.019166666666666665],
            [409.058, 0.09166666666666666],
            [409.241, 0.015],
            [409.269, 0.15],
            [409.35, 0.01],
            [409.549, 0.07416666666666667],
            [409.98, 0.23333333333333334],
            [410.216, 0.049166666666666664],
            [410.43999999999994, 0.019166666666666665],
            [410.47799999999995, 0.021666666666666667],
            [410.517, 0.23333333333333334],
            [410.822, 0.01],
            [410.979, 0.19166666666666668],
            [411.178, 0.7416666666666667],
            [411.233, 0.01],
            [411.35200000000003, 0.019166666666666665],
            [411.51800000000003, 0.35833333333333334],
            [411.64700000000005, 0.15],
            [411.81800000000004, 0.015],
            [411.86400000000003, 0.015],
            [411.946, 0.019166666666666665],
            [412.054, 0.015],
            [412.31899999999996, 0.015],
            [412.35699999999997, 0.16666666666666666],
            [412.407, 0.01],
            [412.80699999999996, 0.25833333333333336],
            [412.88599999999997, 0.01],
            [413.20200000000006, 0.25833333333333336],
            [413.44899999999996, 0.19166666666666668],
            [415.96899999999994, 0.0125],
            [417.401, 0.008333333333333333],
            [417.942, 0.019166666666666665],
            [418.259, 0.0125],
            [418.98400000000004, 0.015],
            [419.15600000000006, 0.015],
            [420.986, 0.019166666666666665],
            [422.662, 0.01],
            [423.246, 0.03],
            [423.29499999999996, 0.015],
            [423.4, 0.015],
            [423.576, 0.01],
            [425.73699999999997, 0.008333333333333333],
            [425.93100000000004, 0.01],
            [426.216, 0.01],
            [426.86400000000003, 0.04666666666666667],
            [427.15500000000003, 0.03833333333333333],
            [427.696, 0.03833333333333333],
            [428.40600000000006, 0.035833333333333335],
            [429.18199999999996, 0.0275],
            [429.611, 0.018333333333333333],
            [429.76800000000003, 0.014166666666666666],
            [429.803, 0.014166666666666666],
            [430.621, 0.014166666666666666],
            [430.718, 0.011666666666666667],
            [430.98, 0.014166666666666666],
            [433.00200000000007, 0.03833333333333333],
            [433.282, 0.0425],
            [434.101, 0.06333333333333334],
            [435.287, 0.08333333333333333],
            [435.49799999999993, 0.010833333333333334],
            [435.59399999999994, 0.0125],
            [436.804, 0.0125],
            [437.323, 0.011666666666666667],
            [437.53000000000003, 0.008333333333333333],
            [437.924, 1.0],
            [438.055, 0.008333333333333333],
            [438.47200000000004, 0.5833333333333334],
            [438.997, 0.4],
            [439.52299999999997, 0.3],
            [440.058, 0.11666666666666667],
            [440.66400000000004, 0.19166666666666668],
            [440.764, 0.23333333333333334],
            [440.82, 0.3],
            [440.851, 0.38333333333333336],
            [441.21400000000006, 0.011666666666666667],
            [441.64700000000005, 0.05333333333333334],
            [441.99399999999997, 0.01],
            [442.157, 0.05333333333333334],
            [442.6, 0.03833333333333333],
            [442.73100000000005, 0.01],
            [442.85200000000003, 0.025833333333333333],
            [442.98, 0.019166666666666665],
            [443.61400000000003, 0.035833333333333335],
            [443.784, 0.05333333333333334],
            [444.168, 0.06916666666666667],
            [444.421, 0.05333333333333334],
            [445.201, 0.050833333333333335],
            [445.74799999999993, 0.034166666666666665],
            [445.776, 0.01],
            [445.976, 0.08333333333333333],
            [446.029, 0.16666666666666666],
            [446.236, 0.050833333333333335],
            [446.80100000000004, 0.01],
            [446.971, 0.03166666666666667],
            [447.404, 0.01],
            [447.471, 0.016666666666666666],
            [448.889, 0.03166666666666667],
            [449.60600000000005, 0.008333333333333333],
            [450.195, 0.01],
            [452.422, 0.011666666666666667],
            [454.53900000000004, 0.03],
            [454.965, 0.008333333333333333],
            [456.071, 0.023333333333333334],
            [457.178, 0.016666666666666666],
            [457.717, 0.0425],
            [457.87299999999993, 0.011666666666666667],
            [458.03999999999996, 0.05333333333333334],
            [458.63599999999997, 0.06916666666666667],
            [459.122, 0.014166666666666666],
            [459.41099999999994, 0.10833333333333334],
            [460.61499999999995, 0.008333333333333333],
            [460.965, 0.0025],
            [461.174, 0.0020833333333333333],
            [461.97700000000003, 0.019166666666666665],
            [462.441, 0.005416666666666667],
            [462.64799999999997, 0.004166666666666667],
            [463.51800000000003, 0.008333333333333333],
            [464.00699999999995, 0.005416666666666667],
            [464.07399999999996, 0.005416666666666667],
            [464.64, 0.010833333333333334],
            [464.889, 0.0025],
            [466.61400000000003, 0.0025],
            [467.049, 0.013333333333333334],
            [468.445, 0.002],
            [468.692, 0.002916666666666667],
            [470.616, 0.004583333333333333],
            [470.657, 0.006666666666666667],
            [471.05600000000004, 0.006666666666666667],
            [471.412, 0.005416666666666667],
            [471.58900000000006, 0.002916666666666667],
            [471.76899999999995, 0.004583333333333333],
            [472.151, 0.0033333333333333335],
            [472.28599999999994, 0.0033333333333333335],
            [472.953, 0.0033333333333333335],
            [473.038, 0.00225],
            [474.26300000000003, 0.00225],
            [474.663, 0.002],
            [474.85200000000003, 0.0033333333333333335],
            [475.09799999999996, 0.00375],
            [475.15600000000006, 0.002916666666666667],
            [475.39300000000003, 0.0033333333333333335],
            [475.74799999999993, 0.005416666666666667],
            [476.663, 0.004583333333333333],
            [477.63599999999997, 0.010833333333333334],
            [478.651, 0.009166666666666667],
            [479.692, 0.010833333333333334],
            [479.97700000000003, 0.0015833333333333333],
            [480.753, 0.010833333333333334],
            [482.745, 0.010833333333333334],
            [483.16400000000004, 0.0125],
            [483.24300000000005, 0.01],
            [483.302, 0.0015833333333333333],
            [484.88100000000003, 0.0015833333333333333],
            [485.14799999999997, 0.02666666666666667],
            [486.26099999999997, 0.002916666666666667],
            [486.474, 0.04],
            [487.12600000000003, 0.00175],
            [487.54799999999994, 0.051666666666666666],
            [488.05600000000004, 0.004583333333333333],
            [488.15600000000006, 0.06166666666666667],
            [489.16, 0.00225],
            [489.421, 0.00175],
            [490.062, 0.004583333333333333],
            [490.429, 0.007916666666666667],
            [492.56499999999994, 0.007083333333333333],
            [493.203, 0.002916666666666667],
            [496.61199999999997, 0.0019166666666666666],
            [500.233, 0.005833333333333334],
            [501.462, 0.007083333333333333],
            [505.163, 0.0023333333333333335],
            [506.412, 0.002916666666666667],
            [510.514, 0.002916666666666667],
            [512.853, 0.009166666666666667],
            [513.842, 0.009166666666666667],
            [513.953, 0.0020833333333333333],
            [514.8720000000001, 0.005833333333333334],
            [515.9350000000001, 0.0033333333333333335],
            [516.9939999999999, 0.0019166666666666666],
            [517.677, 0.005833333333333334],
            [519.201, 0.0016666666666666668],
            [519.299, 0.009166666666666667],
            [519.362, 0.0019166666666666666],
            [519.483, 0.009166666666666667],
            [519.536, 0.004583333333333333],
            [520.661, 0.0016666666666666668],
            [521.659, 0.0033333333333333335],
            [522.577, 0.002916666666666667],
            [523.375, 0.002916666666666667],
            [523.4069999999999, 0.009166666666666667],
            [524.02, 0.0016666666666666668],
            [524.087, 0.009166666666666667],
            [526.098, 0.0014166666666666668],
            [535.341, 0.0033333333333333335],
            [538.3430000000001, 0.002916666666666667],
            [538.514, 0.0033333333333333335],
            [538.83, 0.0011666666666666668],
            [539.787, 0.0009166666666666666],
            [540.193, 0.008333333333333333],
            [541.5260000000001, 0.011666666666666667],
            [541.809, 0.0023333333333333335],
            [542.408, 0.004166666666666667],
            [543.418, 0.02],
            [543.766, 0.0009166666666666666],
            [545.812, 0.0014166666666666668],
            [547.133, 0.0010833333333333333],
            [548.722, 0.0020833333333333333],
            [548.792, 0.007083333333333333],
            [548.9939999999999, 0.0020833333333333333],
            [550.487, 0.0023333333333333335],
            [550.775, 0.005833333333333334],
            [551.118, 0.0011666666666666668],
            [554.5930000000001, 0.0019166666666666666],
            [554.707, 0.005833333333333334],
            [555.875, 0.002916666666666667],
            [556.1659999999999, 0.0023333333333333335],
            [558.45, 0.011666666666666667],
            [558.6, 0.0019166666666666666],
            [559.242, 0.008333333333333333],
            [560.138, 0.0023333333333333335],
            [560.4939999999999, 0.005833333333333334],
            [562.42, 0.0010833333333333333],
            [562.46, 0.016666666666666666],
            [562.489, 0.005833333333333334],
            [562.601, 0.004583333333333333],
            [562.764, 0.03333333333333333],
            [563.246, 0.0010833333333333333],
            [563.39, 0.0008333333333333334],
            [563.551, 0.0010833333333333333],
            [564.611, 0.007083333333333333],
            [565.7439999999999, 0.009166666666666667],
            [566.836, 0.009166666666666667],
            [567.085, 0.025833333333333333],
            [568.322, 0.0016666666666666668],
            [569.8520000000001, 0.1],
            [570.356, 0.07666666666666666],
            [570.698, 0.0475],
            [570.895, 0.0009166666666666666],
            [571.621, 0.0009166666666666666],
            [572.5640000000001, 0.005833333333333334],
            [572.703, 0.07083333333333333],
            [572.766, 0.014166666666666666],
            [573.125, 0.019166666666666665],
            [573.4010000000001, 0.0033333333333333335],
            [573.706, 0.019166666666666665],
            [574.345, 0.009166666666666667],
            [574.77, 0.0014166666666666668],
            [574.887, 0.0033333333333333335],
            [575.274, 0.0014166666666666668],
            [576.141, 0.018083333333333333],
            [577.242, 0.005833333333333334],
            [577.664, 0.002916666666666667],
            [578.261, 0.0009166666666666666],
            [578.35, 0.0009166666666666666],
            [578.438, 0.0033333333333333335],
            [578.616, 0.004583333333333333],
            [578.856, 0.0019166666666666666],
            [580.714, 0.002916666666666667],
            [581.706, 0.0019166666666666666],
            [581.7529999999999, 0.002916666666666667],
            [583.072, 0.004583333333333333],
            [584.63, 0.007083333333333333],
            [585.0319999999999, 0.0009166666666666666],
            [592.457, 0.0033333333333333335],
            [597.891, 0.0023333333333333335],
            [598.078, 0.0016666666666666668],
            [600.231, 0.0023333333333333335],
            [600.263, 0.004583333333333333],
            [601.612, 0.0023333333333333335],
            [602.5409999999999, 0.0016666666666666668],
            [603.973, 0.0375],
            [605.8140000000001, 0.008333333333333333],
            [606.726, 0.0016666666666666668],
            [608.144, 0.04],
            [609.022, 0.10833333333333334],
            [610.698, 0.0023333333333333335],
            [611.167, 0.023333333333333334],
            [611.952, 0.05],
            [612.8340000000001, 0.0016666666666666668],
            [613.538, 0.023333333333333334],
            [615.015, 0.015],
            [617.036, 0.007083333333333333],
            [618.9350000000001, 0.0019166666666666666],
            [619.919, 0.0375],
            [621.387, 0.010833333333333334],
            [621.637, 0.0375],
            [621.831, 0.0023333333333333335],
            [622.45, 0.010833333333333334],
            [623.074, 0.035833333333333335],
            [623.3199999999999, 0.008333333333333333],
            [624.013, 0.004583333333333333],
            [624.2810000000001, 0.014166666666666666],
            [624.3100000000001, 0.059166666666666666],
            [625.182, 0.023333333333333334],
            [625.6899999999999, 0.007083333333333333],
            [625.857, 0.007083333333333333],
            [626.1220000000001, 0.004583333333333333],
            [626.632, 0.007083333333333333],
            [626.882, 0.010833333333333334],
            [627.4649999999999, 0.014166666666666666],
            [628.233, 0.0014166666666666668],
            [628.516, 0.016666666666666666],
            [629.283, 0.016666666666666666],
            [629.649, 0.014166666666666666],
            [631.15, 0.0023333333333333335],
            [632.466, 0.0011666666666666668],
            [632.684, 0.005833333333333334],
            [633.909, 0.004583333333333333],
            [634.948, 0.004166666666666667],
            [635.558, 0.0011666666666666668],
            [635.73, 0.004166666666666667],
            [635.882, 0.0020833333333333333],
            [636.1270000000001, 0.002916666666666667],
            [637.9359999999999, 0.0019166666666666666],
            [639.328, 0.0011666666666666668],
            [643.047, 0.002916666666666667],
            [643.163, 0.0019166666666666666],
            [643.318, 0.0011666666666666668],
            [643.516, 0.0009166666666666666],
            [645.234, 0.005833333333333334],
            [648.8050000000001, 0.0009166666666666666],
            [650.417, 0.004583333333333333],
            [653.143, 0.009166666666666667],
            [654.351, 0.0023333333333333335],
            [655.802, 0.0014166666666666668],
            [656.588, 0.0009166666666666666],
            [660.597, 0.004166666666666667],
            [660.783, 0.00125],
            [662.354, 0.0008333333333333334],
            [662.485, 0.004166666666666667],
            [663.326, 0.0010833333333333333],
            [664.379, 0.0010833333333333333],
            [669.366, 0.0006666666666666666],
            [670.807, 0.0006666666666666666],
            [675.3, 0.005416666666666667],
            [676.012, 0.0008333333333333334],
            [676.649, 0.004166666666666667],
            [678.4979999999999, 0.0033333333333333335],
            [678.632, 0.00125],
            [681.24, 0.0021666666666666666],
            [682.9939999999999, 0.00075],
            [683.2439999999999, 0.00125],
            [683.958, 0.001],
            [684.1899999999999, 0.001],
            [687.088, 0.0008333333333333334],
            [687.1560000000001, 0.0006666666666666666],
            [689.4, 0.0005833333333333334],
            [697.45, 0.001],
        ],
    },
    {
        name: "yittrium",
        lines: [
            [404.764, 0.2],
            [407.738, 0.7833333333333333],
            [408.12199999999996, 0.0075],
            [408.371, 0.16666666666666666],
            [410.238, 0.825],
            [410.639, 0.005],
            [411.081, 0.006666666666666667],
            [412.492, 0.02666666666666667],
            [412.831, 0.7416666666666667],
            [414.285, 0.625],
            [415.76300000000003, 0.008333333333333333],
            [416.75200000000007, 0.2],
            [417.41400000000004, 0.16666666666666666],
            [417.754, 0.6666666666666666],
            [419.928, 0.01],
            [420.46999999999997, 0.03166666666666667],
            [421.302, 0.006666666666666667],
            [421.354, 0.0033333333333333335],
            [421.78000000000003, 0.013333333333333334],
            [422.063, 0.023333333333333334],
            [422.425, 0.006666666666666667],
            [423.573, 0.05],
            [423.59399999999994, 0.18333333333333332],
            [425.12, 0.025],
            [430.23, 0.03],
            [430.963, 0.23333333333333334],
            [431.63, 0.004166666666666667],
            [433.078, 0.009166666666666667],
            [433.729, 0.0025],
            [434.465, 0.005],
            [434.879, 0.03666666666666667],
            [435.233, 0.005],
            [435.27, 0.005],
            [435.77299999999997, 0.01],
            [435.87299999999993, 0.06666666666666667],
            [436.60299999999995, 0.01],
            [437.49399999999997, 1.0],
            [437.561, 0.0125],
            [437.933, 0.006666666666666667],
            [438.54799999999994, 0.0025],
            [438.774, 0.008333333333333333],
            [439.401, 0.0025],
            [439.467, 0.0025],
            [439.802, 0.15],
            [442.259, 0.07416666666666667],
            [443.73400000000004, 0.006666666666666667],
            [444.366, 0.008333333333333333],
            [444.663, 0.010833333333333334],
            [446.52700000000004, 0.0016666666666666668],
            [447.389, 0.0033333333333333335],
            [447.572, 0.014166666666666666],
            [447.696, 0.015],
            [447.745, 0.013333333333333334],
            [448.72799999999995, 0.009166666666666667],
            [448.747, 0.025],
            [449.175, 0.0025],
            [449.242, 0.0020833333333333333],
            [450.59499999999997, 0.041666666666666664],
            [451.358, 0.004166666666666667],
            [451.401, 0.006666666666666667],
            [452.20500000000004, 0.0033333333333333335],
            [452.725, 0.07416666666666667],
            [452.78000000000003, 0.03666666666666667],
            [454.43199999999996, 0.008333333333333333],
            [455.937, 0.008333333333333333],
            [456.439, 0.0025],
            [457.35600000000005, 0.005],
            [458.13199999999995, 0.002916666666666667],
            [458.177, 0.0025],
            [459.65500000000003, 0.010833333333333334],
            [460.48, 0.007916666666666667],
            [461.3, 0.0033333333333333335],
            [464.37, 0.16666666666666666],
            [465.832, 0.016666666666666666],
            [465.889, 0.005833333333333334],
            [466.747, 0.007083333333333333],
            [467.082, 0.005],
            [467.48400000000004, 0.16666666666666666],
            [467.83500000000004, 0.005],
            [468.23199999999997, 0.021666666666666667],
            [468.97700000000003, 0.007083333333333333],
            [469.68100000000004, 0.015],
            [470.88500000000005, 0.002916666666666667],
            [472.58500000000004, 0.005],
            [472.85299999999995, 0.014166666666666666],
            [473.23699999999997, 0.005],
            [474.14, 0.007083333333333333],
            [475.279, 0.013333333333333334],
            [476.09799999999996, 0.034166666666666665],
            [478.01800000000003, 0.0014166666666666668],
            [478.104, 0.01],
            [478.658, 0.013333333333333334],
            [478.689, 0.014166666666666666],
            [479.93, 0.015],
            [480.43100000000004, 0.004166666666666667],
            [480.48100000000005, 0.005833333333333334],
            [481.738, 0.007083333333333333],
            [481.82, 0.011666666666666667],
            [481.96400000000006, 0.011666666666666667],
            [482.213, 0.01],
            [482.331, 0.015833333333333335],
            [483.91499999999996, 0.005],
            [483.98699999999997, 0.06416666666666666],
            [484.56800000000004, 0.04583333333333333],
            [485.26899999999995, 0.034166666666666665],
            [485.425, 0.01],
            [485.48699999999997, 0.07416666666666667],
            [485.66999999999996, 0.004166666666666667],
            [485.98400000000004, 0.0275],
            [487.965, 0.004166666666666667],
            [488.36899999999997, 0.15833333333333333],
            [488.628, 0.004166666666666667],
            [488.66499999999996, 0.0033333333333333335],
            [489.34399999999994, 0.007916666666666667],
            [490.012, 0.09166666666666666],
            [490.611, 0.008333333333333333],
            [490.9, 0.00375],
            [492.187, 0.0125],
            [493.093, 0.002916666666666667],
            [495.066, 0.00375],
            [497.43, 0.01],
            [498.213, 0.01],
            [500.697, 0.008333333333333333],
            [507.021, 0.00625],
            [507.21899999999994, 0.00625],
            [508.742, 0.09166666666666666],
            [508.81800000000004, 0.0025],
            [511.91099999999994, 0.0175],
            [512.321, 0.0375],
            [513.52, 0.015],
            [519.643, 0.01],
            [520.0409999999999, 0.08],
            [520.572, 0.125],
            [524.081, 0.015],
            [528.982, 0.005],
            [532.078, 0.00375],
            [538.062, 0.00625],
            [540.278, 0.018333333333333333],
            [541.703, 0.002],
            [542.437, 0.0075],
            [543.824, 0.015833333333333335],
            [546.646, 0.059166666666666666],
            [546.847, 0.008333333333333333],
            [547.339, 0.0075],
            [548.074, 0.0075],
            [549.317, 0.005],
            [549.559, 0.002916666666666667],
            [549.741, 0.02],
            [550.345, 0.025],
            [550.99, 0.020833333333333332],
            [551.364, 0.005],
            [552.163, 0.01],
            [552.17, 0.0],
            [552.676, 0.002],
            [552.754, 0.06166666666666667],
            [554.163, 0.002916666666666667],
            [554.45, 0.01],
            [554.461, 0.0],
            [554.6020000000001, 0.0075],
            [555.643, 0.00625],
            [556.775, 0.005],
            [557.742, 0.015],
            [558.108, 0.002],
            [558.187, 0.051666666666666666],
            [559.096, 0.00175],
            [559.412, 0.00175],
            [560.633, 0.01],
            [562.391, 0.00125],
            [563.013, 0.04666666666666667],
            [563.225, 0.002],
            [563.289, 0.00175],
            [564.4689999999999, 0.01],
            [564.847, 0.01],
            [566.294, 0.06166666666666667],
            [567.527, 0.0075],
            [569.363, 0.0015],
            [570.673, 0.013333333333333334],
            [572.0609999999999, 0.002],
            [572.889, 0.00625],
            [573.012, 0.0125],
            [573.2090000000001, 0.00175],
            [574.385, 0.0075],
            [574.693, 0.0015],
            [576.422, 0.002],
            [576.5640000000001, 0.00625],
            [577.395, 0.002916666666666667],
            [578.169, 0.008333333333333333],
            [580.0, 0.00125],
            [581.858, 0.00125],
            [582.187, 0.0025],
            [583.2270000000001, 0.00175],
            [583.807, 0.00075],
            [585.883, 0.00125],
            [587.183, 0.00125],
            [587.614, 0.002],
            [587.996, 0.002],
            [589.394, 0.002],
            [590.296, 0.002916666666666667],
            [591.2189999999999, 0.002],
            [593.11, 0.002],
            [593.908, 0.0075],
            [594.572, 0.00375],
            [595.0020000000001, 0.002],
            [595.641, 0.00625],
            [597.204, 0.10833333333333334],
            [598.1859999999999, 0.004166666666666667],
            [598.764, 0.08333333333333333],
            [600.36, 0.06166666666666667],
            [600.4649999999999, 0.01],
            [600.919, 0.01],
            [601.987, 0.051666666666666666],
            [602.341, 0.01],
            [603.6600000000001, 0.041666666666666664],
            [605.3810000000001, 0.035],
            [607.278, 0.010833333333333334],
            [608.8, 0.004166666666666667],
            [608.9350000000001, 0.0175],
            [609.678, 0.013333333333333334],
            [610.7819999999999, 0.010833333333333334],
            [611.473, 0.010833333333333334],
            [612.738, 0.00625],
            [613.206, 0.11666666666666667],
            [613.504, 0.01],
            [613.8430000000001, 0.0125],
            [614.836, 0.09166666666666666],
            [615.172, 0.01],
            [616.508, 0.06833333333333333],
            [618.223, 0.04666666666666667],
            [619.173, 0.1],
            [619.982, 0.049166666666666664],
            [621.796, 0.0375],
            [622.259, 0.025],
            [623.672, 0.0225],
            [625.105, 0.00375],
            [627.501, 0.01],
            [629.546, 0.005],
            [631.62, 0.002],
            [633.8100000000001, 0.002],
            [635.948, 0.00125],
            [636.987, 0.00125],
            [640.201, 0.00625],
            [643.5, 0.08333333333333333],
            [643.7180000000001, 0.002],
            [650.1229999999999, 0.0015],
            [651.833, 0.0015],
            [653.5840000000001, 0.0015],
            [653.86, 0.0075],
            [655.384, 0.001],
            [655.739, 0.005833333333333334],
            [657.258, 0.001],
            [657.6850000000001, 0.002916666666666667],
            [658.487, 0.0019166666666666666],
            [661.375, 0.007916666666666667],
            [662.249, 0.0011666666666666668],
            [663.649, 0.0015833333333333333],
            [665.0609999999999, 0.0033333333333333335],
            [666.4399999999999, 0.00175],
            [668.758, 0.0125],
            [669.183, 0.0011666666666666668],
            [669.475, 0.0005833333333333334],
            [669.926, 0.0013333333333333333],
            [670.071, 0.005833333333333334],
            [671.3199999999999, 0.002916666666666667],
            [673.5989999999999, 0.0033333333333333335],
            [679.371, 0.015833333333333335],
            [679.5409999999999, 0.005833333333333334],
            [680.3149999999999, 0.001],
            [681.516, 0.00175],
            [683.249, 0.0011666666666666668],
            [684.524, 0.00375],
            [685.824, 0.0011666666666666668],
            [688.722, 0.002416666666666667],
            [689.6, 0.00175],
            [690.826, 0.00075],
            [693.3520000000001, 0.0011666666666666668],
            [695.0310000000001, 0.002],
            [695.168, 0.0008333333333333334],
            [695.804, 0.0008333333333333334],
            [697.988, 0.002],
        ],
    },
    {
        name: "tin",
        lines: [
            [429.465, 0.002],
            [452.474, 0.08],
            [457.913, 0.002],
            [458.029, 0.002],
            [487.72200000000004, 0.004],
            [494.43100000000004, 0.006],
            [497.97299999999996, 0.04],
            [507.11400000000003, 0.004],
            [507.267, 0.004],
            [517.454, 0.04],
            [533.236, 0.02],
            [556.1949999999999, 0.04],
            [558.892, 0.05],
            [559.62, 0.004],
            [563.171, 1.0],
            [575.359, 0.03],
            [579.72, 0.002],
            [579.918, 0.03],
            [592.544, 0.1],
            [597.03, 0.2],
            [603.77, 0.3],
            [605.486, 0.4],
            [606.9, 0.5],
            [607.346, 0.2],
            [607.7479999999999, 0.012],
            [607.97, 0.01],
            [614.971, 0.8],
            [615.46, 0.4],
            [617.15, 0.3],
            [631.078, 0.2],
            [635.4350000000001, 0.08],
            [645.35, 0.14],
            [676.145, 0.016],
            [684.405, 0.05]
        ]
    }
];

const elementSymbols = {
    hydrogen: "H",
    helium: "E",
    lithium: "I",
    beryllium: "E",
    boron: "B",
    carbon: "C",
    nitrogen: "N",
    oxygen: "O",
    fluorine: "F",
    neon: "E",
    sodium: "A",
    magnesium: "G",
    aluminium: "L",
    silicon: "I",
    phosphorus: "P",
    sulfur: "S",
    chlorine: "L",
    argon: "R",
    potassium: "K",
    calcium: "A",
    scandium: "C",
    titanium: "I",
    vanadium: "V",
    chromium: "R",
    manganese: "N",
    iron: "E",
    cobalt: "O",
    nickel: "I",
    copper: "U",
    zinc: "N",
    gallium: "A",
    germanium: "E",
    arsenic: "S",
    selenium: "E",
    bromine: "R",
    krypton: "R",
    rubidium: "B",
    strontium: "R",
    yttrium: "Y",
    zirconium: "R",
    niobium: "B",
    molybdenum: "O",
    technetium: "C",
    ruthenium: "U",
    rhodium: "H",
    palladium: "D",
    silver: "G",
    cadmium: "D",
    indium: "N",
    tin: "N",
    antimony: "B",
    tellurium: "E",
    iodine: "I",
    xenon: "E",
    caesium: "S",
    barium: "A",
    lanthanum: "A",
    cerium: "E",
    praseodymium: "R",
    neodymium: "D",
    promethium: "M",
    samarium: "M",
    europium: "U",
    gadolinium: "D",
    terbium: "B",
    dysprosium: "Y",
    holmium: "O",
    erbium: "R",
    thulium: "M",
    ytterbium: "B",
    lutetium: "U",
    hafnium: "F",
    tantalum: "A",
    tungsten: "W",
    rhenium: "E",
    osmium: "S",
    iridium: "R",
    platinum: "T",
    gold: "U",
    mercury: "G",
    thallium: "L",
    lead: "B",
    bismuth: "I",
    polonium: "O",
    astatine: "T",
    radon: "N",
    francium: "R",
    radium: "A",
    actinium: "C",
    thorium: "H",
    protactinium: "A",
    uranium: "U",
    neptunium: "P",
    plutonium: "U",
    americium: "M",
    curium: "M",
    berkelium: "K",
    californium: "F",
    einsteinium: "S",
    fermium: "M",
    mendelevium: "D",
    nobelium: "O",
    lawrencium: "R",
    rutherfordium: "F",
    dubnium: "B",
    seaborgium: "G",
    bohrium: "H",
    hassium: "S",
    meitnerium: "T",
    darmstadtium: "S",
    roentgenium: "G",
    copernicium: "N",
    nihonium: "H",
    flerovium: "L",
    moscovium: "C",
    livermorium: "V",
    tennessine: "S",
    oganesson: "G"
};

const fraunhoferLines = [
    898.765, // y O2
    822.696, // Z O2
    759.370, // A O2
    686.719, // B O2
    656.281, // C Hα
    627.661, // a O2
    589.592, // D1 Na
    588.995, // D2 Na
    587.5618, // D3 (or d) He
    546.073, // e Hg
    527.039, // E2 Fe
    518.362, // b1 Mg
    517.270, // b2 Mg
    516.891, // b3 Fe
    516.733, // b4 Mg
    495.761, // c Fe
    486.134, // F Hβ
    466.814, // d Fe
    438.355, // e Fe
    434.047, // G' Hγ
    430.790, // G Fe
    430.774, // G Ca
    410.175, // h Hδ
    396.847, // H Ca+
    393.366, // K Ca+
    382.044, // L Fe
    358.121, // N Fe
    336.112, // P Ti+
    302.108, // T Fe
    299.444  // t Ni
];

function logAndReturn(elementName) {
    console.error(elementName);
    return "Unknown";
}

function getElementSymbol(elementName) {
    
    return elementSymbols[elementName.toLowerCase()] || logAndReturn(elementName);
}

export { elementSpectra, getElementSymbol, fraunhoferLines };