import * as Dice from './Dice/dice';
import * as Maglev from './Maglev/maglev';
import * as Pixelize from './Pixelize/pixelize';
import * as Qube from './Qube/qube';
import * as Sand from './Sand/sand';
import * as Sprimal from './Sprimal/sprimal';

window.Animations = {
    Dice,
    Maglev,
    Pixelize,
    Qube,
    Sand,
    Sprimal
};

//window.addEventListener('load', () => {
//    if ('serviceWorker' in navigator) {
//        const swFile = process.env.ENVIRONMENT === 'production' ? 'service-worker.prod.js' : 'service-worker.dev.js';
//        navigator.serviceWorker.register(swFile);
//    }
//});