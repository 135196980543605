function createBlobUrl(data, mimeType, filename) {
    var blob = new Blob([data], { type: mimeType });
    var url = URL.createObjectURL(blob);
    return url + '#' + encodeURIComponent(filename); // Append filename as fragment
};

function revokeBlobUrl(url) {
    URL.revokeObjectURL(url);
};

function getDimensions() {
    return {
        width: window.innerWidth,
        height: window.innerHeight
    };
};

function loadImageAsBase64(imagePath) {
    return fetch(imagePath)
        .then(response => response.blob())
        .then(blob => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        });
}

function getElementSize(elementId) {
    var element = document.getElementById(elementId);
    if (element != null) {
        return {
            width: element.offsetWidth,
            height: element.offsetHeight
        };
    }
}

export { createBlobUrl, revokeBlobUrl, getDimensions, loadImageAsBase64, getElementSize };