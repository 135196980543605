function getElementSize(elementId) {
    var element = document.getElementById(elementId);
    if (element != null) {
        return {
            width: element.offsetWidth,
            height: element.offsetHeight
        };
    }
};

function loadEmbeddedCSV(filePath) {
    return fetch(filePath)
        .then(response => response.text());
}

function loadBinaryFile(filePath) {
    return fetch(filePath)
        .then(response => response.arrayBuffer())
        .then(buffer => {
            return new Uint8Array(buffer);
        });
}

function loadBitmapFile(filePath) {
    return fetch(filePath)
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.blob();
        })
        .then(blob => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    let base64String = reader.result;
                    base64String = base64String.replace(/^data:.+;base64,/, ''); // Remove the prefix
                    resolve(base64String);
                };
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        });
}

function createBlobUrl(data, mimeType, filename) {
    var blob = new Blob([data], { type: mimeType });
    var url = URL.createObjectURL(blob);
    return url + '#' + encodeURIComponent(filename); // Append filename as fragment
};

function revokeBlobUrl(url) {
    URL.revokeObjectURL(url);
};

export { getElementSize, loadEmbeddedCSV as loadBinaryFile, loadBitmapFile, createBlobUrl, revokeBlobUrl };