import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

let scene, camera, renderer, controls;
let particles = [];
let particleSize = 1;

let selectedGenerator = null;
const raycaster = new THREE.Raycaster();
const mouse = new THREE.Vector2();

let axesHelper = null;

function createAxesHelper(generatorMesh) {
    if (axesHelper) {
        scene.remove(axesHelper);
    }

    axesHelper = new THREE.AxesHelper(particleSize * 5);
    axesHelper.position.copy(generatorMesh.position);
    scene.add(axesHelper);
}

function removeAxesHelper() {
    if (axesHelper) {
        scene.remove(axesHelper);
        axesHelper = null;
    }
}

function highlightGeneratorInUI(generatorId) {
    document.querySelectorAll('#generator-controls > div').forEach(div => div.style.backgroundColor = '#eef');

    const element = document.getElementById(generatorId);
    if (element) element.style.backgroundColor = '#efe';
}

function selectGenerator(generatorMesh) {
    if (selectedGenerator) { removeAxesHelper(); }
    selectedGenerator = generatorMesh;
    createAxesHelper(generatorMesh);
    highlightGeneratorInUI(generatorMesh.userData.generatorId);
}

function onMouseClick(event) {
    event.preventDefault();

    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

    raycaster.setFromCamera(mouse, camera);

    const intersects = raycaster.intersectObjects(scene.children);

    if (intersects.length > 0) {
        for (let i = 0; i < intersects.length; i++) {
            const intersected = intersects[i].object;
            if (intersected.userData.isGenerator) {
                selectGenerator(intersected);
                return;
            }
        }
    }
}

function selectGeneratorById(generatorId) {
    const generator = particles.find(p => p.id === generatorId);
    if (generator) selectGenerator(generator.mesh);
}

function updateGeneratorPosition(generatorId, x, y, z) {
    const generator = particles.find(p => p.id === generatorId);
    if (generator) {
        const playCubeSize = particleSize * 10;
        const scaledX = x * particleSize - playCubeSize / 2 + particleSize / 2;
        const scaledY = y * particleSize - playCubeSize / 2 + particleSize / 2;
        const scaledZ = z * particleSize - playCubeSize / 2 + particleSize / 2;

        generator.mesh.position.set(scaledX, scaledY, scaledZ);

        // Update axesHelper if this generator is currently selected
        if (selectedGenerator && selectedGenerator.userData.generatorId === generatorId) {
            axesHelper.position.set(scaledX, scaledY, scaledZ);
        }
    }
}

function updateGeneratorColor(generatorId, colorHex) {
    const generator = particles.find(p => p.id === generatorId);
    if (generator) {
        generator.mesh.material.color.setHex(colorHex);
    }
}

function initialize3DSceneForSand(playCubeSize) {
    window.addEventListener('click', onMouseClick, false);

    const canvas = document.getElementById('canvas3d');
    const container = document.getElementById('canvas-container');

    scene = new THREE.Scene();

    camera = new THREE.PerspectiveCamera(75, container.clientWidth / container.clientHeight, 0.1, playCubeSize * 10);
    camera.position.set(playCubeSize / 2, playCubeSize / 2, playCubeSize * 2);
    camera.lookAt(playCubeSize / 2, playCubeSize / 2, 0);

    renderer = new THREE.WebGLRenderer({ canvas: canvas });
    resizeCanvasToContainer(container);

    const boxGeometry = new THREE.BoxGeometry(playCubeSize, playCubeSize, playCubeSize);
    const boxEdges = new THREE.EdgesGeometry(boxGeometry);
    const boxMaterial = new THREE.LineBasicMaterial({ color: 0xffffff });
    const box = new THREE.LineSegments(boxEdges, boxMaterial);
    scene.add(box);

    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    scene.add(ambientLight);

    controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.25;
    controls.screenSpacePanning = true;
    controls.minDistance = 0.1;
    controls.maxDistance = playCubeSize * 4;
    controls.maxPolarAngle = Math.PI;

    animate();

    adjustCanvasHeight();
    window.addEventListener('resize', () => resizeCanvasToContainer(container));
}

function adjustCanvasHeight() {
    var controls = document.getElementById('controls-container');
    if (controls != null) {
        var topRow = document.querySelector('.top-row');
        var canvasContainer = document.getElementById('canvas-container');
        if (topRow != null && canvasContainer != null) {
            var availableHeight = window.innerHeight - controls.offsetHeight - topRow.offsetHeight;
            canvasContainer.style.height = `${availableHeight}px`;
            resizeCanvasToContainer(canvasContainer);
        } else {
            console.warn('Missing elements in adjustCanvasHeight: topRow or canvasContainer is null.');
        }
    } else {
        console.warn('Missing element: controls-container is null.');
    }
}

function resizeCanvasToContainer(container) {
    renderer.setSize(container.clientWidth, container.clientHeight);
    camera.aspect = container.clientWidth / container.clientHeight;
    camera.updateProjectionMatrix();
}

function animate() {
    requestAnimationFrame(animate);

    controls.update();
    renderer.render(scene, camera);
}

function update3DSceneForSand(playCubeSize, particlesData) {
    //console.log('Received particles data:', particlesData);

    const updatedParticleIds = new Set();

    particlesData.forEach(({ id, x, y, z, color }) => {
        const scaledX = x * particleSize - playCubeSize / 2 + particleSize / 2;
        const scaledY = y * particleSize - playCubeSize / 2 + particleSize / 2;
        const scaledZ = z * particleSize - playCubeSize / 2 + particleSize / 2;

        let particle = particles.find(p => p.id === id);

        if (particle) {
            particle.mesh.position.set(scaledX, scaledY, scaledZ);
        } else {
            const isGenerator = id.startsWith("generator");
            const geometry = new THREE.BoxGeometry(particleSize, particleSize, particleSize);
            const material = new THREE.MeshBasicMaterial({ color: color });
            if (isGenerator) material.wireframe = true;
            const mesh = new THREE.Mesh(geometry, material);
            mesh.position.set(scaledX, scaledY, scaledZ);
            mesh.userData.isGenerator = isGenerator;
            if (isGenerator) mesh.userData.generatorId = id;
            scene.add(mesh);
            particles.push({ mesh: mesh, id: id });
        }

        updatedParticleIds.add(id);
    });

    particles = particles.filter(p => {
        if (!updatedParticleIds.has(p.id)) {
            scene.remove(p.mesh);
            return false;
        }
        return true;
    });
}

export { initialize3DSceneForSand, update3DSceneForSand, adjustCanvasHeight, updateGeneratorColor, selectGeneratorById, updateGeneratorPosition };